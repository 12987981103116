import { FetchData, PostQuery } from "@hooks/api/useApi";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import Group from "../../../../components/Master/Groups/Group";

function Index() {
  document.title = "Groups | WOW Awards 2023";

  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const updateActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
        updateToggle();
      }
    },
  };
  const addActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        validation.resetForm();
        formik.resetForm();
        refetch();
        toggle();
      }
    },
  };

  const deleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setDeleteModal(false);
        refetch();
      } else {
        setDeleteModal(false);
        setShowErrorAlert(false);
        setShowErrorMsg(data.data.errors[0]);
      }
    },
  };
  const {
    data: groupList,
    refetch,
    isFetching,
    isLoading,
    isFetched,
  } = FetchData("groups", "/Admin/get-all-group", actions);

  const {
    mutate: updateGroup,
    isLoading: isLoadingUpdateGroup,
    isFetching: isFetchingUpdateGroup,
  } = PostQuery("/Admin/upsert-group", "post", updateActions);
  const {
    mutate: addGroup,
    isLoading: isLoadingAddGroup,
    isFetching: isFetchingAddGroup,
  } = PostQuery("/Admin/upsert-group", "post", addActions);
  const { mutate: deleteGroup } = PostQuery(
    "/Admin/delete-group",
    "post",
    deleteActions
  );

  // validation
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status: true,
      title: "",
      desc: "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Group name"),
      desc: Yup.string().required("Please Write Description"),
    }),

    onSubmit: (values) => {
      let data = {
        Group_Id: modalData.group_id,
        Group_Name: values.title,
        Group_Detail: values.desc,
        IsActive: JSON.parse(values.status),
      };
      updateGroup(data);
    },
  });
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status: true,
      title: "",
      desc: "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Group name"),
      desc: Yup.string().required("Please Write Description"),
    }),

    onSubmit: (values) => {
      let data = {
        Group_Name: values.title,
        Group_Detail: values.desc,
        IsActive: JSON.parse(values.status),
      };
      addGroup(data);
    },
  });

  const toggleViewModal = () => setModal1(!modal1);

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const updateToggle = () => {
    if (updateModal) {
      setUpdateModal(false);
    } else {
      setUpdateModal(true);
    }
  };

  const handleUpdateGroup = (arg) => {
    setModalData(arg);
    formik.setValues({
      status: arg.is_active === "Draft" ? false : true,
      title: arg.group_name,
      desc: arg.group_detail,
    });
    updateToggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setModalData(order);
    setDeleteModal(true);
  };

  const handleDeleteGroup = () => {
    const data = {
      Group_Id: modalData.group_id,
    };
    deleteGroup(data);
  };
  const handleOrderClicks = () => {
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Date",
        accessor: "create_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <>{moment(cellProps.value).format("DD/MM/YYYY")}</>;
        },
      },
      {
        Header: "Group Name",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Group Description",
        accessor: "group_detail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Status",
        accessor: "is_active",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.value === "Published" ? "success" : "danger")
              }
              pill
            >
              {cellProps.value === "Published" ? "Published" : "Draft"}
            </Badge>
          );
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleUpdateGroup(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (!showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(!showErrorAlert);
        setShowErrorMsg("");
      }, 3000);
    }
  }, [showErrorAlert]);

  return (
    <React.Fragment>
      <Group
        showErrorAlert={showErrorAlert}
        modal1={modal1}
        modalData={modalData}
        toggleViewModal={toggleViewModal}
        deleteModal={deleteModal}
        handleDeleteGroup={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
        showErrorMsg={showErrorMsg}
        isFetching={isFetching}
        isLoading={isLoading}
        groupList={groupList}
        columns={columns}
        isFetched={isFetched}
        modal={modal}
        toggle={toggle}
        validation={validation}
        formik={formik}
        isLoadingAddGroup={isLoadingAddGroup}
        updateModal={updateModal}
        updateToggle={updateToggle}
        isLoadingUpdateGroup={isLoadingUpdateGroup}
        onClick={() => handleOrderClicks()}
      />
    </React.Fragment>
  );
}

export default Index;
