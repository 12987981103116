import React from "react";
import { Col, Container, Row } from "reactstrap";
import successImg from "../../assets/images/Personal goals-bro.svg";

const FormSuccess = () => {
  return (
    <>
      <div className="my-5 pt-sm-2">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center">
                {/* <NavLink to="/dashboard" className="d-block auth-logo">
                  <img src={successImg} alt="logo" height="300" />
                </NavLink> */}
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        // src={maintanence}
                        src={successImg}
                        alt="logo"
                        // height="900"
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">
                  Registeration form submitted Successfully!
                </h4>
                <p className="text-muted">We"ll get back to you shortly!</p>

                <Row className="justify-content-center mt-5">
                  <Col md="8">
                    <div className="counter-number">
                      {/* <Countdown date="2021/12/31" /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FormSuccess;
