import { FetchData, PostQuery } from "@hooks/api/useApi";
import { useFormik } from "formik";
import * as moment from "moment";
import React, { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import Event from "../../../components/Events/Event";

const status = [
  { text: "Publish", value: 1 },
  { text: "Draft", value: 2 },
  { text: "Inactive", value: 3 },
];

function Index() {
  const history = useHistory();
  const [modal1, setModal1] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  const [modalData, setModalData] = useState(null);

  const [order, setOrder] = useState(null);

  const toggleViewModal = () => setModal1(!modal1);

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: eventsList,
    refetch,
    isFetching,
    isLoading,
    isFetched,
  } = FetchData("events", "/Admin/get-all-event", actions);

  const updateActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setIsEdit(false);
        toggle();
        refetch();
      }
    },
  };
  const deleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setDeleteModal(false);
        refetch();
      } else {
        setShowErrorAlert(false);
        setDeleteModal(false);
        setTimeout(() => {
          setShowErrorAlert(true);
        }, 5000);
        setShowErrorMsg(data.data.errors[0]);
      }
    },
  };

  const { mutate: updateEvent, isLoading: isLoadingUpdateEvent } = PostQuery(
    "/Admin/update-event",
    "post",
    updateActions
  );
  const { mutate: deleteEvent } = PostQuery(
    "/Admin/delete-event",
    "post",
    deleteActions
  );

  const handleDeleteOrder = () => {
    const data = {
      Event_ID: modalData.event_id,
    };
    deleteEvent(data);
  };

  const validation = useFormik({
    initialValues: {
      eventName: "",
      description: "",
      eventStart: [],
      regStart: [],
      eventEnd: [],
      regEnd: [],
      status: 1,
      round: 1,
    },

    validationSchema: Yup.object({
      eventName: Yup.string().required("Please Write Event Name"),
      description: Yup.string().required("Please Write Description"),
      eventStart: Yup.array().min(1, "Please Select start date"),
      regStart: Yup.array().min(1, "Please Select start date"),
      eventEnd: Yup.array().min(1, "Please Select end date"),
      regEnd: Yup.array().min(1, "Please Select end date"),
    }),
    onSubmit: (values) => {
      let data = {
        Event_Id: modalData.event_id,
        Event_Code: modalData.event_code,
        Event_Description: values.description,
        Event_Start_Date: moment(values.eventStart[0]).format("DD/MM/YYYY"),
        Event_End_Date: moment(values.eventEnd[0]).format("DD/MM/YYYY"),
        Registration_Start_Date: moment(values.regStart[0]).format(
          "DD/MM/YYYY"
        ),
        Registration_End_Date: moment(values.regEnd[0]).format("DD/MM/YYYY"),
        Event_Status: Number(values.status),
        Round: Number(values.round),
      };
      updateEvent(data);
    },
  });

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const onClickDelete = (data) => {
    setModalData(data);
    setDeleteModal(true);
  };

  const handleUpdateEvent = (arg) => {
    setModalData(arg);
    validation.setValues({
      eventName: arg.event_name,
      description: arg.event_description,
      eventStart: [new Date(arg.event_start_date)],
      eventEnd: [new Date(arg.event_end_date)],
      regStart: [new Date(arg.registration_start_date)],
      regEnd: [new Date(arg.registration_end_date)],
      status:
        arg.event_status === "Published"
          ? 1
          : arg.event_status === "Draft"
          ? 2
          : 3,
      round: arg?.round,
    });
    setIsEdit(true);
    toggle();
  };

  const region = [
    ...new Set(
      eventsList?.event_list?.event_categorydata?.map((item) => item.regionName)
    ),
  ];
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Award code",
        accessor: "event_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Award name",
        accessor: "event_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },

      {
        Header: "Registration Start & End Date",
        accessor: "registrationStartEnd",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>
              {moment(cellProps.row.original.registration_start_date).format(
                "DD/MM/YYYY"
              )}{" "}
              to{" "}
              {moment(cellProps.row.original.registration_end_date).format(
                "DD/MM/YYYY"
              )}
            </p>
          );
        },
      },
      {
        Header: "Award Start & End Date",
        accessor: "eventStartEnd",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>
              {moment(cellProps.row.original.event_start_date).format(
                "DD/MM/YYYY"
              )}{" "}
              to{" "}
              {moment(cellProps.row.original.event_end_date).format(
                "DD/MM/YYYY"
              )}
            </p>
          );
        },
      },
      {
        Header: "Region",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          const region = [
            ...new Set(
              cellProps.row.original?.event_categorydata?.map(
                (item) => item.regionName
              )
            ),
          ];
          return (
            <>
              {cellProps.row.original.event_categorydata.length > 0 && (
                <span className="text-primary">{region?.join(", ")}</span>
              )}
            </>
          );
        },
      },
      {
        Header: "Status",
        accessor: "event_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.value === "Published"
                  ? "success"
                  : cellProps.value === "Draft"
                  ? "warning"
                  : "danger")
              }
              pill
            >
              {cellProps.value === "Published"
                ? "Published"
                : cellProps.value === "Draft"
                ? "Draft"
                : "Inactive"}
            </Badge>
          );
        },
      },
      {
        Header: "Details",
        accessor: "view",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  setModalData(cellProps.cell.row.original);
                  toggleViewModal();
                }}
                style={{ marginBottom: "4px", marginRight: "8px" }}
              >
                View Details
              </Button>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() =>
                  history.push({
                    pathname: "/manage-event-category",
                    search: `id=${cellProps.cell.row.original.event_id}&event_name=${cellProps.cell.row.original.event_name}`,
                  })
                }
              >
                Manage Categories
              </Button>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleUpdateEvent(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="right" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  // useEffect(() => {
  //   const checkRegDate =
  //     validation?.values?.regStart?.length > 0 &&
  //     moment(validation?.values?.regStart[0]).format("DD/MM/YYYY") ===
  //       moment(validation?.values?.regStart[1]).format("DD/MM/YYYY");

  //   const checkEventDate =
  //     validation?.values?.eventStart?.length > 0 &&
  //     moment(validation?.values?.eventStart[0]).format("DD/MM/YYYY") ===
  //       moment(validation?.values?.eventStart[1]).format("DD/MM/YYYY");

  //   if (checkRegDate && validation?.values?.regStart?.length > 0) {
  //     validation.setFieldValue("regStart", []);
  //     validation.setFieldError("regStart", "Select start or end date");
  //   }
  //   if (checkEventDate && validation?.values?.eventStart?.length > 0) {
  //     validation.setFieldValue("eventStart", []);
  //     validation.setFieldError("eventStart", "Select start or end date");
  //   }
  // }, [validation?.values?.regStart, validation?.values?.eventStart]);
  document.title = "Events | WOW Awards";

  return (
    <React.Fragment>
      <Event
        modal1={modal1}
        modal={modal}
        showErrorAlert={showErrorAlert}
        showErrorMsg={showErrorMsg}
        isEdit={isEdit}
        deleteModal={deleteModal}
        modalData={modalData}
        toggleViewModal={toggleViewModal}
        handleDeleteOrder={handleDeleteOrder}
        setDeleteModal={setDeleteModal}
        isFetching={isFetching}
        isLoading={isLoading}
        eventsList={eventsList}
        columns={columns}
        isFetched={isFetched}
        toggle={toggle}
        validation={validation}
        isLoadingUpdateEvent={isLoadingUpdateEvent}
        status={status}
        history={history}
      />
    </React.Fragment>
  );
}

export default Index;
