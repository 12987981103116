import React from "react";
import { Col, Row } from "reactstrap";

const DetailsIntroCard = (props) => {
  return (
    <>
      <div
        // className="bg-primary bg-soft"
        className={
          "font-size-12 badge-soft-" +
          (props.status === "Approved"
            ? "success"
            : props.status === "Reject"
            ? "danger"
            : "warning")
          //  (cell.value === 'Approved' ? 'success' cell.value === "Reject" ? "denger" )
        }
      >
        <Row>
          <Col xs="10">
            <div
              className="text-primary p-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h2
                  style={{
                    color:
                      props.status === "Approved"
                        ? "#34c38f"
                        : props.status === "Reject"
                        ? "#f46a6a"
                        : "#f1b44c",
                  }}
                  className={
                    props.status === "Approved"
                      ? "success"
                      : props.status === "Reject"
                      ? "danger"
                      : "warning"
                    //  (cell.value === 'Approved' ? 'success' cell.value === "Reject" ? "denger" )
                  }
                >
                  {props.details}
                </h2>
                <h3
                  style={{
                    color:
                      props?.status === "Approved"
                        ? "#34c38f"
                        : props?.status === "Reject"
                        ? "#f46a6a"
                        : "#f1b44c",
                  }}
                  className={
                    props?.status === "Approved"
                      ? "success"
                      : props?.status === "Reject"
                      ? "danger"
                      : "warning"
                  }
                >
                  {props?.name}
                </h3>
              </div>
            </div>
          </Col>
          <Col xs="2" className="align-self-end">
            <i
              className={`bx bx-user display-1 ${
                props?.status === "Approved"
                  ? "success"
                  : props?.status === "Reject"
                  ? "danger"
                  : "warning"
              }`}
              style={{ margin: "15px 0" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DetailsIntroCard;
