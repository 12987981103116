import Breadcrumbs from "@components/Common/Breadcrumb";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import ReasonModel from "../Common/ReasonModel";
import ScreenLoader from "../Common/ScreenLoader";
import ShowReasonModel from "../Common/ShowReasonModel";
import AddressCard from "./AddressCard";
import ExecutiveCard from "./ExecutiveCard";
import IntroCard from "./IntroCard";
import RejectAprrove from "./RejectAprrove";
import TableCard from "./TableCard";

const EntrantDetailPage = (props) => {
  return (
    <>
      <ReasonModel
        isOpen={props.modal1}
        toggle={props.toggleViewModal}
        setReson={props.setReson}
        reson={props.reson}
        handleReson={props.handleReson}
        errorMess={props.errorMess}
        setErrorMess={props.setErrorMess}
        handleChnageReson={props.handleChnageReson}
        isApprovalLoading={props.isApprovalLoading}
      />
      <ShowReasonModel
        isOpen={props.isOpenModel}
        toggle={props.toggleShowModal}
        data={props?.details?.reason}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Entrants" breadcrumbItem="Entrant Details" />
          {props.details?.is_accepted === null ? null : (
            <>
              {props.details?.status === "Pending" ? (
                <Row>
                  <Col xl={12}>
                    {" "}
                    <RejectAprrove
                      heading={props.heading}
                      onClick={props.toggleViewModal}
                      handleReason={props.handleReson}
                      Reject={props.reject}
                      Approve={props.approve}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12}>
                    <Button
                      onClick={props.handleEdit}
                      style={{ float: "right", marginBottom: "10px" }}
                    >
                      {props.showApprovalBox ? "Hide" : "Edit form Approval"}
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
          {props.showApprovalBox ? (
            <RejectAprrove
              heading={
                props.details?.status === "Pending"
                  ? "Accept/Reject Entrant"
                  : "Edit form Approval"
              }
              onClick={props.toggleViewModal}
              handleReason={props.handleReson}
              Reject={props.details?.status !== "Reject" ? "Reject" : null}
              Approve={props.details?.status !== "Approved" ? "Approve" : null}
            />
          ) : null}

          <Row>
            <Col xl="12">
              <IntroCard
                details={props.details}
                toggleShowModal={props.toggleShowModal}
                setIsReson={props.AddressCardsetIsReson}
                salutation={props.details?.primary?.primary_salutation}
                firstName={props.details?.primary?.pcp_first_name}
                lastName={props.details?.primary?.pcp_last_name}
                username={props.details?.general?.user_name}
                website={props.details?.general?.website}
                gst={props.details?.general?.gst_number}
                designation={props.details?.primary?.pcp_designation}
                mobile={props.details?.primary?.pcp_mobile_number}
                tel={props.details?.primary?.pcp_tel_number}
              />
            </Col>
          </Row>
          <Row>
            <Col
              xl={`${
                props.details?.is_official_address &&
                props.details?.is_person_same_as_execut
                  ? "12"
                  : props.details?.is_official_address ||
                    props.details?.is_person_same_as_execut
                  ? "6"
                  : "4"
              }`}
            >
              <AddressCard
                title={"Primary Address"}
                city={props.details?.primary?.pcp_city}
                state={props.details?.primary?.pcp_state}
                country={props.details?.primary?.pcp_country}
                add1={props.details?.primary?.pcp_address_line1}
                add2={props.details?.primary?.pcp_address_line2}
                add3={props.details?.primary?.pcp_address_line3}
                zip={props.details?.primary?.pcp_zip}
              />
            </Col>
            {props.details?.is_official_address ? null : (
              <Col
                xl={`${
                  props.details?.is_official_address ||
                  props.details?.is_person_same_as_execut
                    ? "6"
                    : "4"
                }`}
              >
                <AddressCard
                  title={"Official Address"}
                  city={props.details?.official?.city_secondary}
                  state={props.details?.official?.state_secondary}
                  country={props.details?.official?.country_secondary}
                  add1={props.details?.official?.address_line1_secondary}
                  add2={props.details?.official?.address_line2_secondary}
                  add3={props.details?.official?.address_line3_secondary}
                  zip={props.details?.official?.zip_secondary}
                />
              </Col>
            )}
            {props.details?.is_person_same_as_execut ? null : (
              <Col
                xl={`${
                  props.details?.is_official_address ||
                  props.details?.is_person_same_as_execut
                    ? "6"
                    : "4"
                }`}
              >
                <ExecutiveCard
                  title={"Senior / Director / CEO Details "}
                  salution={props.details?.executive?.salutation}
                  firstName={props.details?.executive?.first_name_sr_executive}
                  lastname={props.details?.executive?.last_name_sr_executive}
                  email={props.details?.executive?.email_id_sr_executive}
                  designation={
                    props.details?.executive?.designation_sr_executive
                  }
                  mobile={props.details?.executive?.mobile_no_sr_executive}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {props.details?.entry_of_entrant?.length > 0 && (
                <TableCard
                  entrantName={props.details?.general?.entrant_name}
                  details={props.details}
                  columns={props.columns}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={5}
                />
              )}
            </Col>
          </Row>
        </div>
        {(props.isLoading || props.isFetching || props.isApprovalLoading) && (
          <ScreenLoader />
        )}
      </div>
    </>
  );
};

export default EntrantDetailPage;
