import React from "react";
import Flatpickr from "react-flatpickr";
import { FormFeedback, InputGroup, Label } from "reactstrap";

export const DatePicker = (props) => {
  return (
    <>
      <Label className="form-label">{props.label}</Label>
      <InputGroup>
        <Flatpickr
          className={props.className}
          placeholder={props.placeholder}
          name={props.name}
          type={props.type}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value || ""}
          invalid={props.invalid}
          options={props.options}
        />
      </InputGroup>
      {props.validation.touched[props.name] &&
      props.validation.errors[props.name] ? (
        <FormFeedback type="invalid" style={{ display: "flex" }}>
          {props.validation.errors[props.name]}
        </FormFeedback>
      ) : null}
    </>
  );
};
