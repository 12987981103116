import { getFromStorage } from "@utils/storage";
import React from "react";
import AdminAssignJury from "../Admin/Jury/AssignJury";
import AuditAssignJury from "../Audit/Jury/AssignJury";

function Assign() {
  const userType = getFromStorage("type");

  if (userType === "Admin") {
    return <AdminAssignJury />;
  } else {
    return <AuditAssignJury />;
  }
}

export default Assign;
