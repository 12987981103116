import React from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const UpdateCategory = (props) => {
  return (
    <div>
      <Modal scrollable isOpen={props.updateModal} toggle={props.updateToggle}>
        <ModalHeader toggle={props.updateToggle} tag="h4">
          {"Update Category"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              props.validation.handleSubmit();
              return false;
            }}
          >
            <Row form>
              <Col className="col-12">
                <div style={{ textAlign: "end" }}>
                  <div className="btn-group" role="group">
                    <input
                      onChange={(e) =>
                        props.validation.setFieldValue(
                          "Category_Status",
                          e.target.value
                        )
                      }
                      type="radio"
                      className="btn-check"
                      name="Category_Status"
                      id="btnradio5"
                      autoComplete="off"
                      value={true}
                      defaultChecked={
                        props.validation.values.Category_Status ? true : false
                      }
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btnradio5"
                    >
                      Publish
                    </label>

                    <input
                      onChange={(e) =>
                        props.validation.setFieldValue(
                          "Category_Status",
                          e.target.value
                        )
                      }
                      type="radio"
                      className="btn-check"
                      name="Category_Status"
                      id="btnradio6"
                      autoComplete="off"
                      value={false}
                      defaultChecked={
                        !props.validation.values.Category_Status ? true : false
                      }
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btnradio6"
                    >
                      Draft
                    </label>
                  </div>
                </div>
                <Row>
                  <Col md="12">
                    <div className="mb-4">
                      <Label className="form-label">Select Region:</Label>

                      <Select
                        value={props.regionValue}
                        // isMulti={true}
                        onChange={(e) => props.onChangeRegion(e)}
                        type="select"
                        options={props.options}
                        classNamePrefix="select2-selection"
                        name="Category_Region"
                      />
                      {props.validation.touched.Category_Region &&
                      props.validation.errors.Category_Region ? (
                        <FormFeedback type="invalid">
                          {props.validation.errors.Category_Region}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-4">
                      <Label className="form-label">Select Group:</Label>
                      <Select
                        name="Category_Group"
                        type="select"
                        value={props.groupValue}
                        // isMulti={true}
                        onChange={(e) => props.onChangeGroup(e)}
                        options={props.optionGroup}
                        classNamePrefix="select2-selection"
                      />
                      {props.validation.touched.Category_Group &&
                      props.validation.errors.Category_Group ? (
                        <FormFeedback type="invalid">
                          {props.validation.errors.Category_Group}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-4">
                      <Label className="form-label">Select Sub-group:</Label>
                      <Select
                        value={props.subGroupValue}
                        // isMulti={true}
                        onChange={(e) => props.onChangeSubgroup(e)}
                        name="Category_Subgroup"
                        options={props.optionSubgroup || []}
                        classNamePrefix="select2-selection"
                      />
                      {props.validation.touched.Category_Subgroup &&
                      props.validation.errors.Category_Subgroup ? (
                        <FormFeedback type="invalid">
                          {props.validation.errors.Category_Subgroup}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <Label className="form-label">Category Name</Label>
                  <Input
                    name="Category_Title"
                    type="text"
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    value={props.validation.values.Category_Title || ""}
                    invalid={
                      props.validation.touched.Category_Title &&
                      props.validation.errors.Category_Title
                        ? true
                        : false
                    }
                  />
                  {props.validation.touched.Category_Title &&
                  props.validation.errors.Category_Title ? (
                    <FormFeedback type="invalid">
                      {props.validation.errors.Category_Title}
                    </FormFeedback>
                  ) : null}
                </div>
                {/* <div className="mb-4">
                  <Label className="form-label">Description</Label>
                  <Input
                    name="Category_Des"
                    type="textarea"
                    rows="3"
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    value={props.validation.values.Category_Des || ''}
                    invalid={
                      props.validation.touched.Category_Des &&
                      props.validation.errors.Category_Des
                        ? true
                        : false
                    }
                  />
                  {props.validation.touched.Category_Des &&
                  props.validation.errors.Category_Des ? (
                    <FormFeedback type="invalid">
                      {props.validation.errors.Category_Des}
                    </FormFeedback>
                  ) : null}
                </div> */}
                {/* <Row>
                  <Col md="6">
                    <div className="mb-4">
                      <Label className="form-label">Select Currency:</Label>
                      <Select
                        name="Category_Curr"
                        type="select"
                        value={props.currencyValue}
                        onChange={(e) => props.onChange(e)}
                        options={props.optionCurrency}
                        classNamePrefix="select2-selection"
                      />
                      {props.validation.touched.Category_Curr &&
                      props.validation.errors.Category_Curr ? (
                        <FormFeedback type="invalid">
                          {props.validation.errors.Category_Curr}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-4">
                      <Label className="form-label">Price:</Label>
                      <Input
                        name="Category_Price"
                        type="text"
                        onChange={props.validation.handleChange}
                        onBlur={props.validation.handleBlur}
                        value={props.validation.values.Category_Price || ''}
                        invalid={
                          props.validation.touched.Category_Price &&
                          props.validation.errors.Category_Price
                            ? true
                            : false
                        }
                      />
                      {props.validation.touched.Category_Price &&
                      props.validation.errors.Category_Price ? (
                        <FormFeedback type="invalid">
                          {props.validation.errors.Category_Price}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row> */}
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    disabled={props.isLoadingUpdateCat}
                  >
                    {props.isLoadingUpdateCat && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateCategory;
