import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

const AddressCard = (props) => {
  return (
    <>
      <Card className="overflow-hidden">
        <CardBody>
          <CardTitle className="mb-4">{props.title}</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3> {props.city}</h3>
            <p>
              {props.state}, {props.country}
            </p>
          </div>
          <Row>
            <Col sm="12">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <p className="text-muted mb-0">Address</p>
                    <h5 className="font-size-15">
                      {props.add1} {props.add2} {props.add3}
                    </h5>
                  </Col>
                  <Col xs="6">
                    <p className="text-muted mb-0">Zipcode</p>
                    <h5 className="font-size-15">{props.zip}</h5>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default AddressCard;
