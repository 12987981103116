import React from "react";
import { Button, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import FormHeader from "../Common/FormHeader";
import { DatePicker } from "../Form/DatePicker";
import { InputField } from "../Form/Input";

const AddEventPage = (props) => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-add-to-queue"}
                    heading={"Add Awards"}
                    hasButton={false}
                  />
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      props.validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col sm="12">
                        <div style={{ textAlign: "end" }}>
                          <div className="btn-group" role="group">
                            {props.status.map((item, index) => (
                              <>
                                <input
                                  onChange={(e) =>
                                    props.validation.setFieldValue(
                                      "status",
                                      e.target.value
                                    )
                                  }
                                  type="radio"
                                  className="btn-check"
                                  name="status"
                                  value={item.value}
                                  id={`btnradio${item.value}`}
                                  autoComplete="off"
                                  defaultChecked={
                                    item.value === 1 ? true : false
                                  }
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor={`btnradio${item.value}`}
                                >
                                  {item.text}
                                </label>
                              </>
                            ))}
                          </div>
                        </div>

                        <Row style={{ display: "flex" }}>
                          <Col sm="2">
                            <label>Select Round</label>
                            <div className="btn-group">
                              <input
                                onChange={(e) =>
                                  props.validation.setFieldValue(
                                    "round",
                                    e.target.value
                                  )
                                }
                                type="radio"
                                className="btn-check "
                                name="round"
                                id="round-one"
                                value={1}
                                autoComplete="off"
                                defaultChecked={true}
                              />
                              <label
                                className="btn btn-outline-primary"
                                htmlFor="round-one"
                              >
                                Round 1
                              </label>

                              <input
                                onChange={(e) =>
                                  props.validation.setFieldValue(
                                    "round",
                                    e.target.value
                                  )
                                }
                                type="radio"
                                className="btn-check"
                                name="round"
                                value={2}
                                autoComplete="off"
                                id="round-two"
                                disabled
                              />
                              <label
                                className="btn btn-outline-primary"
                                htmlFor="round-two"
                                style={{ opacity: "0.4" }}
                              >
                                Round 2
                              </label>
                            </div>
                          </Col>
                          <Col sm="10">
                            <div>
                              <InputField
                                label={"Awards Name"}
                                id={"eventName"}
                                name={"eventName"}
                                type={"text"}
                                className={"form-control"}
                                onChange={props.validation.handleChange}
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values.eventName || ""}
                                invalid={
                                  props.validation.touched.eventName &&
                                  props.validation.errors.eventName
                                    ? true
                                    : false
                                }
                                placeholder
                                validation={props.validation}
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-3">
                          <InputField
                            id={"description"}
                            label={"Description"}
                            name={"description"}
                            type={"textarea"}
                            value={props.validation.values.description || ""}
                            onChange={props.validation.handleChange}
                            onBlur={props.validation.handleBlur}
                            invalid={
                              props.validation.touched.description &&
                              props.validation.errors.description
                                ? true
                                : false
                            }
                            validation={props.validation}
                          />
                        </div>

                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <DatePicker
                                label={"Registration Start Date"}
                                name={"regStart"}
                                className={"form-control d-block"}
                                placeholder={"Select Registration date"}
                                options={{
                                  // mode: "range",
                                  dateFormat: "d/m/Y",
                                  minDate: "today",
                                }}
                                dateFormat="YYYY-MM-DD"
                                // minDate="2023-01"
                                onChange={(e, val) =>
                                  props.validation.setFieldValue("regStart", e)
                                }
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values.regStart || ""}
                                invalid={
                                  props.validation.touched.regStart &&
                                  props.validation.errors.regStart
                                    ? true
                                    : false
                                }
                                validation={props.validation}
                              />
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <DatePicker
                                label={"Registration End Date"}
                                name={"regEnd"}
                                className={"form-control d-block"}
                                placeholder={"Select Registration date"}
                                options={{
                                  // mode: "range",
                                  dateFormat: "d/m/Y",
                                  minDate:
                                    props.validation?.values?.regStart[0]?.fp_incr(
                                      1
                                    ),
                                }}
                                dateFormat="YYYY-MM-DD"
                                // minDate="2023-01"
                                onChange={(e, val) =>
                                  props.validation.setFieldValue("regEnd", e)
                                }
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values.regEnd || ""}
                                invalid={
                                  props.validation.touched.regEnd &&
                                  props.validation.errors.regEnd
                                    ? true
                                    : false
                                }
                                validation={props.validation}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <DatePicker
                                label={"Awards Start date"}
                                name={"eventStart"}
                                className={"form-control d-block"}
                                placeholder={"Select Event date"}
                                options={{
                                  // mode: "range",
                                  dateFormat: "d/m/Y",
                                  minDate:
                                    props.validation?.values?.regEnd[0]?.fp_incr(
                                      1
                                    ),
                                }}
                                onChange={(e, val) =>
                                  props.validation.setFieldValue(
                                    "eventStart",
                                    e
                                  )
                                }
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values.eventStart || ""}
                                invalid={
                                  props.validation.touched.eventStart &&
                                  props.validation.errors.eventStart
                                    ? true
                                    : false
                                }
                                validation={props.validation}
                              />
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <DatePicker
                                label={"Awards End date"}
                                name={"eventEnd"}
                                className={"form-control d-block"}
                                placeholder={"Select Event date"}
                                options={{
                                  // mode: "range",
                                  dateFormat: "d/m/Y",
                                  minDate:
                                    props.validation?.values?.regEnd[0]?.fp_incr(
                                      1
                                    ),
                                }}
                                onChange={(e, val) =>
                                  props.validation.setFieldValue("eventEnd", e)
                                }
                                onBlur={props.validation.handleBlur}
                                value={props.validation.values.eventEnd || ""}
                                invalid={
                                  props.validation.touched.eventEnd &&
                                  props.validation.errors.eventEnd
                                    ? true
                                    : false
                                }
                                validation={props.validation}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <DatePicker
                                label={" Eligibility Start date"}
                                name={"eligibilityStart"}
                                className={"form-control d-block"}
                                placeholder={"Select Eligibility date"}
                                options={{
                                  // mode: "range",
                                  dateFormat: "d/m/Y",
                                  // minDate: "today",
                                }}
                                onChange={(e, val) =>
                                  props.validation.setFieldValue(
                                    "eligibilityStart",
                                    e
                                  )
                                }
                                onBlur={props.validation.handleBlur}
                                value={
                                  props.validation.values.eligibilityStart || ""
                                }
                                invalid={
                                  props.validation.touched.eligibilityStart &&
                                  props.validation.errors.eligibilityStart
                                    ? true
                                    : false
                                }
                                // options={{
                                //   mode: "range",
                                //   dateFormat: "d/m/Y",
                                //   minDate:
                                //     props.validation?.values?.regStart[1]?.fp_incr(
                                //       1
                                //     ),
                                // }}
                                // onChange={(e, val) =>
                                //   props.validation.setFieldValue(
                                //     "eventStart",
                                //     e
                                //   )
                                // }
                                // onBlur={props.validation.handleBlur}
                                // value={props.validation.values.eventStart || ""}
                                // invalid={
                                //   props.validation.touched.eventStart &&
                                //   props.validation.errors.eventStart
                                //     ? true
                                //     : false
                                // }
                                validation={props.validation}
                              />

                              {/* <Label htmlFor="eligibilityStart">
                                Eligibility Start & End date
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  name="eligibilityStart"
                                  className="form-control d-block"
                                  placeholder="Select Eligibility date"
                                  options={{
                                    mode: "range",
                                    dateFormat: "d/m/Y",
                                  }}
                                  onChange={(e, val) =>
                                    props.validation.setFieldValue(
                                      "eligibilityStart",
                                      e
                                    )
                                  }
                                  onBlur={props.validation.handleBlur}
                                  value={
                                    props.validation.values.eligibilityStart ||
                                    ""
                                  }
                                  invalid={
                                    props.validation.touched.eligibilityStart &&
                                    props.validation.errors.eligibilityStart
                                      ? true
                                      : false
                                  }
                                />
                              </DatePicker>
                              {props.validation.touched.eligibilityStart &&
                              props.validation.errors.eligibilityStart ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ display: "flex" }}
                                >
                                  {props.validation.errors.eligibilityStart}
                                </FormFeedback>
                              ) : null} */}
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <DatePicker
                                label={" Eligibility End date"}
                                name={"eligibilityEnd"}
                                className={"form-control d-block"}
                                placeholder={"Select Eligibility date"}
                                options={{
                                  // mode: "range",
                                  dateFormat: "d/m/Y",
                                  // minDate: "today",
                                }}
                                onChange={(e, val) =>
                                  props.validation.setFieldValue(
                                    "eligibilityEnd",
                                    e
                                  )
                                }
                                onBlur={props.validation.handleBlur}
                                value={
                                  props.validation.values.eligibilityEnd || ""
                                }
                                invalid={
                                  props.validation.touched.eligibilityEnd &&
                                  props.validation.errors.eligibilityEnd
                                    ? true
                                    : false
                                }
                                // options={{
                                //   mode: "range",
                                //   dateFormat: "d/m/Y",
                                //   minDate:
                                //     props.validation?.values?.regStart[1]?.fp_incr(
                                //       1
                                //     ),
                                // }}
                                // onChange={(e, val) =>
                                //   props.validation.setFieldValue(
                                //     "eventStart",
                                //     e
                                //   )
                                // }
                                // onBlur={props.validation.handleBlur}
                                // value={props.validation.values.eventStart || ""}
                                // invalid={
                                //   props.validation.touched.eventStart &&
                                //   props.validation.errors.eventStart
                                //     ? true
                                //     : false
                                // }
                                validation={props.validation}
                              />

                              {/* <Label htmlFor="eligibilityStart">
                                Eligibility Start & End date
                              </Label>
                              <InputGroup>
                                <Flatpickr
                                  name="eligibilityStart"
                                  className="form-control d-block"
                                  placeholder="Select Eligibility date"
                                  options={{
                                    mode: "range",
                                    dateFormat: "d/m/Y",
                                  }}
                                  onChange={(e, val) =>
                                    props.validation.setFieldValue(
                                      "eligibilityStart",
                                      e
                                    )
                                  }
                                  onBlur={props.validation.handleBlur}
                                  value={
                                    props.validation.values.eligibilityStart ||
                                    ""
                                  }
                                  invalid={
                                    props.validation.touched.eligibilityStart &&
                                    props.validation.errors.eligibilityStart
                                      ? true
                                      : false
                                  }
                                />
                              </DatePicker>
                              {props.validation.touched.eligibilityStart &&
                              props.validation.errors.eligibilityStart ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ display: "flex" }}
                                >
                                  {props.validation.errors.eligibilityStart}
                                </FormFeedback>
                              ) : null} */}
                            </div>
                          </Col>
                        </Row>
                        {props.isErrorMsg || props.isError ? (
                          <div
                            style={{
                              color: "red",
                              textAlign: "center",
                              marginTop: "10px",
                            }}
                          >
                            {props.isErrorMsg}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <div
                      className="d-flex flex-wrap gap-2 mt-4"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        disabled={props.isLoading}
                      >
                        {props.isLoading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        )}
                        Add Event
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AddEventPage;
