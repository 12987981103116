import features from "@assets/images/crypto/features-img/img-1.png";
import Breadcrumbs from "@components/Common/Breadcrumb";
import * as moment from "moment";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import ImageModal from "../../components/Common/ImageModal";
import ScoreModel from "../../components/Common/ScoreModel";
import ScreenLoader from "../../components/Common/ScreenLoader";
import { FetchData, PostQuery } from "../../hooks/api/useApi";
import SlideWithFade from "../../pages/Admin/Entries/slidewithfade";
import { getFromStorage } from "../../utils/storage";

function ViewSecoundEntryDetails(props) {
  const Img_url = process.env.REACT_APP_IMG_URL;
  const getType = getFromStorage("type");
  const location = useLocation();
  const [imageModal, setImageModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [scoreModal, setScoreModal] = useState(false);
  const [score, setScore] = useState(null);
  const [updateScore, setUpdateScore] = useState(null);

  const RoundTwoScoreOption = [
    {
      options: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
      ],
    },
  ];

  const RoundOneScoreOption = [
    {
      options: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
      ],
    },
  ];

  // const getId = location?.search.slice(4, 7);
  // const getAssignId = location?.search.slice(17);

  const getAssignId = location?.search.split("&");
  const getId = getAssignId[0]?.split("=");
  const getAssignIdTwo = getAssignId[1]?.split("=");
  const [error, setError] = useState("");
  const handleOpenImage = (elem) => {
    setImageModal(true);
    setModalData(elem);
  };
  const actions = {
    select: (data) => {
      const list = data.data;
      return list;
    },
    onSuccess: (data) => {
      const scoreValue =
        RoundTwoScoreOption &&
        RoundTwoScoreOption[0].options.find(
          (item) =>
            Number(item.value) === data?.entry_basic_detail?.score_round2
        );
      setUpdateScore(scoreValue);
    },
    onSuccess: (data) => {
      if (data?.status) {
        const scoreValue =
          RoundTwoScoreOption &&
          RoundTwoScoreOption[0].options.find(
            (item) =>
              Number(item.value) ===
              data?.responseData?.entry_basic_detail?.score_round1
          );
        setUpdateScore(scoreValue);
      } else {
        setError(data?.errors?.[0]);
      }
    },
  };

  const {
    data: entryDetails,
    refetch,
    isLoading,
    isFetching,
  } = FetchData(
    "entrants-details",
    `Jury/get-entry-detail-by-id-jury?EntryId=${getId[1]}&AssignJuryid=${getAssignIdTwo[1]}`,
    actions
  );
  const updateActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setScoreModal(!scoreModal);
        refetch();
      }
    },
  };
  const { mutate: updateEntryScore, isLoading: isLoadingUpdateScore } =
    PostQuery("/Jury/update-entry-score", "post", updateActions);
  const toggleViewModal = () => setImageModal(!imageModal);
  const toggleScoreModal1 = () => setScoreModal(!scoreModal);

  const handleChnageScore = (e) => {
    setScore(e.value);
  };

  const handleScoreUpdate = () => {
    let data = {
      EntryId: Number(getId[1]),
      Score: Number(score),
      jury_assign_id: Number(entryDetails?.responseData?.jury_assign_id),
    };
    updateEntryScore(data);
  };

  return (
    <React.Fragment>
      <ImageModal
        isOpen={imageModal}
        toggle={toggleViewModal}
        data={modalData}
      />
      <ScoreModel
        isOpen={scoreModal}
        toggle={toggleScoreModal1}
        setScore={setScore}
        score={score}
        handleChnageScore={handleChnageScore}
        scoreOption={entryDetails?.responseData?.entry_basic_detail?.region == "Middle East" ? RoundOneScoreOption: RoundTwoScoreOption}
        handleScoreUpdate={handleScoreUpdate}
        isLoadingUpdateScore={isLoadingUpdateScore}
        data={updateScore}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Entries" breadcrumbItem="Details" />
          {error == "" ? (
            <>
              {getType === "Jury" && (
                <Row>
                  <Col xs={12}>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn "
                      onClick={() => toggleScoreModal1()}
                      style={{ float: "right", marginBottom: "10px" }}
                    >
                      Edit Score
                    </Button>
                  </Col>
                </Row>
              )}
              <Row>
                <Col xl="12">
                  <Card className="overflow-hidden">
                    <span
                      style={{
                        color: entryDetails?.responseData?.entry_basic_detail
                          ?.is_entry_rejected
                          ? "#f46a6a"
                          : "#f1b44c",
                        position: "absolute",
                        top: "5px",
                        right: "10px",
                        fontSize: "13px",
                        cursor: entryDetails?.responseData?.entry_basic_detail
                          ?.is_entry_rejected
                          ? "pointer"
                          : "none",
                        zIndex: entryDetails?.responseData?.entry_basic_detail
                          ?.is_entry_rejected
                          ? "1"
                          : "0",
                      }}
                      onClick={() => {
                        // toggleShowModal();
                        // setIsReson(
                        //   entryDetails?.responseData?.entry_basic_detail?.entry_reject_reason
                        // );
                      }}
                    >
                      {" "}
                      {entryDetails?.responseData?.entry_basic_detail
                        ?.is_entry_rejected &&
                        "Rejected (Click here to show reason)"}
                    </span>
                    <div
                      className={
                        "font-size-12 badge-soft-" +
                        (entryDetails?.responseData?.entry_basic_detail
                          ?.is_entry_rejected
                          ? "danger"
                          : "primary")
                      }
                    >
                      <Row>
                        <Col xs="10">
                          <div className="text-primary p-3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                {getType !== "Jury" ? (
                                  <>
                                    {" "}
                                    <h2
                                      style={{
                                        color: entryDetails?.responseData
                                          ?.entry_basic_detail
                                          ?.is_entry_rejected
                                          ? "#f46a6a"
                                          : "#f1b44c",
                                      }}
                                      className={
                                        entryDetails?.responseData
                                          ?.entry_basic_detail
                                          ?.is_entry_rejected
                                          ? "danger"
                                          : "success"
                                      }
                                    >
                                      {
                                        entryDetails?.responseData
                                          ?.entry_basic_detail?.entry_group
                                          ?.name
                                      }
                                    </h2>
                                    <h3
                                      style={{
                                        color: entryDetails?.responseData
                                          ?.entry_basic_detail
                                          ?.is_entry_rejected
                                          ? "#f46a6a"
                                          : "#f1b44c",
                                      }}
                                      className={
                                        entryDetails?.responseData
                                          ?.entry_basic_detail
                                          ?.is_entry_rejected
                                          ? "danger"
                                          : "success"
                                      }
                                    >
                                      {
                                        entryDetails?.responseData
                                          ?.entry_basic_detail?.entry_subgroup
                                          ?.name
                                      }
                                    </h3>
                                    <h4
                                      style={{
                                        color: entryDetails?.responseData
                                          ?.entry_basic_detail
                                          ?.is_entry_rejected
                                          ? "#f46a6a"
                                          : "#f1b44c",
                                      }}
                                      className={
                                        entryDetails?.responseData
                                          ?.entry_basic_detail
                                          ?.is_entry_rejected
                                          ? "danger"
                                          : "success"
                                      }
                                    >
                                      {
                                        entryDetails?.responseData
                                          ?.entry_basic_detail?.entry_category
                                          ?.name
                                      }
                                    </h4>
                                  </>
                                ) : (
                                  <>
                                    <h3
                                      style={{
                                        color: "#f1b44c",
                                      }}
                                    >
                                      {`Category Name :${entryDetails?.responseData?.entry_basic_detail?.entry_category?.category_name}`}
                                    </h3>
                                    <div style={{ display: "flex" }}>
                                      <h4
                                        style={{
                                          color: "#f1b44c",
                                          marginRight: "15px",
                                        }}
                                      >
                                        Campaign Start Date:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                          {moment(
                                            props?.entryDetails?.responseData
                                              ?.entry_basic_detail
                                              ?.campaign_start_date
                                          ).format("DD/MM/YYYY")}
                                        </span>
                                      </h4>

                                      <h4
                                        style={{
                                          color: "#f1b44c",
                                          marginRight: "15px",
                                        }}
                                      >
                                        Campaign End Date:{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                          {moment(
                                            props?.entryDetails?.responseData
                                              ?.entry_basic_detail
                                              ?.campaign_End_Date
                                          ).format("DD/MM/YYYY")}
                                        </span>
                                      </h4>
                                    </div>
                                  </>
                                )}
                                <div style={{ display: "flex" }}>
                                  <h4
                                    style={{
                                      color: entryDetails?.responseData
                                        ?.entry_basic_detail?.is_entry_rejected
                                        ? "#f46a6a"
                                        : "#f1b44c",
                                      marginRight: "15px",
                                    }}
                                    className={
                                      entryDetails?.responseData
                                        ?.entry_basic_detail?.is_entry_rejected
                                        ? "danger"
                                        : "success"
                                    }
                                  >
                                    Entry Name:{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {
                                        entryDetails?.responseData
                                          ?.entry_basic_detail?.entry_name
                                      }
                                    </span>
                                  </h4>

                                  <h4
                                    style={{
                                      color: entryDetails?.responseData
                                        ?.entry_basic_detail?.is_entry_rejected
                                        ? "#f46a6a"
                                        : "#f1b44c",
                                      marginRight: "15px",
                                    }}
                                    className={
                                      entryDetails?.responseData
                                        ?.entry_basic_detail?.is_entry_rejected
                                        ? "danger"
                                        : "success"
                                      //  (cell.value === 'Approved' ? 'success' cell.value === "Reject" ? "denger" )
                                    }
                                  >
                                    Entry Code:
                                    <span style={{ fontWeight: "bold" }}>
                                      {
                                        entryDetails?.responseData
                                          ?.entry_basic_detail?.entry_code
                                      }
                                    </span>
                                  </h4>
                                </div>

                                <h3
                                  style={{ color: "#fff", fontWeight: "bold" }}
                                  className="text-primary"
                                >
                                  PLEASE NOTE: 1 is the lowest score & 5 is the
                                  highest *
                                </h3>
                                <h4
                                  style={{ color: "#fff", fontWeight: "bold" }}
                                  className="text-primary"
                                >
                                  Round{" "}
                                  {entryDetails?.responseData?.event_round}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs="1" className="align-self-start">
                          {getType !== "Jury" ? (
                            <i
                              className="bx bx-news  display-2"
                              style={{
                                marginTop: "15px",
                                color: `${
                                  entryDetails?.responseData?.entry_basic_detail
                                    ?.is_entry_rejected
                                    ? "Reject"
                                    : "success"
                                }`,
                              }}
                            />
                          ) : (
                            entryDetails?.responseData?.entry_basic_detail
                              ?.score_round2 !== null && (
                              <div
                                style={{
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <>
                                    {" "}
                                    <span
                                      style={{
                                        color: "#f1b44c",
                                        fontWeight: "500",
                                        fontSize: "80px",
                                        lineHeight: "81px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {
                                        entryDetails?.responseData
                                          ?.entry_basic_detail?.score_round2
                                      }
                                    </span>
                                    <span
                                      style={{
                                        color: "#f1b44c",
                                        fontSize: "16px",
                                        lineHeight: "18px",
                                        fontWeight: "500",
                                        textAlign: "center",
                                      }}
                                    >
                                      Score
                                    </span>
                                  </>
                                </div>
                              </div>
                            )
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row>
                {entryDetails?.responseData?.entry_profile !== null &&
                  (entryDetails?.responseData?.entry_profile?.summary ||
                    entryDetails?.responseData?.entry_profile?.objective ||
                    entryDetails?.responseData?.entry_profile?.concept ||
                    entryDetails?.responseData?.entry_profile?.execution ||
                    entryDetails?.responseData?.entry_profile?.result) && (
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                                <i className="bx bx-receipt text-primary display-7" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">
                              Event Campaign Details
                            </h5>
                          </div>
                          {entryDetails?.responseData?.entry_profile
                            ?.summary && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. EXECUTIVE SUMMARY
                              </p>
                              <span>
                                #{" "}
                                {
                                  entryDetails?.responseData?.entry_profile
                                    ?.summary
                                }
                              </span>
                            </div>
                          )}
                          {entryDetails?.responseData?.entry_profile
                            ?.objective && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. BRIEF / OBJECTIVE
                              </p>
                              <span>
                                #{" "}
                                {
                                  entryDetails?.responseData?.entry_profile
                                    ?.objective
                                }
                              </span>
                            </div>
                          )}
                          {entryDetails?.responseData?.entry_profile
                            ?.concept && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. CONCEPT
                              </p>
                              <span>
                                #{" "}
                                {
                                  entryDetails?.responseData?.entry_profile
                                    ?.concept
                                }
                              </span>
                            </div>
                          )}
                          {entryDetails?.responseData?.entry_profile
                            ?.execution && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. EXECUTION / AMPLIFICATION
                              </p>
                              <span>
                                #{" "}
                                {
                                  entryDetails?.responseData?.entry_profile
                                    ?.execution
                                }
                              </span>
                            </div>
                          )}
                          {entryDetails?.responseData?.entry_profile
                            ?.result && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. RESULTS / SCALE
                              </p>
                              <span>
                                #{" "}
                                {
                                  entryDetails?.responseData?.entry_profile
                                    ?.result
                                }
                              </span>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                {entryDetails?.responseData?.entry_photos?.length > 0 ? (
                  getType === "User" ? (
                    <Col xl="6">
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-3">Photos</CardTitle>
                          {entryDetails?.responseData && (
                            <SlideWithFade
                              images={entryDetails?.responseData?.entry_photos}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-3">Photos</CardTitle>
                          <div>
                            <Row>
                              {entryDetails?.responseData?.entry_photos?.map(
                                (item) => {
                                  return (
                                    <Col xl="3">
                                      <div
                                        style={{
                                          border: "1px solid",
                                          borderColor: "#f1b44c",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleOpenImage(
                                            `${Img_url}${item?.photo}`
                                          )
                                        }
                                        // onClick={() =>
                                        //   handleOpenImage(
                                        //     `https://api.wowawards.com${item?.photo}`
                                        //   )
                                        // }
                                      >
                                        <img
                                          src={`${Img_url}${item?.photo}`}
                                          // src={`https://api.wowawards.com${item?.photo}`}
                                          alt="img"
                                          height="200"
                                          width="200"
                                          style={{
                                            objectFit: "cover",
                                            objectPosition: "top",
                                          }}
                                        ></img>
                                      </div>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                ) : null}
                {entryDetails?.responseData?.entry_videos?.length > 0 &&
                  (getType === "User" ? (
                    <Col xl="6">
                      <Card>
                        <div>
                          <Row>
                            <Col lg="9" sm="8">
                              <div className="p-4">
                                <h5 className="text-primary">Links</h5>
                                <p>Video links:</p>

                                <div className="text-muted">
                                  {entryDetails?.responseData?.entry_videos?.map(
                                    (item, i) => (
                                      <p key={i} className="mb-1">
                                        <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                        <a
                                          href={item.video_link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.video_link}
                                        </a>
                                      </p>
                                    )
                                  )}
                                </div>
                                {/* <p className="mt-3">Reference links:</p>

                        <div className="text-muted">
                        {entryDetails?.responseData?.entry_reflinks?.map((item, i)=>(
                          <p key={i} className="mb-1">
                            <i className="mdi mdi-circle-medium align-middle text-primary me-1" /> <a href={item.ref_link} target="_blank" rel="noreferrer">{item.ref_link}</a>
                          </p>))}
                          
                        </div> */}
                              </div>
                            </Col>
                            <Col lg="3" sm="4" className="align-self-center">
                              <div>
                                <img
                                  src={features}
                                  alt=""
                                  className="img-fluid d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ) : (
                    <Col xl="12">
                      <Card>
                        <div>
                          <Row>
                            <Col lg="9" sm="8">
                              <div className="p-4">
                                <h5 className="text-primary">Links</h5>

                                <div className="text-muted">
                                  {entryDetails?.responseData?.entry_videos?.map(
                                    (item, i) => (
                                      <p key={i} className="mb-1">
                                        <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                        <a
                                          href={item.video_link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.video_link}
                                        </a>
                                      </p>
                                    )
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg="3" sm="4" className="align-self-center">
                              <div>
                                <img
                                  src={features}
                                  alt=""
                                  className="img-fluid d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4 className="text-primary">{error}</h4>
            </div>
          )}
        </div>

        {(isLoading || isFetching) && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
}

export default ViewSecoundEntryDetails;
