import Breadcrumbs from "@components/Common/Breadcrumb";
import TableContainer from "@components/Common/TableContainer";
import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import FormHeader from "../../components/Common/FormHeader";
import ScreenLoader from "../../components/Common/ScreenLoader";
import { FetchData } from "../../hooks/api/useApi";


const EntryListdetails = () => {
  const location = useLocation();
  const getId = location?.search.slice(4);


  const history = useHistory();

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: juryEntryList,
    isLoading,
    isFetching,
  } = FetchData(
    "entrants-details",
    `Jury/get-entries-by-categoryid?CategoryId=${getId}`,
    actions
  );

  const roundTwoActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: juryRoundTwoEntryList,
    isLoading: juryRoundTwoEntryLoading,
    isFetching: juryRoundTwoEntryFetching,
  } = FetchData(
    "roundTwoEntries",
    `Jury/get-entries-by-categoryid-round2?CategoryId=${getId}`,
    roundTwoActions
  );
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },

      {
        Header: "Group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Subgroup",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Entries Scored",
        accessor: "score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div style={{ textAlign: "center" }}>
              <span>{cellProps.row.original.score}</span>
            </div>
          );
        },
      },

      {
        Header: "Details",
        accessor: "view",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  console.log("cellProps.row", cellProps)
                  history.push({
                    pathname: "/ViewEntryDetails",
                    search: `id=${cellProps.row.original.entry_id}&assignId=${cellProps?.row?.original?.entryjuryassignid}`,
                    state:{"entries":  cellProps?.data?.filter(
                          (item) => item?.entry_round == 1
                        )}
                  });
                }}
              >
                Score the Entry
              </Button>
            </>
          );
        },
      },
    ],
    []
  );


  console.log("location", location);

  const roundTwocolumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },

      {
        Header: "Group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Subgroup",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Entries Scored",
        accessor: "score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div style={{ textAlign: "center" }}>
              <span>{cellProps.row.original.score}</span>
            </div>
          );
        },
      },

      {
        Header: "Details",
        accessor: "view",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {
                  history.push({
                    pathname: "/ViewEntryTwoDetails",
                    search: `id=${cellProps.row.original.entry_id}&assignId=${cellProps?.row?.original?.entryjuryassignid}`,
                  });
                }}
              >
                Scored the Entry
              </Button>
            </>
          );
        },
      },
    ],
    []
  );
  // const secoundRoundEntries = juryEntryList?.filter(
  //   (item) => item?.entry_round == 2
  // );
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Dashboard" breadcrumbItem="Entries" />

          {juryRoundTwoEntryList?.length > 0 ? (
            <Card>
              <CardBody>
                <FormHeader
                  iconName={"bx-layer"}
                  heading={"Entries (Round 2)"}
                  pageLoading={isFetching}
                />
                {juryRoundTwoEntryList?.length > 0 && (
                  <div>
                    <TableContainer
                      columns={roundTwocolumns}
                      data={juryRoundTwoEntryList}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          ) : (
            juryEntryList?.length > 0 && (
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-layer"}
                    heading={"Entries (Round 1)"}
                    pageLoading={isFetching}
                  />
                  {juryEntryList?.length > 0 && (
                    <div>
                      <TableContainer
                        columns={columns}
                        data={juryEntryList?.filter(
                          (item) => item?.entry_round == 1
                        )}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            )
          )}
        </div>
        {isFetching && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
};

export default EntryListdetails;
