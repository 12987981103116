import React from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { DatePicker } from "../Form/DatePicker";
import { InputField } from "../Form/Input";

const UpdateEvent = (props) => {
  return (
    <>
      <Modal scrollable isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {"Edit Award"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={props.validation.handleSubmit}>
            <Row>
              <Col sm="12">
                <div style={{ textAlign: "end" }}>
                  <div className="btn-group" role="group">
                    {props.status.map((item, index) => (
                      <>
                        <input
                          onChange={(e) =>
                            props.validation.setFieldValue(
                              "status",
                              e.target.value
                            )
                          }
                          type="radio"
                          className="btn-check"
                          name="status"
                          value={item.value}
                          id={`btnradio${item.value}`}
                          autoComplete="off"
                          defaultChecked={
                            item.value === props.validation.values.status
                              ? true
                              : false
                          }
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor={`btnradio${item.value}`}
                        >
                          {item.text}
                        </label>
                      </>
                    ))}
                  </div>
                </div>
              </Col>
              {/* <Row> */}
              <Col sm="5">
                <label>Select Round</label>
                <div className="btn-group">
                  <input
                    onChange={(e) =>
                      props.validation.setFieldValue("round", e.target.value)
                    }
                    type="radio"
                    className="btn-check "
                    name="round"
                    id="round-one"
                    value={1}
                    autoComplete="off"
                    defaultChecked={
                      props.validation.values.round == 1 ? true : false
                    }
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor="round-one"
                  >
                    Round 1
                  </label>

                  <input
                    onChange={(e) =>
                      props.validation.setFieldValue("round", e.target.value)
                    }
                    type="radio"
                    className="btn-check"
                    name="round"
                    value={2}
                    autoComplete="off"
                    id="round-two"
                    defaultChecked={
                      props.validation.values.round == 2 ? true : false
                    }
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor="round-two"
                  >
                    Round 2
                  </label>
                </div>
              </Col>
              <Col sm="7">
                <div className="mb-3">
                  <InputField
                    id={"entrant"}
                    label={"Award Name"}
                    name={"eventName"}
                    type={"text"}
                    className={"form-control"}
                    value={props.validation.values.eventName || ""}
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    invalid={
                      props.validation.touched.eventName &&
                      props.validation.errors.eventName
                        ? true
                        : false
                    }
                    disabled
                    validation={props.validation}
                  />
                  {/* <Label htmlFor="entrant">Awards Name</Label>
                      <Input
                        disabled
                        id="entrant"
                        name="eventName"
                        value={props.validation.values.eventName}
                        type="text"
                        className="form-control"
                        onChange={props.validation.handleChange}
                      /> */}
                </div>
              </Col>
              {/* </Row> */}

              <Col sm="12">
                <div className="mb-3">
                  <InputField
                    id={"entrant"}
                    label={"description"}
                    name={"description"}
                    type={"textarea"}
                    value={props.validation.values.description || ""}
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    invalid={
                      props.validation.touched.description &&
                      props.validation.errors.description
                        ? true
                        : false
                    }
                    validation={props.validation}
                  />
                  {/* <Label htmlFor="productname">Description</Label>
                      <Input
                        id="entrant"
                        col="3"
                        name="description"
                        value={props.validation.values.description}
                        type="textarea"
                        className="form-control"
                        onChange={props.validation.handleChange}
                        onBlur={props.validation.handleBlur}
                        invalid={
                          props.validation.touched.description &&
                          props.validation.errors.description
                            ? true
                            : false
                        }
                      />
                      {props.validation.touched.description &&
                      props.validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {props.validation.errors.description}
                        </FormFeedback>
                      ) : null} */}
                </div>

                <Row>
                  <Col sm="6">
                    <div className="mb-3">
                      <DatePicker
                        label={"Awards Start date"}
                        name={"eventStart"}
                        className={"form-control d-block"}
                        placeholder={"Select Event date"}
                        value={props.validation.values.eventStart}
                        options={{
                          // mode: "range",
                          dateFormat: "d/m/Y",
                        }}
                        onChange={(e, val) =>
                          props.validation.setFieldValue("eventStart", e)
                        }
                        onBlur={props.validation.handleBlur}
                        invalid={
                          props.validation.touched.eventStart &&
                          props.validation.errors.eventStart
                            ? true
                            : false
                        }
                        validation={props.validation}
                      />
                      {/* <Label htmlFor="entrant">
                            Awards Start & End date
                          </Label>

                          <DatePicker>
                            <Flatpickr
                              value={props.validation.values.eventStart}
                              name="eventStart"
                              className="form-control d-block"
                              placeholder="Select Event date"
                              options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                              }}
                              onChange={(e, val) =>
                                props.validation.setFieldValue("eventStart", e)
                              }
                              onBlur={props.validation.handleBlur}
                              invalid={
                                props.validation.touched.eventStart &&
                                props.validation.errors.eventStart
                                  ? true
                                  : false
                              }
                            />
                          </DatePicker>
                          {props.validation.touched.eventStart &&
                          props.validation.errors.eventStart ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "flex" }}
                            >
                              {props.validation.errors.eventStart}
                            </FormFeedback>
                          ) : null} */}
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className="mb-3">
                      <DatePicker
                        label={"Awards End date"}
                        name={"eventEnd"}
                        className={"form-control d-block"}
                        placeholder={"Select Event date"}
                        value={props.validation.values.eventEnd}
                        options={{
                          // mode: "range",
                          dateFormat: "d/m/Y",
                        }}
                        onChange={(e, val) =>
                          props.validation.setFieldValue("eventEnd", e)
                        }
                        onBlur={props.validation.handleBlur}
                        invalid={
                          props.validation.touched.eventEnd &&
                          props.validation.errors.eventEnd
                            ? true
                            : false
                        }
                        validation={props.validation}
                      />
                      {/* <Label htmlFor="entrant">
                            Awards Start & End date
                          </Label>

                          <DatePicker>
                            <Flatpickr
                              value={props.validation.values.eventStart}
                              name="eventStart"
                              className="form-control d-block"
                              placeholder="Select Event date"
                              options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                              }}
                              onChange={(e, val) =>
                                props.validation.setFieldValue("eventStart", e)
                              }
                              onBlur={props.validation.handleBlur}
                              invalid={
                                props.validation.touched.eventStart &&
                                props.validation.errors.eventStart
                                  ? true
                                  : false
                              }
                            />
                          </DatePicker>
                          {props.validation.touched.eventStart &&
                          props.validation.errors.eventStart ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "flex" }}
                            >
                              {props.validation.errors.eventStart}
                            </FormFeedback>
                          ) : null} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <div className="mb-3">
                      <DatePicker
                        label={"Registration Start Date"}
                        name={"regStart"}
                        className={"form-control d-block"}
                        placeholder={"Select Event date"}
                        options={{
                          // mode: "range",
                          dateFormat: "d/m/Y",
                        }}
                        value={props.validation.values.regStart}
                        onChange={(e, val) =>
                          props.validation.setFieldValue("regStart", e)
                        }
                        onBlur={props.validation.handleBlur}
                        invalid={
                          props.validation.touched.eventStart &&
                          props.validation.errors.eventStart
                            ? true
                            : false
                        }
                        validation={props.validation}
                      />

                      {/* <Label htmlFor="userName">
                            Registration Start & End Date
                          </Label>
                          <DatePicker>
                            <Flatpickr
                              value={props.validation.values.regStart}
                              name="regStart"
                              className="form-control d-block"
                              placeholder="Select Event date"
                              options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                              }}
                              onChange={(e, val) =>
                                props.validation.setFieldValue("regStart", e)
                              }
                              onBlur={props.validation.handleBlur}
                              invalid={
                                props.validation.touched.regStart &&
                                props.validation.errors.regStart
                                  ? true
                                  : false
                              }
                            />
                          </DatePicker>

                          {props.validation.touched.regStart &&
                          props.validation.errors.regStart ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "flex" }}
                            >
                              {props.validation.errors.regStart}
                            </FormFeedback>
                          ) : null} */}
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="mb-3">
                      <DatePicker
                        label={"Registration End Date"}
                        name={"regEnd"}
                        className={"form-control d-block"}
                        placeholder={"Select Event date"}
                        options={{
                          // mode: "range",
                          dateFormat: "d/m/Y",
                        }}
                        value={props.validation.values.regEnd}
                        onChange={(e, val) =>
                          props.validation.setFieldValue("regEnd", e)
                        }
                        onBlur={props.validation.handleBlur}
                        invalid={
                          props.validation.touched.regEnd &&
                          props.validation.errors.regEnd
                            ? true
                            : false
                        }
                        validation={props.validation}
                      />

                      {/* <Label htmlFor="userName">
                            Registration Start & End Date
                          </Label>
                          <DatePicker>
                            <Flatpickr
                              value={props.validation.values.regStart}
                              name="regStart"
                              className="form-control d-block"
                              placeholder="Select Event date"
                              options={{
                                mode: "range",
                                dateFormat: "d/m/Y",
                              }}
                              onChange={(e, val) =>
                                props.validation.setFieldValue("regStart", e)
                              }
                              onBlur={props.validation.handleBlur}
                              invalid={
                                props.validation.touched.regStart &&
                                props.validation.errors.regStart
                                  ? true
                                  : false
                              }
                            />
                          </DatePicker>

                          {props.validation.touched.regStart &&
                          props.validation.errors.regStart ? (
                            <FormFeedback
                              type="invalid"
                              style={{ display: "flex" }}
                            >
                              {props.validation.errors.regStart}
                            </FormFeedback>
                          ) : null} */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    disabled={props.isLoadingUpdateEvent}
                  >
                    {props.isLoadingUpdateEvent && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateEvent;
