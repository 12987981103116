import { FetchData, PostQuery } from "@hooks/api/useApi";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EntryDetail from "../../../components/Common/EntryDeatils";
import { getFromStorage } from "../../../utils/storage";

function EntryDetails() {
  const getType = getFromStorage("type");
  document.title = "Entry details | WOW Awards";
  const location = useLocation();
  const history = useHistory();
  const entryId = location?.search?.slice(4);
  const [modal1, setModal1] = useState(false);
  const [reson, setReson] = useState("");
  const [errorMess, setErrorMess] = useState("");
  const [isOpenModel, setIsOpenModel] = useState(false);
  const [isReson, setIsReson] = useState(false);
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);

  const entryActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };

  const {
    data: entryDetails,
    isLoading,
    isFetching,
  } = FetchData(
    "entry-details",
    `User/get-user-entry-by-id?id=${entryId}`,
    entryActions
  );

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };
  const t_col12 = () => {
    setcol12(!col12);
    setcol11(false);
    setcol10(false);
    setcol9(false);
  };
  const t_col13 = () => {
    setcol13(!col13);
    setcol11(false);
    setcol10(false);
    setcol9(false);
  };
  const [imageModal, setImageModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleOpenImage = (elem) => {
    setImageModal(true);
    setModalData(elem);
  };
  const toggleViewModal = () => setImageModal(!imageModal);

  const statusAction = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.replace("/entries");
      }
    },
  };
  const toggleViewModal1 = () => {
    setModal1(!modal1);
  };
  const { mutate, isLoading: isRejectLoading } = PostQuery(
    "Admin/rejected-user-entry",
    "post",
    statusAction
  );

  const handleReson = (e) => {
    e.preventDefault();
    if (!reson && e.target.value == "false") {
      setErrorMess("Please Enter Reason");
    } else {
      const rejectEntry = {
        entry_id: Number(entryId),
        reason: reson,
      };
      mutate(rejectEntry);
    }
  };

  const handleChnageReson = (e) => {
    if (e.target.value) {
      setReson(e.target.value);
      setErrorMess("");
    } else {
      setErrorMess("Please Enter Reason");
    }
  };

  const toggleShowModal = () => {
    if (entryDetails?.entry_basic_detail?.is_entry_rejected) {
      setIsOpenModel(!isOpenModel);
    }
  };

  return (
    <React.Fragment>
      <EntryDetail
        handleChnageReson={handleChnageReson}
        toggleShowModal={toggleShowModal}
        isOpenModel={isOpenModel}
        setIsOpenModel={setIsOpenModel}
        entryDetails={entryDetails}
        setReson={setReson}
        errorMess={errorMess}
        handleReson={handleReson}
        toggleViewModal1={toggleViewModal1}
        isLoading={isLoading}
        toggleViewModal={toggleViewModal}
        isFetching={isFetching}
        handleOpenImage={handleOpenImage}
        setIsReson={setIsReson}
        imageModal={imageModal}
        modalData={modalData}
        isReson={isReson}
        reson={reson}
        modal1={modal1}
        setErrorMess={setErrorMess}
        isRejectLoading={isRejectLoading}
        getType={getType}
        error={""}
      />
    </React.Fragment>
  );
}

export default EntryDetails;
