import TableContainer from "@components/Common/TableContainer";
import React from "react";
import { Card, CardBody, Col, Row, UncontrolledAlert } from "reactstrap";
import GroupDetailsModal from "../../../pages/Admin/Master/Group/GroupDetailsModal";
import DeleteModal from "../../Common/DeleteModal";
import FormHeader from "../../Common/FormHeader";
import AddGroup from "./AddGroup";
import UpdateGroup from "./UpdateGroup";

const Group = (props) => {
  return (
    <div>
      {!props.showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${
            props.showErrorAlert ? "show" : ""
          }`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-block-helper me-2"></i>
          {props.showErrorMsg}
        </UncontrolledAlert>
      )}
      <GroupDetailsModal
        etailsModal
        isOpen={props.modal1}
        toggle={props.toggleViewModal}
        data={props.modalData}
      />
      <DeleteModal
        show={props.deleteModal}
        onDeleteClick={props.handleDeleteGroup}
        onCloseClick={() => props.onCloseClick()}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={`Groups ${
                      props.groupList?.length > 0
                        ? `(${props.groupList?.length})`
                        : " "
                    }`}
                    buttonName={"Add New Group"}
                    onButtonClick={() => props.onClick()}
                    pageLoading={props.isFetching || props.isLoading}
                    hasButton={true}
                  />

                  {props.groupList ? (
                    <TableContainer
                      columns={props.columns}
                      data={props.groupList}
                      isGlobalFilter={true}
                      customPageSize={10}
                      // pagination
                    />
                  ) : null}
                </CardBody>
              </Card>
              {props.isFetched && props.groupList === null && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="text-primary">No data Found</h4>
                </div>
              )}
            </Col>
          </Row>

          <AddGroup
            validation={props.validation}
            modal={props.modal}
            toggle={props.toggle}
            isLoadingAddGroup={props.isLoadingAddGroup}
          />
          <UpdateGroup
            formik={props.formik}
            updateModal={props.updateModal}
            updateToggle={props.updateToggle}
            isLoadingUpdateGroup={props.isLoadingUpdateGroup}
          />
        </div>
      </div>
    </div>
  );
};

export default Group;
