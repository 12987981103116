import TableContainer from "@components/Common/TableContainer";
import classNames from "classnames";
import React from "react";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import GroupDetailsModal from "../../../pages/Admin/Master/Category/GroupDetailsModal";
import DeleteModal from "../../Common/DeleteModal";
import FormHeader from "../../Common/FormHeader";
import AddCategory from "./AddCategory";
import UpdateCategory from "./UpdateCategory";

const Category = (props) => {
  return (
    <div>
      {!props.showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${
            props.showErrorAlert ? "show" : ""
          }`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-block-helper me-2"></i>
          {props.showErrorMsg}
        </UncontrolledAlert>
      )}
      <GroupDetailsModal
        isOpen={props.modal1}
        toggle={props.toggleViewModal}
        data={props.categoryDataById}
      />
      <DeleteModal
        show={props.deleteModal}
        onDeleteClick={() => props.onDeleteClick()}
        onCloseClick={() => props.onCloseClick()}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={`Category list ${
                      props.categoryList?.length > 0
                        ? `(${props.categoryList?.length})`
                        : " "
                    }`}
                    buttonName={"Add New Category"}
                    onButtonClick={() => props.onClickOrderClicks()}
                    pageLoading={
                      props.isLoadingcategory || props.isFetchingCategory
                    }
                    hasButton={true}
                  />

                  <div
                    className="mb-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {props.regions.length > 1 ? (
                      <Nav
                        pills
                        className="icon-tab nav-justified"
                        role="tablist"
                        style={{ width: "45%" }}
                      >
                        {props.regions?.map((item, i) => {
                          return (
                            <NavItem key={i}>
                              <NavLink
                                className={classNames({
                                  active: props.activeTab === i + 1,
                                })}
                                onClick={() => {
                                  props.setactiveTab(i + 1);
                                  props.setactiveTabName(item);
                                }}
                              >
                                {`${item} (${
                                  props.categoryList?.filter(
                                    (cat) => cat?.region?.region_name === item
                                  )?.length
                                })`}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                    ) : null}
                  </div>
                  {props.categoryList?.length > 0 && (
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Col sm="5">
                          <Label className="form-label">Group:</Label>{" "}
                          <Select
                            value={props.selectFilterGroup}
                            classNamePrefix="select2-selection"
                            onChange={(e) => {
                              props.handleGroup(e);
                            }}
                            options={props.optionGroup}
                          />
                        </Col>
                        <Col sm="5">
                          <Label className="form-label">Subgroup:</Label>{" "}
                          <Select
                            value={props.selectFilterSubGroup}
                            classNamePrefix="select2-selection"
                            onChange={(e) => {
                              props.handleSubgroup(e);
                            }}
                            options={props.optionSubgroup}
                          />
                        </Col>

                        <Col sm="1">
                          <Button
                            outline
                            type="button"
                            style={{ padding: "8px" }}
                            color="primary"
                            className="btn-sm mt-4 align-items-center"
                            onClick={props.handleClearAll}
                          >
                            Clear All
                          </Button>
                        </Col>
                      </div>
                    </Row>
                  )}

                  {props.categoryList &&
                  props.selectFilterGroup === null &&
                  props.selectFilterSubGroup === null ? (
                    <TableContainer
                      columns={props.columns}
                      data={props.categoryList?.filter(
                        (item) =>
                          item.region.region_name === props.activeTabName
                      )}
                      isGlobalFilter={true}
                      customPageSize={10}
                      handleData={props.handleData}
                    />
                  ) : null}
                  {props.categoryList &&
                  (props.selectFilterGroup !== null ||
                    props.selectFilterSubGroup !== null) ? (
                    <TableContainer
                      columns={props.columns}
                      data={props.handleData()}
                      isGlobalFilter={true}
                      customPageSize={10}
                    />
                  ) : null}
                </CardBody>
              </Card>
              {props.isFetched && props.categoryList == null && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="text-primary">No data Found</h4>
                </div>
              )}
            </Col>
          </Row>

          <AddCategory
            modal={props.modal}
            toggle={props.toggle}
            formik={props.formik}
            options={props.optionRegion}
            optionGroup={props.optionGroup}
            optionSubgroup={props.optionSubgroup}
            optionCurrency={props.optionCurrency}
            isLoadingAddCat={props.isLoadingAddCat}
            loader={props.loader}
            onChange={(e) => props.onChange(e)}
            onChangeSubgroup={(e) => props.onChangeSubgroup(e)}
            onChangeGroup={(e) => props.onChangeGroup(e)}
            onChangeRegion={(e) => props.onChangeRegion(e)}
          />
          <UpdateCategory
            updateModal={props.updateModal}
            updateToggle={props.updateToggle}
            validation={props.validation}
            // onChange={(e) => props.onChange(e)}
            regionValue={props.regionValue}
            options={props.optionRegion}
            // onChangeGroup={(e) => props.onChangeGroup(e)}
            groupValue={props.groupValue}
            optionGroup={props.optionGroup}
            // onChangeSubgroup={(e) => props.onChangeSubgroup(e)}
            subGroupValue={props.subGroupValue}
            optionSubgroup={props.optionSubgroup}
            currencyValue={props.currencyValue}
            onChange={(e) => props.onChange(e)}
            onChangeSubgroup={(e) => props.onChangeSubgroup(e)}
            onChangeGroup={(e) => props.onChangeGroup(e)}
            onChangeRegion={(e) => props.onChangeRegion(e)}
            optionCurrency={props.optionCurrency}
            isLoadingUpdateCat={props.isLoadingUpdateCat}
          />
        </div>
      </div>
    </div>
  );
};

export default Category;
