import React from "react";
import Select from "react-select";
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import SelectInput from "../Form/SelectInput";

const AssignJurryModel = (props) => {
  const {
    isOpen,
    toggle,
    handleMultiJury,
    formik,
    optionJurry,
    optionAwards,
    handleSelectAward,
    handleAward,
    selectedAward,
    handleSelectGroup,
    selectedGroup,
    optionGroup,
    modal,
    usertype,
  } = props;
  return (
    <Modal
      isOpen={selectedAward !== null && !modal ? isOpen : modal}
      role="dialog"
      autoFocus={true}
      centered={true}
      className={`exampleModal ${
        selectedAward == null && modal && "assign-modal"
      }`}
      tabIndex="-1"
      toggle={selectedAward !== null && !modal && toggle}
      zIndex={selectedAward == null && modal && 900}
    >
      <div className="modal-content">
        <ModalHeader toggle={selectedAward !== null && !modal && toggle}>
          {selectedAward !== null && !modal
            ? "Select Jury Member By Group"
            : "Select Award"}
        </ModalHeader>
        <ModalBody>
          {selectedAward !== null && isOpen && usertype == "Admin" && (
            <Row>
              <Col sm="12">
                <div className="mb-3">
                  <SelectInput
                    // isMulti={true}
                    label={"Group"}
                    name={"group"}
                    type={"select"}
                    onChange={(e) => {
                      handleSelectGroup(e);
                    }}
                    options={optionGroup?.reverse()}
                    // onBlur={props.formik.handleBlur}
                    // invalid={props.formik.touched.group && props.formik.errors.group ? true : false}
                    // formik={props.formik}
                  />
                </div>
              </Col>
              {/* {selectedGroup !== null &&
            <Col sm="4">
            <Button type="button" color="primary">
              Show Jury
          </Button>
            </Col> 
          } */}
            </Row>
          )}

          <div className="mb-3">
            {selectedAward !== null && isOpen && usertype == "Admin" && (
              <Label>Select Jury's</Label>
            )}
            <Select
              isMulti={selectedAward !== null && !modal ? true : false}
              onChange={(e) => {
                selectedAward !== null && !modal
                  ? handleMultiJury(e)
                  : handleSelectAward(e);
              }}
              options={
                selectedAward !== null && !modal ? optionJurry : optionAwards
              }
              classNamePrefix="select2-selection"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            value="false"
            disabled={selectedAward === null ? true : false}
            onClick={selectedAward !== null && !modal ? formik : handleAward}
          >
            {" "}
            {/* {isApprovalLoading && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            )} */}
            Submit
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default AssignJurryModel;
