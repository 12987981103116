import Breadcrumbs from "@components/Common/Breadcrumb";
import DeleteModal from "@components/Common/DeleteModal";
import TableContainer from "@components/Common/TableContainer";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import ScreenLoader from "../../../../components/Common/ScreenLoader";

function Index() {
  document.title = "Add Event | WOW Awards";

  const [order, setOrder] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [optionGroup, setOptionGroup] = useState();
  const [isGroupLoading, setIsGroupLoading] = useState(false);
  const [isSubGroupLoading, setIsSubGroupLoading] = useState(false);
  const [awardName, setAwardName] = useState("");

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Region",
        accessor: "regionName",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Group",
        accessor: "groupName",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Sub-group",
        accessor: "subGroupName",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "category",
        accessor: "categoryName",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={(e) => {
                  e.preventDefault();
                  onClickDelete(cellProps.row.original);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );
  //url query params
  const location = useLocation();
  const event = location?.search?.split("&")[0]?.split("=")[1];
  let name = location?.search?.split("&")[1]?.split("=")[1];

  //handling select dropdown
  const [selectedMultiGroup, setselectedMultiGroup] = useState(null);
  const [selectedMultiSubgroup, setselectedMultiSubgroup] = useState([]);
  const [selectedMultiCategory, setselectedMultiCategory] = useState([]);

  //handling values stored in dropdown
  const [showGroup, setShowGroup] = useState(false);
  const [showSubgroup, setShowSubgroup] = useState(false);
  const [showCategory, setShowCategory] = useState(false);

  const [subGroupData, setSubGroupData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const actions = {
    select: (data) => {
      const list = data.data.responseData.event_list[0];
      return list;
    },
  };

  const groupActions = {
    select: (data) => {
      const list = data.data.responseData?.reverse();
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.group_name;
          obj["value"] = item.group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionGroup(getList);
        }
      });
    },
  };

  const deleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setDeleteModal(false);
        refetch();
      }
    },
  };

  const catActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
        setselectedMultiCategory([]);
        setselectedMultiGroup(null);
        setselectedMultiSubgroup([]);
        setSubGroupData([]);
        setCategoryData([]);
        setShowGroup(false);
        setShowSubgroup(false);
        setShowCategory(false);
      }
    },
  };

  const {
    data: details,
    refetch,
    isLoading: isLoadingevents,
    isFetching,
  } = FetchData("event-details", `Admin/get-event-by-id?id=${event}`, actions);

  const { data: groupData } = FetchData(
    "group-data",
    `Admin/get-all-group`,
    groupActions
  );

  const { mutate: deleteGroup, isLoading: isGroupFetching } = PostQuery(
    "/Admin/delete-event-category-map",
    "post",
    deleteActions
  );
  const { mutate: postEventCat, isLoading: isCategoryLoading } = PostQuery(
    "/Admin/add-event-category",
    "post",
    catActions
  );

  function handleMultiGroup(item) {
    setselectedMultiGroup(item);
  }

  function handleMultiSubgroup(i, item) {
    const list = [...selectedMultiSubgroup];
    list[i] = item;
    setselectedMultiSubgroup(list);
  }

  const subList = () => {
    let subListing = [];
    selectedMultiSubgroup?.forEach((element) => {
      element?.forEach((ele) => {
        subListing.push(ele);
      });
    });

    return subListing;
  };

  const catList = () => {
    let catListing = [];
    selectedMultiCategory?.forEach((element) => {
      element?.forEach((ele) => {
        let obj = {};
        obj["Event_Id"] = JSON.parse(event);
        obj["Category_Id"] = ele.cat;
        obj["SubGroup_Id"] = ele.subgroup;
        obj["Group_Id"] = ele.group;
        catListing.push(obj);
      });
    });
    return catListing;
  };

  function handleMultiCategory(i, item) {
    const list = [...selectedMultiCategory];
    list[i] = item;
    setselectedMultiCategory(list);
  }

  const handleDeleteOrder = () => {
    let data = {
      Event_id: Number(event),
      Category_id: Number(order?.categoryId),
    };
    deleteGroup(data);
  };

  const handleSubmitGroups = (e) => {
    e.preventDefault();
    if (selectedMultiGroup?.length > 0) {
      selectedMultiGroup.map((data) => {
        let optionList = [];
        setIsGroupLoading(true);
        axios
          .get(`Admin/get-subgroup-of-group-by-id?groupid=${data.value}`)
          .then((apiResponse) => {
            setIsGroupLoading(false);
            let data = apiResponse?.data?.responseData?.reverse();
            data &&
              data.map((item) => {
                let obj = {};
                if (item.is_active === "Published") {
                  obj["title"] = data.label;
                  obj["label"] = item.sub_group_name;
                  obj["value"] = item.sub_group_id;
                  optionList.push(obj);
                }
              });
          });
        setSubGroupData((prev) => [...prev, optionList]);
      });
      setShowGroup(true);
    }
  };

  const handleSubmitSubgroups = (e) => {
    subList()?.map((data) => {
      let optionList = [];
      setIsSubGroupLoading(true);
      axios
        .get(`Admin/get-category-of-subgroup-by-id?subgroupid=${data.value}`)
        .then((apiResponse) => {
          setIsSubGroupLoading(false);
          let data = apiResponse?.data?.responseData?.reverse();
          data &&
            data.map((item) => {
              let obj = {};
              obj["title"] = data.label;
              obj[
                "label"
              ] = `${item.region.region_name}: ${item.category_name}`;
              obj["cat"] = item.category_id;
              obj["value"] = item.category_id;
              obj["group"] = item.group.id;
              obj["subgroup"] = item.subgroup.id;
              item.is_active === "Publish" && optionList.push(obj);
            });
        });
      setCategoryData((prev) => [...prev, optionList]);
    });
    setShowSubgroup(true);
    setShowCategory(true);
    e.preventDefault();
  };

  const handleSubmitCategory = (e) => {
    e.preventDefault();
    const data = {
      AddEventCategoryRequestList: catList(),
    };
    postEventCat(data);
  };

  const region = [
    ...new Set(details?.event_categorydata?.map((item) => item.regionName)),
  ];

  useEffect(() => {
    setAwardName(name?.replaceAll("%20", " "));
  }, [name]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Breadcrumbs title="Awards" breadcrumbItem="Add Categories" />
        <Container fluid>
          <Row>
            <Col xs="12">
              {details?.event_categorydata?.length > 0 && (
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center mb-4">
                      <div className="avatar-xs me-3 ">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-add-to-queue text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">
                        Selected Categories for Award: {awardName}
                      </h3>
                    </div>

                    {details?.event_categorydata && (
                      <h5 className="font-size-25 mb-0">
                        Selected Regions:- {region?.join(", ")}{" "}
                      </h5>
                    )}
                    <div>
                      {details?.event_categorydata && (
                        <TableContainer
                          columns={columns}
                          data={details?.event_categorydata}
                          isGlobalFilter={false}
                          isAddOptions={false}
                          customPageSize={details?.event_categorydata.length}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>

            <Col xs="12">
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex align-items-center mb-4">
                      <div className="avatar-xs me-3 ">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-add-to-queue text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">
                        Add Categories : {awardName}
                      </h3>
                    </div>
                  </div>
                  <Form>
                    <Row>
                      <Col sm="12">
                        {!showGroup ? (
                          <>
                            <div className="mb-3">
                              <Label className="form-label">
                                Select Groups:
                              </Label>
                              <Select
                                // value={optionGroup}
                                isMulti={true}
                                onChange={(e) => {
                                  handleMultiGroup(e);
                                }}
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                            <div
                              className="d-flex flex-wrap gap-2 mt-4"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <Button
                                type="submit"
                                color="primary"
                                className="btn "
                                disabled={selectedMultiGroup === null}
                                onClick={handleSubmitGroups}
                              >
                                Show Sub-groups
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="mb-3">
                            <Label className="form-label">Select Groups:</Label>
                            <ul className="ps-3 mb-0">
                              {selectedMultiGroup?.map((item, i) => (
                                <Badge
                                  style={{ fontSize: "12px" }}
                                  key={i}
                                  className="me-2 bg-warning"
                                >
                                  {item.label}
                                </Badge>
                              ))}
                            </ul>
                          </div>
                        )}

                        {showGroup ? (
                          <>
                            <Row>
                              {!showSubgroup &&
                                subGroupData?.map((item, i) => (
                                  <Col sm="6">
                                    <div className="mb-3" key={i}>
                                      <Label className="form-label">
                                        Select Sub-Groups from{" "}
                                        {selectedMultiGroup[i]?.label}
                                      </Label>
                                      <Select
                                        isMulti={true}
                                        type="select"
                                        onChange={(e) => {
                                          handleMultiSubgroup(i, e);
                                        }}
                                        options={item}
                                        classNamePrefix="select2-selection"
                                      />
                                    </div>
                                  </Col>
                                ))}
                            </Row>
                            {!showSubgroup && (
                              <div
                                className="d-flex flex-wrap gap-2 mt-4"
                                style={{ justifyContent: "flex-end" }}
                              >
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="btn "
                                  disabled={subList()?.length === 0}
                                  onClick={handleSubmitSubgroups}
                                >
                                  Show categories
                                </Button>
                              </div>
                            )}
                            {showSubgroup && (
                              <div className="mb-3">
                                <Label className="form-label">
                                  Selected Sub-groups:
                                </Label>
                                <ul className="ps-3 mb-0">
                                  {subList()?.map((item, i) => (
                                    <Badge
                                      style={{ fontSize: "12px" }}
                                      key={i}
                                      className="me-2 bg-warning"
                                    >
                                      {item.label}
                                    </Badge>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        ) : null}
                        {showCategory && (
                          <>
                            <Row>
                              {categoryData.map((item, i) => (
                                <Col sm="6">
                                  <div key={i} className="mb-3">
                                    <Label className="form-label">
                                      Select Categories from{" "}
                                      {subList()[i]?.label}:
                                    </Label>
                                    <Select
                                      isMulti={true}
                                      type="select"
                                      onChange={(e) => {
                                        handleMultiCategory(i, e);
                                      }}
                                      options={item}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </Col>
                              ))}
                            </Row>
                            <div
                              className="d-flex flex-wrap gap-2 mt-4"
                              style={{ justifyContent: "flex-end" }}
                            >
                              <Button
                                type="submit"
                                color="primary"
                                className="btn "
                                disabled={catList().length === 0}
                                onClick={handleSubmitCategory}
                              >
                                Submit
                              </Button>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {(isLoadingevents ||
            isGroupLoading ||
            isSubGroupLoading ||
            isCategoryLoading ||
            isFetching) && <ScreenLoader />}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Index;
