import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const showReasonModal = (props) => {
  const {
    isOpen,
    toggle,
    data,

    // onSubmitClick,
    // setReson,
    // reson,
    // handleReson,
    // errorMess,
    // setErrorMess,
    // handleChnageReson,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Rejection Reason </ModalHeader>
        <ModalBody>
          <p>{data}</p>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default showReasonModal;
