import Breadcrumbs from "@components/Common/Breadcrumb";
import classNames from "classnames";

import * as moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import ConfirmModal from "../Common/ConfirmModel";
import DeleteModal from "../Common/DeleteModal";
import DetailsCard from "../Common/EntryDetailsCard";
import ErrorAlertBox from "../Common/ErrorAlertBox";
import FormHeader from "../Common/FormHeader";
import ScreenLoader from "../Common/ScreenLoader";
import { DatePicker } from "../Form/DatePicker";
import { InputField } from "../Form/Input";
import SelectInput from "../Form/SelectInput";

const UserIndex = (props) => {
  const Img_url = process.env.REACT_APP_IMG_URL;

  let date =
    props.choosenEvent &&
    moment(props.choosenEvent[0]?.eligibility_start_date).format("DD/MM/YYYY");
  return (
    <div>
      {" "}
      <DeleteModal
        show={props.deleteModal}
        onDeleteClick={props.handleDeleteGroup}
        onCloseClick={() => props.setDeleteModal(false)}
      />
      <ConfirmModal
        show={props.confirmModal}
        onClick={props.handleConfirmEntry}
        onCloseClick={() => props.setConfirmModal(false)}
        message={
          "You want to submit this entry form ? Once  Submitted, you will not be able to edit the form. The form submitted now will be your Final entry. Proceed"
        }
      />
      <ErrorAlertBox
        show={props.fileSizeError}
        onClick={props.handleClose}
        onCloseClick={() => props.setFileSizeError(false)}
        message={props.errorMess}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem={
              props.action === "/add-entry" ? "Add Entry" : "Duplicate Entry"
            }
          />
          {props.action !== "/add-entry" && (
            <DetailsCard entryDetails={props.entryDetails} />
          )}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={
                      props.action === "/add-entry"
                        ? "Add Entry"
                        : "Duplicate Entry"
                    }
                  />

                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classNames({
                            current: props.activeTab === 1,
                          })}
                        >
                          <NavLink
                            className={classNames({
                              current: props.activeTab === 1,
                            })}
                            onClick={() => {
                              props.setactiveTab(props.activeTab);
                            }}
                            disabled={
                              !(props.passedSteps || []).includes(
                                props.activeTab
                              )
                            }
                          >
                            <span className="number">1.</span> Basic Details
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classNames({
                            current: props.activeTab === 2,
                          })}
                        >
                          <NavLink
                            className={classNames({
                              active: props.activeTab === 2,
                            })}
                            onClick={() => {
                              props.setactiveTab(2);
                            }}
                            disabled={!(props.passedSteps || []).includes(2)}
                          >
                            <span className="number ms-2">02</span> Entry
                            Details
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="wizard clearfix">
                <div className="content clearfix mt-4">
                  <TabContent activeTab={props.activeTab}>
                    <TabPane tabId={1}>
                      <Form>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col sm="12">
                                <Row>
                                  <Col sm="12">
                                    <div className="mb-3">
                                      <SelectInput
                                        label={"Region"}
                                        name={"region"}
                                        onChange={(e) => {
                                          props.handleSelectRegion(e);
                                        }}
                                        value={props.selectedRegion}
                                        options={props.optionRegion}
                                        onBlur={props.formik.handleBlur}
                                        invalid={
                                          props.formik.touched.region &&
                                          props.formik.errors.region
                                            ? true
                                            : false
                                        }
                                        formik={props.formik}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <SelectInput
                                        label={"Event"}
                                        name={"event"}
                                        onChange={(e) => {
                                          props.handleSelectEventList(e);
                                        }}
                                        options={props.eventList || []}
                                        onBlur={props.formik.handleBlur}
                                        invalid={
                                          props.formik.touched.event &&
                                          props.formik.errors.event
                                            ? true
                                            : false
                                        }
                                        formik={props.formik}
                                        value={props.selectedEventList}
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <SelectInput
                                        label={" SELECT GROUP*"}
                                        name={"group"}
                                        onChange={(e) => {
                                          props.handleSelectGroup(e);
                                        }}
                                        options={props.groupList || []}
                                        onBlur={props.formik.handleBlur}
                                        invalid={
                                          props.formik.touched.group &&
                                          props.formik.errors.group
                                            ? true
                                            : false
                                        }
                                        formik={props.formik}
                                        value={props.selectedGroup}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col sm="6">
                                    {" "}
                                    <div className="mb-3">
                                      <SelectInput
                                        label={"SELECT SUBGROUP*"}
                                        name={"subGroup"}
                                        onChange={(e) => {
                                          props.handleSelectSubGroup(e);
                                        }}
                                        type={"select"}
                                        options={props.optionSubGroup || []}
                                        onBlur={props.formik.handleBlur}
                                        invalid={
                                          props.formik.touched.subGroup &&
                                          props.formik.errors.subGroup
                                            ? true
                                            : false
                                        }
                                        formik={props.formik}
                                        value={props.selectedSubGroup}
                                      />
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    {" "}
                                    <div className="mb-3">
                                      <SelectInput
                                        label={"SELECT CATEGORY *"}
                                        name={"category"}
                                        type={"select"}
                                        onChange={(e) => {
                                          props.handleSelectCategory(e);
                                        }}
                                        options={props?.optionCategory || []}
                                        classNamePrefix="select2-selection"
                                        onBlur={props.formik.handleBlur}
                                        invalid={
                                          props.formik.touched.category &&
                                          props.formik.errors.category
                                            ? true
                                            : false
                                        }
                                        formik={props.formik}
                                        value={props.selectedCategory}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <div className="mb-3">
                                  <InputField
                                    label={"EVENT / CAMPAIGN / PROJECT NAME *"}
                                    id={"projectName"}
                                    name={"projectName"}
                                    type={"text"}
                                    className={"form-control"}
                                    value={
                                      props.formik.values.projectName || ""
                                    }
                                    onChange={props.formik.handleChange}
                                    onBlur={props.formik.handleBlur}
                                    classNamePrefix="select2-selection"
                                    invalid={
                                      props.formik.touched.projectName &&
                                      props.formik.errors.projectName
                                        ? true
                                        : false
                                    }
                                    placeholder={"Enter Event Name"}
                                    validation={props.formik}
                                  />
                                </div>

                                <div className="mb-3">
                                  {props.choosenEvent ? (
                                    <p>
                                      {`Eligibility date to include campaigns that
                                      launched, debuted or concluded between
                                      ${moment(
                                        props.choosenEvent[0]
                                          ?.eligibility_start_date
                                      ).format("DD/MM/YYYY")} to
                                      ${moment(
                                        props.choosenEvent[0]
                                          ?.eligibility_end_date
                                      ).format("DD/MM/YYYY")}`}
                                    </p>
                                  ) : null}

                                  <Label htmlFor="entrant">
                                    EVENT / CAMPAIGN START DATE (IN CASE OF A
                                    SINGLE DAY EVENT, SELECT THE END DATE SAME
                                    AS THE START DATE) *
                                  </Label>
                                </div>
                                <Row>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <DatePicker
                                        label={"Event Start date"}
                                        name={"eventStart"}
                                        className={"form-control d-block"}
                                        placeholder={"Select Event date"}
                                        options={{
                                          // mode: "range",
                                          dateFormat: "d/m/Y",
                                          maxDate:
                                            props.choosenEvent &&
                                            moment(
                                              props.choosenEvent[0]
                                                ?.eligibility_end_date
                                            ).format("DD/MM/YYYY"),
                                          minDate:
                                            props.choosenEvent &&
                                            moment(
                                              props.choosenEvent[0]
                                                ?.eligibility_start_date
                                            ).format("DD/MM/YYYY"),

                                          // maxDate:
                                          //   props.choosenEvent &&
                                          //   moment(
                                          //     props.choosenEvent[0]
                                          //       ?.eligibility_end_date
                                          //   ).format("DD/MM/YYYY"),
                                          // defaultDate: "01/03/2022",
                                          // minDate: "01/03/2022",
                                          // enable: [
                                          //   {
                                          //     from: date,
                                          //     to:
                                          //       props.choosenEvent &&
                                          //       moment(
                                          //         props.choosenEvent[0]
                                          //           ?.eligibility_end_date
                                          //       ).format("DD/MM/YYYY"),
                                          //   },
                                          // ],
                                        }}
                                        onChange={(e, val) =>
                                          props.formik.setFieldValue(
                                            "eventStart",
                                            e
                                          )
                                        }
                                        onBlur={props.formik.handleBlur}
                                        value={
                                          (props.action === "/add-entry"
                                            ? props.formik.values.eventStart
                                            : moment(
                                                props.formik.values
                                                  .eventStart[0]
                                              ).format("DD-MM-YYYY")) || ""
                                        }
                                        invalid={
                                          props.formik.touched.eventStart &&
                                          props.formik.errors.eventStart
                                            ? true
                                            : false
                                        }
                                        validation={props.formik}
                                      />
                                      {/* <Label htmlFor="eventStart">
                                        Event Start & End date
                                      </Label>
                                      <InputGroup>
                                        <Flatpickr
                                          name="eventStart"
                                          className="form-control d-block"
                                          placeholder="Select Event date"
                                          options={{
                                            mode: "range",
                                            dateFormat: "d/m/Y",
                                            maxDate:
                                              props.choosenEvent &&
                                              moment(
                                                props.choosenEvent[0]
                                                  ?.eligibility_end_date
                                              ).format("DD/MM/YYYY"),
                                            minDate:
                                              props.choosenEvent &&
                                              moment(
                                                props.choosenEvent[0]
                                                  ?.eligibility_start_date
                                              ).format("DD/MM/YYYY"),
                                          }}
                                          onChange={(e, val) =>
                                            props.formik.setFieldValue(
                                              "eventStart",
                                              e
                                            )
                                          }
                                          onBlur={props.formik.handleBlur}
                                          value={
                                            props.formik.values.eventStart || ""
                                          }
                                          invalid={
                                            props.formik.touched.eventStart &&
                                            props.formik.errors.eventStart
                                              ? true
                                              : false
                                          }
                                        />
                                      </DatePicker>
                                      {props.formik.touched.eventStart &&
                                      props.formik.errors.eventStart ? (
                                        <FormFeedback
                                          type="invalid"
                                          style={{ display: "flex" }}
                                        >
                                          {props.formik.errors.eventStart}
                                        </FormFeedback>
                                      ) : null} */}
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <DatePicker
                                        label={"Event End date"}
                                        name={"eventEnd"}
                                        className={"form-control d-block"}
                                        placeholder={"Select Event date"}
                                        options={{
                                          // mode: "range",
                                          dateFormat: "d/m/Y",
                                          maxDate:
                                            props.choosenEvent &&
                                            moment(
                                              props.choosenEvent[0]
                                                ?.eligibility_end_date
                                            ).format("DD/MM/YYYY"),
                                          minDate:
                                            props.choosenEvent &&
                                            moment(
                                              props.choosenEvent[0]
                                                ?.eligibility_start_date
                                            ).format("DD/MM/YYYY"),
                                        }}
                                        onChange={(e, val) =>
                                          props.formik.setFieldValue(
                                            "eventEnd",
                                            e
                                          )
                                        }
                                        onBlur={props.formik.handleBlur}
                                        value={
                                          (props.action === "/add-entry"
                                            ? props.formik.values.eventEnd
                                            : moment(
                                                props.formik.values.eventEnd[0]
                                              ).format("DD-MM-YYYY")) || ""
                                        }
                                        invalid={
                                          props.formik.touched.eventEnd &&
                                          props.formik.errors.eventEnd
                                            ? true
                                            : false
                                        }
                                        validation={props.formik}
                                      />
                                      {/* <Label htmlFor="eventStart">
                                        Event Start & End date
                                      </Label>
                                      <InputGroup>
                                        <Flatpickr
                                          name="eventStart"
                                          className="form-control d-block"
                                          placeholder="Select Event date"
                                          options={{
                                            mode: "range",
                                            dateFormat: "d/m/Y",
                                            maxDate:
                                              props.choosenEvent &&
                                              moment(
                                                props.choosenEvent[0]
                                                  ?.eligibility_end_date
                                              ).format("DD/MM/YYYY"),
                                            minDate:
                                              props.choosenEvent &&
                                              moment(
                                                props.choosenEvent[0]
                                                  ?.eligibility_start_date
                                              ).format("DD/MM/YYYY"),
                                          }}
                                          onChange={(e, val) =>
                                            props.formik.setFieldValue(
                                              "eventStart",
                                              e
                                            )
                                          }
                                          onBlur={props.formik.handleBlur}
                                          value={
                                            props.formik.values.eventStart || ""
                                          }
                                          invalid={
                                            props.formik.touched.eventStart &&
                                            props.formik.errors.eventStart
                                              ? true
                                              : false
                                          }
                                        />
                                      </DatePicker>
                                      {props.formik.touched.eventStart &&
                                      props.formik.errors.eventStart ? (
                                        <FormFeedback
                                          type="invalid"
                                          style={{ display: "flex" }}
                                        >
                                          {props.formik.errors.eventStart}
                                        </FormFeedback>
                                      ) : null} */}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        {props.formik.values.IsClient_Approval_Require && (
                          <Card>
                            <CardBody>
                              <CardTitle>CLIENT DETAILS</CardTitle>
                              <Row>
                                <Col sm="2">
                                  <div className="mb-3">
                                    <SelectInput
                                      label={"Salutation"}
                                      name={"salutation"}
                                      type={"select"}
                                      value={props.selectedSalutation}
                                      onChange={(e) => {
                                        props.handleSelectSalutation(e);
                                      }}
                                      options={props.salutationOption || []}
                                    />
                                  </div>
                                </Col>
                                <Col sm="10">
                                  <div className="mb-3">
                                    <InputField
                                      label={"CLIENT NAME*"}
                                      id={"clientName"}
                                      name={"clientName"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={
                                        props.formik.values.clientName || ""
                                      }
                                      invalid={
                                        props.formik.touched.clientName &&
                                        props.formik.errors.clientName
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Designation*"}
                                      id={"designation"}
                                      name={"designation"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={
                                        props.formik.values.designation || ""
                                      }
                                      invalid={
                                        props.formik.touched.designation &&
                                        props.formik.errors.designation
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <InputField
                                      label={" COMPANY / ASSOCIATION NAME *"}
                                      id={"company"}
                                      name={"company"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={props.formik.values.company || ""}
                                      invalid={
                                        props.formik.touched.company &&
                                        props.formik.errors.company
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="6">
                                  {" "}
                                  <div className="mb-3">
                                    <InputField
                                      label={" DIRECT NUMBER *"}
                                      id={"mobile"}
                                      name={"mobile"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={props.formik.values.mobile || ""}
                                      invalid={
                                        props.formik.touched.mobile &&
                                        props.formik.errors.mobile
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Email*"}
                                      id={"email"}
                                      name={"email"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={props.formik.values.email || ""}
                                      invalid={
                                        props.formik.touched.email &&
                                        props.formik.errors.email
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="6">
                                  {" "}
                                  <div className="mb-3">
                                    <InputField
                                      label={"Country*"}
                                      id={"country"}
                                      name={"country"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={props.formik.values.country || ""}
                                      invalid={
                                        props.formik.touched.country &&
                                        props.formik.errors.country
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <InputField
                                      label={"City*"}
                                      id={"city"}
                                      name={"city"}
                                      type={"text"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      value={props.formik.values.city || ""}
                                      invalid={
                                        props.formik.touched.city &&
                                        props.formik.errors.city
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        )}
                        <Card>
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="form-check mb-3">
                                <Input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="IsClient_Approval_Require"
                                  name="IsClient_Approval_Require"
                                  onChange={props.handleCheckTerms}
                                  onBlur={props.formik.handleBlur}
                                  invalid={
                                    props.formik.touched
                                      .IsClient_Approval_Require &&
                                    props.formik.errors
                                      .IsClient_Approval_Require
                                      ? true
                                      : false
                                  }
                                  value={
                                    props.formik.values
                                      .IsClient_Approval_Require || ""
                                  }
                                  checked={
                                    !props.formik.values
                                      .IsClient_Approval_Require
                                      ? true
                                      : false
                                  }
                                />

                                <Label
                                  className="form-check-label mb-3"
                                  htmlFor="IsClient_Approval_Require"
                                >
                                  {" "}
                                  Incase Client Approval is not required,
                                  request you to select the same and mention the
                                  reason the client approval is not relevant to
                                  this event / campaign
                                </Label>

                                {!props.formik.values
                                  .IsClient_Approval_Require && (
                                  <div>
                                    <InputField
                                      id={"Client_Approval_Des"}
                                      name={"Client_Approval_Des"}
                                      type={"textarea"}
                                      className={"form-control"}
                                      onChange={props.formik.handleChange}
                                      onBlur={props.formik.handleBlur}
                                      rows={"5"}
                                      value={
                                        props.formik.values
                                          .Client_Approval_Des || ""
                                      }
                                      invalid={
                                        props.formik.touched
                                          .Client_Approval_Des &&
                                        props.formik.errors.Client_Approval_Des
                                          ? true
                                          : false
                                      }
                                      validation={props.formik}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <Form>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <InputField
                                    label={"Q1. EXECUTIVE SUMMARY"}
                                    id={"adddSummary"}
                                    name={"adddSummary"}
                                    type={"textarea"}
                                    className={"form-control"}
                                    placeholder="Enter Your Summary "
                                    onChange={props.detailsFormik.handleChange}
                                    onBlur={props.detailsFormik.handleBlur}
                                    value={
                                      props.detailsFormik.values.adddSummary ||
                                      ""
                                    }
                                    invalid={
                                      props.detailsFormik.touched.adddSummary &&
                                      props.detailsFormik.errors.adddSummary
                                        ? true
                                        : false
                                    }
                                    validation={props.detailsFormik}
                                  />
                                </div>
                                <div className="mb-3">
                                  <InputField
                                    label={" Q2. BRIEF / OBJECTIVE"}
                                    id={"addObjective"}
                                    name={"addObjective"}
                                    type={"textarea"}
                                    className={"form-control"}
                                    placeholder="Enter Your Objective"
                                    onChange={props.detailsFormik.handleChange}
                                    onBlur={props.detailsFormik.handleBlur}
                                    value={
                                      props.detailsFormik.values.addObjective ||
                                      ""
                                    }
                                    invalid={
                                      props.detailsFormik.touched
                                        .addObjective &&
                                      props.detailsFormik.errors.addObjective
                                        ? true
                                        : false
                                    }
                                    validation={props.detailsFormik}
                                  />
                                </div>
                                <div className="mb-3">
                                  <InputField
                                    label={" Q3. CONCEPT"}
                                    id={"addConcept"}
                                    name={"addConcept"}
                                    type={"textarea"}
                                    className={"form-control"}
                                    placeholder="Enter Your Concept"
                                    onChange={props.detailsFormik.handleChange}
                                    onBlur={props.detailsFormik.handleBlur}
                                    value={
                                      props.detailsFormik.values.addConcept ||
                                      ""
                                    }
                                    invalid={
                                      props.detailsFormik.touched.addConcept &&
                                      props.detailsFormik.errors.addConcept
                                        ? true
                                        : false
                                    }
                                    validation={props.detailsFormik}
                                  />
                                </div>
                                <div className="mb-3">
                                  <InputField
                                    label={" Q4. EXECUTION / AMPLIFICATION"}
                                    id={"addAmplification"}
                                    name={"addAmplification"}
                                    type={"textarea"}
                                    className={"form-control"}
                                    placeholder="Enter Your Execution"
                                    onChange={props.detailsFormik.handleChange}
                                    onBlur={props.detailsFormik.handleBlur}
                                    value={
                                      props.detailsFormik.values
                                        .addAmplification || ""
                                    }
                                    invalid={
                                      props.detailsFormik.touched
                                        .addAmplification &&
                                      props.detailsFormik.errors
                                        .addAmplification
                                        ? true
                                        : false
                                    }
                                    validation={props.detailsFormik}
                                  />
                                </div>
                                <div className="mb-3">
                                  <InputField
                                    label={" Q5. RESULTS / SCALE"}
                                    id={"addScale"}
                                    name={"addScale"}
                                    type={"textarea"}
                                    className={"form-control"}
                                    placeholder="Enter Your Result"
                                    onChange={props.detailsFormik.handleChange}
                                    onBlur={props.detailsFormik.handleBlur}
                                    value={
                                      props.detailsFormik.values.addScale || ""
                                    }
                                    invalid={
                                      props.detailsFormik.touched.addScale &&
                                      props.detailsFormik.errors.addScale
                                        ? true
                                        : false
                                    }
                                    validation={props.detailsFormik}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card>
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <CardTitle>Upload</CardTitle>
                              </div>
                              <div>
                                {props.getPhotLength === 4 ? null : (
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn "
                                    onClick={props.handleAddPhoto}
                                  >
                                    Add Photo
                                  </Button>
                                )}
                              </div>
                            </div>

                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <Label htmlFor="productname">Photo</Label>
                                  <span
                                    style={{
                                      display: "block",
                                    }}
                                  >
                                    {" "}
                                    (Image size should not exceed 5mb* (each
                                    image 1 mb*))
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",
                                      position: "relative",
                                    }}
                                  >
                                    {props?.getPhotos?.map((data, i) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            position: "relative",
                                            marginTop: "12px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              backgroundImage: `url(${Img_url}${data?.photo})`,
                                              // backgroundImage: `url(https://api.wowawards.com${data?.photo})`,
                                              backgroundPosition: "top center",
                                              backgroundSize: "cover",
                                              height: "80px",
                                              backgroundRepeat: "no-repeat",
                                              width: "80px",
                                              marginRight: "16px",
                                              borderRadius: "10px",
                                              border: "2px solid #CC962B",
                                            }}
                                          >
                                            {" "}
                                            <Link
                                              to={
                                                props.action === "/add-entry"
                                                  ? `add-entry?id=${props.entryId}`
                                                  : `duplicate-entry?id=${props.entryId}`
                                              }
                                              className="text-danger photo-danger"
                                              onClick={() => {
                                                props.onClickDelete(data);
                                              }}
                                              style={{
                                                position: "absolute",
                                                top: " -15px",
                                                right: "1px",
                                              }}
                                            >
                                              <i
                                                className="mdi mdi-delete font-size-22 me-2"
                                                id="deletetooltip"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="deletetooltip"
                                              >
                                                Delete
                                              </UncontrolledTooltip>
                                            </Link>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>

                                  {props.photoInput.map((data, i) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          margin: "15px 0",
                                        }}
                                      >
                                        <Input
                                          className="form-control"
                                          name="photo"
                                          type="file"
                                          id="formFile"
                                          // value={toString(data)}
                                          onChange={(e) =>
                                            props.handleChangePhoto(e, i)
                                          }
                                          accept="image/x-png,image/gif,image/jpeg"
                                          onBlur={
                                            props.detailsFormik.handleBlur
                                          }
                                          invalid={
                                            props.detailsFormik.touched.photo &&
                                            props.detailsFormik.errors.photo
                                              ? true
                                              : false
                                          }
                                          data-max-size="5120"
                                        />

                                        <s
                                          to="#"
                                          sty
                                          className="text-danger"
                                          onClick={() => {
                                            props.handlePhotoDelete(i);
                                          }}
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-22 me-2"
                                            id="deletetooltip"
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="deletetooltip"
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </s>
                                      </div>
                                    );
                                  })}
                                  {/* {props.photoInput?.length > 0 && (
                                    <span
                                      style={{ color: "red", fontSize: "10px" }}
                                    >
                                      {props.errorMess}
                                    </span>
                                  )} */}
                                  {props.detailsFormik.touched.photo &&
                                  props.getPhotos?.length === 0 &&
                                  props.detailsFormik.errors.photo ? (
                                    <FormFeedback
                                      type="invalid"
                                      style={{ display: "flex" }}
                                    >
                                      {" "}
                                      {props.detailsFormik.errors.photo}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <CardTitle>Links</CardTitle>
                              </div>
                              <div>
                                {props?.videoLinkInput?.length === 2 ? null : (
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn "
                                    onClick={props.handleAddVideoLinks}
                                  >
                                    Add Links
                                  </Button>
                                )}
                              </div>
                            </div>

                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <Label htmlFor="video">
                                    Video/Webpage links
                                  </Label>
                                  <span
                                    style={{
                                      display: "block",
                                      margin: "10px 0",
                                    }}
                                  >
                                    {" "}
                                    (Please add video link of Youtube, Vimeo or
                                    Social Media or G-drive with view access)*
                                  </span>{" "}
                                  <span style={{ display: "block" }}>
                                    {" "}
                                    (Video link should not exceed more than 2
                                    min's)*
                                  </span>
                                  {props?.videoLinkInput?.map((data, id) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          margin: "15px 0",
                                        }}
                                      >
                                        <Input
                                          className="form-control"
                                          type="text"
                                          id="link"
                                          name="video"
                                          placeholder="Enter link"
                                          defaultValue={data}
                                          onChange={(e) =>
                                            props.handleChangeVideoLinkInput(
                                              e,
                                              id
                                            )
                                          }
                                          onBlur={
                                            props.detailsFormik.handleBlur
                                          }
                                          invalid={
                                            props.detailsFormik.touched.video &&
                                            props.detailsFormik.errors.video
                                              ? true
                                              : false
                                          }
                                        />

                                        <Link
                                          to={`add-entry?id=${props.entryId}`}
                                          className="text-danger"
                                          onClick={() => {
                                            props.handleVideoLinkDelete(id);
                                          }}
                                        >
                                          <i
                                            className="mdi mdi-delete font-size-22 me-2"
                                            id="deletetooltip"
                                            onClick={() => {
                                              props.handleVideoLinkDelete(id);
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                          <UncontrolledTooltip
                                            placement="top"
                                            target="deletetooltip"
                                          >
                                            Delete
                                          </UncontrolledTooltip>
                                        </Link>
                                      </div>
                                    );
                                  })}
                                  {props.detailsFormik.touched.video &&
                                  props.detailsFormik.errors.video ? (
                                    <FormFeedback
                                      type="invalid"
                                      style={{ display: "flex" }}
                                    >
                                      {props.detailsFormik.errors.video}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Form>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    {props.activeTab === 2 && (
                      <li
                        className={
                          props.activeTab === 1
                            ? "previous disabled"
                            : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            props.submitHandler();
                          }}
                        >
                          Save
                        </Link>
                      </li>
                    )}
                    <li className={props.activeTab === 2 ? "" : "next"}>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (props.activeTab === 2) {
                            // if (props.detailsFormik.isValid) {
                            props.detailsFormik.handleSubmit();
                            // } else {
                            // window.scrollTo(0, 0);
                            // }
                          } else {
                            props.formik.handleSubmit();
                            // props.toggleTab(props.activeTab + 1);
                          }
                        }}
                      >
                        {props.activeTab === 2 ? "Submit" : "Next"}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {(props.isEntryDetailsLoading ||
          props.isEntryDetailsFetching ||
          props.eventLoading ||
          props.regionLoading ||
          props.isLoadingPostEntry ||
          props.isLoadingAddEntry) && <ScreenLoader />}
      </div>
    </div>
  );
};

export default UserIndex;
