import TableContainer from "@components/Common/TableContainer";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const TableCard = (props) => {
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle> {props.entrantName} - Entries</CardTitle>
          <div>
            {props.details?.entry_of_entrant ? (
              <TableContainer
                columns={props.columns}
                data={props.details?.entry_of_entrant}
                isGlobalFilter={props.isGlobalFilter}
                isAddOptions={props.isAddOptions}
                customPageSize={props.customPageSize}
              />
            ) : null}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TableCard;
