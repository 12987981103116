import { getFromStorage } from "@utils/storage";
import React from "react";
import AdminDashboard from "../Admin/Dashboard/index";
import AuditDashboard from "../Audit/Dashboard/index";
import JuryDashboard from "../Jury";
import UserDashboard from "../User/Dashboard/Index";

function Dashboard() {
  const userType = getFromStorage("type");

  if (userType === "Admin") {
    return <AdminDashboard />;
  } else if (userType === "Jury") {
    return <JuryDashboard />;
  } else if (userType === "User") {
    return <UserDashboard />;
  } else {
    return <AuditDashboard />;
  }
}

export default Dashboard;
