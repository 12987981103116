import { FetchData, PostQuery } from "@hooks/api/useApi";
import { getFromStorage } from "@utils/storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AddJuryPage from "../../../components/Jury/AddJuryPage";

function AddJury() {
  const getUserName = getFromStorage("userName");
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [addJuryError, setAddJuryError] = useState();
  const [optionAwards, setOptionAwards] = useState(null);
  const [selectedAward, setSelectedAward] = useState(null);

  const history = useHistory();
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  var symbols = "!@#$%^&*(){}[]=<>/.";

  const addJuryActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.push("/jury-list");
        formik.resetForm();
        setAddJuryError("");
      } else {
        setAddJuryError(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setAddJuryError("Something went wrong!");
    },
  };

  const { mutate, isLoading } = PostQuery(
    "Jury/create-jury-account",
    "post",
    addJuryActions
  );

  const actionsRegion = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const { data: regionList, isLoading: isLoadingRegion } = FetchData(
    "region",
    "Admin/get-all-region",
    actionsRegion
  );

  const [optionGroup, setOptionGroup] = useState(null);

  function handleSelectAward(item) {
    setSelectedAward(item);
    // setIsAward(item.label);

    // console.log("optionAwards", optionAwards);
    let filterGroupList = optionAwards[0]?.options.filter((eachVal) => {
      return eachVal.value === item.value;
    });

    let group = [];
    filterGroupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.groupName;
      obj["value"] = item.groupId;
      group.push(obj);
      const arrayUniqueByKey = [
        ...new Map(group.map((item) => [item["label"], item])).values(),
      ];
      setOptionGroup(arrayUniqueByKey);
    });
  }

  // const groupActions = {
  //   select: (data) => {
  //     const list = data.data.responseData;
  //     return list;
  //   },
  //   onSuccess: (data) => {
  //     let optionList = [];
  //     data.map((item) => {
  //       let obj = {};
  //       if (item.is_active === "Published") {
  //         obj["label"] = item.group_name;
  //         obj["value"] = item.group_id;
  //         optionList.push(obj);
  //         setOptionGroup(optionList);
  //       }
  //     });
  //   },
  // };
  // const { data: groupList, isLoading: isLoadingGroup } = FetchData(
  //   "groups",
  //   "Admin/get-all-group",
  //   groupActions
  // );

  useEffect(() => {
    if (regionList) {
      setSelectedRegion(regionList);
    }
  }, [regionList]);

  function handleSelectRegion(item) {
    setSelectedRegion(item);
    formik.setFieldValue("region", item?.value);
  }
  function handleSelectGroup(item) {
    setSelectedGroup(item);

    formik.setFieldValue(
      "group",
      item?.map((ele) => ele?.value)
    );
  }

  function generatePassword(length) {
    const symbols = "!@#$%^&*";
    const numbers = "0123456789";
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let password = "";

    // Generate at least one symbol
    const randomSymbolIndex = Math.floor(Math.random() * symbols.length);
    password += symbols[randomSymbolIndex];

    // Generate at least one number
    const randomNumberIndex = Math.floor(Math.random() * numbers.length);
    password += numbers[randomNumberIndex];

    // Generate remaining characters
    const remainingLength = length - 2;
    for (let i = 0; i < remainingLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Shuffle the password characters
    password = password
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    return password;
  }

  function generatePass() {
    formik.setFieldValue("password", generatePassword(15));
  }

  const formik = useFormik({
    initialValues: {
      region: "",
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
      group: [],
    },
    validationSchema: Yup.object({
      region: Yup.string().required("Please Select Region").nullable(),
      group: Yup.array().min(1, "Please Select Group").nullable(),
      firstName: Yup.string().required("Please Enter First Name"),
      lastName: Yup.string().required("Please Enter Last Name"),
      userName: Yup.string()
        .email("Email is Invalid")
        .required("Please Enter user Name"),
      password: Yup.string()
        .matches(
          passwordRegex,
          "password must be at least 8 characters with at least a symbol, upper and lower case letters and a number"
        )
        .required("Please Enter password"),
    }),
    onSubmit: (values) => {
      const juryList = {
        first_name: values.firstName,
        last_name: values.lastName,
        region_Id: values.region,
        user_name: values.userName,
        password: values.password,
        GroupId: values?.group,
      };

      mutate(juryList);
    },
  });

  const optionsList =
    regionList &&
    regionList.map((elem, index) => {
      return {
        label: elem.name,
        value: elem.id,
      };
    });

    const awardsActions = {
      select: (data) => {
        const list = data.data.responseData;
        return list;
      },
      onSuccess: (data) => {
        let optionList = [];
        data.event_list.map((item) => {
          // console.log("newObj", item);
          let obj = {};
          if (item.event_status === "Published") {
            obj["label"] = item.event_name;
            obj["value"] = item.event_id;
            obj["round"] = item.round;
            optionList.push({...item, ...obj});
            let getList = [];
            let newObj = {};
            newObj["options"] = optionList;
            getList.push(newObj);
            setOptionAwards(getList);
          }
        });
      },
    };
  
    const {
      data: eventsList,
      isFetching,
      isLoading:isEventLoading ,
      isFetched,
    } = FetchData("events", "/Admin/get-all-event", awardsActions);

  document.title = "Add Jury | WOW Awards";

  return (
    <React.Fragment>
      <AddJuryPage
        formik={formik}
        handleSelectRegion={handleSelectRegion}
        handleSelectGroup={handleSelectGroup}
        optionsList={optionsList}
        optionGroup={optionGroup}
        isLoading={isLoading}
        addJuryError={addJuryError}
        isLoadingRegion={isLoadingRegion}
        generatePass={generatePass}
        handleSelectAward={handleSelectAward}
        optionAwards={optionAwards}
      />
    </React.Fragment>
  );
}

export default AddJury;
