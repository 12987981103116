import { FetchData, PostQuery } from "@hooks/api/useApi";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import Subgroup from "../../../../components/Master/SubGroups/Subgroup";

function Index() {
  const [updateSubGroup, setUpdateSubGroup] = useState(null);
  const [optionList, setOptionList] = useState();
  document.title = "Orders | Skote - React Admin & Dashboard Template";
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  const actionsSubgroup = {
    select: (data) => {
      const list = data.data.responseData.filter(
        (eachVal) => eachVal.is_active === "Published"
      );
      return list;
    },
  };
  const { data: groupList, refetch: refetchGroup } = FetchData(
    "groups",
    "Admin/get-all-group",
    actionsSubgroup
  );

  useEffect(() => {
    let optionList = [];
    groupList?.map((item) => {
      let obj = {};
      obj["label"] = item.group_name;
      obj["value"] = item.group_id;
      optionList.push(obj);
      let getList = [];
      let newObj = {};
      newObj["options"] = optionList;
      getList.push(newObj);
      setOptionList(getList);
    });
  }, [groupList]);

  useEffect(() => {
    const getLabel =
      optionList &&
      optionList[0].options.find((item) => item.value === modalData?.group.id);
    setUpdateSubGroup(getLabel);
  }, [modalData, optionList]);

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: subGroupList,
    refetch,
    isLoading: isLoadingSubGroup,
    isFetching: isFetchingSubGroup,
    isFetched,
  } = FetchData("sub-groups", "Admin/get-all-subgroup", actions);

  const addActions = {
    onSuccess: async (data) => {
      if (data.data.status) {
        validation.resetForm();
        refetch();
        toggle();
      }
    },
  };
  const { mutate: addGroup, isLoading: isLoadingAddSubGroup } = PostQuery(
    "Admin/upsert-subgroup",
    "post",
    addActions
  );
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: true,
      title: "",
      desc: "",
      updateSubGroup: "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Subgroup Name"),
      desc: Yup.string().required("Please Write Description"),
      updateSubGroup: Yup.string().required("Please Select Group"),
    }),

    onSubmit: (values) => {
      let data = {
        group_Id: values.updateSubGroup,
        sub_group_name: values.title,
        sub_group_detail: values.desc,
        is_active: JSON.parse(values.status),
      };
      addGroup(data);
    },
  });

  const deleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setDeleteModal(false);
        refetch();
      } else {
        setDeleteModal(false);
        setShowErrorAlert(false);
        setShowErrorMsg(data.data.errors[0]);
      }
    },
  };

  const { mutate: deleteGroup } = PostQuery(
    "Admin/delete-subgroup",
    "post",
    deleteActions
  );

  const handleDeleteGroup = () => {
    const data = {
      Subgroup_Id: modalData.sub_group_id,
    };
    deleteGroup(data);
  };

  const updateActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
        updateToggle();
      }
    },
  };

  const { mutate: updateGroup, isLoading: isLoadingUpdateSubGroup } = PostQuery(
    "/Admin/upsert-Subgroup",
    "post",
    updateActions
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: true,
      title: "",
      desc: "",
      group: null,
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Subgroup Name"),
      desc: Yup.string().required("Please Write Description"),
    }),

    onSubmit: (values) => {
      let data = {
        sub_Group_Id: modalData.sub_group_id,
        group_Id: updateSubGroup.value,
        sub_group_name: values.title,
        sub_group_detail: values.desc,
        is_active: JSON.parse(values.status),
      };

      updateGroup(data);
    },
  });

  const toggleViewModal = () => {
    setModal1(!modal1);
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
      refetchGroup();
    }
  };

  const updateToggle = () => {
    if (updateModal) {
      setUpdateModal(false);
    } else {
      setUpdateModal(true);
      refetchGroup();
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (order) => {
    setDeleteModal(true);
    setModalData(order);
  };

  const handleUpdateSubGroup = (arg) => {
    setModalData(arg);
    formik.setValues({
      status: arg.is_active === "Draft" ? false : true,
      title: arg.sub_group_name,
      desc: arg.sub_group_detail,
      group: arg.group.name,
    });
    updateToggle();
  };

  const handleOrderClicks = () => {
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Date",
        accessor: "create_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              {moment(cellProps.row.original?.creted_date).format("DD/MM/YYYY")}
            </>
          );
        },
      },
      {
        Header: "Subgroup Name",
        accessor: "sub_group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Subgroup Description",
        accessor: "sub_group_detail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Group Name",
        accessor: "group.name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Status",
        accessor: "is_active",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.value === "Published" ? "success" : "danger")
              }
              pill
            >
              {cellProps.value === "Published" ? "Published" : "Draft"}
            </Badge>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const data = cellProps.row.original;
                  handleUpdateSubGroup(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="right" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="right" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  function handleSelectCountry(updateSubGroup) {
    setUpdateSubGroup(updateSubGroup);
    validation.setFieldValue("updateSubGroup", updateSubGroup.value);
  }
  function handleSelectGroupUpdate(item) {
    setUpdateSubGroup(item);
    formik.setFieldValue("group", item.value);
  }

  useEffect(() => {
    if (!showErrorAlert) {
      setTimeout(() => {
        setShowErrorAlert(!showErrorAlert);
        setShowErrorMsg("");
      }, 3000);
    }
  }, [showErrorAlert]);

  return (
    <React.Fragment>
      <Subgroup
        showErrorAlert={showErrorAlert}
        showErrorMsg={showErrorMsg}
        modal1={modal1}
        toggleViewModal={toggleViewModal}
        modalData={modalData}
        list={optionList}
        subGroupList={subGroupList}
        deleteModal={deleteModal}
        onDeleteClick={() => handleDeleteGroup()}
        onCloseClick={() => setDeleteModal(false)}
        isLoadingSubGroup={isLoadingSubGroup}
        isFetchingSubGroup={isFetchingSubGroup}
        onClick={() => handleOrderClicks()}
        columns={columns}
        isFetched={isFetched}
        validation={validation}
        modal={modal}
        toggle={toggle}
        isLoadingAddSubGroup={isLoadingAddSubGroup}
        onChange={(e) => handleSelectCountry(e)}
        options={optionList}
        formik={formik}
        updateModal={updateModal}
        updateToggle={updateToggle}
        isLoadingUpdateSubGroup={isLoadingUpdateSubGroup}
        onChangeUpdate={(e) => handleSelectGroupUpdate(e)}
        updateSubGroup={updateSubGroup}
      />
      {/* {!showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${showErrorAlert ? 'show' : ''}`}
          role="alert"
          style={{
            position: 'fixed',
            right: '10px',
            top: '12%',
            zIndex: '2000',
          }}
        >
          <i className="mdi mdi-block-helper me-2"></i>
          {showErrorMsg}
        </UncontrolledAlert>
      )}
      <GroupDetailsModal
        isOpen={modal1}
        toggle={toggleViewModal}
        data={modalData}
        list={optionList}
      />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGroup}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="d-flex align-items-center mb-3">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-sitemap text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">
                        Sub-groups{' '}
                        {subGroupList?.length > 0 &&
                          `(${subGroupList?.length})`}
                      </h3>
                      {(isLoadingSubGroup || isFetchingSubGroup) && (
                        <span>
                          <Spinner
                            style={{
                              height: '25px',
                              width: '25px',
                              marginLeft: '10px',
                            }}
                            color="primary"
                          />
                        </span>
                      )}
                    </div>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-sm "
                      onClick={() => handleOrderClicks()}
                    >
                      Add New Sub-group
                    </Button>
                  </div>

                  {subGroupList ? (
                    <TableContainer
                      columns={columns}
                      data={subGroupList}
                      isGlobalFilter={true}
                      customPageSize={10}
                    />
                  ) : null}
                  {isFetched && subGroupList.length === 0 && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <h4 className="text-primary">No data Found</h4>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AddSubgroup
            validation={validation}
            modal={modal}
            toggle={toggle}
            isLoadingAddSubGroup={isLoadingAddSubGroup}
            onChange={(e) => handleSelectCountry(e)}
            options={optionList}
          />
          <UpdateSubgroup
            formik={formik}
            updateModal={updateModal}
            updateToggle={updateToggle}
            isLoadingUpdateSubGroup={isLoadingUpdateSubGroup}
            onChange={(e) => handleSelectGroupUpdate(e)}
            options={optionList}
            updateSubGroup={updateSubGroup}
          />
        </div>
      </div> */}
    </React.Fragment>
  );
}

export default Index;
