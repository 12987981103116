import profileImg from '@assets/images/profile-img.png';
import { FetchData } from '@hooks/api/useApi';
import { getFromStorage } from '@utils/storage';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';

const WelcomeComp = () => {
  const getUserName = getFromStorage('userName');

  const actionsDashboard = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };

  const { data: dashboardList } = FetchData(
    'dashboard',
    'Dashbord/get-dashboard',
    actionsDashboard
  );

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className=" bg-soft" style={{ backgroundColor: '#111' }}>
          <Row>
            <Col xs="10">
              <div className="text-primary p-3">
                <h3 style={{ fontWeight: 'bold' }} className="text-primary">
                  Welcome {getUserName}!
                </h3>
                <h5 style={{ fontWeight: 'bold' }} className="text-primary">
                  Admin Dashboard
                </h5>
              </div>
            </Col>
            <Col xs="2" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeComp;
