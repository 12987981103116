import { PostQuery } from "@hooks/api/useApi";
import "flatpickr/dist/themes/material_blue.css";
import { useFormik } from "formik";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import AddEventPage from "../../../components/Events/AddEventPage";

const status = [
  { text: "Publish", value: 1 },
  { text: "Draft", value: 2 },
  { text: "Inactive", value: 3 },
];

function AddEvent() {
  const history = useHistory();
  document.title = "Add Event | WOW Awards";
  const [isErrorMsg, setisErrorMsg] = useState("");

  const actions = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.replace("/awards-list");
      } else {
        setisErrorMsg(data.data.errors[0]);
      }
    },
  };

  const { mutate, isLoading, isError } = PostQuery(
    "/Admin/add-event",
    "post",
    actions
  );

  const validation = useFormik({
    initialValues: {
      eventName: "",
      description: "",
      eventStart: [],
      regStart: [],
      eligibilityStart: [],
      eventEnd: [],
      regEnd: [],
      eligibilityEnd: [],
      status: 1,
      round: 1,
    },

    validationSchema: Yup.object({
      eventName: Yup.string().required("Please Enter Award Name"),
      description: Yup.string().required("Please Enter Description"),
      eventStart: Yup.array().min(1, "Please Select start date"),
      regStart: Yup.array().min(1, "Please Select start date"),
      eligibilityStart: Yup.array().min(1, "Please Select start date"),
      eventEnd: Yup.array().min(1, "Please Select end date"),
      regEnd: Yup.array().min(1, "Please Select end date"),
      eligibilityEnd: Yup.array().min(1, "Please Select end date"),
    }),
    onSubmit: (values) => {
      setisErrorMsg("");
      let data = {
        Event_Name: values.eventName,
        Event_Description: values.description,
        Event_Start_Date: moment(values.eventStart[0]).format("DD/MM/YYYY"),
        Event_End_Date: moment(values.eventEnd[0]).format("DD/MM/YYYY"),
        Registration_Start_Date: moment(values.regStart[0]).format(
          "DD/MM/YYYY"
        ),
        Registration_End_Date: moment(values.regEnd[0]).format("DD/MM/YYYY"),
        Eligibility_Start_Date: moment(values.eligibilityStart[0]).format(
          "DD/MM/YYYY"
        ),
        Eligibility_End_Date: moment(values.eligibilityEnd[0]).format(
          "DD/MM/YYYY"
        ),
        Event_Status: Number(values.status),
        Round: Number(values.round),
      };
      mutate(data);
    },
  });

  useEffect(() => {
    const checkRegDate =
      validation?.values?.regStart?.length > 0 &&
      moment(validation?.values?.regStart[0]).format("DD/MM/YYYY") ===
        moment(validation?.values?.regStart[1]).format("DD/MM/YYYY");

    const checkEventDate =
      validation?.values?.eventStart?.length > 0 &&
      moment(validation?.values?.eventStart[0]).format("DD/MM/YYYY") ===
        moment(validation?.values?.eventStart[1]).format("DD/MM/YYYY");

    const checkEligDate =
      validation?.values?.eligibilityStart?.length > 0 &&
      moment(validation?.values?.eligibilityStart[0]).format("DD/MM/YYYY") ===
        moment(validation?.values?.eligibilityStart[1]).format("DD/MM/YYYY");

    if (checkRegDate && validation?.values?.regStart?.length > 0) {
      validation.setFieldValue("regStart", []);
      validation.setFieldError("regStart", "Select start or end date");
    }
    if (checkEventDate && validation?.values?.eventStart?.length > 0) {
      validation.setFieldValue("eventStart", []);
      validation.setFieldError("eventStart", "Select start or end date");
    }
    if (checkEligDate && validation?.values?.eligibilityStart?.length > 0) {
      validation.setFieldValue("eligibilityStart", []);
      validation.setFieldError("eligibilityStart", "Select start or end date");
    }
  }, [
    validation?.values?.regStart,
    validation?.values?.eventStart,
    validation?.values?.eligibilityStart,
  ]);

  return (
    <React.Fragment>
      <AddEventPage
        validation={validation}
        isLoading={isLoading}
        isError={isError}
        history={history}
        status={status}
      />
    </React.Fragment>
  );
}

export default AddEvent;
