import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
import { deleteFromStorage, getFromStorage } from "../../utils/storage";

const SidebarContent = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location?.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }
  const userType = getFromStorage("type");

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {userType === "Admin" ? (
              <>
                <li>
                  <Link to="/">
                    <i className="bx bx-home-circle"></i>
                    <span>{"Dashboard"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-sitemap"></i>
                    <span>{"Master"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/group">{"Group"}</Link>
                    </li>
                    <li>
                      <Link to="/sub-group">{"Sub-group"}</Link>
                    </li>
                    <li>
                      <Link to="/category">{"Category"}</Link>
                    </li>
                    <li>
                      <Link to="/awards-list">{"Awards"}</Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-sitemap"></i>
                    <span>{"Awards"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/awards-list">{"Awards list"}</Link>
                    </li>
                    <li>
                      <Link to="/add-awards">{"Add Awards"}</Link>
                    </li>
                  </ul>
                </li> */}
                {/* <li>
                  <Link to="/register">
                    <i className="bx bx-food-menu"></i>
                    <span>{"Register"}</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/entrants">
                    <i className="bx bx-folder"></i>
                    <span>{"Entrants"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/entries">
                    <i className="bx bx-layer"></i>
                    <span>{"Entries"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-show-alt"></i>
                    <span>{"Jury"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/jury-list">{"Members list"}</Link>
                    </li>
                    <li>
                      <Link to="/add-jury">{"Add New Member"}</Link>
                    </li>
                    <li>
                      <Link to="/assign-jury">{"Assign Jury"}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/result">
                    <i className="bx bxs-report"></i>
                    <span>{"Report"}</span>
                  </Link>
                </li>
              </>
            ) : userType === "Jury" ? (
              <>
                {" "}
                <li>
                  <Link to="/"  onClick={()=> deleteFromStorage("url")}>
                    <i className="bx bx-home-circle"></i>
                    <span>{"Dashboard"}</span>
                  </Link>
                </li>
              </>
            ) : userType === "User" ? (
              <li>
                <Link to="/">
                  <i className="bx bx-home-circle"></i>
                  <span>{"Dashboard"}</span>
                </Link>
              </li>
            ) : (
              <>
                <li>
                  <Link to="/">
                    <i className="bx bx-home-circle"></i>
                    <span>{"Dashboard"}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-show-alt"></i>
                    <span>{"Jury"}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/jurylist">{"View jury List"}</Link>
                    </li>
                    {/* <li>
                      <Link to="/add-new-jury">{"Add New Jury"}</Link>
                    </li> */}
                    {/* <li>
                      <Link to="/assign-jury">{"Assign Jury"}</Link>
                    </li> */}
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default withRouter(SidebarContent);
