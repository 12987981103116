import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import profileImg from '@assets/images/profile-img.png';
import wowLogo from '@assets/images/wow-logo.svg';
import { PostQuery } from '@hooks/api/useApi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { InputField } from '../../components/Form/Input';

const ResetPassword = () => {
  document.title = 'Reset password | WOW Awards';
  const [errorMsg, setErrorMSg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const history = useHistory();
  const location = useLocation();
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const resetPasswordActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setSuccessMsg('Reset Password Suscessfull');
        setErrorMSg('');
        setTimeout(() => {
          history.push('/login');
        }, 3000);
        validation.resetForm();
      } else {
        setErrorMSg(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setSuccessMsg('');
      setErrorMSg('Something went wrong!');
    },
  };

  const { mutate, isLoading } = PostQuery(
    'Authentication/reset-password',
    'post',
    resetPasswordActions
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
      Confimpassword: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string()
        .matches(
          passwordRegex,
          'password must be at least 8 characters with at least a symbol, upper and lower case letters and a number'
        )
        .required('Please Enter Your Password'),
      Confimpassword: Yup.string()
        .required('Please Enter Your Confirm Password')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      setErrorMSg(' ');
      const getDetails = {
        user_name: values.email,
        reset_token: location?.search.slice(7),
        new_password: values.Confimpassword,
      };
      mutate(getDetails);
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                        <p>Enter new password</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={wowLogo} alt="" height="25" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <InputField
                          label={'Email'}
                          name={'email'}
                          type={'email'}
                          placeholder={'Enter email'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                        {/* <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null} */}
                      </div>

                      <div className="mb-3">
                        <InputField
                          label={'Enter new Password'}
                          name={'password'}
                          type={'password'}
                          placeholder={'Enter new Password'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                        {/* <Label className="form-label">Enter new Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter new Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        /> */}
                        {/* {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                      <div className="mb-3">
                        <InputField
                          label={'Confirm new Password'}
                          name={'Confimpassword'}
                          type={'password'}
                          placeholder={'Enter new Password'}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Confimpassword || ''}
                          invalid={
                            validation.touched.Confimpassword &&
                            validation.errors.Confimpassword
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                        {/* <Label className="form-label">
                          Confirm new Password
                        </Label>
                        <Input
                          name="Confimpassword"
                          className="form-control"
                          placeholder="Enter new Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.Confimpassword || ''}
                          invalid={
                            validation.touched.Confimpassword &&
                            validation.errors.Confimpassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.Confimpassword &&
                        validation.errors.Confimpassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.Confimpassword}
                          </FormFeedback>
                        ) : null} */}
                      </div>

                      <div className="text-end">
                        <Col xs="12" className="text-end">
                          <div className="mt-3 d-grid">
                            <Button
                              color="primary"
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={isLoading}
                            >
                              {isLoading && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              )}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </Form>
                    {errorMsg ? (
                      <div
                        style={{
                          color: 'red',
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        {errorMsg}
                      </div>
                    ) : null}
                    {successMsg ? (
                      <div
                        style={{
                          color: 'green',
                          textAlign: 'center',
                          marginTop: '10px',
                        }}
                      >
                        {successMsg}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Not you ? return{' '}
                  <Link to="/login" className="fw-medium text-primary">
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ResetPassword;
