// import middleEastLogo from "@assets/images/middle-east-logo.png";
import logo from "@assets/images/wow-awards-logo.png";
import React from "react";
import { Link } from "react-router-dom";

const RegisterHeader = (props) => {
  const urlParams = new URLSearchParams(window.location.search);

  return (
    <React.Fragment>
      <div
        style={{
          height: "70px",
          width: "100%",
          backgroundColor: "black",
          position: "fixed",
          zIndex: 10,
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "calc(64px / 2)",
          paddingRight: "calc(64px / 2)",
        }}
      >
        <Link to="/" className="logo logo-dark">
          <img src={logo} alt="" height="40" />
        </Link>

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "25px",
            fontWeight: "bold",
          }}
        >
          <Link
            to="https://asia.wowawards.com/categories/"
            style={{ color: "white" }}
          >
            FAQs
          </Link>
          <Link
            to="https://asia.wowawards.com/categories/"
            style={{ color: "white" }}
          >
            Explore Categories
          </Link>

        
        </div> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "25px",
            fontWeight: "bold",
          }}
        >
          <a
            href="https://asia.wowawards.com/support/"
            style={{ color: "white" }}
            target="_blank"
          >
            {" "}
            FAQs
          </a>
          {/* <Link to="/" style={{ color: "white" }}>
            FAQs
          </Link> */}

          <a
            href="https://asia.wowawards.com/categories/"
            style={{ color: "white" }}
            target="_blank"
          >
            {" "}
            Explore Categories
          </a>
          {/* <Link to="/" style={{ color: "white" }}>
            Explore Categories
          </Link> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterHeader;
