import TableContainer from "@components/Common/TableContainer";
import { FetchData } from "@hooks/api/useApi";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import FormHeader from "../../../components/Common/FormHeader";

function Index() {
  const history = useHistory();
  const [entrentData, setEntrantData] = useState();
  const [sucessMsg, setSucessMsg] = useState(false);
  const actionsjuryList = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };

  const { data: juryList, isFetching } = FetchData(
    "jurylist",
    "Auditor/get-all-jury-by-auditor",
    actionsjuryList
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Jury Name",
        accessor: "jury_name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Jury Email",
        accessor: "jury_mail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Region",
        accessor: "region",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Scored",
        accessor: "number_of_entries_score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Total",
        accessor: "total_number_of_entries",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
    ],
    []
  );

  document.title = "Entrants | WOW Awards";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-folder"}
                    heading={"Jury list"}
                    buttonName={"Add Jury Member"}
                    onButtonClick={() => history.push("/add-new-jury")}
                    pageLoading={isFetching}
                    // hasButton={true}
                  />

                  <div>
                    {juryList?.length > 0 ? (
                      <TableContainer
                        columns={columns}
                        data={juryList}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                        download
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h4 className="text-primary">No data Found</h4>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Index;
