import RegisterHeader from "@components/VerticalLayout/RegisterHeader";
import { PostQuery } from "@hooks/api/useApi";
import { useFormik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import * as Yup from "yup";
import ScreenLoader from "../../components/Common/ScreenLoader";
import { InputField } from "../../components/Form/Input";
import SelectInput from "../../components/Form/SelectInput";
import FormSuccess from "../../components/Registration/FormSuccess";

const optionGroup = [
  {
    options: [
      { label: "Dr.", value: "Dr." },
      { label: "Mr.", value: "Mr." },
      { label: "Mrs.", value: "Mrs." },
      { label: "Ms.", value: "Ms." },
    ],
  },
];

function Index() {
  document.title = "Register | WOW Awards";
  const urlParams = new URLSearchParams(window.location.search);
  const [showSuccessAlert, setShowSuccessAlert] = useState(true);
  const [showSuccessmess, setShowSuccessMess] = useState(true);
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showErrorMess, setShowErrorMess] = useState("");
  // const location = useLocation()

  const registerFormActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setShowSuccessAlert(false);
        setShowSuccessMess(data.data.responseData);
        // setTimeout(() => {
        //   setShowSuccessAlert(true);
        // }, 3000);
        validation.resetForm();
      } else {
        setShowErrorAlert(false);
        setShowErrorMess(data.data.errors[0]);

        setTimeout(() => {
          setShowErrorAlert(true);
          setShowErrorMess("");
        }, 3000);
      }
    },
    onError: (error) => {
      setShowErrorAlert(false);
      setTimeout(() => {
        setShowErrorAlert(true);
      }, 3000);
    },
  };

  const { mutate, isLoading } = PostQuery(
    "Admin/upsert-registration-detail",
    "post",
    registerFormActions
  );

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const gstPattern =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const textOnly = /^[A-Za-z]+$/;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      entrant: "",
      userName: "",
      website: "",
      gst: "",
      salutation: {},
      firstName: "",
      lastName: "",
      designation: "",
      secSalutation: {},
      secFirstName: "",
      secLastName: "",
      secDesignation: "",
      secEmail: "",
      secMobile: "",
      mobile: "",
      tel: "",
      ad1: "",
      ad2: "",
      ad3: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      offAdd1: "",
      offAdd2: "",
      offAdd3: "",
      offCountry: "",
      offState: "",
      offCity: "",
      offZip: "",
      telPhone1: "",
      telPhone2: "",
      isOfficialAd: null,
      isPrimarySame: null,
      isAcceptingTerms: false,
    },

    validationSchema: Yup.object({
      entrant: Yup.string()
        .min(3, "Please Write Valid Entrant Name")
        .required("Please Enter Entrant Name"),
      userName: Yup.string()
        .email("Email is Invalid")
        .required("Please Enter User Name"),
      gst: Yup.string().matches(gstPattern, "GST number not match"),

      firstName: Yup.string()
        .min(3, "Please Write Valid First Name")
        .matches(textOnly, "Please enter only alphabets")
        .required("Please Enter Your First Name"),
      lastName: Yup.string()
        .min(3, "Please Write Valid Last Name")
        .matches(textOnly, "Please enter only alphabets")
        .required("Please Enter Your Last Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      secFirstName: Yup.string()
        .min(3, "Please Write Valid First Name")
        .matches(textOnly, "Please enter only alphabets")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your First Name"),
        }),
      secLastName: Yup.string()
        .min(3, "Please Write Valid Last Name")
        .matches(textOnly, "Please enter only alphabets")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your Last Name"),
        }),

      secDesignation: Yup.string().when("isPrimarySame", {
        is: false,
        then: Yup.string().required("Please Enter Your Designation"),
      }),
      secEmail: Yup.string().when("isPrimarySame", {
        is: false,
        then: Yup.string().required("Please Enter Your Email"),
      }),
      secMobile: urlParams.get("region") == "middleeast" ? Yup.string() : Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your Mobile"),
        }),

      mobile: urlParams.get("region") == "middleeast" ? Yup.string() : Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .required("Please Enter Your Mobile"),

      isOfficialAd: Yup.boolean()
        .nullable()
        .required("Select one of the above button"),
      isPrimarySame: Yup.boolean()
        .nullable()
        .required("Select one of the above button"),
      ad1: Yup.string().required("Please Enter Address"),
      ad2: Yup.string().required("Please Enter Address"),
      country: Yup.string().required("Please Enter Your country"),
      state: Yup.string().required("Please Enter Your state"),
      city: Yup.string().required("Please Enter Your city"),
      zip: Yup.string()
        .max(6, "Invalid Zip Code")
        .required("Please Enter Your zip"),
      offAdd1: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Official address"),
      }),
      offAdd2: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Official address"),
      }),
      offCountry: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your country"),
      }),
      offState: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your state"),
      }),
      offCity: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your city"),
      }),
      offZip: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your zip"),
      }),
      telPhone1: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your telephone"),
      }),
      isAcceptingTerms: Yup.bool().oneOf(
        [true],
        "You need to accept the terms and conditions"
      ),
    }),

    onSubmit: (values) => {
      const getForm = {
        entrant_name: values.entrant,
        username: values.userName,
        website: values.website,
        gst_number: values.gst,
        primary_contact_person_salutation: values.salutation.value,
        pcp_first_name: values.firstName,
        pcp_last_name: values.lastName,
        pcp_designation: values.designation,
        pcp_mobile_number: values.mobile,
        pcp_tel_number: values.tel,
        pcp_address_line1: values.ad1,
        pcp_address_line2: values.ad2,
        pcp_address_line3: values.ad3,
        pcp_country: values.country,
        pcp_state: values.state,
        pcp_zip: values.zip,
        pcp_city: values.city,
        is_official_address: values.isOfficialAd,
        address_line1_secondary: values.offAdd1,
        address_line2_secondary: values.offAdd2,
        address_line3_secondary: values.offAdd3,
        country_secondary: values.offCountry,
        state_secondary: values.offState,
        city_secondary: values.offCity,
        zip_secondary: values.offZip,
        telephoneno1_secondary: values.telPhone1,
        telephoneno2_secondary: values.telPhone2,
        isaccepted: values.isAcceptingTerms,
        // reason: ,
        ispersonsameasexecut: values.isPrimarySame,
        salutation_sr_executive: values.secSalutation.value,
        firstname_sr_executive: values.secFirstName,
        lastname_sr_executive: values.secLastName,
        designation_sr_executive: values.secDesignation,
        emailId_sr_executive: values.secEmail,
        mobileNo_sr_executive: values.secMobile,
        Region_Id:urlParams.get("region") == "middleeast" ? 2 : 1,
      };
      mutate(getForm);
    },
  });

  const handleCheckTerms = (e) => {
    if (e.target.checked) {
      validation.setFieldValue("isAcceptingTerms", e.target.checked);
    } else {
      validation.setFieldValue("isAcceptingTerms", e.target.checked);
    }
  };

  const handleSameContact = (e) => {
    if (e.target.value === "yes") {
      validation.setFieldValue("isPrimarySame", true);
    } else {
      validation.setFieldValue("isPrimarySame", false);
    }
  };
  const handleOfficialAd = (e) => {
    if (e.target.value === "yes") {
      validation.setFieldValue("isOfficialAd", true);
    } else {
      validation.setFieldValue("isOfficialAd", false);
    }
  };

  return (
    <React.Fragment>
      <RegisterHeader />
      <div id="layout-wrapper">
        {!showErrorAlert && (
          <UncontrolledAlert
            color="danger"
            className={`alert-dismissible fade ${showErrorAlert ? "show" : ""}`}
            role="alert"
            style={{
              position: "fixed",
              right: "10px",
              top: "12%",
              zIndex: "2000",
            }}
          >
            <i className="mdi mdi-block-helper me-2"></i>
            {showErrorMess}!
          </UncontrolledAlert>
        )}

        <div className="page-content">
          <Container fluid>
            {!showSuccessAlert ? (
              <FormSuccess />
            ) : (
              <Row>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit(e);
                    return false;
                  }}
                >
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="d-flex align-items-center mb-2">
                            <div className="avatar-xs me-3 ">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                                <i className="bx bx-food-menu text-primary display-7" />
                              </span>
                            </div>
                            <h3 className="font-size-25 mb-0">
                              Registration form
                            </h3>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <Row>
                          <Col sm="12">
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={`Entrant Name (Organisation, Agency, Venue,
                                      Vendor Co.)*`}
                                    name={"entrant"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.entrant || ""}
                                    invalid={
                                      validation.touched.entrant &&
                                      validation.errors.entrant
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={`Username (Email ID)*`}
                                    name={"userName"}
                                    type={"email"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.userName || ""}
                                    invalid={
                                      validation.touched.userName &&
                                      validation.errors.userName
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={"Website"}
                                    name={"website"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    value={validation.values.website || ""}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={`GST No. (For Indian Companies)`}
                                    name={"gst"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.gst || ""}
                                    invalid={
                                      validation.touched.gst &&
                                      validation.errors.gst
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3">
                          PRIMARY CONTACT PERSON
                        </CardTitle>
                        <Row>
                          <Col sm="12">
                            <Row>
                              <Col sm="2">
                                <div className="mb-3">
                                  <SelectInput
                                    label={"Salutation"}
                                    id={"salutation"}
                                    name={"salutation"}
                                    type={"select"}
                                    options={optionGroup}
                                    value={validation.values.salutation}
                                    onChange={(option) => {
                                      validation.setFieldValue(
                                        "salutation",
                                        option
                                      );
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col sm="5">
                                <div className="mb-3">
                                  <InputField
                                    label={"First Name*"}
                                    name={"firstName"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""}
                                    invalid={
                                      validation.touched.firstName &&
                                      validation.errors.firstName
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="5">
                                <div className="mb-3">
                                  <InputField
                                    label={"Last Name*"}
                                    name={"lastName"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""}
                                    invalid={
                                      validation.touched.lastName &&
                                      validation.errors.lastName
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="mb-3">
                              <InputField
                                label={"Designation*"}
                                name={"designation"}
                                type={"text"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.designation || ""}
                                invalid={
                                  validation.touched.designation &&
                                  validation.errors.designation
                                    ? true
                                    : false
                                }
                                validation={validation}
                              />
                            </div>
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={"Mobile No.*"}
                                    name={"mobile"}
                                    type={"tel"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.mobile || ""}
                                    invalid={
                                      validation.touched.mobile &&
                                      validation.errors.mobile
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={"Telephone No."}
                                    name={"tel"}
                                    type={"tel"}
                                    onChange={validation.handleChange}
                                    value={validation.values.tel || ""}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <div className="mb-3">
                                  <InputField
                                    label={"Address Line 1*"}
                                    name={"ad1"}
                                    type={"textarea"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.ad1 || ""}
                                    invalid={
                                      validation.touched.ad1 &&
                                      validation.errors.ad1
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="mb-3">
                                  <InputField
                                    label={"Address Line 2*"}
                                    name={"ad2"}
                                    type={"textarea"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.ad2 || ""}
                                    invalid={
                                      validation.touched.ad2 &&
                                      validation.errors.ad2
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="mb-3">
                                  <InputField
                                    label={"Address Line 3"}
                                    name={"ad3"}
                                    type={"textarea"}
                                    value={validation.values.ad3 || ""}
                                    onChange={validation.handleChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={"Country*"}
                                    name={"country"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.country || ""}
                                    invalid={
                                      validation.touched.country &&
                                      validation.errors.country
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={"State*"}
                                    name={"state"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.state || ""}
                                    invalid={
                                      validation.touched.state &&
                                      validation.errors.state
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={"City*"}
                                    name={"city"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city || ""}
                                    invalid={
                                      validation.touched.city &&
                                      validation.errors.city
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={`Zip / Postal Code (if not applicable, please
                                      key in N/A)*`}
                                    name={"zip"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.zip || ""}
                                    invalid={
                                      validation.touched.zip &&
                                      validation.errors.zip
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="mb-3">
                              <Label htmlFor="official-add">
                                Is this the official address*
                              </Label>
                              <div style={{ display: "flex" }}>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isOfficialAd"
                                    id="exampleRadios1"
                                    value="yes"
                                    onClick={handleOfficialAd}
                                    invalid={
                                      validation.touched.isOfficialAd &&
                                      validation.errors.isOfficialAd
                                        ? true
                                        : false
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div
                                  style={{ marginLeft: 20 }}
                                  className="form-check"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isOfficialAd"
                                    id="exampleRadios2"
                                    value="no"
                                    onClick={handleOfficialAd}
                                    invalid={
                                      validation.touched.isOfficialAd &&
                                      validation.errors.isOfficialAd
                                        ? true
                                        : false
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                              {validation.touched.isOfficialAd &&
                              validation.errors.isOfficialAd ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{
                                    display: "flex",
                                    marginTop: "-10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {validation.errors.isOfficialAd}
                                </FormFeedback>
                              ) : null}

                              {!validation.values.isOfficialAd &&
                                validation.values.isOfficialAd !== null && (
                                  <>
                                    <Row>
                                      <Col sm="4">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Address Line 1*"}
                                            name={"offAdd1"}
                                            type={"textarea"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offAdd1 || ""
                                            }
                                            invalid={
                                              validation.touched.offAdd1 &&
                                              validation.errors.offAdd1
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Address Line 2*"}
                                            name={"offAdd2"}
                                            type={"textarea"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offAdd2 || ""
                                            }
                                            invalid={
                                              validation.touched.offAdd2 &&
                                              validation.errors.offAdd2
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Address Line 3"}
                                            name={"offAdd3"}
                                            type={"textarea"}
                                            onChange={validation.handleChange}
                                            value={
                                              validation.values.offAdd3 || ""
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Country*"}
                                            name={"offCountry"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offCountry || ""
                                            }
                                            invalid={
                                              validation.touched.offCountry &&
                                              validation.errors.offCountry
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={`State / Province / Region*`}
                                            name={"offState"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offState || ""
                                            }
                                            invalid={
                                              validation.touched.offState &&
                                              validation.errors.offState
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={"City*"}
                                            name={"offCity"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offCity || ""
                                            }
                                            invalid={
                                              validation.touched.offCity &&
                                              validation.errors.offCity
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={`Zip / Postal Code (if not
                                              applicable, please key in N/A)*`}
                                            name={"offZip"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offZip || ""
                                            }
                                            invalid={
                                              validation.touched.offZip &&
                                              validation.errors.offZip
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm="6">
                                        <div className="mb-3">
                                          <InputField
                                            label={`Telephone No. #1*`}
                                            name={"telPhone1"}
                                            type={"tel"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.telPhone1 || ""
                                            }
                                            invalid={
                                              validation.touched.telPhone1 &&
                                              validation.errors.telPhone1
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="mb-3">
                                          <InputField
                                            label={`Telephone No. #2`}
                                            name={"telPhone2"}
                                            type={"tel"}
                                            onChange={validation.handleChange}
                                            value={
                                              validation.values.telPhone2 || ""
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-3">
                          SENIOR EXECUTIVE’S DETAILS (COMPANY OWNER, DIRECTOR,
                          PROPRIETOR, ETC.)*
                        </CardTitle>
                        <div className="mb-0">
                          <Label htmlFor="executive-add">
                            Same as above mentioned Primary Contact Person*
                          </Label>
                          <div style={{ display: "flex" }}>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isPrimarySame"
                                style={{ cursor: "pointer" }}
                                id="isPrimarySame1"
                                value="yes"
                                onClick={handleSameContact}
                                invalid={
                                  validation.touched.isPrimarySame &&
                                  validation.errors.isPrimarySame
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isPrimarySame1"
                              >
                                Yes
                              </label>
                            </div>
                            <div
                              style={{ marginLeft: 20 }}
                              className="form-check"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isPrimarySame"
                                id="isPrimarySame2"
                                value="no"
                                onClick={handleSameContact}
                                style={{ cursor: "pointer" }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isPrimarySame2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                        {validation.errors.isPrimarySame &&
                        validation.touched.isPrimarySame ? (
                          <FormFeedback
                            type="invalid"
                            style={{
                              display: "flex",
                              marginTop: "-10px",
                              marginBottom: "10px",
                            }}
                          >
                            {validation.errors.isPrimarySame}
                          </FormFeedback>
                        ) : null}
                        {!validation.values.isPrimarySame &&
                          validation.values.isPrimarySame !== null && (
                            <>
                              <Row>
                                <Col sm="2">
                                  <div className="mb-3">
                                    <SelectInput
                                      label={"Salutation"}
                                      id={"secSalutation"}
                                      name={"secSalutation"}
                                      type={"select"}
                                      options={optionGroup}
                                      value={validation.values.secSalutation}
                                      onChange={(option) => {
                                        validation.setFieldValue(
                                          "secSalutation",
                                          option
                                        );
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col sm="5">
                                  <div className="mb-3">
                                    <InputField
                                      label={"First Name*"}
                                      name={"secFirstName"}
                                      type={"text"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secFirstName || ""
                                      }
                                      invalid={
                                        validation.touched.secFirstName &&
                                        validation.errors.secFirstName
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                                <Col sm="5">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Last Name*"}
                                      name={"secLastName"}
                                      type={"text"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secLastName || ""
                                      }
                                      invalid={
                                        validation.touched.secLastName &&
                                        validation.errors.secLastName
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Designation*"}
                                      name={"secDesignation"}
                                      type={"text"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secDesignation || ""
                                      }
                                      invalid={
                                        validation.touched.secDesignation &&
                                        validation.errors.secDesignation
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Email*"}
                                      name={"secEmail"}
                                      type={"email"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.secEmail || ""}
                                      invalid={
                                        validation.touched.secEmail &&
                                        validation.errors.secEmail
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Mobile No.*"}
                                      name={"secMobile"}
                                      type={"tel"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.secMobile || ""}
                                      invalid={
                                        validation.touched.secMobile &&
                                        validation.errors.secMobile
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="form-check mb-3">
                            <InputField
                              label={"I accept the Terms and Conditions*"}
                              name={"isAcceptingTerms"}
                              type={"checkbox"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.isAcceptingTerms || ""}
                              invalid={
                                validation.touched.isAcceptingTerms &&
                                validation.errors.isAcceptingTerms
                                  ? true
                                  : false
                              }
                              style={{ cursor: "pointer" }}
                              validation={validation}
                            />
                          </div>
                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              type="submit"
                              color="primary"
                              className="btn "
                              disabled={isLoading}
                            >
                              {isLoading && (
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              )}
                              Submit
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Form>
              </Row>
            )}
          </Container>
          {isLoading && <ScreenLoader />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Index;
