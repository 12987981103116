import React from "react";
import { Card, CardBody, Table } from "reactstrap";
import DetailsIntroCard from "../Common/DetailsIntroCard";

const IntroCard = (props) => {
  return (
    <>
      <Card className="overflow-hidden">
        <span
          style={{
            color:
              props.details?.status === "Approved"
                ? "#34c38f"
                : props.details?.status === "Reject"
                ? "#f46a6a"
                : "#f1b44c",
            position: "absolute",
            top: "2px",
            right: "8px",
            fontSize: "12px",
            cursor: props.details?.status === "Reject" ? "pointer" : "none",
            zIndex: props.details?.status === "Reject" ? "1" : "0",
          }}
          onClick={() => {
            props.toggleShowModal();
            props.setIsReson(props.details?.reason);
          }}
        >
          {" "}
          {props.details?.status === "Approved"
            ? "Approved"
            : props.details?.status === "Reject"
            ? "Rejected (Click here to show reason)"
            : "Pending"}
        </span>
        {/* <div
          className={
            "font-size-12 badge-soft-" +
            (props.details?.status === "Approved"
              ? "success"
              : props.details?.status === "Reject"
              ? "danger"
              : "warning")
          }
        >
          <Row>
            <Col xs="10">
              <div
                className="text-primary p-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h2
                    style={{
                      color:
                        props.details?.status === "Approved"
                          ? "#34c38f"
                          : props.details?.status === "Reject"
                          ? "#f46a6a"
                          : "#f1b44c",
                    }}
                    className={
                      props.details?.status === "Approved"
                        ? "success"
                        : props.details?.status === "Reject"
                        ? "danger"
                        : "warning"
                    }
                  >
                    {props.details?.general?.entrant_name}
                  </h2>
                  <h3
                    style={{
                      color:
                        props.details?.status === "Approved"
                          ? "#34c38f"
                          : props.details?.status === "Reject"
                          ? "#f46a6a"
                          : "#f1b44c",
                    }}
                    className={
                      props.details?.status === "Approved"
                        ? "success"
                        : props.details?.status === "Reject"
                        ? "danger"
                        : "warning"
                    }
                  >
                    {props.salutation} {props.firstName} {props.lastName}
                  </h3>
                </div>
              </div>
            </Col>
            <Col xs="2" className="align-self-end">
              <i
                className={`bx bx-user display-1 ${
                  props.details?.status === "Approved"
                    ? "success"
                    : props.details?.status === "Reject"
                    ? "danger"
                    : "warning"
                }`}
              />
            </Col>
          </Row>
        </div> */}
        <DetailsIntroCard
          status={props.details?.status}
          name={props.details?.general?.entrant_name}
        />
        <CardBody className="pt-0">
          <div className="table-responsive">
            <Table className="table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Name :</th>
                  <td>
                    {props.firstName} {props.lastName}
                  </td>
                </tr>
                <tr>
                  <th scope="row">E-mail :</th>
                  <td>{props.username}</td>
                </tr>
                <tr>
                  <th scope="row">Website :</th>
                  <td> {props.website}</td>
                </tr>
                <tr>
                  <th scope="row">GST :</th>
                  <td>{props.gst}</td>
                </tr>
                <tr>
                  <th scope="row">Designation :</th>
                  <td>{props.designation}</td>
                </tr>
                <tr>
                  <th scope="row">Mobile :</th>
                  <td>{props.mobile}</td>
                </tr>
                <tr>
                  <th scope="row">Phone :</th>
                  <td> {props.tel} </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default IntroCard;
