import TableContainer from "@components/Common/TableContainer";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import AssignJurryModel from "../../../components/Common/AssignJurryModel";
import ConfirmModal from "../../../components/Common/ConfirmModel";
import FormHeader from "../../../components/Common/FormHeader";
import JurydetailsModel from "../../../components/Common/JurydetailsModel";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import SelectInput from "../../../components/Form/SelectInput";
import { ID } from "../../Admin/Jury/EditableCol";
// import WelcomeAudit from "../";

// const region = [
//   {
//     options: [
//       { label: "Asia", value: "asia" },
//       { label: "Middle East", value: "middle-east" },
//     ],
//   },
// ];

const roundOptions = [
  {
    options: [
      { label: "Round 1", value: "0" },
      { label: "Round 2", value: "1" },
    ],
  },
];

const optionJuryMember = [
  {
    options: [
      { label: "Purva Mantri", value: "PurvaMantri" },
      { label: "Nupur Pant", value: "NupurPant" },
      { label: "Devika Singh", value: "Devika Singh" },
      { label: "Vipin Aneja", value: "VipinAneja" },
    ],
  },
];

function Index() {
  const history = useHistory();
  const [optionCategory, setOptionCategory] = useState();
  const [optionJurry, setOptionJurry] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addEntry, setAddEntry] = useState([]);
  const [selectedRounds, setselectedRounds] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [juryDetails, setJuryDetails] = useState(null);
  const [juryData, setJuryData] = useState(null);
  const [tableDataError, setTableDataError] = useState("");
  const [juryDataError, setJuryDataError] = useState("");
  const [secoundRounds, setSecoundRounds] = useState(true);
  const [modalAward, setModalAward] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [isAward, setIsAward] = useState(null);
  const [optionAwards, setOptionAwards] = useState(null);
  const [avgScore, setAvgScore] = useState(false);
  const [listEntries, setListEntries] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(true);
  const [optionGroup, setOptionGroup] = useState(null);
  const [isCheckAll, setIsCheckAll] = useState(false);
  // const [optionCategory, setOptionCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const [catList, setCatList] = useState(null);
  const [groupList, setGroupList] = useState(null);

  const location = useLocation();

  let awardName = location?.search?.slice(12);
  let awardId = location?.search?.slice(7, 9);
  let awardRound = location?.search?.slice(10, 11);

  const categoryActions = {
    refetchOnWindowFocus: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionCategory(getList);
        }
      });
    },
  };

  const firstRoundActions = {
    refetchOnWindowFocus: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      if (data.data.status) {
        var items = document.getElementsByName("entry-check");
        var allItem = document.getElementsByName("selectAll");

        for (var i = 0; i < items.length; i++) {
          if (items[i].type == "checkbox") items[i].checked = false;
        }
        for (var i = 0; i < allItem.length; i++) {
          if (allItem[i].type == "checkbox") allItem[i].checked = false;
        }
        setIsModal(false);
        setShowSuccessAlert(!showSuccessAlert);
        setTimeout(() => {
          setShowSuccessAlert(true);
        }, 3000);
        setShowButton(true);
        setFirstRound(true);
        setSecoundRounds(false);
        refetch();
        setAddEntry([]);
      } else {
        setShowErrorAlert(false);
      }
    },
  };

  const { data: categoryData, isFetching: categoryFetching } = FetchData(
    "category",
    "/Admin/get-all-category",
    categoryActions
  );

  // const handleChange = (e, item) => {
  //   console.log("e", e);
  //   if (e.target.checked) {
  //     setAddEntry((prev) => {
  //       return [...prev, item];
  //     });
  //   } else {
  //     setAddEntry((prev) => {
  //       return prev.filter((ele) => {
  //         if (ele !== item) {
  //           return item;
  //         }
  //       });
  //     });
  //   }
  // };

  const handleChange = (e, item) => {
    let index = addEntry.findIndex((idx, i) => idx == item);
    let entry = addEntry;

    if (index > -1) {
      entry.splice(index, 1);
      setAddEntry([...entry]);
    } else {
      entry.push(item);
      setAddEntry([...entry]);
    }
    // if (e.target.checked) {
    //   setAddEntry((prev) => {
    //     return [...prev, item];
    //   });
    // } else {
    //   setAddEntry((prev) => {
    //     return prev.filter((ele) => {
    //       if (ele !== item) {
    //         return item;
    //       }
    //     });
    //   });
    // }
  };

  const handleAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(!isCheckAll);

      var items = document.getElementsByName("entry-check");
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox") {
          items[i].checked = true;
        }
      }
    } else {
      setIsCheckAll(false);
      var items = document.getElementsByName("entry-check");
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox") items[i].checked = false;
      }
    }
  };

  const juryColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Jury Member",
        accessor: "jury_member",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Jury score",
        accessor: "score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.code}</p>;
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: "#CC962B",
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entry-details",
                  search: `id=${cellProps.row.original.entry_Detail?.id}`,
                });
              }}
            >
              <span>
                <p>{cellProps.row.original?.entry_Detail?.name}</p>
              </span>
            </p>
          );
        },
      },

      {
        Header: "Entrant organization",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.entrant_name}</p>;
        },
      },
      {
        Header: "Group",
        accessor: "group_detail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.group_detail?.group_name}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "category_detail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>{cellProps.row.original?.category_detail?.category_name}</p>
          );
        },
      },

      {
        Header: "Jury Assigned",
        accessor: "3",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {cellProps.row.original.jury_member_round1?.length}
            </span>
          );
        },
      },

      {
        Header: "Jury Scored",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                cellProps.row.original.jury_member_round1?.filter(
                  (item) => item.score !== null
                )?.length
              }
            </span>
          );
        },
      },
      {
        Header: "Average score",
        accessor: "average_score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          //   return <ID {...cellProps} />;
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color:
                    cellProps.row?.original?.jury_member_round1?.length > 0 &&
                    "#CC962B",

                  cursor:
                    cellProps.row?.original?.jury_member_round1?.length > 0 &&
                    "pointer",
                  fontWeight:
                    cellProps.row?.original?.jury_member_round1?.length > 0 &&
                    "600",
                }}
                onClick={() =>
                  cellProps.row?.original?.jury_member_round1?.length > 0 &&
                  toggle(
                    cellProps.row?.original?.jury_member_round1,
                    cellProps.row?.original,
                    setAvgScore(false)
                  )
                }
              >
                {Math.round(cellProps.row.original.average_score_round1)}
              </span>
            </div>
          );
        },
      },

      {
        Header: "",
        accessor: "cellProps",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          // console.log("row.row.original.entry_Detail?.id", row.row.original);
          return (
            <Input
              id={row.row.original.entry_Detail?.id}
              style={{ cursor: "pointer" }}
              name="entry-check"
              type="checkbox"
              className="checkbox"
              onChange={(e) => {
                handleChange(
                  e,
                  row.row.original.entry_Detail?.id,
                  row.row.original.entry_Detail
                );
              }}
            />
          );
        },
      },
    ],
    []
  );
  const secoundRoundColums = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.code}</p>;
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: "#CC962B",
                cursor: "pointer",
                fontWeight: "600",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entry-details",
                  search: `id=${cellProps.row.original.entry_Detail?.id}`,
                });
              }}
            >
              <span>
                <p>{cellProps.row.original?.entry_Detail?.name}</p>
              </span>
            </p>
          );
        },
      },

      {
        Header: "Entrant organization",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.entrant_name}</p>;
        },
      },
      {
        Header: "Group",
        accessor: "group_detail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.group_detail?.group_name}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "category_detail",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>{cellProps.row.original?.category_detail?.category_name}</p>
          );
        },
      },

      {
        Header: "Jury Assigned",
        accessor: "3",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {cellProps.row.original.jury_member_round2?.length}
            </span>
          );
        },
      },

      {
        Header: "Jury Scored",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                cellProps.row.original.jury_member_round2?.filter(
                  (item) => item.score !== null
                )?.length
              }
            </span>
          );
        },
      },
      {
        Header: "Average score",
        accessor: "average_score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          //   return <ID {...cellProps} />;
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  color:
                    cellProps.row?.original?.jury_member_round2?.length > 0 &&
                    "#CC962B",

                  cursor:
                    cellProps.row?.original?.jury_member_round2?.length > 0 &&
                    "pointer",
                  fontWeight:
                    cellProps.row?.original?.jury_member_round2?.length > 0 &&
                    "600",
                }}
                onClick={() =>
                  cellProps.row?.original?.jury_member_round2?.length > 0 &&
                  toggle(
                    cellProps.row?.original?.jury_member_round2,
                    cellProps.row?.original,
                    setAvgScore(true)
                  )
                }
              >
                {Math.round(cellProps.row.original.average_score_round2)}
              </span>
            </div>
          );
        },
      },

      // {
      //   Header: "",
      //   accessor: "cellProps",
      //   filterable: true,
      //   disableFilters: true,
      //   disableSortBy: true,
      //   Cell: (row) => {
      //     return (
      //       <Input
      //         style={{ cursor: "pointer" }}
      //         name="entry-check"
      //         type="checkbox"
      //         onChange={(e) => {
      //           handleChange(
      //             e,
      //             row.row.original.entry_Detail?.id,
      //             row.row.original
      //           );
      //         }}
      //         className="checkbox"
      //         defaultChecked={row.row.original.winner ? true : null}
      //       />
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    setIsAward(awardName.replaceAll("%20", " "));
    if (!awardName) {
      setModalAward(!modalAward);
      setSelectedAward(null);
    }
  }, [awardName]);

  useEffect(() => {
    if (awardId) {
      refetch();
    }
  }, [awardId]);

  const awardsActions = {
    refetchOnWindowFocus: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.event_list.map((item) => {
        let obj = {};
        if (item.event_status === "Published") {
          obj["label"] = item.event_name;
          obj["value"] = item.event_id;
          obj["round"] = item.round;
          // optionList.push(obj);
          optionList.push({...item, ...obj});
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionAwards(getList);
        }
      });
    },
  };
  const {
    data: eventsList,
    isFetching,
    isLoading,
    isFetched,
  } = FetchData("events", "/Admin/get-all-event", awardsActions);

  function handleSelectAward(item) {
    setSelectedAward(item);
    setIsAward(item.label);

    
  }

  const [isModal, setIsModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleSubmit = () => {
    if (isAward && addEntry?.length > 0) {
      setIsModal(!isModal);
    } else {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  };

  function handleRounds(e) {
    setselectedRounds(e);
  }

  function handleSelectMember(e) {
    setSelectedMember(e);
  }

  function handleSelectCategory(item) {
    setSelectedCategory(item);
  }

  const [assignEntries, setAssignEntries] = useState([]);
  const [roundTwoData, setRoundTwoData] = useState(null);
  const actions = {
    enabled: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const entryList = data.data.responseData;
      return entryList;
    },
    onSuccess: (data) => {
      if (data) {
        setRoundTwoData(
          data?.filter(
            (item) =>
              item?.round == 2 &&
              item?.event_Detail?.name === isAward &&
              item?.jury_member_round2?.length > 0
          )
        );

        // } else {
        setAssignEntries(
          data?.filter(
            (ele) => ele.form_status !== "Incomplete" && !ele.is_entry_rejected
          )
        );
      }
    },
  };

  const entriesActions = {
    enabled: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      const entryList = data.data.responseData;
      return entryList;
    },
    onSuccess: (data) => {
      if (data) {
        // if (selectedAward?.round == 2 || awardRound == 2) {
        setRoundTwoData(
          data?.filter(
            (item) =>
              item?.round == 2 &&
              item?.event_Detail?.name === isAward &&
              item?.jury_member_round2?.length > 0
          )
        );

        // } else {
        setAssignEntries(
          data?.filter(
            (ele) => ele.form_status !== "Incomplete" && !ele.is_entry_rejected
          )
        );
        // }
      }
    },
  };

  const {
    data,
    isFetching: EntriyFetching,
    refetch,
  } = FetchData(
    "AssignEntries",
    `Auditor/get-all-jury-allocation-auditor?event_id=${
      selectedAward?.value || awardId
    }`,
    actions
  );

  const {
    data: getAllEntries,
    isFetching: getAllEntryFetching,
    refetch: getEntriesRefetch,
  } = FetchData(
    "entries",
    `Auditor/get-all-jury-allocation-auditor?event_id=${
      selectedAward?.value || awardId
    }&update_data=true`,
    entriesActions
  );

  const handleRefetch = () => {
    getEntriesRefetch();
  };

  const { mutate: roundEntries, isLoading: isLoadingJury } = PostQuery(
    "/Admin/update-entry-for-next-round",
    "post",
    firstRoundActions
  );

  const [modal, setModal] = useState(false);
  const toggle = (item, data) => {
    if (modal) {
      setModal(false);
      setJuryDetails(null);
    } else {
      setModal(true);
      setJuryDetails(item);
      setJuryData(data);
    }
  };

  const toggleViewModal = () => {
    setModalAward(!modalAward);
  };

  const handleAward = () => {
    if (selectedAward) {
      setModalAward(false);
      refetch();
      history.push({
        pathname: "/result",
        search: `award=${selectedAward?.value}:${selectedAward?.round}:${selectedAward?.label}`,
      });

      let filterGroupList = optionAwards[0]?.options.filter((eachVal) => {
        return eachVal.value === selectedAward.value;
      });
  
      let group = [];
      filterGroupList[0]?.event_categorydata?.map((item) => {
        let obj = {};
        obj["label"] = item.groupName;
        obj["value"] = item.groupId;
        group.push(obj);
        const arrayUniqueByKey = [
          ...new Map(group.map((item) => [item["label"], item])).values(),
        ];
        setGroupList(arrayUniqueByKey);
      });
  
      let categories = [];
      filterGroupList[0]?.event_categorydata.map((item) => {
        let obj = {};
        obj["label"] = item.categoryName;
        obj["value"] = item.categoryId;
        categories.push(obj);
        const arrayUniqueByKeyCatList = [
          ...new Map(categories.map((item) => [item["label"], item])).values(),
        ];
        setCatList(arrayUniqueByKeyCatList);
      });
    }
  };

  document.title = "Report | WOW Awards";

  const onResetClick = () => {
    if (awardName) {
      // setModalAward(!modalAward);
      setSelectedAward(null);
      setSelectedCategory(null);
      setSelectedGroup(null);
      setIsAward(null);
      setAddEntry([]);

      // history.replace("/result");
      history.push({
        pathname: "/result",
        search: "",
      });
    }
  };
  const [firstRound, setFirstRound] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [showFinalCard, setShowFinalCard] = useState(false);
  const [final, setFinal] = useState(false);

  const handleRoundOne = () => {
    const data = { Entry_Id: addEntry };
    roundEntries(data);
  };

  const handleFinalEntry = () => {
    const data = { Entry_Id: addEntry };
    roundEntries(data);
    setFinal(true);
    setIsModal(false);
    setFirstRound(true);
    setSecoundRounds(true);
    setShowFinalCard(true);
    setAddEntry([]);
  };

  const onHideClick = () => {
    // if (firstRound && final) {
    //   setFirstRound(!firstRound);
    // } else {
    setFirstRound(!firstRound);
    setSecoundRounds(!secoundRounds);
    // }
    // if (secoundRounds && final) {
    //   setSecoundRounds(!secoundRounds);
    // } else {
    //   setFirstRound(!firstRound);
    //   setSecoundRounds(!secoundRounds);
    // }
  };
  const onRoundOneHide = () => {
    // if (firstRound) {
    setFirstRound(false);
    setSecoundRounds(true);

    if (final) {
      setFinal(false);
    }
    // }
  };

  const onRoundSecoundHide = () => {
    // if (secoundRounds) {
    setSecoundRounds(false);
    setFirstRound(true);
    if (final) {
      setFinal(false);
    }

    // }
  };

  const onFinalHideClick = () => {
    setFinal(true);
    setSecoundRounds(true);
    setFirstRound(true);
    // if (secoundRounds) {
    // setFinal(!final);
    // }
  };

  // let getfristRoundData = assignEntries?.filter(
  //   (ele) => ele.form_status !== "Incomplete" && !ele.is_entry_rejected
  // );

  // let getRoundTwoData = assignEntries?.filter(
  //   (item) =>
  //     item?.round == 2 &&
  //     item?.event_Detail?.name === isAward &&
  //     item?.jury_member_round2?.length > 0
  // );

  // let getFinalata = assignEntries?.filter(
  //   (item) => item?.winner == true && item?.event_Detail?.name === isAward
  // );

  function handleSelectGroup(item) {
    setSelectedGroup(item);
  }

  function handleSelectCategory(item) {
    setSelectedCategory(item);
  }

  const groupActions = {
    refetchOnWindowFocus: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.group_name;
          obj["value"] = item.group_id;
          optionList.push(obj);
          setOptionGroup(optionList);
        }
      });
    },
  };
  const {
    data: groupListData,
    refetch: groupFetch,
    isLoading: isLoadingGroup,
  } = FetchData("groups", "Admin/get-all-group", groupActions);

  const [grouError, setGrouError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  let list_Of_Entries = assignEntries;

  useEffect(() => {
    if (selectedGroup) {
      let groupData = list_Of_Entries?.filter((item) => {
        return item?.group_detail?.group_name === selectedGroup?.label;
      });
      if (groupData?.length === 0) {
        groupData = list_Of_Entries;
        setGrouError("Data Not Found");
      } else {
        setGrouError("");
      }
      list_Of_Entries = groupData;
      setListEntries(groupData);
    }

    if (selectedCategory) {
      let categoryData = list_Of_Entries?.filter((item) => {
        return item?.category_detail?.category_name === selectedCategory?.label;
      });

      if (categoryData?.length === 0) {
        categoryData = list_Of_Entries;
        setCategoryError("Data Not Found");
      } else {
        setCategoryError("");
      }
      list_Of_Entries = categoryData;
      setListEntries(categoryData);
    }
  }, [list_Of_Entries, selectedGroup, selectedCategory]);

  const onClearClick = () => {
    if (awardName) {
      setSelectedCategory(null);
      setSelectedGroup(null);
      setGrouError("");
      setCategoryError("");
    }
  };

  // useEffect(() => {
  //   if (listEntries?.length > 0 && isCheckAll) {
  //     const getData = listEntries.map((ele) => ele.entry_id);
  //     setAddEntry(getData);
  //   } else {
  //     setAddEntry([]);
  //   }
  // }, [listEntries, isCheckAll]);

  return (
    <React.Fragment>
      {!showSuccessAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${showSuccessAlert ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-check-all me-2"></i>Please Update Event For
          Round 2!
        </UncontrolledAlert>
      )}

      {showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${showErrorAlert ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="bx bx-error-circle me-1"></i>Please Select One Entry!
        </UncontrolledAlert>
      )}

      <JurydetailsModel
        toggle={toggle}
        modal={modal}
        juryColumns={juryColumns}
        data={juryDetails}
        details={juryData}
        avgScore={avgScore}
      />

      <ConfirmModal
        show={isModal}
        onClick={!firstRound ? handleRoundOne : handleFinalEntry}
        onCloseClick={() => setIsModal(false)}
        message={`Submit round ${awardRound} entries of ${awardName.replaceAll(
          "%20",
          " "
        )}`}
      />

      <AssignJurryModel
        isOpen={modalAward}
        toggle={toggleViewModal}
        handleSelectAward={handleSelectAward}
        optionAwards={optionAwards}
        handleAward={handleAward}
        selectedAward={selectedAward}
        modal={modalAward}
      />
      {isAward !== null ? (
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            {/* <Breadcrumbs title="Jury" breadcrumbItem="Assign" /> */}

            {awardName && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "10px",
                }}
              >
                <Button
                  type="button"
                  color="primary"
                  className="btn "
                  onClick={handleRefetch}
                  style={{ marginRight: "10px" }}
                >
                  Refetch
                </Button>
                <Button
                  type="button"
                  outline
                  color="primary"
                  // className="btn-sm "
                  onClick={onResetClick}
                >
                  Reset Award
                </Button>
              </div>
            )}
            {awardName && (
              <Row>
                <Col xs="12">
                  {awardName && assignEntries?.length > 0 ? (
                    <Card>
                      <CardBody>
                        <Row>
                          <FormHeader
                            iconName={"bx-layer"}
                            heading={`Round 1 Submissions : ${awardName.replaceAll(
                              "%20",
                              " "
                            )}`}
                            // hasButton={
                            //   getRoundTwoData?.length === 0 && awardName
                            //     ? true
                            //     : false
                            // }
                            hasButton={true}
                            buttonName="Submit Entries"
                            onButtonClick={handleSubmit}
                            secoundRounds={secoundRounds}
                            firstRound={firstRound}
                          />
                        </Row>
                        <Row className="align-items-end mt-5">
                          <Col sm="5">
                            <div className="mb-3">
                              <SelectInput
                                label={"Select Category"}
                                name={"category"}
                                type={"select"}
                                onChange={(e) => {
                                  handleSelectCategory(e);
                                }}
                                options={catList}
                                value={selectedCategory || ""}
                              />
                              {categoryError && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                  }}
                                >
                                  {categoryError}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm="5">
                            <div className="mb-3">
                              <SelectInput
                                label={"Select Group"}
                                name={"group"}
                                type={"select"}
                                onChange={(e) => {
                                  handleSelectGroup(e);
                                }}
                                options={groupList}
                                value={selectedGroup || ""}
                              />
                              {grouError && (
                                <span
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                  }}
                                >
                                  {grouError}
                                </span>
                              )}
                            </div>
                          </Col>
                          <Col sm={2}>
                            <div className="mb-3">
                              <Button
                                type="button"
                                outline
                                color="primary"
                                // className="btn-sm "
                                onClick={onClearClick}
                              >
                                Clear Filter
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          {selectedCategory == null && selectedGroup == null ? (
                            <TableContainer
                              columns={columns}
                              data={assignEntries?.filter(
                                (ele) =>
                                  ele.form_status !== "Incomplete" &&
                                  !ele.is_entry_rejected
                              )}
                              isGlobalFilter={true}
                              isAddOptions={false}
                              customPageSize={10}
                              download
                              reportData={assignEntries?.filter(
                                (ele) =>
                                  ele.form_status !== "Incomplete" &&
                                  !ele.is_entry_rejected
                              )}
                            />
                          ) : (
                            listEntries &&
                            (selectedCategory !== null ||
                              selectedGroup !== null) && (
                              <TableContainer
                                columns={columns}
                                data={listEntries}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                customPageSize={10}
                                download
                                reportData={assignEntries?.filter(
                                  (ele) =>
                                    ele.form_status !== "Incomplete" &&
                                    !ele.is_entry_rejected
                                )}
                              />
                            )
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#CC962B",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      No data found
                    </span>
                  )}
                </Col>
              </Row>
            )}

            {roundTwoData?.length > 0 && awardName ? (
              <Row>
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <FormHeader
                          iconName={"bx-layer"}
                          heading="Qualified Round 2 Entries"
                          hasButton={false}
                          buttonName="Submit Entries"
                          onButtonClick={handleSubmit}
                          showButton={false}
                        />
                      </Row>

                      <div>
                        {roundTwoData?.length > 0 && (
                          <TableContainer
                            columns={secoundRoundColums}
                            data={roundTwoData}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            download
                            reportData={roundTwoData}
                            secoundRound
                          />
                        )}
                      </div>
                    </CardBody>
                  </Card>
                  {/* )} */}
                </Col>
              </Row>
            ) : (
              awardName &&
              assignEntries?.filter(
                (item) =>
                  item?.round == 2 && item?.event_Detail?.name === isAward
              )?.length > 0 && (
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Assign the Entries For Round 2
                </p>
              )
            )}
          </Container>
        </div>
      ) : null}
      {(EntriyFetching || getAllEntryFetching || categoryFetching) && (
        <ScreenLoader />
      )}
    </React.Fragment>
  );
}

export default Index;
