import { FetchData, PostQuery } from "@hooks/api/useApi";
import * as moment from "moment";
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Badge } from "reactstrap";
import EntrantDetailPage from "../../../components/EntrantDetails/EntrantDetailPage";
import { ID } from "../Entries/EditableCol";

function EntrantDetails() {
  document.title = "Entrant details | WOW Awards";
  const [showApprovalBox, setShowApprovalBox] = useState(null);
  const [modal1, setModal1] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const [reson, setReson] = useState("");
  const [errorMess, setErrorMess] = useState("");
  const [isReson, setIsReson] = useState(false);
  const getId = location?.search.slice(8);

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: details,
    isLoading,
    isFetching,
  } = FetchData(
    "entrants-details",
    `Admin/get-entrant-by-id?id=${getId}`,
    actions
  );

  const statusAction = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.replace("/entrants");
      }
    },
  };

  const { mutate, isLoading: isApprovalLoading } = PostQuery(
    "Admin/give-user-approval",
    "post",
    statusAction
  );
  const handleChnageReson = (e) => {
    if (e.target.value) {
      setReson(e.target.value);
      setErrorMess("");
    } else {
      setErrorMess("Please Enter Reason");
    }
  };
  const handleReson = (e) => {
    e.preventDefault();
    if (!reson && e.target.value === "false") {
      setErrorMess("Please Enter Reason");
    } else {
      const Approvestatus = {
        RegistrationId: Number(getId),
        Confirm: JSON.parse(e.target.value),
        Reason: reson,
      };
      mutate(Approvestatus);
    }
  };
  const toggleViewModal = () => {
    setModal1(!modal1);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setShowApprovalBox(!showApprovalBox);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <>{row.row.index + 1}</>;
        },
      },
      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <>{cellProps.value}</>;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("Do MMM YYYY");
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <>{cellProps.value}</>;
        },
      },
      {
        Header: "Event Name",
        accessor: "event_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Group",
        accessor: "category_group",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original.category_group?.name}</p>;
        },
      },
      {
        Header: "Sub-group",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original.category_subgroup?.name}</p>;
        },
      },
      {
        Header: "Category",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original.category?.name}</p>;
        },
      },
      {
        Header: "Region",
        accessor: "region",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <>{cellProps.value}</>;
        },
      },
      {
        Header: "Form Status",
        accessor: "form_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.value === "Submitted" ? "success" : "warning")
              }
              pill
            >
              {cellProps.value === "Submitted" ? "Submitted" : "Incomplete"}
            </Badge>
          );
        },
      },
    ],
    []
  );
  const toggleShowModal = () => {
    if (details?.status === "Reject") {
      setIsOpenModel(!isOpenModel);
    }
  };

  return (
    <React.Fragment>
      <EntrantDetailPage
        modal1={modal1}
        toggleViewModal={toggleViewModal}
        setReson={setReson}
        reson={reson}
        handleReson={handleReson}
        errorMess={errorMess}
        setErrorMess={setErrorMess}
        handleChnageReson={handleChnageReson}
        isApprovalLoading={isApprovalLoading}
        isOpenModel={isOpenModel}
        toggleShowModal={toggleShowModal}
        isReson={isReson}
        details={details}
        heading={"Accept / Reject Entrant"}
        reject={"Reject"}
        approve={"Approve"}
        handleEdit={handleEdit}
        showApprovalBox={showApprovalBox}
        setIsReson={setIsReson}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
      />
    </React.Fragment>
  );
}

export default EntrantDetails;
