import TableContainer from "@components/Common/TableContainer";
import { FetchData } from "@hooks/api/useApi";
import { getFromStorage } from "@utils/storage";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import AssignJurryModel from "../../components/Common/AssignJurryModel";
import FormHeader from "../../components/Common/FormHeader";
import { deleteFromStorage, saveToStorage } from "../../utils/storage";
import WelcomeJury from "./Welcomejury";

const JuryDashboard = () => {
  const history = useHistory();
  const location = useLocation();
  const [modalAward, setModalAward] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [isAward, setIsAward] = useState(null);
  const [optionAwards, setOptionAwards] = useState(null);
  const getUserName = getFromStorage("userName");

  // let awardName = location?.search?.slice(12);
  let getAward = location?.search?.split("=")[1]?.split(":");

  const actions = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: getEntryList,
    refetch,
    isLoading,
    isFetching,
  } = FetchData(
    "category",
    `/Jury/get-entry-scored-by-jury?event_id=${getAward?.[0]}`,
    actions
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Category List",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{ cursor: "pointer", color: "#CC962B" }}
              onClick={() => {
                // console.log("location.state", location.state)
                history.push({
                  pathname: "/EntryListdetails",
                  search: `id=${cellProps.row.original.category.id}`,
                });
              }}
            >
              {cellProps.row.original.category.name}
            </p>
          );
        },
      },
      {
        Header: "Entries Scored",
        accessor: "scored_entries_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              <span>{cellProps.row.original.scored_entries_count}</span>
            </div>
          );
        },
      },
      {
        Header: "No of Entries",
        accessor: "entry_count",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              <span>{cellProps.row.original.entry_count}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  const awardsActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.event_list.map((item) => {
        let obj = {};
        if (item.event_status === "Published") {
          obj["label"] = item.event_name;
          obj["value"] = item.event_id;
          obj["round"] = item.round;
          obj["region"]=item?.event_categorydata[0]?.regionId
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionAwards(getList);
        }
      });
    },
  };
  const {
    data: eventsList,
    isFetching: eventFetching,
    isLoading: eventLoading,
    isFetched,
  } = FetchData("events", "/Admin/get-all-event", awardsActions);

  function handleSelectAward(item) {
    setSelectedAward(item);
    setIsAward(item.label);
  }
  const toggleViewModal = () => {
    setModalAward(!modalAward);
  };
  useEffect(() => {
    setIsAward(getAward?.[2]?.replaceAll("%20", " "));
    if (!getAward) {
      setModalAward(!modalAward);
      setSelectedAward(null);
    }
  }, [getAward]);

  useEffect(() => {
    if (getAward?.[1]) {
      refetch();
    }
  }, [getAward?.[1]]);
  const handleAward = () => {
    if (selectedAward) {
      setModalAward(false);
      saveToStorage("url", `?award=${selectedAward?.value}:${selectedAward?.round}:${selectedAward?.label}`)
      history.push({
        pathname: "/",
        search: `award=${selectedAward?.value}:${selectedAward?.round}:${selectedAward?.label}`
      });
      
    }
  };

  const onResetClick = () => {
    if (getAward) {
      setSelectedAward(null);
      setIsAward(null);
      deleteFromStorage("url")
      history.push("/");
    }
  };

  console.log("location 174", location.state, selectedAward);
  return (
    <React.Fragment>
      <AssignJurryModel
        isOpen={modalAward}
        toggle={toggleViewModal}
        handleSelectAward={handleSelectAward}
        optionAwards={optionAwards}
        handleAward={handleAward}
        selectedAward={selectedAward}
        modal={modalAward}
      />

      {getAward && (
        <div className="page-content">
          <div className="container-fluid">
            <Row>
              <Col xl="12">
                <WelcomeJury
                  getUserName={getUserName}
                  note={
                    getEntryList?.entry_round == 1 || selectedAward?.region == 2
                      ? "PLEASE NOTE: 1 is the lowest score & 10 is the highest *"
                      : "PLEASE NOTE: 1 is the lowest score & 5 is the highest *"
                  }
                  awardRound={getEntryList?.entry_round}
                  data={getEntryList?.details}
                />
              </Col>
            </Row>
            {getAward && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "10px",
                }}
              >
                <Button
                  type="button"
                  outline
                  color="primary"
                  // className="btn-sm "
                  onClick={onResetClick}
                >
                  Reset Award
                </Button>
              </div>
            )}
            {getAward && getEntryList?.details?.length > 0 ? (
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={"Category list"}
                    pageLoading={isFetching}
                  />

                  {getEntryList?.details && (
                    <div>
                      <TableContainer
                        columns={columns}
                        data={getEntryList?.details}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            ) : (
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#CC962B",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                No data found
              </span>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default JuryDashboard;
