import React from "react";
import { Redirect, Route } from "react-router-dom";

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
        if (isAuthProtected && !localStorage.getItem("token")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
        else if(!isAuthProtected && localStorage.getItem("token")){
          return (
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
            />
          )
        }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default Authmiddleware;
