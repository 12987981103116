const ID = (cell) => {
  return cell.value ? cell.value : '';
};

const Age = (cell) => {
  return cell.value ? cell.value : '';
};

const Qty = (cell) => {
  return cell.value ? cell.value : '';
};

const Cost = (cell) => {
  return cell.value ? cell.value : '';
};

export { ID, Age, Qty, Cost };
