import TableContainer from "@components/Common/TableContainer";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import { getFromStorage } from "@utils/storage";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import AssignJurryModel from "../../../components/Common/AssignJurryModel";
import FormHeader from "../../../components/Common/FormHeader";
import JurydetailsModel from "../../../components/Common/JurydetailsModel";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import SelectInput from "../../../components/Form/SelectInput";
import { ID } from "./EditableCol";

function AssignJury() {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [optionCategory, setOptionCategory] = useState();
  const [optionJurry, setOptionJurry] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addEntry, setAddEntry] = useState([]);
  const [selectedRounds, setselectedRounds] = useState(null);
  const [selectedMultiJury, setselectedMultiJury] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [optionAwards, setOptionAwards] = useState(null);
  const [selectedAward, setSelectedAward] = useState(null);
  const [isAward, setIsAward] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(true);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [juryDetails, setJuryDetails] = useState(null);
  const [modalTable, setModalTable] = useState(false);
  const [entryData, setEntryData] = useState(null);
  const [entryCodeError, setEntryCodeError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [catList, setCatList] = useState(null);
  const [groupList, setGroupList] = useState(null);



  const history = useHistory();
  const location = useLocation();

  let awardName = location?.search?.slice(12);
  let awardId = location?.search?.slice(7, 9);
  let awardRound = location?.search?.slice(10, 11);
  const usertype = getFromStorage("type");

  const categoryActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionCategory(getList);
        }
      });
    },
  };

  const { data: categoryData, isFetching: categoryFetching } = FetchData(
    "category",
    "/Admin/get-all-category",
    categoryActions
  );

  const actionsjuryList = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data?.map((item) => {
        let obj = {};
        obj["label"] = item.juryName;
        obj["value"] = item.juryId;
        optionList.push(obj);

        setOptionJurry(optionList);
      });
    },
  };

  const {
    data: juryList,
    refetch: fetchJurys,
    isFetching: juryFetching,
  } = FetchData(
    "jurylist",
    `Jury/get-jury-by-groupId?id=${selectedGroup?.value}`,
    actionsjuryList
  );

  const actions = {
    enabled: false,
    select: (data) => {
      const entryList = data.data.responseData.filter(
        (ele) =>
          ele.form_status !== "Incomplete" &&
          ele.final_status == "Completed" &&
          !ele.is_entry_rejected
      );

      const returnEntries =
        (selectedAward?.round == 2 || awardRound == 2) && entryList
          ? entryList?.filter(
              (item) => item?.bitIsRound2 === true && item?.bitIsRound1 === true
            )
          : entryList?.filter((item) => item?.bitIsRound1 === true);
      return returnEntries;
    },
  };

  const awardsActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.event_list.map((item) => {
        if (item.event_status === "Published") {
          let obj = {};
          obj["label"] = item.event_name;
          obj["value"] = item.event_id;
          obj["round"] = item.round;
          optionList.push({...item, ...obj});
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionAwards(getList);
        }
      });
    },
  };

  const {
    data: allEntries,
    refetch,
    isFetching: EntriyFetching,
  } = FetchData(
    "all-entries",
    `User/get-all-user-entry?event_id=${awardId}`,
    actions
  );

  useEffect(() => {
    if (awardId) {
      refetch();
    }
  }, [awardId]);

  const assignActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        if (data.data.status) {
          setShowSuccessAlert(false);
          var items = document.getElementsByName("entry-check");
          var allItem = document.getElementsByName("selectAll");

          for (var i = 0; i < items.length; i++) {
            if (items[i].type == "checkbox") items[i].checked = false;
          }
          for (var i = 0; i < allItem.length; i++) {
            if (allItem[i].type == "checkbox") allItem[i].checked = false;
          }
          refetch();
          setTimeout(() => {
            setShowSuccessAlert(true);
          }, 3000);
          setModal1(false);
          setAddEntry([]);
          formik.resetForm();
        } else {
          setShowErrorAlert(false);
        }
        // refetchEntryDetails();
        // reset();
      }
    },
  };
  const { mutate: assignJury, isLoading: isLoadingJury } = PostQuery(
    "/Admin/upsert-map-entry-category-jury",
    "post",
    assignActions
  );

  const getAssignJuryActions = {
    enabled: false,
    onSuccess: (data) => {
      if (data.data.status) {
        toggle();
      }
    },
  };
  const {
    data: getAssignJurys,
    refetch: isAssignJuryRefetch,
    isFetching: isAssignJuryFetching,
  } = FetchData(
    "getjurylist",
    `/User/jury-assign-entries-as-per-round?EventId=${entryData?.award}&EntryCode=${entryData?.code}`,
    getAssignJuryActions
  );

  useEffect(() => {
    if (entryData) {
      isAssignJuryRefetch();
    }
  }, [entryData]);

  const {
    data: eventsList,
    isFetching,
    isLoading,
    isFetched,
  } = FetchData("events", "/Admin/get-all-event", awardsActions);

  const handleChange = (e, item) => {
    if (e.target.checked) {
      setAddEntry((prev) => {
        return [...prev, item];
      });
    } else {
      setAddEntry((prev) => {
        return prev.filter((ele) => {
          if (ele !== item) {
            return item;
          }
        });
      });
    }
  };

  function handleRounds(e) {
    setselectedRounds(e);
  }

  function handleMultiJury(item) {
    setselectedMultiJury(item);
    formik.setFieldValue("jury", item);
  }

  function handleSelectCategory(item) {
    setSelectedCategory(item);
  }

  function handleSelectMember(item) {
    setSelectedMember(item);
    formik.setFieldValue("juryMember", item);
  }

  // console.log("optionAwards[0]?.options", optionAwards[0]?.options);
  function handleSelectAward(item) {
    setSelectedAward(item);
    setIsAward(item.label);
    formik.setFieldValue("awards", item);

    let filterGroupList = optionAwards[0]?.options.filter((eachVal) => {
      return eachVal.value === item.value;
    });

    let group = [];
    filterGroupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.groupName;
      obj["value"] = item.groupId;
      group.push(obj);
      const arrayUniqueByKey = [
        ...new Map(group.map((item) => [item["label"], item])).values(),
      ];
      setGroupList(arrayUniqueByKey);
    });

    let categories = [];
    filterGroupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.categoryName;
      obj["value"] = item.categoryId;
      categories.push(obj);
      const arrayUniqueByKeyCatList = [
        ...new Map(categories.map((item) => [item["label"], item])).values(),
      ];
      setCatList(arrayUniqueByKeyCatList);
    });
  }

  const [listEntries, setListEntries] = useState(null);
  let list_of_entries = allEntries;

  useEffect(() => {
    formik.setFieldValue("entries", addEntry);
  }, [addEntry]);
  const toggleViewModal1 = () => {
    if (isAward && addEntry?.length > 0) {
      setErrorMess("");
      groupFetch();
      // fetchJurys();
      setModal1(!modal1);
    } else {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (selectedGroup !== null) {
      fetchJurys();
    }
  }, [selectedGroup]);

  const toggleViewModal = () => {
    setModal(!modal);
  };
  const formik = useFormik({
    initialValues: {
      awards: "",
      category: "",
      juryMember: "",
      entryCode: "",
      jury: "",
      entries: "",
    },

    onSubmit: (values) => {
      let data = {
        intAward_Id: Number(awardId),
        EntryIdList: addEntry,
        JuryIdList: selectedMultiJury.map((item) => item.value),
      };
      assignJury(data);
    },
  });
  document.title = "Assign Jury | WOW Awards";
  const [optionGroup, setOptionGroup] = useState(null);

  function handleSelectGroup(item) {
    setSelectedGroup(item);
  }
  const groupActions = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.group_name;
          obj["value"] = item.group_id;
          optionList.push(obj);
          setOptionGroup(optionList);
        }
      });
    },
  };
  const {
    data: groupListData,
    refetch: groupFetch,
    isLoading: isLoadingGroup,
  } = FetchData("groups", "Admin/get-all-group", groupActions);

  useEffect(() => {
    if (isAward && list_of_entries !== null) {
      const awardData = list_of_entries?.filter(
        (item) => item?.event_detail?.event_name === isAward
      );

      const awardFilter = awardData?.filter(
        (item) => item?.bitIsRound2 === true && item?.bitIsRound1 === true
      );

      list_of_entries = awardData;
      setListEntries(
        awardFilter?.length > 0 &&
          (selectedAward?.round == 2 || awardRound == 2)
          ? awardFilter
          : awardData
      );
    }
    if (selectedCategory) {
      let categoryData = list_of_entries?.filter(
        (item) => item?.category_name === selectedCategory?.label
      );
      if (categoryData?.length === 0) {
        categoryData = list_of_entries;
        setCategoryError("Data Not Found");
      } else {
        setCategoryError("");
      }
      list_of_entries = categoryData;
      setListEntries(categoryData);
    }

    if (formik.values.entryCode) {
      let entryCodeData = list_of_entries?.filter(
        (item) => item?.entry_code === formik.values.entryCode.toString()
      );
      if (entryCodeData?.length === 0) {
        entryCodeData = list_of_entries;
        setEntryCodeError("Data Not Found");
      } else {
        setEntryCodeError("");
      }
      list_of_entries = entryCodeData;
      setListEntries(entryCodeData);
    }
  }, [
    list_of_entries,
    selectedCategory,
    selectedAward,
    formik.values.entryCode,
    isAward,
  ]);

  useEffect(() => {
    setIsAward(awardName.replaceAll("%20", " "));
    if (!awardName) {
      setModal(!modal);
      setSelectedAward(null);
    }
  }, [awardName]);

  const handleAward = () => {
    if (selectedAward) {
      setModal(false);
      history.push({
        pathname: "assign-jury",
        search: `award=${selectedAward?.value}:${selectedAward?.round}:${selectedAward?.label}`,
      });
    }
  };
  const onResetClick = () => {
    if (awardName) {
      // setModal(!modal);
      setSelectedAward(null);
      setIsAward(null);
      history.replace("/assign-jury");
    }
  };

  const handleAll = (e) => {
    if (e.target.checked) {
      setIsCheckAll(!isCheckAll);

      var items = document.getElementsByName("entry-check");
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox") {
          items[i].checked = true;
        }
      }
    } else {
      setIsCheckAll(false);
      var items = document.getElementsByName("entry-check");
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == "checkbox") items[i].checked = false;
      }
    }
  };
  const [juryData, setJuryData] = useState(null);

  const toggle = () => {
    if (modalTable) {
      setModalTable(false);
      setJuryDetails(null);
      setEntryData(null);
    } else {
      setModalTable(true);
      setJuryDetails(null);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <Input
            style={{ cursor: "pointer" }}
            type="checkbox"
            onChange={(e) => handleAll(e)}
            name="selectAll"
            // id="selectAll"
          />
        ),
        accessor: "cellProps",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return (
            <Input
              id={row.row.original.entry_id}
              style={{ cursor: "pointer" }}
              name="entry-check"
              type="checkbox"
              onChange={(e) => {
                handleChange(e, row.row.original.entry_id, row.row.original);
              }}
              className="checkbox"
            />
          );
        },
      },

      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("Do MMM YYYY");
        },
      },
      {
        Header: "Entrant organization",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entrants-details",
                  search: `userId=${cellProps.row.original?.entrant_id}`,
                });
              }}
            >
              {cellProps.value}
            </p>
          );
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entry-details",
                  search: `id=${cellProps.row.original.entry_id}`,
                });
              }}
            >
              {cellProps.value}
            </p>
          );
        },
      },
      {
        Header: "Award Name",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original.event_detail?.event_name}</p>;
        },
      },

      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Subgroup",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Assign Jury",
        // accessor: "finalStatus",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {cellProps?.row?.original?.bitIsRound2 &&
              cellProps?.row?.original?.bitIsRound1 ? (
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  onClick={
                    () => {
                      const entry_data = {
                        code: cellProps?.row?.original?.entry_code,
                        award: cellProps?.row?.original?.event_detail?.event_id,
                      };
                      setEntryData(entry_data);
                      setJuryData(cellProps.row?.original);
                    }

                    // cellProps.row?.original?.juryAssignToEntriesRound2?.length >
                    //   0 &&

                    // toggle(
                    //   cellProps.row?.original?.juryAssignToEntriesRound2,
                    //   cellProps.row?.original
                    // )
                  }
                  // disabled={
                  //   cellProps.row?.original?.jury_member_round1?.length === 0
                  //     ? true
                  //     : false
                  // }
                >
                  View Jurys
                </Button>
              ) : (
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  // onClick={() =>
                  //   // cellProps.row?.original?.juryAssignToEntriesRound1?.length >
                  //   // 0 &&
                  //   toggle(
                  //     cellProps.row?.original?.juryAssignToEntriesRound2,
                  //     cellProps.row?.original
                  //   )
                  // }
                  onClick={() => {
                    const entry_data = {
                      code: cellProps?.row?.original?.entry_code,
                      award: cellProps?.row?.original?.event_detail?.event_id,
                    };
                    setEntryData(entry_data);
                    setJuryData(cellProps.row?.original);
                  }}
                  // disabled={
                  //   cellProps.row?.original?.jury_member_round1?.length === 0
                  //     ? true
                  //     : false
                  // }
                >
                  View Jurys
                </Button>
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const juryColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Jury Member",
        accessor: "jury_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps?.value;
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (listEntries?.length > 0 && isCheckAll) {
      const getData = listEntries.map((ele) => ele.entry_id);
      setAddEntry(getData);
    } else {
      setAddEntry([]);
    }
  }, [listEntries, isCheckAll]);

  const onClearClick = () => {
    if (awardName) {
      setSelectedCategory(null);
      setEntryCodeError("");
      setCategoryError("");
      setListEntries(null);
    }
  };

  console.log("isCheck", addEntry);
  return (
    <React.Fragment>
      {!showSuccessAlert && (
        <UncontrolledAlert
          color="success"
          className={`alert-dismissible fade ${showSuccessAlert ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-check-all me-2"></i>Entry Assign To Jury has
          been Successfully!
        </UncontrolledAlert>
      )}
      {showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${showErrorAlert ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="bx bx-error-circle me-1"></i>Please Select One Entry!
        </UncontrolledAlert>
      )}

      <JurydetailsModel
        toggle={toggle}
        modal={modalTable}
        juryColumns={juryColumns}
        data={
          awardRound == 2 || selectedAward?.round == 2
            ? getAssignJurys?.data?.responseData?.juryNameAssignRound2
            : getAssignJurys?.data?.responseData?.juryNameAssignRound1
        }
        showScore
        details={juryData}
      />
      <AssignJurryModel
        isOpen={modal1}
        toggle={toggleViewModal1}
        handleMultiJury={handleMultiJury}
        optionJurry={optionJurry}
        formik={formik.handleSubmit}
        toggleModal={toggleViewModal}
        handleSelectAward={handleSelectAward}
        handleSelectGroup={handleSelectGroup}
        optionAwards={optionAwards}
        handleAward={handleAward}
        selectedAward={selectedAward}
        selectedGroup={selectedGroup}
        optionGroup={groupList}
        modal={modal}
        usertype={usertype}
      />

      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={
                      awardName && !modal
                        ? `Assign Jury : ${awardName.replaceAll(
                            "%20",
                            " "
                          )} (Round ${awardRound}) `
                        : "Assign Jury "
                    }
                    buttonName={"Assign Jury"}
                    onButtonClick={() => toggleViewModal1()}
                    pageLoading={false}
                    hasButton={true}
                    resetButton={"Reset"}
                    onResetClick={onResetClick}
                    awardName={awardName}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row className="align-items-end">
                      <Col sm="5">
                        <div className="mb-3">
                          <SelectInput
                            label={"Select Category"}
                            name={"category"}
                            type={"select"}
                            onChange={(e) => {
                              handleSelectCategory(e);
                            }}
                            options={catList}
                            value={selectedCategory || ""}
                          />
                        </div>
                      </Col>
                      <Col xl="5">
                        <div className="mb-3">
                          <Label htmlFor="productname">Enter Entry Code</Label>
                          <Input
                            name="entryCode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleChange}
                            value={formik.values.entryCode || ""}
                          ></Input>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="mb-3">
                          <Button
                            type="button"
                            outline
                            color="primary"
                            // className="btn-sm "
                            onClick={onClearClick}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              {awardName && listEntries?.length > 0 ? (
                <Card>
                  <CardBody>
                    <CardTitle>Select Entries</CardTitle>

                    <div>
                      {allEntries &&
                      selectedCategory === null &&
                      isAward === null &&
                      formik.values.entryCode === "" ? (
                        <TableContainer
                          columns={columns}
                          data={allEntries}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          setAddEntry={setAddEntry}
                        />
                      ) : null}
                    </div>
                    {listEntries && (
                      <div>
                        {selectedCategory !== null ||
                        isAward !== null ||
                        formik.values.entryCode !== "" ? (
                          <TableContainer
                            columns={columns}
                            data={listEntries}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            setAddEntry={setAddEntry}
                          />
                        ) : null}
                      </div>
                    )}
                  </CardBody>
                </Card>
              ) : (
                awardName && (
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#CC962B",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    No data found
                  </span>
                )
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {(EntriyFetching ||
        isAssignJuryFetching ||
        juryFetching ||
        categoryFetching) && <ScreenLoader />}
    </React.Fragment>
  );
}

export default AssignJury;
