import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ReasonModal = (props) => {
  const {
    isOpen,
    toggle,
    onSubmitClick,
    setReson,
    reson,
    handleReson,
    errorMess,
    setErrorMess,
    handleChnageReson,
    isApprovalLoading,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Enter Reason</ModalHeader>
        <ModalBody>
          <textarea
            className="form-control"
            id="projectdesc"
            rows="3"
            name="reson"
            placeholder="Please enter reason for rejection "
            // value={reson}
            onChange={handleChnageReson}
          />
          <span style={{ color: "red", fontSize: "10px" }}>{errorMess}</span>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="danger"
            value="false"
            onClick={handleReson}
          >
            {" "}
            {isApprovalLoading && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            )}
            Reject
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};
// ReasonModal.propTypes = {
//   onCloseClick: PropTypes.func,
//   onSubmitClick: PropTypes.func,
//   show: PropTypes.any,

// };
export default ReasonModal;
