import TableContainer from "@components/Common/TableContainer";
import React from "react";
import { Card, CardBody, Col, Row, UncontrolledAlert } from "reactstrap";
import EventsDetailModal from "../../pages/Admin/Events/EventsDetailModal";
import DeleteModal from "../Common/DeleteModal";
import FormHeader from "../Common/FormHeader";
import UpdateEvent from "./UpdateEvent";

const Event = (props) => {
  return (
    <div>
      {" "}
      {!props.showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${
            props.showErrorAlert ? "show" : ""
          }`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-block-helper me-2"></i>
          {props.showErrorMsg}
        </UncontrolledAlert>
      )}
      <EventsDetailModal
        isOpen={props.modal1}
        toggle={props.toggleViewModal}
        data={props.modalData}
      />
      <DeleteModal
        show={props.deleteModal}
        onDeleteClick={props.handleDeleteOrder}
        onCloseClick={() => props.setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={"Awards"}
                    buttonName={"Add New Award"}
                    onButtonClick={() => props.history.push("/add-awards")}
                    pageLoading={props.isFetching || props.isLoading}
                    hasButton={true}
                  />

                  <div>
                    {props.eventsList?.event_list?.length > 0 ? (
                      <TableContainer
                        columns={props.columns}
                        data={props.eventsList.event_list}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              {props.isFetched && props.eventsList.event_list.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="text-primary">No data Found</h4>
                </div>
              )}
            </Col>
          </Row>
          <UpdateEvent
            validation={props.validation}
            status={props.status}
            modal={props.modal}
            toggle={props.toggle}
            isLoadingUpdateEvent={props.isLoadingUpdateEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default Event;
