import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';

const RejectAprrove = (props) => {
  return (
    <>
      <Card>
        <CardBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div className="form-check mb-3" style={{ paddingLeft: '0' }}>
              <h4 className="form-check-label" htmlFor="isAcceptingTerms">
                {' '}
                {props.heading}
              </h4>
            </div>
            <div className="d-flex flex-wrap gap-2">
              {props.Reject && (
                <Button
                  outline
                  type="submit"
                  color="primary"
                  className="btn "
                  // disabled={isLoading}
                  onClick={() => props.onClick()}
                >
                  {props.Reject}
                </Button>
              )}

              {props.Approve && (
                <Button
                  color="primary"
                  value="true"
                  onClick={(e) => props.handleReason(e)}
                >
                  {props.Approve}
                </Button>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default RejectAprrove;
