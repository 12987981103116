import TableContainer from "@components/Common/TableContainer";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import classNames from "classnames";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledAlert,
} from "reactstrap";
// import AwardModel from "../../../components/Common/AwardModel";
import FormHeader from "../../../components/Common/FormHeader";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import SelectInput from "../../../components/Form/SelectInput";
import { ID } from "./EditableCol";

function Entries() {
  const history = useHistory();
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabName, setactiveTabName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSubroup, setSelectedSubgroup] = useState(null);
  const [selectedEntrant, setSelectedEntrant] = useState(null);
  const [selectedFormStatus, setSelectedFormStatus] = useState(null);
  const [selectedClientStatus, setSelectedClientStatus] = useState(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
  const [selectedFinalStatus, setSelectedFinalStatus] = useState(null);
  const [regions, setRegions] = useState([]);
  const [errorMsg, setErrorMsg] = useState();
  const [listFormStatusError, setListFormStatusError] = useState();
  const [successMsg, setSuccessMsg] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showSuccesAlert, setShowSuccessAlert] = useState(true);
  const [optionGroup, setOptionGroup] = useState();
  const [optionSubgroup, setOptionSubgroup] = useState();
  const [optionEntrant, setOptionEntrant] = useState();
  const [optionCategory, setOptionCategory] = useState();
  const [openFilters, setOpenFilters] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [listEntries, setListEntries] = useState(null);
  const [grouError, setGrouError] = useState("");
  const [tableDataError, setTableDataError] = useState("");
  const [subgroupError, setSubgroupError] = useState("");
  const [entrantError, setEntrantError] = useState("");
  const [formStatusError, setFormStatusError] = useState("");
  const [approvalError, setApprovalError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [finalStatusError, setFinalStatusError] = useState("");
  const location = useLocation();
  let awardId = location?.search?.slice(7, 9);

  const optionFormStatus = [
    {
      options: [
        { label: "Incomplete", value: "0" },
        { label: "Submitted", value: "1" },
        { label: "Entry Rejected", value: "true" },
      ],
    },
  ];
  const optionClientStatus = [
    {
      options: [
        { label: "Not Required", value: "Not_Requried" },
        { label: "Pending", value: "Pending" },
        { label: "Approved", value: "Approved" },
      ],
    },
  ];
  const optionPaymentStatus = [
    {
      options: [
        { label: "Processing", value: "0" },
        { label: "Pending", value: "1" },
        { label: "Paid", value: "2" },
      ],
    },
  ];
  const optionFinalStatus = [
    {
      options: [
        { label: "Incomplete", value: "0" },
        { label: "Completed", value: "1" },
      ],
    },
  ];

  const categoryActions = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionCategory(getList);
        }
      });
    },
  };

  const { data: categoryData, refetch: fetchCategory } = FetchData(
    "category",
    "/Admin/get-all-category",
    categoryActions
  );

  const actions = {
    select: (data) => {
      const entryList = data.data.responseData;
      return entryList;
    },
    onSuccess: (data) => {
      fetchCategory();
      setRegions([...new Set(data?.map((item) => item.region))]);
      setactiveTabName([...new Set(data?.map((item) => item.region))][0]);
    },
  };
  const {
    data: allEntries,
    isFetching,
    isLoading,
    refetch,
    isFetched,
  } = FetchData("all-entries", `User/get-all-user-entry`, actions);

  const resentEmailActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setShowSuccessAlert(false);
        setSuccessMsg(data.data.responseData);
        setTimeout(() => {
          setShowSuccessAlert(true);
          setSuccessMsg(null);
        }, 3000);
      } else {
        setShowErrorAlert(false);
        setErrorMsg(data.data.errors[0]);
        setTimeout(() => {
          setShowErrorAlert(true);
        }, 3000);
      }
    },
    onError: (error) => {
      setErrorMsg("Something went wrong!");
    },
  };

  const clientApproveActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
      } else {
        // setErrorMsg(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setErrorMsg("Something went wrong!");
    },
  };

  // useEffect(() => {
  //   if (!awardId) {
  //     setIsOpen(true);
  //   }
  // }, [awardId]);

  const formStatusActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
        setShowSuccessAlert(false);
        setSuccessMsg(data.data.responseData);
        setTimeout(() => {
          setShowSuccessAlert(true);
          setSuccessMsg(null);
        }, 3000);
      } else {
        setShowErrorAlert(false);
        setErrorMsg(data.data.errors[0]);
        setTimeout(() => {
          setShowErrorAlert(true);
        }, 3000);
      }
    },
    onError: (error) => {
      setErrorMsg("Something went wrong!");
    },
  };
  const paymentActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
      } else {
        // setErrorMsg(data.data.errors[0]);
      }
    },
    onError: (error) => {
      // setErrorMsg('Something went wrong!');
    },
  };
  const markEntryCompleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
      }
    },
  };

  const { mutate, isLoading: resendClientApprovalLoading } = PostQuery(
    "Admin/resend-client-approval-mail",
    "post",
    resentEmailActions
  );
  const { mutate: formStatus, isLoading: formStatusLoading } = PostQuery(
    "Admin/change-form-status",
    "post",
    formStatusActions
  );
  const { mutate: approveClientStatus, isLoading: approveClientLoading } =
    PostQuery(
      "Admin/client-approval-for-entry-status",
      "post",
      clientApproveActions
    );
  const { mutate: mutatePayment, isLoading: paymentLoading } = PostQuery(
    "Admin/change-payment-status",
    "post",
    paymentActions
  );
  const { mutate: mutateMarkEntryComplete, isLoading: entryCompleteLoading } =
    PostQuery("Admin/mark-entry-complete", "post", markEntryCompleteActions);

  const GroupActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.group_name;
          obj["value"] = item.group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionGroup(getList);
        }
      });
    },
  };

  const { data: groupData, refetch: fetchgroup } = FetchData(
    "groups",
    "/Admin/get-all-group",
    GroupActions
  );

  const subgroupActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data?.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.sub_group_name;
          obj["value"] = item.sub_group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionSubgroup(getList);
        }
      });
    },
  };

  const { data: subgroupData, refetch: fetchsubgroup } = FetchData(
    "sub-groups",
    "Admin/get-all-subgroup",
    subgroupActions
  );

  const entrantActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.status === "Approved" && item.number_of_entries > 0) {
          obj["label"] = item.entrant_name;
          obj["value"] = item.registration_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionEntrant(getList);
        }
      });
    },
  };
  const {
    data: allEntrant,
    isFetching: isEntarntFetching,
    isLoading: isEntarntLoading,
  } = FetchData("entrants", "Admin/get-all-entrant", entrantActions);

  // const [optionAwardList, setAwardOptionList] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);

 

  // const eventActions = {
  //   select: (data) => {
  //     const list = data.data.responseData;
  //     return list;
  //   },
  //   onSuccess: (data) => {
  //     let optionList = [];
  //     data.event_list?.map((item) => {
  //       let obj = {};
  //       // if (item.is_active === "Published") {
  //       obj["label"] = item.event_name;
  //       obj["value"] = item.event_id;
  //       // obj["round"] = item.event_round;
  //       optionList.push(obj);
  //       setAwardOptionList(optionList);
  //       // }
  //     });
  //   },
  // };

  // const {
  //   data: eventsList,
  //   isFetching: isEventFetching,
  //   isLoading: isEventLoading,
  // } = FetchData("events", "/Admin/get-all-event", eventActions);

  const handleAprroveEmail = (id, value) => {
    const Approvestatus = {
      Entry_Id: id,
    };
    mutate(Approvestatus);
  };

  const handleListFormStatus = (id) => {
    const FormStatus = {
      EntryId: id,
      FormStatus: true,
    };
    formStatus(FormStatus);
  };
  const handleAprroveStatus = (id, value) => {
    const ApproveClientstatus = {
      Entry_Id: id,
      Entry_Status: Number(value),
    };
    approveClientStatus(ApproveClientstatus);
  };
  const handleProcessingPayment = (id, status) => {
    const payment = {
      Entry_Id: id,
      Payment_Status: Number(status),
    };
    mutatePayment(payment);
  };

  const handleMarkEntryComplete = (id, status) => {
    const markEntryComplete = {
      Entry_Id: id,
      Final_status: status,
    };
    mutateMarkEntryComplete(markEntryComplete);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_Date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.row.original?.created_date).format(
            "Do MMM YYYY"
          );
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entry-details",
                  search: `id=${cellProps.row.original.entry_id}`,
                });
              }}
            >
              {cellProps.value}
            </p>
          );
        },
      },

      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Category Group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Category Sub-group",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Entrant Name",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entrants-details",
                  search: `userId=${cellProps.row.original?.entrant_id}`,
                });
              }}
            >
              {cellProps.value}
            </p>
          );
        },
      },
      {
        Header: "Entrant Email",
        accessor: "entrant_Email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Form Status",
        accessor: "form_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.row.original.is_entry_rejected
                  ? "danger"
                  : cellProps.value === "Submitted"
                  ? "success"
                  : "warning")
              }
              pill
            >
              {cellProps.row.original.is_entry_rejected
                ? "Entry Rejected"
                : cellProps.value === "Submitted"
                ? "Submitted"
                : "Incomplete"}
            </Badge>
          );
        },
      },
      {
        Header: "Client Approval Status",
        accessor: "client_approval_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p style={{ fontWeight: "700" }}>
              {cellProps.row.original.client_approval_status?.replace(/_/, " ")}
            </p>
          );
        },
      },
      {
        Header: "Payment Code",
        accessor: "payment_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p style={{ fontWeight: "700" }}> {cellProps?.value} </p>;
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p style={{ fontWeight: "700" }}> {cellProps?.value} </p>;
        },
      },
      {
        Header: "Final Status",
        accessor: "final_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p style={{ fontWeight: "700" }}> {cellProps?.value} </p>;
        },
      },
      {
        Header: "Not Submitting",
        accessor: "not_submitting_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        disableSortBy: true,
        Cell: (e) => {
          return (
            !e.row.original.is_entry_rejected && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: 175,
                  width: 175,
                }}
              >
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm"
                  disabled={
                    e.row.original.is_entry_rejected ||
                    e.row.original.form_status === "Incomplete"
                      ? true
                      : false
                  }
                  onClick={() => {
                    handleListFormStatus(
                      e.cell.row.original.entry_id
                      // e.row.original.client_approval_status === "Submitted"
                      //   ? "0"
                      //   : "1"
                    );
                  }}
                >
                  {"Mark Form Status Incomplete"}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm"
                  disabled={e.row.original.is_entry_rejected ? true : false}
                  onClick={(event) => {
                    event.preventDefault();
                    // console.log("Rohan")
                    handleAprroveStatus(
                      e.cell.row.original.entry_id,
                      e.row.original.client_approval_status === "Pending"
                        ? "0"
                        : "1"
                    );
                  }}
                >
                  {e.row.original.client_approval_status === "Approved"
                    ? "Unapprove Client"
                    : "Approve Client"}
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm"
                  disabled={e.row.original.is_entry_rejected ? true : false}
                  onClick={() => {
                    handleAprroveEmail(e.cell.row.original.entry_id);
                  }}
                >
                  Resend Client Approval Email
                </Button>

                <Button
                  type="button"
                  color="primary"
                  className="btn-sm"
                  onClick={() =>
                    handleProcessingPayment(
                      e.cell.row.original.entry_id,
                      e.row.original.payment_status == "Processing" ? 3 : 2
                    )
                  }
                  disabled={e.row.original.is_entry_rejected ? true : false}
                >
                  {e.row.original.payment_status === "Processing"
                    ? "Mark Payment as Paid"
                    : "Mark Payment as Processing"}
                </Button>
                <Button
                  disabled={
                    e.row.original.form_status === "Submitted" &&
                    (e.row.original.client_approval_status === "Not_Requried" ||
                      e.row.original.client_approval_status === "Approved") &&
                    e.row.original.payment_status === "Paid" &&
                    e.row.original.not_submitting_status === "Submitting"
                      ? false
                      : true
                  }
                  type="button"
                  color="primary"
                  className="btn-sm"
                  onClick={() => {
                    handleMarkEntryComplete(
                      e.cell.row.original.entry_id,
                      e.row.original.final_status === "Completed" ? 1 : 2
                    );
                  }}
                >
                  {e.row.original.final_status === "Completed"
                    ? " Mark Entry Incomplete"
                    : "Mark Entry Complete"}
                </Button>
              </div>
            )
          );
        },
      },
    ],
    []
  );

  function handleGroup(item) {
    setSelectedGroup(item);
  }

  function handleSubgroup(item) {
    setSelectedSubgroup(item);
  }

  function handleCategory(item) {
    setSelectedCategory(item);
  }

  function handleFormStatus(item) {
    setSelectedFormStatus(item);
  }
  function handleClientStatus(item) {
    setSelectedClientStatus(item);
  }
  function handlePaymentStatus(item) {
    setSelectedPaymentStatus(item);
  }
  function handleFinalStatus(item) {
    setSelectedFinalStatus(item);
  }
  function handleEntrant(item) {
    setSelectedEntrant(item);
  }

  // const [selectedAward, setSelectedAward] = useState(null);
  const [eventError, setEventError] = useState("");

  let list_Of_Entries = allEntries;

  useEffect(() => {
    // if (selectedAward || awardId) {
    //   let eventData = list_Of_Entries?.filter((item) => {
    //     return item?.event_detail?.event_id === Number(awardId);
    //   });
    //   if (eventData?.length === 0) {
    //     eventData = list_Of_Entries;
    //     setEventError("Data Not Found");
    //   } else {
    //     setEventError("");
    //   }
    //   list_Of_Entries = eventData;
    //   setListEntries(eventData);
    // }
    if (selectedGroup) {
      let groupData = list_Of_Entries?.filter((item) => {
        return item?.group_name === selectedGroup?.label;
      });
      if (groupData?.length === 0) {
        groupData = list_Of_Entries;
        setGrouError("Data Not Found");
      } else {
        setGrouError("");
      }
      list_Of_Entries = groupData;
      setListEntries(groupData);
    }
    if (selectedSubroup) {
      let subGroupData = list_Of_Entries.filter((item) => {
        return item?.subgroup_name === selectedSubroup?.label;
      });
      if (subGroupData?.length === 0) {
        subGroupData = list_Of_Entries;
        setSubgroupError("Data Not Found");
      } else {
        setSubgroupError("");
      }
      list_Of_Entries = subGroupData;
      setListEntries(subGroupData);
    }
    if (selectedCategory) {
      let categoryData = list_Of_Entries?.filter((item) => {
        return item?.category_name === selectedCategory?.label;
      });
      if (categoryData?.length === 0) {
        categoryData = list_Of_Entries;
        setTableDataError("Data Not Found");
      } else {
        setTableDataError("");
      }
      list_Of_Entries = categoryData;
      setListEntries(categoryData);
    }
    if (selectedEntrant) {
      let entrantData = list_Of_Entries?.filter((item) => {
        return item?.entrant_name === selectedEntrant?.label;
      });
      if (entrantData?.length === 0) {
        entrantData = list_Of_Entries;
        setEntrantError("Data Not Found");
      } else {
        setEntrantError("");
      }
      list_Of_Entries = entrantData;
      setListEntries(entrantData);
    }
    if (selectedFormStatus) {
      let formStatusData = list_Of_Entries?.filter((item) => {
        if (selectedFormStatus.label === "Entry Rejected") {
          return item?.is_entry_rejected === true;
        } else if (selectedFormStatus.label === "Incomplete") {
          return (
            item?.form_status === selectedFormStatus?.label &&
            item?.is_entry_rejected === false
          );
        } else {
          return item?.form_status === selectedFormStatus?.label;
        }
      });

      if (formStatusData?.length === 0) {
        formStatusData = list_Of_Entries;
        setFormStatusError("Data Not Found");
      } else {
        setFormStatusError("");
      }
      list_Of_Entries = formStatusData;
      setListEntries(formStatusData);
    }
    if (selectedClientStatus) {
      let clientStatusData = list_Of_Entries?.filter((item) => {
        return item?.client_approval_status === selectedClientStatus?.value;
      });
      if (clientStatusData?.length === 0) {
        clientStatusData = list_Of_Entries;
        setApprovalError("Data Not Found");
      } else {
        setApprovalError("");
      }
      list_Of_Entries = clientStatusData;
      setListEntries(clientStatusData);
    }
    if (selectedPaymentStatus) {
      let paymentStatusData = list_Of_Entries?.filter((item) => {
        return item?.payment_status === selectedPaymentStatus?.label;
      });
      if (paymentStatusData?.length === 0) {
        paymentStatusData = list_Of_Entries;
        setPaymentError("Data Not Found");
      } else {
        setPaymentError("");
      }
      list_Of_Entries = paymentStatusData;
      setListEntries(paymentStatusData);
    }
    if (selectedFinalStatus) {
      let finalStatusData = list_Of_Entries?.filter((item) => {
        return item?.final_status === selectedFinalStatus?.label;
      });
      if (finalStatusData?.length === 0) {
        finalStatusData = list_Of_Entries;
        setFinalStatusError("Data Not Found");
      } else {
        setFinalStatusError("");
      }
      list_Of_Entries = finalStatusData;
      setListEntries(finalStatusData);
    }
  }, [
    list_Of_Entries,
    // awardId,
    // selectedAward,
    selectedGroup,
    selectedSubroup,
    selectedCategory,
    selectedFormStatus,
    selectedClientStatus,
    selectedEntrant,
    selectedPaymentStatus,
    selectedFinalStatus,
  ]);
  const handleClearAll = () => {
    setSelectedGroup(null);
    setSelectedCategory(null);
    setSelectedSubgroup(null);
    setSelectedFormStatus(null);
    setSelectedClientStatus(null);
    setSelectedPaymentStatus(null);
    setSelectedFinalStatus(null);
    setSelectedEntrant(null);
    setTableDataError(" ");
    setGrouError(" ");
    setSubgroupError("");
    setFormStatusError(" ");
    setEntrantError(" ");
    setApprovalError(" ");
    setPaymentError("");
    setFinalStatusError("");
  };
  const handleFilter = () => {
    setOpenFilters(!openFilters);
  };

  // function handleSelectAward(item) {
  //   setSelectedAward(item);
  // }

  // const handleAward = () => {
  //   if (selectedAward) {
  //     setIsOpen(false);
  //     history.push({
  //       pathname: "/entries",
  //       search: `award=${selectedAward?.value}`,
  //     });
  //   }
  // };
  document.title = "Entries | WOW Awards";

  // const handleReset = () => {
  //   if (awardId) {
  //     setSelectedAward(null);
  //     awardId = "";
  //     history.replace("/entries");
  //   }
  // };

  return (
    <React.Fragment>
      {/* <AwardModel
        isOpen={isOpen}
        toggle={toggle}
        handleSelectAward={handleSelectAward}
        optionAwards={optionAwardList}
        handleAward={handleAward}
        selectedAward={selectedAward}
        usertype={"Admin"}
      /> */}
      <div className="page-content">
        {!showErrorAlert && (
          <UncontrolledAlert
            color="danger"
            className={`alert-dismissible fade ${showErrorAlert ? "show" : ""}`}
            role="alert"
            style={{
              position: "fixed",
              right: "10px",
              top: "12%",
              zIndex: "2000",
            }}
          >
            <i className="mdi mdi-block-helper me-2"></i>
            {errorMsg}
          </UncontrolledAlert>
        )}
        {!showSuccesAlert && (
          <UncontrolledAlert
            color="success"
            className={`alert-dismissible fade ${
              showSuccesAlert ? "show" : ""
            }`}
            role="alert"
            style={{
              position: "fixed",
              right: "10px",
              top: "12%",
              zIndex: "2000",
            }}
          >
            <i className="mdi mdi-check-all me-2"></i>
            {successMsg}
          </UncontrolledAlert>
        )}
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              {listEntries?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="text-primary">No data Found</h4>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <FormHeader
                      iconName={"bx-layer"}
                      heading={`Entries
                    ${listEntries?.length > 0
                        ? `(${listEntries?.length})`
                        : " "
                    }`}
                      pageLoading={isLoading || isFetching}
                      hasButton={false}
                    />

                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Col sm="8">
                          {regions?.length > 1 ? (
                            <Nav
                              pills
                              className="icon-tab nav-justified"
                              role="tablist"
                              style={{ width: "70%" }}
                            >
                              {regions?.map((item, i) => {
                                return (
                                  <NavItem key={i}>
                                    <NavLink
                                      className={classNames({
                                        active: activeTab === i + 1,
                                      })}
                                      onClick={() => {
                                        setactiveTab(i + 1);
                                        setactiveTabName(item);
                                      }}
                                    >
                                      {`${item} (${
                                        allEntries?.filter(
                                          (elem) => elem?.region === item
                                        )?.length
                                      })`}
                                    </NavLink>
                                  </NavItem>
                                );
                              })}
                            </Nav>
                          ) : null}
                        </Col>
                        {allEntries && (
                          <Col sm="4">
                            <div style={{ display: "flex", float: "right" }}>
                              <div style={{ marginRight: "20px" }}>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="btn "
                                  onClick={handleFilter}
                                >
                                  {openFilters ? "Hide Filter" : "Filter"}
                                </Button>
                              </div>

                              {/* <div>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="btn "
                                  onClick={handleReset}
                                >
                                  Reset Award
                                </Button>
                              </div> */}
                            </div>
                          </Col>
                        )}
                        {/* {awardId && (
                          <Col sm="4">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="btn "
                                onClick={handleReset}
                              >
                                Reset Award
                              </Button>
                            </div>
                          </Col>
                        )} */}
                      </div>
                    </Row>
                    {openFilters && (
                      <div>
                        <div style={{ margin: "10px 0" }}>
                          <Row>
                            <Col sm="3">
                              <SelectInput
                                label={" Group:"}
                                name={"select_group"}
                                type={"select"}
                                onChange={(e) => {
                                  handleGroup(e);
                                }}
                                options={optionGroup}
                                value={selectedGroup}
                              />
                              {grouError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {grouError}
                                </span>
                              )}
                            </Col>
                            <Col sm="3">
                              <SelectInput
                                label={"Subgroup:"}
                                name={"select_subgroup"}
                                type={"select"}
                                onChange={(e) => {
                                  handleSubgroup(e);
                                }}
                                options={optionSubgroup}
                                value={selectedSubroup}
                              />
                              {subgroupError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {subgroupError}
                                </span>
                              )}
                            </Col>

                            <Col sm="3">
                              <SelectInput
                                label={"Category:"}
                                name={"select_category"}
                                type={"select"}
                                onChange={(e) => {
                                  handleCategory(e);
                                }}
                                options={optionCategory}
                                value={selectedCategory}
                              />
                              {tableDataError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {tableDataError}
                                </span>
                              )}
                            </Col>
                            <Col sm="3">
                              <SelectInput
                                label={"Entrant Name:"}
                                name={"select_entrant"}
                                type={"select"}
                                onChange={(e) => {
                                  handleEntrant(e);
                                }}
                                options={optionEntrant}
                                value={selectedEntrant}
                              />
                              {entrantError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {entrantError}
                                </span>
                              )}
                            </Col>
                          </Row>
                        </div>
                        <div style={{ margin: "20px 0" }}>
                          <Row>
                            <Col sm="3">
                              <SelectInput
                                label={"Client Approval Status:"}
                                name={"select_clientStatus"}
                                type={"select"}
                                onChange={(e) => {
                                  handleClientStatus(e);
                                }}
                                options={optionClientStatus}
                                value={selectedClientStatus}
                              />
                              {approvalError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {approvalError}
                                </span>
                              )}
                            </Col>
                            <Col sm="3">
                              <SelectInput
                                label={"Form Status:"}
                                name={"select_formStatus"}
                                type={"select"}
                                onChange={(e) => {
                                  handleFormStatus(e);
                                }}
                                options={optionFormStatus}
                                value={selectedFormStatus}
                              />
                              {formStatusError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {formStatusError}
                                </span>
                              )}
                            </Col>
                            <Col sm="3">
                              <SelectInput
                                label={"Payment Status:"}
                                name={"select_paymentStatus"}
                                type={"select"}
                                onChange={(e) => {
                                  handlePaymentStatus(e);
                                }}
                                options={optionPaymentStatus}
                                value={selectedPaymentStatus}
                              />

                              {paymentError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {paymentError}
                                </span>
                              )}
                            </Col>
                            <Col sm="3">
                              <SelectInput
                                label={"Final Status :"}
                                name={"select_finalStatus"}
                                type={"select"}
                                onChange={(e) => {
                                  handleFinalStatus(e);
                                }}
                                options={optionFinalStatus}
                                value={selectedFinalStatus}
                              />

                              {finalStatusError && (
                                <span
                                  style={{ color: "red", position: "absolute" }}
                                >
                                  {finalStatusError}
                                </span>
                              )}
                            </Col>
                          </Row>
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Button
                              outline
                              type="button"
                              color="primary"
                              className="btn-sm mt-4 align-items-center"
                              onClick={handleClearAll}
                            >
                              Clear All
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div>
                      {allEntries &&
                      selectedCategory === null &&
                      selectedClientStatus === null &&
                      selectedGroup === null &&
                      selectedEntrant === null &&
                      selectedFinalStatus === null &&
                      selectedFormStatus === null &&
                      selectedPaymentStatus === null &&
                      selectedSubroup === null ? (
                        <TableContainer
                          columns={columns}
                          data={allEntries?.filter(
                            (item) => item?.region === activeTabName
                          )}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                        />
                      ) : null}
                    </div>

                    <div>
                      {listEntries &&
                      ((selectedCategory !== null) ||
                        selectedClientStatus !== null ||
                        selectedGroup !== null ||
                        selectedEntrant !== null ||
                        selectedFinalStatus !== null ||
                        selectedFormStatus !== null ||
                        selectedPaymentStatus !== null ||
                        selectedSubroup !== null) ? (
                        <TableContainer
                          columns={columns}
                          data={listEntries?.length ? listEntries : listEntries}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                        />
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
      {(isFetching ||
        isLoading ||
        paymentLoading ||
        resendClientApprovalLoading ||
        entryCompleteLoading ||
        formStatusLoading ||
        approveClientLoading) && <ScreenLoader />}
    </React.Fragment>
  );
}

export default Entries;
