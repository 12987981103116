const fetch_type = (type) => {
  let event = new CustomEvent("fetch_type", {
    type,
  });
  document.dispatchEvent(event);
};

const fetch_region = (region) => {
  console.log("type", region);
  let event = new CustomEvent("fetch_region", {
    region,
  });
  document.dispatchEvent(event);
};

export { fetch_region, fetch_type };

