import { FetchData, PostQuery } from "@hooks/api/useApi";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Row,
//   UncontrolledAlert,
// } from "reactstrap";

import {
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledAlert
} from "reactstrap";
import FormHeader from "../../../components/Common/FormHeader";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import TableContainer from "../../../components/Common/TableContainer";
import { ID } from "./EditableCol";
function JuryList() {
  const history = useHistory();
  const [sucessMsg, setSucessMsg] = useState(false);
  const [regions, setRegions] = useState([]);
  const [activeTab, setactiveTab] = useState(1);

  const [activeTabName, setactiveTabName] = useState("");



  const actionsjuryList = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      setRegions([...new Set(data?.map((item) => item.region))]);
      setactiveTabName([...new Set(data?.map((item) => item.region))][0]);
    },
  };

  const statusAction = {
    onSuccess: (data) => {
      if (data.data.status) {
        setSucessMsg(true);
        setTimeout(() => {
          setSucessMsg(false);
        }, 3000);
      }
    },
  };
  const { mutate, isLoading: loadingResend } = PostQuery(
    "Admin/resend-password-to-user",
    "post",
    statusAction
  );

  const {
    data: juryList,
    isLoading,
    isFetching,
  } = FetchData("jurylist", "Jury/get-all-jury-detail", actionsjuryList);

  const handleResetPassword = (e, email) => {
    e.preventDefault();
    const Approvestatus = {
      email: email,
    };
    mutate(Approvestatus);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      // {
      //   Header: "Id",
      //   accessor: "id",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <p>{cellProps.value}</p>;
      //   },
      // },
      {
        Header: "Jury Name",
        accessor: "jury_Name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Jury Email",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Region",
        accessor: "region",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Entries Scored",
        accessor: "entries_score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              <span>{cellProps.row.original.entries_score}</span>
            </div>
          );
        },
      },
      {
        Header: "Assign Entries",
        accessor: "entries_assign",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div>
              <span>{cellProps.row.original.entries_assign}</span>
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "view",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded "
                onClick={(e) =>
                  handleResetPassword(e, cellProps.row.original.email)
                }
              >
                Resend Password
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  document.title = "Jury | WOW Awards";

  return (
    <React.Fragment>
      {sucessMsg && (
        <UncontrolledAlert
          color="success"
          className={`alert-dismissible fade ${sucessMsg ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-check-all me-2"></i>
          Password Resend To The Jury
        </UncontrolledAlert>
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={"Jury list"}
                    buttonName={"Add Jury Member"}
                    onButtonClick={() => history.push("/add-jury")}
                    pageLoading={isLoading}
                    hasButton={true}
                  />

<Row><div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Col sm="8">
                          {regions?.length > 1 ? (
                            <Nav
                              pills
                              className="icon-tab nav-justified"
                              role="tablist"
                              style={{ width: "70%" }}
                            >
                              {regions?.map((item, i) => {
                                return (
                                  <NavItem key={i}>
                                    <NavLink
                                      className={classNames({
                                        active: activeTab === i + 1,
                                      })}
                                      onClick={() => {
                                        setactiveTab(i + 1);
                                        setactiveTabName(item);
                                      }}
                                    >
                                      {`${item} (${
                                        juryList?.filter(
                                          (elem) => elem?.region === item
                                        )?.length
                                      })`}
                                    </NavLink>
                                  </NavItem>
                                );
                              })}
                            </Nav>
                          ) : null}
                        </Col>
                      </div>
                    </Row>

                  {juryList ? (
                    <div>
                      <TableContainer
                        columns={columns}
                        data={juryList}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {(isFetching || loadingResend ) && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
}

export default JuryList;
