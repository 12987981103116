import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const EcommerceOrdersModal = (props) => {
  const { isOpen, toggle, data } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Category Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Is Active: <span className="text-primary">{data?.is_active}</span>
          </p>
          <p className="mb-2">
            Title: <span className="text-primary">{data?.category_name}</span>
          </p>
          {/* <p className="mb-4">
            Description:{" "}
            <span className="text-primary">{data?.category_detail}</span>
          </p>
          <p className="mb-2">
            Currency: <span className="text-primary">{data?.price_unit}</span>
          </p>
          <p className="mb-2">
            Price: <span className="text-primary">{data?.price}</span>
          </p> */}
          <p className="mb-2">
            Region:{" "}
            <span className="text-primary">{data?.region.region_name}</span>
          </p>
          <p className="mb-2">
            Selected Groups:{" "}
            <span className="text-primary">{data?.group?.name}</span>
          </p>
          <p className="mb-2">
            Selected Sub-groups:{" "}
            <span className="text-primary">{data?.subgroup?.name}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default EcommerceOrdersModal;
