import React from "react";
import Select from "react-select";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// import SelectInput from "../../components/Form/SelectInput";

const ReasonModal = (props) => {
  const {
    isOpen,
    toggle,
    handleChnageScore,
    scoreOption,
    data,
    handleScoreUpdate,
    isLoadingUpdateScore,
    setScore,
    score,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Update Score</ModalHeader>
        <ModalBody>
          <Select
            className="form-control"
            id="projectdesc"
            name="score"
            type="select"
            defaultValue={data}
            // value={score}
            onChange={(e) => handleChnageScore(e)}
            options={scoreOption}
          />
          {/* <SelectInput
            className={"form-control"}
            id={"projectdesc"}
            name={"reson"}
            defaultValue={data}
            type={"number"}
            onChange={handleChnageScore}
            options={scoreOption}
          /> */}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="primary"
            value="false"
            onClick={handleScoreUpdate}
          >
            {" "}
            {isLoadingUpdateScore && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            )}
            Update Score
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};
// ReasonModal.propTypes = {
//   onCloseClick: PropTypes.func,
//   onSubmitClick: PropTypes.func,
//   show: PropTypes.any,

// };
export default ReasonModal;
