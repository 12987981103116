import profileImg from "@assets/images/profile-img.png";
import { getFromStorage } from "@utils/storage";
import React from "react";
import { Card, Col, Row } from "reactstrap";

const WelcomeAudit = () => {
  const getUserName = getFromStorage("userName");

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className=" bg-soft" style={{ backgroundColor: "#111" }}>
          <Row>
            <Col xs="10">
              <div className="text-primary p-3">
                <h3 style={{ fontWeight: "bold" }} className="text-primary">
                  Welcome!
                </h3>
                <h5 style={{ fontWeight: "bold" }} className="text-primary">
                  {getUserName}
                </h5>
              </div>
            </Col>
            <Col xs="2" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeAudit;
