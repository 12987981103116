import profile from "@assets/images/profile-img.png";
import wowLogo from "@assets/images/wow-awards-logo.png";
import { PostQuery } from "@hooks/api/useApi";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { InputField } from "../../components/Form/Input";

const ForgetPasswordPage = (props) => {
  document.title = "Forgot password | WOW Awards";
  const [errorMsg, setErrorMSg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const forgotPasswordActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setErrorMSg(" ");
        setSuccessMsg(data.data.responseData);
        validation.resetForm();
      } else {
        setErrorMSg(data?.data?.errors[0]);
        setTimeout(() => {
          setSuccessMsg(" ");
        }, 3000);
      }
    },
    onError: (error) => {
      setSuccessMsg(" ");
      setErrorMSg("Somthing went wrong!");
    },
  };

  const { mutate, isLoading } = PostQuery(
    "Authentication/forgot-password",
    "post",
    forgotPasswordActions
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email is Invalid")
        .required("Please Enter Your Email"),
    }),

    onSubmit: (values, { resetForm }) => {
      setErrorMSg("  ");
      setSuccessMsg("  ");
      const getEmail = {
        user_name: values.email,
      };

      mutate(getEmail);
    },
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Forgot Password</h5>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={wowLogo} alt="" height="25" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <InputField
                          label={"Email"}
                          name={"email"}
                          type={"email"}
                          placeholder={"Enter email"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          )}
                          Continue
                        </button>
                      </div>
                    </Form>
                  </div>
                  {errorMsg ? (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {errorMsg}
                    </div>
                  ) : null}
                  {successMsg ? (
                    <div
                      style={{
                        color: "green",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {successMsg}
                    </div>
                  ) : null}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordPage;
