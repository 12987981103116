import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ImageModal = ({ isOpen, toggle, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleImageModal"
      tabIndex="-1"
      toggle={toggle}
      size="lg"
    >
      <>
        {" "}
        <div className="modal-content">
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <div
              style={{
                backgroundImage: `url(${data})`,
                backgroundPosition: "top center",
                backgroundSize: "contain",
                height: "500px",
                backgroundRepeat: "no-repeat",
                width: "750px",

                //   marginRight: '16px',
                // borderRadius: "10px",
                // border: "2px solid #CC962B",
              }}
            ></div>
          </ModalBody>
          {/* <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter> */}
        </div>
        {/* <div className="modal-content">
        <ModalBody>
          <div
            style={{
              backgroundImage: `url(${data})`,
              backgroundPosition: 'top center',
              backgroundSize: 'cover',
              height: '450px',
              backgroundRepeat: 'no-repeat',
              width: '450px',
              //   marginRight: '16px',
              borderRadius: '10px',
              border: '2px solid #CC962B',
            }}
          ></div>
        </ModalBody>
      </div> */}
      </>
    </Modal>
    // <div
    //   id="myModal"
    //   class="modal"
    //   style={{
    //     display: "none",
    //     position: "fixed",
    //     zIindex: "1",
    //     paddingTop: "100px",
    //     left: "0",
    //     top: "0",
    //     width: "100%",
    //     height: "100%",
    //     overflow: "auto",
    //     backgroundColor: " rgb(0,0,0)",
    //     backgroundColor: " rgba(0,0,0,0.9)",
    //   }}
    // >
    //   <span
    //     class="close"
    //     style={{
    //       position: "absolute",
    //       top: "15px",
    //       right: "35px",
    //       color: "#f1f1f1",
    //       fontSize: "40px",
    //       fontWeight: "bold",
    //       transition: " 0.3s",
    //     }}
    //   >
    //     &times;
    //   </span>
    //   <img
    //     class="modal-content"
    //     src="data"
    //     id="img01"
    //     style={{
    //       margin: "auto",
    //       display: "block",
    //       width: "80%",
    //       maxWidth: " 700px",
    //     }}
    //   />
    //   <div
    //     id="caption"
    //     style={{
    //       margin: "auto",
    //       display: "block",
    //       width: "80%",
    //       maxWidth: "700px",
    //       textAlign: "center",
    //       color: "#ccc",
    //       padding: "10px 0",
    //       height: "150px",
    //     }}
    //   ></div>
    // </div>
  );
};

export default ImageModal;
