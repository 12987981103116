import { FetchData, PostQuery } from "@hooks/api/useApi";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, UncontrolledTooltip } from "reactstrap";
import * as Yup from "yup";
import Category from "../../../../components/Master/Category/Category";

const optionCurrency = [
  {
    options: [
      { label: "INR", value: "Rs." },
      { label: "USD", value: "USD" },
      { label: "EUR", value: "EUR" },
    ],
  },
];

function Index() {
  const [categoryDataById, setCategoryDataById] = useState(null);

  //meta title
  document.title = "Orders | Skote - React Admin & Dashboard Template";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectFilterGroup, setSelectFilterGroup] = useState(null);
  const [selectFilterSubGroup, setSelectFilterSubGroup] = useState(null);

  const [selectedRegion, setselectedRegion] = useState(null);
  const [selectedSubgroup, setselectedSubgroup] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [optionGroup, setOptionGroup] = useState(null);
  const [optionSubgroup, setoptionSubgroup] = useState(null);
  const [optionRegion, setOptionRegion] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [groupValue, setGroupValue] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [subGroupValue, setSubGroupValue] = useState(null);
  const [regionValue, setRegionValue] = useState(null);
  const [currencyValue, setCurrencyValue] = useState(null);
  const [editCategoryData, setEditCategoryData] = useState();
  const [loader, setLoader] = useState(false);
  const [regions, setRegions] = useState([]);
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabName, setactiveTabName] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const [showErrorMsg, setShowErrorMsg] = useState("");

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      setRegions([...new Set(data?.map((item) => item.region.region_name))]);
      setactiveTabName(
        [...new Set(data?.map((item) => item.region.region_name))][0]
      );
    },
  };
  const SelectGroupActions = {
    // enabled: false,
    select: (data) => {
      const list = data.data.responseData.filter(
        (eachVal) => eachVal.is_active === "Published"
      );
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        obj["label"] = item.group_name;
        obj["value"] = item.group_id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setOptionGroup(getList);
      });
    },
  };

  const selectSubGroupAction = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData.filter(
        (eachVal) => eachVal.is_active === "Published"
      );
      return list;
    },
    onSuccess: (data) => {
      setLoader(false);
      let optionList = [];
      data.map((item) => {
        let obj = {};
        obj["label"] = item.sub_group_name;
        obj["value"] = item.sub_group_id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setoptionSubgroup(getList);
      });
    },
  };
  const selectFilterSubGroupAction = {
    select: (data) => {
      const list = data.data.responseData;
    },
    onSuccess: (data) => {
      setLoader(false);
      let optionList = [];
      data?.map((item) => {
        let obj = {};
        obj["label"] = item.sub_group_name;
        obj["value"] = item.sub_group_id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setoptionSubgroup(getList);
      });
    },
  };

  const selectRegionAction = {
    enabled: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        obj["label"] = item.name;
        obj["value"] = item.id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setOptionRegion(getList);
      });
    },
  };
  const updateActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
        updateToggle();
      }
    },
  };

  const addActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        refetch();
        formik.resetForm();
        toggle();
      }
    },
  };

  const deleteActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setDeleteModal(false);
        refetch();
      } else {
        setDeleteModal(false);
        setShowErrorAlert(false);
        setTimeout(() => {
          setShowErrorAlert(true);
        }, 5000);
        setShowErrorMsg(data.data.errors[0]);
      }
    },
  };
  const {
    data: categoryList,
    refetch,
    isLoading: isLoadingcategory,
    isFetching: isFetchingCategory,
    isFetched,
  } = FetchData("category", "/Admin/get-all-category", actions);

  const { mutate: updateCategory, isLoading: isLoadingUpdateCat } = PostQuery(
    "/Admin/upsert-category",
    "post",
    updateActions
  );
  const { data: selectOptionGroup, refetch: fetchGroups } = FetchData(
    "categoryGroup",
    "/Admin/get-all-group",
    SelectGroupActions
  );

  const { data: selectOptionSubGroup, refetch: fetchSubGroups } = FetchData(
    "categorySubGroup",
    `/Admin/get-subgroup-of-group-by-id?groupid=${
      selectedGroup?.value || selectedGroup?.id
    }`,
    selectSubGroupAction
  );
  const { data: selectOptionFilterSubGroup } = FetchData(
    "categorySubGroup",
    `Admin/get-all-subgroup`,
    selectFilterSubGroupAction
  );

  const { data: selectRegion, refetch: fetchRegions } = FetchData(
    "categoryRegion",
    `/Admin/get-all-region`,
    selectRegionAction
  );

  const { mutate: addCategory, isLoading: isLoadingAddCat } = PostQuery(
    "/Admin/upsert-category",
    "post",
    addActions
  );
  const { mutate: deleteGroup } = PostQuery(
    "/Admin/delete-category",
    "post",
    deleteActions
  );

  useEffect(() => {
    if (selectedGroup) {
      fetchSubGroups();
      setLoader(true);
    }
  }, [selectedGroup]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      Category_Title: "",
      Category_Des: "",
      Category_Curr: "",
      Category_Price: "",
      Category_Region: null,
      Category_Group: null,
      Category_Subgroup: null,
      Category_Status: true,
      // Category_Code: '',
    },
    validationSchema: Yup.object({
      Category_Title: Yup.string().required("Please Enter Category Name "),
      Category_Des: Yup.string().required("Please Enter Category Desciption"),
      // Category_Curr: Yup.string().required("Please Select Currency"),
      Category_Price: Yup.string().required("Total Amount"),
      // Category_Region: Yup.string().required("Please Select Region"),
      // Category_Group: Yup.string().required("Please Select group"),
      // Category_Subgroup: Yup.string().required("Please Select Subgroup"),
    }),
    onSubmit: (values) => {
      let data = {
        Category_Id: values.Category_ID,
        SubGroup_Id: values.Category_Subgroup.id || values.Category_Subgroup,
        // ? values.Category_Subgroup
        // : values.Category_Subgroup,
        Group_Id: values.Category_Group.id || values.Category_Group,
        // ? values.Category_Group
        // : values.Category_Group,
        Category_Name: values.Category_Title,
        Category_Detail: values.Category_Des,
        Region_Id: values.Category_Region.value
          ? values.Category_Region.value
          : values.Category_Region.region_id,
        Price: values.Category_Price.toString(),
        PriceUnit: values.Category_Curr,
        IsActive: JSON.parse(values.Category_Status),
      };
      updateCategory(data);
    },
  });

  const getLabel = optionCurrency.map((item) =>
    item?.options.map((ele) => ele.label)
  );

  //Add Category //
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Category_Status: true,
      Category_Title: "",
      // Category_Des: "No Description",
      // Category_Curr: optionCurrency[0]?.options[0],
      // Category_Price: 8000,
      Category_Region: "",
      Category_Group: "",
      Category_Subgroup: "",
    },

    validationSchema: Yup.object({
      Category_Title: Yup.string().required("Please Enter Category Name "),
      // Category_Des: Yup.string().required("Please Enter Category Desciption"),
      // Category_Curr: Yup.array().min(1, "Please Select Currency").nullable(),
      // Category_Price: Yup.string().required("please Add Price"),
      Category_Region: Yup.string().required("Please Select Region"),
      Category_Group: Yup.string().required("Please Select Group"),
      Category_Subgroup: Yup.string().required("Please Select Subgroup"),
    }),

    onSubmit: (values) => {
      const data = {
        SubGroup_Id: values.Category_Subgroup,
        Group_Id: values.Category_Group,
        Category_Name: values.Category_Title,
        Category_Detail: "No Description",
        Region_Id: JSON.parse(selectedRegion.value),
        Price: "8000",
        PriceUnit: "INR",
        IsActive: JSON.parse(values.Category_Status),
      };
      addCategory(data);
    },
  });

  function handleSelectCurrency(selectedCurrency) {
    setSelectedCurrency(selectedCurrency);
    setCurrencyValue(selectedCurrency);
    formik.setFieldValue("Category_Curr", selectedCurrency);
    validation.setFieldValue("Category_Curr", selectedCurrency.value);
  }

  function handleSelectRegion(selectedRegion) {
    setselectedRegion(selectedRegion);
    setRegionValue(selectRegion.label);
    formik.setFieldValue("Category_Region", selectedRegion.value);
    validation.setFieldValue("Category_Region", selectedRegion);
  }
  function handleSelectGroup(groupDetails) {
    setGroupValue(groupDetails);
    setSelectedGroup(groupDetails);
    formik.setFieldValue("Category_Group", groupDetails.value);
    validation.setFieldValue("Category_Group", groupDetails.value);
  }

  function handleSelectSubgroup(selectedSubgroup) {
    setselectedSubgroup(selectedSubgroup);
    setSubGroupValue(selectedSubgroup);
    formik.setFieldValue("Category_Subgroup", selectedSubgroup?.value);
    validation.setFieldValue("Category_Subgroup", selectedSubgroup?.value);
  }
  function handleGroup(item) {
    setSelectFilterGroup(item);
  }
  function handleSubgroup(item) {
    setSelectFilterSubGroup(item);
  }

  useEffect(() => {
    const getGroupLabel =
      optionGroup &&
      optionGroup[0].options.find(
        (item) => item.value === editCategoryData?.group?.id
      );
    setGroupValue(getGroupLabel);
  }, [optionGroup, editCategoryData]);

  useEffect(() => {
    const getSubgroupLabel =
      optionSubgroup &&
      optionSubgroup[0].options.find(
        (item) => item.value === editCategoryData?.subgroup?.id
      );

    setSubGroupValue(getSubgroupLabel);
    setselectedSubgroup(getSubgroupLabel);
  }, [editCategoryData, optionSubgroup]);

  useEffect(() => {
    const getRegionLabel =
      optionRegion &&
      optionRegion[0].options.find(
        (item) => item.label === editCategoryData?.region?.region_name
      );

    setRegionValue(getRegionLabel);
  }, [optionRegion, editCategoryData]);

  useEffect(() => {
    const getCurrencyLabel =
      optionCurrency &&
      optionCurrency[0].options.find(
        (item) => item.value === editCategoryData?.price_unit
      );

    setCurrencyValue(getCurrencyLabel);
  }, [optionCurrency, editCategoryData]);

  const toggleViewModal = () => {
    setModal1(!modal1);
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  const updateToggle = () => {
    if (updateModal) {
      setUpdateModal(false);
    } else {
      setUpdateModal(true);
    }
  };

  const updateCategoryClick = (arg) => {
    setEditCategoryData(arg);
    setSelectedGroup(arg?.group);

    validation.setValues({
      // Category_Title: arg?.category_name.slice(
      //   arg?.category_name.indexOf(" "),
      //   arg?.category_name?.length
      // ),
      Category_Title: arg?.category_name,
      Category_Des: arg?.category_detail,
      Category_Curr: arg?.price_unit,
      Category_Price: Number(arg?.price),
      Category_Region: arg?.region,
      Category_Group: arg?.group?.id,
      Category_Subgroup: arg?.subgroup?.id,
      Category_ID: arg?.category_id,
      Category_Status: arg?.is_active === "Draft" ? false : true,
      // Category_Code: arg?.category_code,
    });
    fetchGroups();
    setSelectedGroup(arg.group);
    fetchRegions();
    updateToggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteCategorry = () => {
    const data = {
      Category_Id: order.category_id,
    };
    deleteGroup(data);
  };
  const handleOrderClicks = () => {
    setOrderList("");
    fetchGroups();
    fetchRegions();
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Date",
        accessor: "create_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              {moment(cellProps.row.original?.creted_date).format("DD/MM/YYYY")}
            </>
          );
        },
      },

      {
        Header: "Category Name",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Group Name",
        accessor: "group.name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Subgroup Name",
        accessor: "subgroup.name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Status",
        accessor: "is_active",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.value === "Published" ? "success" : "danger")
              }
              pill
            >
              {cellProps.value === "Published" ? "Published" : "Draft"}
            </Badge>
          );
        },
      },

      {
        Header: "View Details",
        accessor: "view",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                toggleViewModal();
                setCategoryDataById(cellProps.cell.row.original);
              }}
            >
              View Details
            </Button>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  updateCategoryClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  function handleData() {
    return categoryList?.filter(
      (item) =>
        item?.group?.name === selectFilterGroup?.label ||
        item?.subgroup?.name === selectFilterSubGroup?.label
    );
  }
  const handleClearAll = () => {
    setSelectFilterGroup(null);
    setSelectFilterSubGroup(null);
  };

  return (
    <React.Fragment>
      <Category
        showErrorAlert={showErrorAlert}
        showErrorMsg={showErrorMsg}
        handleGroup={handleGroup}
        handleSubgroup={handleSubgroup}
        selectFilterGroup={selectFilterGroup}
        selectFilterSubGroup={selectFilterSubGroup}
        modal1={modal1}
        categoryDataById={categoryDataById}
        toggleViewModal={toggleViewModal}
        deleteModal={deleteModal}
        onDeleteClick={() => handleDeleteCategorry()}
        onCloseClick={() => setDeleteModal(false)}
        categoryList={categoryList}
        isLoadingcategory={isLoadingcategory}
        isFetchingCategory={isFetchingCategory}
        onClickOrderClicks={() => handleOrderClicks()}
        regions={regions}
        activeTab={activeTab}
        setactiveTab={setactiveTab}
        setactiveTabName={setactiveTabName}
        columns={columns}
        activeTabName={activeTabName}
        isFetched={isFetched}
        modal={modal}
        handleData={handleData}
        handleClearAll={handleClearAll}
        toggle={toggle}
        formik={formik}
        options={optionRegion}
        optionGroup={optionGroup}
        optionSubgroup={optionSubgroup}
        optionCurrency={optionCurrency}
        isLoadingAddCat={isLoadingAddCat}
        loader={loader}
        onChange={(e) => {
          handleSelectCurrency(e);
        }}
        onChangeSubgroup={(e) => {
          handleSelectSubgroup(e);
        }}
        onChangeGroup={(e) => {
          handleSelectGroup(e);
        }}
        onChangeRegion={(e) => {
          handleSelectRegion(e);
        }}
        updateModal={updateModal}
        updateToggle={updateToggle}
        validation={validation}
        // onChangeRegions={(e) => {
        //   handleSelectRegion(e);
        // }}
        regionValue={regionValue}
        optionRegion={optionRegion}
        // onChangeGroups={(e) => {
        //   handleSelectGroup(e);
        // }}
        groupValue={groupValue}
        // onChangeSubgroups={(e) => {
        //   handleSelectSubgroup(e);
        // }}
        subGroupValue={subGroupValue}
        currencyValue={currencyValue}
        // onChangeCurrancy={(e) => {
        //   handleSelectCurrency(e);
        // }}
        isLoadingUpdateCat={isLoadingUpdateCat}
      />
    </React.Fragment>
  );
}

export default Index;
