import { PostQuery } from "@hooks/api/useApi";
import { getFromStorage } from "@utils/storage";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
// import fetch_type from "../../../utils/emitter";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const history = useHistory();
  const getUserName = getFromStorage("userName");

  const logoutActions = {
    onSuccess: (data) => {
      if (data?.data?.status) {
        localStorage.clear();
        history.push("/login");
        document.removeEventListener("fetch_type", async (e) => {
          let type = "";
        });
        document.removeEventListener("fetch_region", async (e) => {
          let type = "";
        });
      } else {
        localStorage.clear();
        history.push("/login");
        document.removeEventListener("fetch_type", async (e) => {
          let type = "";
        });
        document.removeEventListener("fetch_region", async (e) => {
          let type = "";
        });
      }
    },
  };

  const { mutate } = PostQuery("Authentication/log-out", "post", logoutActions);

  const handleLogout = () => {
    // document.removeEventListener("fetch_type", "");
    // fetch_type("");
    let getLoginToken = getFromStorage("token");
    const getDetails = {
      jwttoken: getLoginToken,
    };
    mutate(getDetails);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="bx bx-user font-size-16 align-middle me-1" />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {getUserName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => history.push("/profile")}>
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {"Profile"}
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem tag="a" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            {"Logout"}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
