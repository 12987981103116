import '@assets/scss/custom/pages/_profile.scss';
import { PostQuery } from '@hooks/api/useApi';
import { getFromStorage } from '@utils/storage';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
} from 'reactstrap';
import * as Yup from 'yup';

function Index() {
  document.title = 'Profile | WOW Awards';
  const [errorMsg, setErrorMSg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const getUserName = getFromStorage('userName');
  const userType = getFromStorage('type');
  const getUserEmail = getFromStorage('userEmail');

  // change password from
  const changePasswordActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setSuccessMsg(data.data.responseData);
        setErrorMSg('');
        Formik.resetForm();
      } else {
        setErrorMSg(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setSuccessMsg('');
      setErrorMSg('Something went wrong!');
    },
  };

  const { mutate, isLoading } = PostQuery(
    'Authentication/change-password',
    'post',
    changePasswordActions
  );

  const Formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldpassword: '',
      newpassword: '',
      confimpassword: '',
    },
    validationSchema: Yup.object({
      oldpassword: Yup.string().required('Please Enter Your Email'),
      newpassword: Yup.string()
        .matches(
          passwordRegex,
          'password must be at least 8 characters with at least a symbol, upper and lower case letters and a number'
        )
        .required('Please Enter Your Password'),
      confimpassword: Yup.string()
        .required('Please Enter Your Confirm Password')
        .oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      const getDetails = {
        user_name: getUserEmail,
        oldpassword: values.oldpassword,
        new_password: values.newpassword,
      };
      mutate(getDetails);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex" style={{ alignItems: 'center' }}>
                <i className="bx bx-user-circle text-primary display-2" />
                <div className="text-muted" style={{ marginLeft: '10px' }}>
                  <h5>{userType}</h5>
                  <p className="mb-1">{getUserName}</p>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <CardTitle className="mb-4">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i className="bx bx-shield-quarter text-primary display-6" />
                      <h3 style={{ marginLeft: '10px' }}>Change Password</h3>
                    </div>
                  </CardTitle>
                </div>
              </div>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  Formik.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <Label className="form-label">Enter Old Password</Label>
                  <Input
                    name="oldpassword"
                    className="form-control"
                    placeholder="Enter Old Password"
                    type="password"
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    value={Formik.values.oldpassword || ''}
                    invalid={
                      Formik.touched.oldpassword && Formik.errors.oldpassword
                        ? true
                        : false
                    }
                  />
                  {Formik.touched.oldpassword && Formik.errors.oldpassword ? (
                    <FormFeedback type="invalid">
                      {Formik.errors.oldpassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Enter New Password</Label>
                  <Input
                    name="newpassword"
                    className="form-control"
                    placeholder="Enetr New Password"
                    type="password"
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    value={Formik.values.newpassword || ''}
                    invalid={
                      Formik.touched.newpassword && Formik.errors.newpassword
                        ? true
                        : false
                    }
                  />
                  {Formik.touched.newpassword && Formik.errors.newpassword ? (
                    <FormFeedback type="invalid">
                      {Formik.errors.newpassword}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Confirm new Password </Label>
                  <Input
                    name="confimpassword"
                    className="form-control"
                    placeholder="Enter Confirm Password"
                    type="password"
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    value={Formik.values.confimpassword || ''}
                    invalid={
                      Formik.touched.confimpassword &&
                      Formik.errors.confimpassword
                        ? true
                        : false
                    }
                  />
                  {Formik.touched.confimpassword &&
                  Formik.errors.confimpassword ? (
                    <FormFeedback type="invalid">
                      {Formik.errors.confimpassword}
                    </FormFeedback>
                  ) : null}
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    color="primary"
                    className="btn m-2"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    save
                  </Button>
                </div>
              </Form>

              {errorMsg ? (
                <div
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {errorMsg}
                </div>
              ) : null}
              {successMsg ? (
                <div
                  style={{
                    color: 'green',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {successMsg}
                </div>
              ) : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Index;
