import Entrants from "@pages/Admin/Entrants";
import EntrantDetails from "@pages/Admin/Entrants/EntrantDetails";
import Entries from "@pages/Admin/Entries/Entries";
import EntryDetails from "@pages/Admin/Entries/EntryDetails";
import AddEvent from "@pages/Admin/Events/AddEvent";
import ManageEvent from "@pages/Admin/Events/Manage/index";
import EventsList from "@pages/Admin/Events/index";
import AddJury from "@pages/Admin/Jury/AddJury";
import JuryList from "@pages/Admin/Jury/JuryList";
import Category from "@pages/Admin/Master/Category/index";
import Group from "@pages/Admin/Master/Group/index";
import SubGroup from "@pages/Admin/Master/Sub-group/index";
import Register from "@pages/Admin/Register";
import ForgetPasswordPage from "@pages/Authentication/ForgetPassword";
import Login from "@pages/Authentication/Login";
import ResetPassword from "@pages/Authentication/ResetPassword";
import UserRegister from "@pages/Authentication/UserRegister";
import AddEntry from "@pages/User/AddEntry";
import clientApprove from "@pages/User/Client";
import Details from "@pages/User/Details";
// import DuplicateEntry from "@pages/User/DuplicateEntry";
import EntrantEditForm from "../pages/Admin/EntrantEditForm";
import Result from "../pages/Admin/Result/index";
import ViewJuryList from "../pages/Audit/Jury/ViewJuryList";
import Assign from "../pages/Common/Assign";
import Dashboard from "../pages/Common/Dashboard";
import Profile from "../pages/Common/Profile";
import EntryListdetails from "../pages/Jury/EntryListDetails";
import ViewEntryDetails from "../pages/Jury/ViewEntryDetails";
import ViewEntryTwoDetails from "../pages/Jury/ViewEntryTwoDetails";

import Page404 from "../pages/Page404";

const authProtectedRoutes = [
  { path: "/profile", component: Profile },
  { path: "/", component: Dashboard },
  { path: "/assign-jury", component: Assign },
  { path: "/awards-list", component: EventsList },
  { path: "/add-awards", component: AddEvent },
  { path: "/manage-event-category", component: ManageEvent },

  { path: "/group", component: Group },
  { path: "/sub-group", component: SubGroup },
  { path: "/category", component: Category },

  { path: "/jury-list", component: JuryList },
  { path: "/add-jury", component: AddJury },

  { path: "/entry-details", component: EntryDetails },

  { path: "/entries", component: Entries },
  { path: "/add-entry", component: AddEntry },
  // { path: "/duplicate-entry", component: DuplicateEntry },
  { path: "/entrants", component: Entrants },
  { path: "/entrants-details", component: EntrantDetails },
  { path: "/register", component: Register },
  { path: "/entrants-edit-form", component: EntrantEditForm },

  { path: "/user-entry-details", component: Details },
  { path: "/EntryListdetails", component: EntryListdetails },
  { path: "/ViewEntryDetails", component: ViewEntryDetails },
  { path: "/ViewEntryTwoDetails", component: ViewEntryTwoDetails },

  { path: "/view-allocations", component: Dashboard },
  { path: "/jurylist", component: ViewJuryList },
  // { path: "/add-new-jury", component: AddNewJury },
  { path: "/result", component: Result },

  { path: "*", component: Page404 },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  // { path: "/reset-password/:id/:token", component: ResetPassword },
  { path: "/reset-password/:token", component: ResetPassword },
  { path: "/user-register", component: UserRegister },
  { path: "/client-approval/:id", component: clientApprove },
  // { path: '*', component: Page404 },
];

export { authProtectedRoutes, publicRoutes };
