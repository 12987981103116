import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import wowLogo from "../../assets/images/wow-awards-logo.png";
import { getFromStorage } from "../../utils/storage";

// Redux Store

const Header = (props) => {

  const [userRegion, setUserRegion] = useState(null);

  useEffect(() => {
    document.addEventListener("fetch_region", async (e) => {
      let type = await localStorage.getItem("region");
      setUserRegion(JSON.parse(type));
    });
    

    (async () => {
      let type = await localStorage.getItem("region");
      setUserRegion(JSON.parse(type));
    })();
  }, []);
  // const [search, setsearch] = useState(false);
  // const [megaMenu, setmegaMenu] = useState(false);
  // const [socialDrp, setsocialDrp] = useState(false);

  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen();
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen();
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       );
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen();
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen();
  //     }
  //   }
  // }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  console.log("userRegion", userRegion);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={wowLogo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={wowLogo} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Search" + "..."}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <Dropdown
              className="dropdown-mega d-none d-lg-block ms-2"
              isOpen={megaMenu}
              toggle={() => {
                setmegaMenu(!megaMenu);
              }}
            >
              <DropdownToggle
                className="btn header-item "
                caret
                tag="button"
              >
                {" "}
                {"Mega Menu"} <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-megamenu">
                <Row>
                  <Col sm={8}>
                    <Row>
                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {("UI Components")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {("Applications")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{("Ecommerce")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Calendar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Email")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Projects")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Tasks")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Contacts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col md={4}>
                        <h5 className="font-size-14 mt-0">
                          {("Extra Pages")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{("Light Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Compact Sidebar")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Horizontal layout")}</Link>
                          </li>
                          <li>
                            <Link to="#"> {("Maintenance")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Coming Soon")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Timeline")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("FAQs")}</Link>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={4}>
                    <Row>
                      <Col sm={6}>
                        <h5 className="font-size-14 mt-0">
                          {("UI Components")}
                        </h5>
                        <ul className="list-unstyled megamenu-list">
                          <li>
                            <Link to="#">{("Lightbox")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Range Slider")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Sweet Alert")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Rating")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Forms")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Tables")}</Link>
                          </li>
                          <li>
                            <Link to="#">{("Charts")}</Link>
                          </li>
                        </ul>
                      </Col>

                      <Col sm={5}>
                        <div>
                          <img
                            src={megamenuImg}
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </DropdownMenu>
            </Dropdown> */}
          </div>
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            {/* <LanguageDropdown /> */}

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <i className="bx bx-customize" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  (getFromStorage("region") == 2 || userRegion == 2) ? 
                  window.open("https://www.wowawards.com/middle-east/support/", "_blank")
                : 
                  window.open("https://asia.wowawards.com/support/", "_blank")
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-food-menu font-size-16 align-middle me-2" />
                FAQ
              </button>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  (getFromStorage("region") == 2 || userRegion == 2) ? 
                  window.open("https://www.wowawards.com/middle-east/categories/", "_blank")
                : 
                  window.open(
                    "https://asia.wowawards.com/categories/",
                    "_blank"
                  );
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-globe font-size-16 align-middle me-2" />
                Explore categories
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />

            {/* <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
