import React, { useState } from "react";
// Carousel images
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";

const SlideWithFade = (props) => {
  const Img_url = process.env.REACT_APP_IMG_URL

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const itemLength = props?.images?.length - 1;

  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // Next button for Carousel
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const carouselItemData = props?.images?.map((item, i) => {
    return (

      <CarouselItem
        key={i}
      // onExited={() => setAnimating(false)}
      // onExiting={() => setAnimating(true)}
      >
        {/* <div
          style={{
            backgroundImage: `url(https://wowawards.digitalsalt.in${item?.photo})`,
            backgroundPosition: "top center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            minHeight: "400px",
            maxHeight: "100%",
            width: "100%",
            margin: " 0 auto",
          }}
        ></div> */}
        <div>
          <img
            src={`${Img_url}${item?.photo}`}
            // src={`https://api.wowawards.com${item?.photo}`}
            className="d-block img-fluid"
            style={{
              objectFit: "contain",
              width: "100%",
              // maxHeight: '328px',
              // minHeight: '328px',
            }}
          />
        </div>
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <Carousel
        previous={previousButton}
        next={nextButton}
        activeIndex={activeIndex}
      >
        <CarouselIndicators
          items={props?.images}
          activeIndex={activeIndex}
          onClickHandler={(newIndex) => {
            if (animating) return;
            setActiveIndex(newIndex);
          }}
        />
        {carouselItemData}
        <CarouselControl
          directionText="Prev"
          direction="prev"
          onClickHandler={previousButton}
        />
        <CarouselControl
          directionText="Next"
          direction="next"
          onClickHandler={nextButton}
        />
      </Carousel>
    </React.Fragment>
  );
};

export default SlideWithFade;
