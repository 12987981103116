import { useMutation, useQuery } from "react-query";
import axios from "../../utils/axios-utils";

const fetchEntries = (url) => {
  return axios(url);
};

//get APi call
export const FetchData = (name, url, action) => {
  return useQuery(name, () => fetchEntries(url), {...action, refetchOnWindowFocus:false});
};

// post Api call
export const PostQuery = (slug, method, action) => {
  return useMutation((hero) => {
    return axios({
      method: method,
      url: slug,
      data: hero,
    });
  }, action);
};
