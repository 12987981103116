import React from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';

const SelectInput = (props) => {
  return (
    <>
      {' '}
      <Label className="form-label" htmlFor={props.name}>
        {props.label}
      </Label>
      <Select
      isMulti={props?.isMulti}
        name={props.name}
        onChange={props.onChange}
        options={props.options}
        onBlur={props.onBlur}
        invalid={props.invalid}
        type={props.type}
        defaultValue={props.defaultValue}
        value={props.value}
      />
      {props.formik &&
        props.formik.errors[props.name] &&
        props.formik.touched[props.name] && (
          <span style={{ color: '#f46a6a', fontSize: '10px', display: 'flex' }}>
            {props.formik.errors[props.name]}
          </span>
        )}
    </>
  );
};

export default SelectInput;
