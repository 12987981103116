import TableContainer from "@components/Common/TableContainer";
import React from "react";
import { Card, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

const JurydetailsModel = (props) => {
  return (
    <>
      <Modal isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {"Jury Details"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xl="12">
              <Card className="overflow-hidden">
                <div className={"font-size-12 badge-soft-" + "primary"}>
                  <Row>
                    <Col xs="12">
                      <div
                        className="text-primary p-3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h2
                            style={{
                              color: "#f1b44c",
                            }}
                          >
                            {props.details?.entry_Detail?.name
                              ? props.details?.entry_Detail?.name
                              : props.details?.entry_name}
                          </h2>
                        </div>
                        {props.showScore ? null : (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                              }}
                            >
                              {props.details?.average_score_round1 !== null && (
                                <>
                                  {" "}
                                  <span
                                    style={{
                                      color: "#f1b44c",
                                      fontSize: "50px",
                                      lineHeight: "22px",
                                      fontWeight: "bold",
                                      marginBottom: "10px",
                                      // textAlign: "center",
                                    }}
                                  >
                                    {Math.round(
                                      props?.avgScore
                                        ? props.details?.average_score_round2
                                        : props.details?.average_score_round1
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      color: "#f1b44c",
                                      fontSize: "10px",
                                      fontWeight: "bold",
                                      // textAlign: "center",
                                    }}
                                  >
                                    Avg Score
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
         
                </div>
              </Card>
            </Col>
          </Row>
          <div>
            {props.data?.length > 0 ? (
              <TableContainer
                columns={props.juryColumns}
                data={props.data}
                customPageSize={props.data.length}
                isGlobalFilter={false}
                isAddOptions={false}
                pagination
              />
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default JurydetailsModel;
