import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// Import Routes all
import { publicRoutes } from "./routes";
// Import all middleware
import Authmiddleware from "./routes/route";
// layouts Format
import Entrants from "@pages/Admin/Entrants";
import EntrantDetails from "@pages/Admin/Entrants/EntrantDetails";
import Entries from "@pages/Admin/Entries/Entries";
import EntryDetails from "@pages/Admin/Entries/EntryDetails";
import AddEvent from "@pages/Admin/Events/AddEvent";
import ManageEvent from "@pages/Admin/Events/Manage/index";
import EventsList from "@pages/Admin/Events/index";
import AddJury from "@pages/Admin/Jury/AddJury";
import JuryList from "@pages/Admin/Jury/JuryList";
import Category from "@pages/Admin/Master/Category/index";
import Group from "@pages/Admin/Master/Group/index";
import SubGroup from "@pages/Admin/Master/Sub-group/index";
import Register from "@pages/Admin/Register";
import AddEntry from "@pages/User/AddEntry";
import Details from "@pages/User/Details";
import DuplicateEntry from "@pages/User/DuplicateEntry";
import { QueryClient, QueryClientProvider } from "react-query";
import NonAuthLayout from "../src/components/NonAuthLayout";
import VerticalLayout from "../src/components/VerticalLayout/";
import EntrantEditForm from "../src/pages/Admin/EntrantEditForm";
import Result from "../src/pages/Admin/Result/index";
import ViewJuryList from "../src/pages/Audit/Jury/ViewJuryList";
import Assign from "../src/pages/Common/Assign";
import Dashboard from "../src/pages/Common/Dashboard";
import Profile from "../src/pages/Common/Profile";
import EntryListdetails from "../src/pages/Jury/EntryListDetails";
import ViewEntryDetails from "../src/pages/Jury/ViewEntryDetails";
import ViewEntryTwoDetails from "../src/pages/Jury/ViewEntryTwoDetails";

import Page404 from "../src/pages/Page404";

// Import scss
import { useEffect, useState } from "react";
import "./assets/scss/theme.scss";
const queryClient = new QueryClient();
function App() {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    document.addEventListener("fetch_type", async (e) => {
      let type = await localStorage.getItem("type");
      setUserType(JSON.parse(type));
    });
    

    (async () => {
      let type = await localStorage.getItem("type");
      setUserType(JSON.parse(type));
    })();
  }, []);

  let protectedRoutes;

  if (userType == "Admin") {
    protectedRoutes = [
      { path: "/", component: Dashboard },
      { path: "/profile", component: Profile },
      { path: "/group", component: Group },
      { path: "/sub-group", component: SubGroup },
      { path: "/category", component: Category },
      { path: "/entries", component: Entries },
      { path: "/result", component: Result },
      { path: "/entrants", component: Entrants },
      { path: "/entrants-edit-form", component: EntrantEditForm },
      { path: "/entrants-details", component: EntrantDetails },
      { path: "/entry-details", component: EntryDetails },
      { path: "/jury-list", component: JuryList },
      { path: "/add-jury", component: AddJury },
      { path: "/assign-jury", component: Assign },
      { path: "/awards-list", component: EventsList },
      { path: "/add-awards", component: AddEvent },
      { path: "/manage-event-category", component: ManageEvent },
      { path: "/user-entry-details", component: Details },
      { path: "*", component: Page404 },
    ];
  } else if (userType == "Jury") {
    protectedRoutes = [
      { path: "/", component: Dashboard },
      { path: "/profile", component: Profile },
      { path: "/EntryListdetails", component: EntryListdetails },
      { path: "/ViewEntryDetails", component: ViewEntryDetails },
      { path: "/ViewEntryTwoDetails", component: ViewEntryTwoDetails },
      { path: "*", component: Page404 },
    ];
  } else if (userType == "User") {
    protectedRoutes = [
      { path: "/", component: Dashboard },
      { path: "/user-entry-details", component: Details },
      { path: "/profile", component: Profile },
      { path: "/entrants-edit-form", component: EntrantEditForm },
      { path: "/add-entry", component: AddEntry },
      { path: "/duplicate-entry", component: DuplicateEntry },
      { path: "*", component: Page404 },
    ];
  } else {
    protectedRoutes = [
      { path: "/", component: Dashboard },
      { path: "/profile", component: Profile },
      { path: "/jurylist", component: ViewJuryList },
      { path: "/user-entry-details", component: Details },
      { path: "*", component: Page404 },
    ];
  }

  const authProtectedRoutes = [
    { path: "/profile", component: Profile },
    { path: "/", component: Dashboard },
    { path: "/assign-jury", component: Assign },
    { path: "/awards-list", component: EventsList },
    { path: "/add-awards", component: AddEvent },
    { path: "/manage-event-category", component: ManageEvent },

    { path: "/group", component: Group },
    { path: "/sub-group", component: SubGroup },
    { path: "/category", component: Category },

    { path: "/jury-list", component: JuryList },
    { path: "/add-jury", component: AddJury },

    { path: "/entry-details", component: EntryDetails },

    { path: "/entries", component: Entries },
    // { path: "/add-entry", component: AddEntry },
    // { path: "/duplicate-entry", component: DuplicateEntry },
    { path: "/entrants", component: Entrants },
    { path: "/entrants-details", component: EntrantDetails },
    { path: "/register", component: Register },
    { path: "/entrants-edit-form", component: EntrantEditForm },

    { path: "/user-entry-details", component: Details },
    { path: "/EntryListdetails", component: EntryListdetails },
    { path: "/ViewEntryDetails", component: ViewEntryDetails },
    { path: "/ViewEntryTwoDetails", component: ViewEntryTwoDetails },

    { path: "/view-allocations", component: Dashboard },
    { path: "/jurylist", component: ViewJuryList },
    // { path: "/add-new-jury", component: AddNewJury },
    { path: "/result", component: Result },

    { path: "*", component: Page404 },
  ];

  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            {publicRoutes?.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
            {protectedRoutes?.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
