import features from "@assets/images/crypto/features-img/img-1.png";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { BreadcrumbItem, Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import DetailsCard from "../../components/Common/EntryDetailsCard";
import ScreenLoader from "../../components/Common/ScreenLoader";
import ShowReasonModel from "../../components/Common/ShowReasonModel";
import SlideWithFade from "../../pages/Admin/Entries/slidewithfade";
import { getFromStorage } from "../../utils/storage";
import ImageModal from "../Common/ImageModal";
import ScoreModel from "../Common/ScoreModel";
import ReasonModel from "./ReasonModel";


function EntryDetails(props) {
  const Img_url = process.env.REACT_APP_IMG_URL;
  const history = useHistory()

  return (
    <React.Fragment>
      <ImageModal
        isOpen={props.imageModal}
        toggle={props.toggleViewModal}
        data={props.modalData}
      />
      <ScoreModel
        isOpen={props.scoreModal}
        toggle={props.toggleScoreModal1}
        setScore={props.setScore}
        score={props.score}
        handleChnageScore={props.handleChnageScore}
        scoreOption={props.scoreOption}
        handleScoreUpdate={props.handleScoreUpdate}
        isLoadingUpdateScore={props.isLoadingUpdateScore}
        data={props.updateScore}
      />
      <ShowReasonModel
        isOpen={props.isOpenModel}
        toggle={props.toggleShowModal}
        data={props.isReson}
      />

      <ReasonModel
        isOpen={props.modal1}
        toggle={props.toggleViewModal1}
        setReson={props.setReson}
        reson={props.reson}
        handleReson={props.handleReson}
        errorMess={props.errorMess}
        setErrorMess={props.setErrorMess}
        handleChnageReson={props.handleChnageReson}
        isRejectLoading={props.isRejectLoading}
      />

      <div className="page-content">
        <div className="container-fluid">
         {/* <Breadcrumbs title="Entries" breadcrumbItem="Details" /> */}

              <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">Details</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
            <BreadcrumbItem>
                <Link onClick={()=>  {
                  history.push(`/${getFromStorage("url")}`)
                }}>Categories</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link onClick={()=> history.goBack()}>Entries</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link >Details</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>

          {props.getType === "Jury" &&
            props?.entryDetails?.event_round === 1 && (
              <>
                <Row>
                  <Col xs={12}>
                     
                  <Col xs={12}  style={{ display:'flex', justifyContent:'flex-end', alignItems:"flex-end", marginBottom: "10px" }}>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn "
                      onClick={() => props.toggleScoreModal1()}
                      style={{marginRight:"10px"}}
                    >
                      Edit Score
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn "
                      disabled={props.disabledPrevButton}
                      onClick={() => props.goToPreviousEntry()}
                      style={{marginRight:"10px"}}

                    >
                      Go to Previous Entry
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="btn "
                      onClick={() => props.goToNextEntry()}
                      disabled={props.disabledNextButton}
                      // style={{ float: "right", marginBottom: "10px" }}
                    >
                      Go to Next Entry
                    </Button>
                  </Col>
                  </Col>
                </Row>

                {/* <WelcomeJury /> */}
              </>
            )}

          {props?.error == "" ? (
            <>
              <DetailsCard
                entryDetails={props.entryDetails}
                toggleShowModal={props.toggleShowModal}
                setIsReson={props.setIsReson}
                getType={props.getType}
                note={props.note}
                round={props?.entryDetails?.event_round}
              />

              <Row>
                {props.entryDetails?.entry_profile !== null &&
                  (props.entryDetails?.entry_profile?.summary ||
                    props.entryDetails?.entry_profile?.objective ||
                    props.entryDetails?.entry_profile?.concept ||
                    props.entryDetails?.entry_profile?.execution ||
                    props.entryDetails?.entry_profile?.result) && (
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-center mb-3">
                            <div className="avatar-xs me-3">
                              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                                <i className="bx bx-receipt text-primary display-7" />
                              </span>
                            </div>
                            <h5 className="font-size-14 mb-0">
                              Event Campaign Details
                            </h5>
                          </div>
                          {props.entryDetails?.entry_profile?.summary && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. EXECUTIVE SUMMARY
                              </p>
                              <span>
                                # {props.entryDetails?.entry_profile?.summary}
                              </span>
                            </div>
                          )}
                          {props.entryDetails?.entry_profile?.objective && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. BRIEF / OBJECTIVE
                              </p>
                              <span>
                                # {props.entryDetails?.entry_profile?.objective}
                              </span>
                            </div>
                          )}
                          {props.entryDetails?.entry_profile?.concept && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. CONCEPT
                              </p>
                              <span>
                                # {props.entryDetails?.entry_profile?.concept}
                              </span>
                            </div>
                          )}
                          {props.entryDetails?.entry_profile?.execution && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. EXECUTION / AMPLIFICATION
                              </p>
                              <span>
                                # {props.entryDetails?.entry_profile?.execution}
                              </span>
                            </div>
                          )}
                          {props.entryDetails?.entry_profile?.result && (
                            <div className="mb-3">
                              <p
                                style={{ fontWeight: "bold" }}
                                className="mb-1"
                              >
                                Q. RESULTS / SCALE
                              </p>
                              <span>
                                # {props.entryDetails?.entry_profile?.result}
                              </span>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                {props.entryDetails?.entry_photos?.length > 0 ? (
                  props.getType === "User" ? (
                    <Col xl="6">
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-3">Photos</CardTitle>
                          {props.entryDetails && (
                            <SlideWithFade
                              images={props.entryDetails?.entry_photos}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  ) : (
                    <Col xl="12">
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-3">Photos</CardTitle>
                          <div>
                            <Row>
                              {props.entryDetails?.entry_photos?.map((item) => {
                                return (
                                  <Col xl="3">
                                    <div
                                      style={{
                                        border: "1px solid",
                                        borderColor: "#f1b44c",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        props.handleOpenImage(
                                          `${Img_url}${item?.photo}`
                                        )
                                      }
                                      // onClick={() =>
                                      //   props.handleOpenImage(
                                      //     `https://api.wowawards.com${item?.photo}`
                                      //   )
                                      // }
                                    >
                                      <img
                                        src={`${Img_url}${item?.photo}`}
                                        // src={`https://api.wowawards.com${item?.photo}`}
                                        height="200"
                                        width="200"
                                        style={{
                                          objectFit: "cover",
                                          objectPosition: "top",
                                        }}
                                      ></img>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                ) : null}
                {props.entryDetails?.entry_videos?.length > 0 &&
                  (props.getType === "User" ? (
                    <Col xl="6">
                      <Card>
                        <div>
                          <Row>
                            <Col lg="9" sm="8">
                              <div className="p-4">
                                <h5 className="text-primary">Links</h5>
                                <p>Video links:</p>

                                <div className="text-muted">
                                  {props.entryDetails?.entry_videos?.map(
                                    (item, i) => (
                                      <p key={i} className="mb-1">
                                        <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                        <a
                                          href={item.video_link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.video_link}
                                        </a>
                                      </p>
                                    )
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg="3" sm="4" className="align-self-center">
                              <div>
                                <img
                                  src={features}
                                  alt=""
                                  className="img-fluid d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ) : (
                    <Col xl="12">
                      <Card>
                        <div>
                          <Row>
                            <Col lg="9" sm="8">
                              <div className="p-4">
                                <h5 className="text-primary">Links</h5>

                                <div className="text-muted">
                                  {props.entryDetails?.entry_videos?.map(
                                    (item, i) => (
                                      <p key={i} className="mb-1">
                                        <i className="mdi mdi-circle-medium align-middle text-primary me-1" />{" "}
                                        <a
                                          href={item.video_link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.video_link}
                                        </a>
                                      </p>
                                    )
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg="3" sm="4" className="align-self-center">
                              <div>
                                <img
                                  src={features}
                                  alt=""
                                  className="img-fluid d-block"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4 className="text-primary">{props.error}</h4>
            </div>
          )}
        </div>
        {props.getType === "Jury" && props?.entryDetails?.event_round === 1 && (
          <>
            <Row>
              <Col xs={12}>
                <Button
                  type="submit"
                  color="primary"
                  className="btn "
                  onClick={() => props.toggleScoreModal1()}
                  style={{ float: "right", marginBottom: "10px" }}
                >
                  Edit Score
                </Button>
              </Col>
            </Row>

            {/* <WelcomeJury /> */}
          </>
        )}
        {props.getType === "Admin" &&
          !props.entryDetails?.entry_basic_detail?.is_entry_rejected && (
            <Row>
              <Col>
                <Button
                  color="secondary"
                  value="false"
                  style={{ float: "right", marginBottom: "10px" }}
                  onClick={() => {
                    props.toggleViewModal1();
                  }}
                >
                  Reject Entry
                </Button>
              </Col>
            </Row>
          )}
        {(props.isLoading || props.isFetching) && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
}

export default EntryDetails;
