import TableContainer from "@components/Common/TableContainer";
import { FetchData } from "@hooks/api/useApi";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import SelectInput from "../../../components/Form/SelectInput";
import { ID } from "./EditableCol";
// import { usefetchData } from "../../hooks/api/useApi";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import WelcomeComp from "./WelcomeComp";

const Dashboard = () => {
  // const increase = useBearStore((state) => state.increasePopulation);
  const history = useHistory();

  const [optionGroup, setOptionGroup] = useState();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [optionSubgroup, setOptionSubgroup] = useState();
  const [optionRegion, setOptionRegion] = useState();
  const [optionEvents, setOptionEvents] = useState();
  const [selectedSubroup, setSelectedSubgroup] = useState(null);
  const [optionCategory, setOptionCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedEntrant, setSelectedEntrant] = useState(null);
  const [selectedFormStatus, setSelectedFormStatus] = useState(null);
  const [selectedClientStatus, setSelectedClientStatus] = useState(null);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState(null);
  const [selectedFinalStatus, setSelectedFinalStatus] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [optionEntrant, setOptionEntrant] = useState();
  const [openFilters, setOpenFilters] = useState(null);
  const [grouError, setGrouError] = useState("");
  const [tableDataError, setTableDataError] = useState("");
  const [subgroupError, setSubgroupError] = useState("");
  const [entrantError, setEntrantError] = useState("");
  const [formStatusError, setFormStatusError] = useState("");
  const [approvalError, setApprovalError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [finalStatusError, setFinalStatusError] = useState("");
  const [eventsError, setEventsError] = useState("");

  const optionFormStatus = [
    {
      options: [
        { label: "Incomplete", value: "0" },
        { label: "Submitted", value: "1" },
        { label: "Entry Rejected", value: "true" },
      ],
    },
  ];
  const optionClientStatus = [
    {
      options: [
        { label: "Not Required", value: "Not_Requried" },
        { label: "Pending", value: "Pending" },
        { label: "Approved", value: "Approved" },
      ],
    },
  ];
  const optionPaymentStatus = [
    {
      options: [
        { label: "Processing", value: "0" },
        { label: "Pending", value: "1" },
        { label: "Paid", value: "2" },
      ],
    },
  ];
  const optionFinalStatus = [
    {
      options: [
        { label: "Incomplete", value: "0" },
        { label: "Completed", value: "1" },
      ],
    },
  ];
  const actionsDashboard = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const categoryActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionCategory(getList);
        }
      });
    },
  };

  const { data: dashboardList, isLoading } = FetchData(
    "dashboard",
    "Dashbord/get-dashboard",
    actionsDashboard
  );
  const { data: categoryData, refetch: fetchCategory } = FetchData(
    "category",
    "/Admin/get-all-category",
    categoryActions
  );

  const GroupActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.group_name;
          obj["value"] = item.group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionGroup(getList);
        }
      });
    },
  };

  const entrantActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        // if (item.is_active === "Published") {
        obj["label"] = item.entrant_name;
        obj["value"] = item.registration_id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setOptionEntrant(getList);
        // }
      });
    },
  };
  const {
    data: allEntrant,
    isFetching: isEntarntFetching,
    isLoading: isEntarntLoading,
  } = FetchData("entrants", "Admin/get-all-entrant", entrantActions);

  const { data: groupData, refetch: fetchgroup } = FetchData(
    "groups",
    "/Admin/get-all-group",
    GroupActions
  );

  const subgroupActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.sub_group_name;
          obj["value"] = item.sub_group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionSubgroup(getList);
        }
      });
    },
  };

  const { data: subgroupData, refetch: fetchsubgroup } = FetchData(
    "sub-groups",
    "Admin/get-all-subgroup",
    subgroupActions
  );

  const actionsRegion = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        obj["label"] = item.name;
        obj["value"] = item.id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setOptionRegion(getList);
      });
    },
  };
  const { data: regionList, isLoading: isLoadingRegion } = FetchData(
    "region",
    "Admin/get-all-region",
    actionsRegion
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_Date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.row.original?.created_date).format(
            "Do MMM YYYY"
          );
        },
      },

      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: "/entry-details",
                  search: `id=${cellProps.row.original.entry_id}`,
                });
              }}
            >
              {cellProps.value}
            </p>
          );
        },
      },

      {
        Header: "Entrant Name",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },

      {
        Header: "Region",
        accessor: "region",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Awards",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.row.original.event_detail?.event_name}</p>;
        },
      },

      {
        Header: "Group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Sub-group",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Form Status",
        accessor: "form_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.row.original.is_entry_rejected
                  ? "danger"
                  : cellProps.value === "Submitted"
                  ? "success"
                  : "warning")
              }
              pill
            >
              {cellProps.row.original.is_entry_rejected
                ? "Entry Rejected"
                : cellProps.value === "Submitted"
                ? "Submitted"
                : "Incomplete"}
            </Badge>
          );
        },
      },
      {
        Header: "Client Approval Status",
        accessor: "client_approval_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p style={{ fontWeight: "700" }}>
              {cellProps.row.original.client_approval_status?.replace(/_/, " ")}
            </p>
          );
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p style={{ fontWeight: "700" }}> {cellProps?.value} </p>;
        },
      },

      {
        Header: "Final Entry Status",
        accessor: "final_status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p style={{ fontWeight: "700" }}> {cellProps?.value} </p>;
        },
      },
    ],
    []
  );

  // const entrantColumns = useMemo(
  //   () => [
  //     {
  //       Header: "#",
  //       accessor: "",
  //       filterable: true,
  //       disableFilters: true,
  //       disableSortBy: true,
  //       Cell: (row) => {
  //         return <p>{row.row.index + 1}</p>;
  //       },
  //     },
  //     {
  //       Header: "Date",
  //       accessor: "created_date",
  //       filterable: false,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return (
  //           <p>
  //             {moment(cellProps.row.original.created_date).format("DD/MM/YYYY")}
  //           </p>
  //         );
  //       },
  //     },
  //     {
  //       Header: "Name",
  //       accessor: "entrant_name",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <p> {cellProps.value}</p>;
  //       },
  //     },
  //     {
  //       Header: "Email ID ",
  //       accessor: "email",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <p> {cellProps.value}</p>;
  //       },
  //     },
  //     {
  //       Header: "Phone Number",
  //       accessor: "mobie_number",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <p> {cellProps.value}</p>;
  //       },
  //     },
  //     {
  //       Header: "Created Entries",
  //       accessor: "number_of_entries",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return <p> {cellProps.value}</p>;
  //       },
  //     },
  //     {
  //       Header: "Incomplete Entries",
  //       accessor: "",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return (
  //           <p>
  //             {cellProps.cell.row.original.number_of_entries === 0
  //               ? "---"
  //               : cellProps.cell.row.original.form_status.incomplete > 0
  //               ? `${cellProps.cell.row.original.form_status.incomplete}`
  //               : cellProps.cell.row.original.form_status.incomplete === 0 &&
  //                 cellProps.cell.row.original.form_status.submitted > 0
  //               ? "---"
  //               : null}{" "}
  //           </p>
  //         );
  //       },
  //     },
  //     {
  //       Header: "Submited Entries",
  //       accessor: "",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return (
  //           <p>
  //             {cellProps.cell.row.original.number_of_entries === 0
  //               ? "---"
  //               : cellProps.cell.row.original.form_status.incomplete > 0
  //               ? `${cellProps.cell.row.original.form_status.submitted}`
  //               : cellProps.cell.row.original.form_status.incomplete === 0 &&
  //                 cellProps.cell.row.original.form_status.submitted > 0
  //               ? cellProps.cell.row.original.form_status.submitted
  //               : null}{" "}
  //           </p>
  //         );
  //       },
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //       filterable: true,
  //       disableFilters: true,
  //       Cell: (cellProps) => {
  //         return (
  //           <Badge
  //             className={
  //               "font-size-12 badge-soft-" +
  //               (cellProps.value === "Approved"
  //                 ? "success"
  //                 : cellProps.value === "Reject"
  //                 ? "danger"
  //                 : "warning")
  //             }
  //             pill
  //           >
  //             {cellProps.value === "Approved"
  //               ? "Approved"
  //               : cellProps.value === "Reject"
  //               ? "Reject"
  //               : "Pending"}
  //           </Badge>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );
  const actions = {
    select: (data) => {
      const entryList = data.data.responseData;
      return entryList;
    },

    onSuccess: (data) => {
      let optionList = [];
      data?.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.entrant_name;
          obj["value"] = item.registration_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionEntrant(getList);
        }
      });
    },
  };
  const {
    data: allEntries,
    refetch,
    isFetching,
    isLoading: tableLoading,
  } = FetchData("all-entries", `User/get-all-user-entry`, actions);
  const eventActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.event_list?.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.event_name;
          obj["value"] = item.event_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionEvents(getList);
        }
      });
    },
  };

  const {
    data: eventsList,
    isFetching: isEventFetching,
    isLoading: isEventLoading,
    isFetched,
  } = FetchData("events", "/Admin/get-all-event", eventActions);

  function handleGroup(item) {
    setSelectedGroup(item);
  }
  function handleSubgroup(item) {
    setSelectedSubgroup(item);
  }

  function handleCategory(item) {
    setSelectedCategory(item);
  }

  function handleFormStatus(item) {
    setSelectedFormStatus(item);
  }
  function handleClientStatus(item) {
    setSelectedClientStatus(item);
  }
  function handlePaymentStatus(item) {
    setSelectedPaymentStatus(item);
  }
  function handleFinalStatus(item) {
    setSelectedFinalStatus(item);
  }
  function handleEntrant(item) {
    setSelectedEntrant(item);
  }
  function handleEvents(item) {
    setSelectedEvents(item);
  }

  const handleClearAll = () => {
    setSelectedCategory(null);
    setSelectedGroup(null);
    setSelectedSubgroup(null);
    setSelectedFormStatus(null);
    setSelectedClientStatus(null);
    setSelectedPaymentStatus(null);
    setSelectedFinalStatus(null);
    setSelectedEntrant(null);
    setSelectedEvents(null);
    setTableDataError(" ");
    setGrouError(" ");
    setSubgroupError("");
    setFormStatusError(" ");
    setEntrantError(" ");
    setApprovalError(" ");
    setPaymentError("");
    setFinalStatusError("");
    setEventsError("");
  };

  const [listEntries, setListEntries] = useState(null);
  let list_Of_Entries = allEntries;

  useEffect(() => {
    if (selectedGroup) {
      let groupData = list_Of_Entries?.filter((item) => {
        return item?.group_name === selectedGroup?.label;
      });
      if (groupData?.length === 0) {
        groupData = list_Of_Entries;
        setGrouError("Data Not Found");
      } else {
        setGrouError("");
      }
      list_Of_Entries = groupData;
      setListEntries(groupData);
    }
    if (selectedSubroup) {
      let subGroupData = list_Of_Entries?.filter((item) => {
        return item?.subgroup_name === selectedSubroup?.label;
      });
      if (subGroupData?.length === 0) {
        subGroupData = list_Of_Entries;
        setSubgroupError("Data Not Found");
      } else {
        setSubgroupError("");
      }
      list_Of_Entries = subGroupData;
      setListEntries(subGroupData);
    }
    if (selectedCategory) {
      let categoryData = list_Of_Entries?.filter((item) => {
        return item?.category_name === selectedCategory?.label;
      });

      if (categoryData?.length === 0) {
        categoryData = list_Of_Entries;
        setTableDataError("Data Not Found");
      } else {
        setTableDataError("");
      }
      list_Of_Entries = categoryData;
      setListEntries(categoryData);
    }
    if (selectedEntrant) {
      let entrantData = list_Of_Entries?.filter((item) => {
        return item?.entrant_name === selectedEntrant?.label;
      });
      if (entrantData?.length === 0) {
        entrantData = list_Of_Entries;
        setEntrantError("Data Not Found");
      } else {
        setEntrantError("");
      }
      list_Of_Entries = entrantData;
      setListEntries(entrantData);
    }
    if (selectedFormStatus) {
      let formStatusData = list_Of_Entries?.filter((item) => {
        if (selectedFormStatus.label === "Entry Rejected") {
          return item?.is_entry_rejected === true;
        } else if (selectedFormStatus.label === "Incomplete") {
          return (
            item?.form_status === selectedFormStatus?.label &&
            item?.is_entry_rejected === false
          );
        } else {
          return (
            item?.form_status === selectedFormStatus?.label &&
            item?.is_entry_rejected === false
          );
        }
      });

      if (formStatusData?.length === 0) {
        formStatusData = list_Of_Entries;
        setFormStatusError("Data Not Found");
      } else {
        setFormStatusError("");
      }
      list_Of_Entries = formStatusData;
      setListEntries(formStatusData);
    }
    if (selectedClientStatus) {
      let clientStatusData = list_Of_Entries?.filter((item) => {
        return item?.client_approval_status === selectedClientStatus?.value;
      });
      if (clientStatusData?.length === 0) {
        clientStatusData = list_Of_Entries;
        setApprovalError("Data Not Found");
      } else {
        setApprovalError("");
      }
      list_Of_Entries = clientStatusData;
      setListEntries(clientStatusData);
    }
    if (selectedPaymentStatus) {
      let paymentStatusData = list_Of_Entries?.filter((item) => {
        return item?.payment_status === selectedPaymentStatus?.label;
      });
      if (paymentStatusData?.length === 0) {
        paymentStatusData = list_Of_Entries;
        setPaymentError("Data Not Found");
      } else {
        setPaymentError("");
      }

      list_Of_Entries = paymentStatusData;
      setListEntries(paymentStatusData);
    }
    if (selectedFinalStatus) {
      let finalStatusData = list_Of_Entries?.filter((item) => {
        return item?.final_status === selectedFinalStatus?.label;
      });
      if (finalStatusData?.length === 0) {
        finalStatusData = list_Of_Entries;
        setFinalStatusError("Data Not Found");
      } else {
        setFinalStatusError("");
      }
      list_Of_Entries = finalStatusData;
      setListEntries(finalStatusData);
    }
    if (selectedEvents) {
      let eventData = list_Of_Entries?.filter((item) => {
        return item?.region === selectedEvents?.label;
      });
      if (eventData?.length === 0) {
        eventData = list_Of_Entries;
        setEventsError("Data Not Found");
      } else {
        setEventsError("");
      }
      list_Of_Entries = eventData;
      setListEntries(eventData);
    }
  }, [
    list_Of_Entries,
    selectedGroup,
    selectedSubroup,
    selectedCategory,
    selectedFormStatus,
    selectedClientStatus,
    selectedEntrant,
    selectedPaymentStatus,
    selectedFinalStatus,
    selectedEvents,
  ]);

  const handleFilter = () => {
    setOpenFilters(!openFilters);
  };

  document.title = "Dashboard | WOW Awards";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <WelcomeComp />
              {/* <Button name="increase bears" onClick={() => increase()}>
                My bears
              </Button> */}
            </Col>
            <Col xl="12">
              <Row>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Entrants</p>
                          <h4 className="mb-0">
                            {dashboardList?.entrant_count}
                          </h4>
                          <Button
                            className="mt-3"
                            onClick={() => history.push("/entrants")}
                            color="primary"
                          >
                            Click to view all
                          </Button>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx  bx-copy-alt  font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Entries</p>
                          <h4 className="mb-0">
                            {dashboardList?.entries_count}
                          </h4>
                          <Button
                            className="mt-3"
                            onClick={() => history.push("/entries")}
                            color="primary"
                          >
                            Click to view all
                          </Button>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx  bx-copy-alt  font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">Awards</p>
                          <h4 className="mb-0">{dashboardList?.event_count}</h4>
                          <Button
                            className="mt-3"
                            onClick={() => history.push("/awards-list")}
                            color="primary"
                          >
                            Click to view all
                          </Button>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i className="bx  bx-copy-alt  font-size-24"></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>

          {allEntries?.length > 0 && (
            <Card>
              <CardBody>
                <Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div className="avatar-xs me-3 ">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className={`bx bx-layer text-primary display-7`} />
                        </span>
                      </div>
                      <h3>
                        {`Entries Report  (${
                          selectedCategory === null && selectedGroup === null
                            ? allEntries?.length
                            : selectedCategory !== null ||
                              selectedGroup !== null ||
                              selectedSubroup !== null
                            ? listEntries?.length
                            : null
                        })`}
                      </h3>
                    </div>
                    <Col sm="4">
                      <div style={{ float: "right" }}>
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          onClick={handleFilter}
                        >
                          {openFilters ? "Hide Filter" : "Filter"}
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>

                {openFilters && (
                  <div>
                    <div style={{ margin: "10px 0" }}>
                      <Row>
                        <Col sm="4">
                          <SelectInput
                            label={"Group:"}
                            name={"select_group"}
                            type={"select"}
                            onChange={(e) => {
                              handleGroup(e);
                            }}
                            options={optionGroup}
                            value={selectedGroup}
                          />
                          {grouError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {grouError}
                            </span>
                          )}
                        </Col>
                        <Col sm="4">
                          <SelectInput
                            label={"Subgroup:"}
                            name={"select_subgroup"}
                            type={"select"}
                            onChange={(e) => {
                              handleSubgroup(e);
                            }}
                            options={optionSubgroup}
                            value={selectedSubroup}
                          />
                          {subgroupError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {subgroupError}
                            </span>
                          )}
                        </Col>

                        <Col sm="4">
                          <SelectInput
                            label={"Category:"}
                            name={"select_category"}
                            type={"select"}
                            onChange={(e) => {
                              handleCategory(e);
                            }}
                            options={optionCategory}
                            value={selectedCategory}
                          />
                          {tableDataError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {tableDataError}
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div style={{ margin: "20px 0" }}>
                      <Row>
                        <Col sm="2">
                          <SelectInput
                            label={"Entrant Name:"}
                            name={"select_entrant"}
                            type={"select"}
                            onChange={(e) => {
                              handleEntrant(e);
                            }}
                            options={optionEntrant}
                            value={selectedEntrant}
                          />
                          {entrantError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {entrantError}
                            </span>
                          )}
                        </Col>
                        <Col sm="2">
                          <SelectInput
                            label={"Form Status:"}
                            name={"select_formStatus"}
                            type={"select"}
                            onChange={(e) => {
                              handleFormStatus(e);
                            }}
                            options={optionFormStatus}
                            value={selectedFormStatus}
                          />
                          {formStatusError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {formStatusError}
                            </span>
                          )}
                        </Col>
                        <Col sm="2">
                          <SelectInput
                            label={"Approval Status:"}
                            name={"select_clientStatus"}
                            type={"select"}
                            onChange={(e) => {
                              handleClientStatus(e);
                            }}
                            options={optionClientStatus}
                            value={selectedClientStatus}
                          />
                          {approvalError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {approvalError}
                            </span>
                          )}
                        </Col>

                        <Col sm="2">
                          <SelectInput
                            label={" Payment Status:"}
                            name={"select_paymentStatus"}
                            type={"select"}
                            onChange={(e) => {
                              handlePaymentStatus(e);
                            }}
                            options={optionPaymentStatus}
                            value={selectedPaymentStatus}
                          />
                          {paymentError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {paymentError}
                            </span>
                          )}
                        </Col>
                        <Col sm="2">
                          <SelectInput
                            label={"Final Status:"}
                            name={"select_finalStatus"}
                            type={"select"}
                            onChange={(e) => {
                              handleFinalStatus(e);
                            }}
                            options={optionFinalStatus}
                            value={selectedFinalStatus}
                          />
                          {finalStatusError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {finalStatusError}
                            </span>
                          )}
                        </Col>
                        <Col sm="2">
                          <SelectInput
                            label={"Region: "}
                            name={"select_event"}
                            type={"select"}
                            onChange={(e) => {
                              handleEvents(e);
                            }}
                            options={optionRegion}
                            value={selectedEvents}
                          />
                          {eventsError && (
                            <span
                              style={{ color: "red", position: "absolute" }}
                            >
                              {eventsError}
                            </span>
                          )}
                        </Col>
                      </Row>

                      <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          outline
                          type="button"
                          color="primary"
                          className="btn-sm mt-4 align-items-center"
                          onClick={handleClearAll}
                        >
                          Clear All
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {allEntries &&
                  selectedCategory === null &&
                  selectedGroup === null &&
                  selectedSubroup === null &&
                  selectedClientStatus === null &&
                  selectedEntrant === null &&
                  selectedFinalStatus === null &&
                  selectedFormStatus === null &&
                  selectedEvents === null &&
                  selectedPaymentStatus === null ? (
                    <TableContainer
                      columns={columns}
                      data={allEntries}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      download
                      entryData
                    />
                  ) : null}
                </div>

                <div>
                  {selectedCategory !== null ||
                  selectedGroup !== null ||
                  selectedSubroup !== null ||
                  selectedClientStatus !== null ||
                  selectedEntrant !== null ||
                  selectedFinalStatus !== null ||
                  selectedFormStatus !== null ||
                  selectedEvents !== null ||
                  selectedPaymentStatus !== null ? (
                    <TableContainer
                      columns={columns}
                      data={listEntries?.length ? listEntries : list_Of_Entries}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                      download
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
        {isLoading && tableLoading && isFetching && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
