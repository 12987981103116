import React from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { InputField } from '../../Form/Input';
import SelectInput from '../../Form/SelectInput';

const AddSubgroup = (props) => {
  return (
    <div>
      <Modal scrollable isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {'Add SubGroup'}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              props.validation.handleSubmit();
              return false;
            }}
          >
            <Row form>
              <Col className="col-12">
                <div style={{ textAlign: 'end' }}>
                  <div className="btn-group" role="group">
                    <input
                      onChange={(e) =>
                        props.validation.setFieldValue('status', e.target.value)
                      }
                      type="radio"
                      className="btn-check"
                      name="status"
                      id="btnradio5"
                      autoComplete="off"
                      value={true}
                      defaultChecked={
                        props.validation.values.status ? true : false
                      }
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btnradio5"
                    >
                      Publish
                    </label>

                    <input
                      onChange={(e) =>
                        props.validation.setFieldValue('status', e.target.value)
                      }
                      type="radio"
                      className="btn-check"
                      name="status"
                      id="btnradio6"
                      autoComplete="off"
                      value={false}
                      defaultChecked={
                        !props.validation.values.status ? true : false
                      }
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btnradio6"
                    >
                      Draft
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <SelectInput
                    label={'Select Group:'}
                    name={'updateSubGroup'}
                    type={'select'}
                    options={props.options}
                    value={props.updateSubGroup}
                    onBlur={props.validation.handleBlur}
                    onChange={(e) => props.onChange(e)}
                    invalid={
                      props.validation.touched.updateSubGroup &&
                      props.validation.errors.updateSubGroup
                        ? true
                        : false
                    }
                    formik={props.validation}
                  />
                </div>
                <div className="mb-3">
                  <InputField
                    label={'Subgroup Name'}
                    name={'title'}
                    type={'text'}
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    value={props.validation.values.title || ''}
                    invalid={
                      props.validation.touched.title &&
                      props.validation.errors.title
                        ? true
                        : false
                    }
                    validation={props.validation}
                  />
                </div>
                <div className="mb-4">
                  <InputField
                    label={'Description'}
                    name={'desc'}
                    type={'textarea'}
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    value={props.validation.values.desc || ''}
                    invalid={
                      props.validation.touched.desc &&
                      props.validation.errors.desc
                        ? true
                        : false
                    }
                    validation={props.validation}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    disabled={props.isLoadingAddSubGroup}
                  >
                    {props.isLoadingAddSubGroup && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddSubgroup;
