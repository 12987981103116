import { FetchData } from "@hooks/api/useApi";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import EntryDetails from "../../components/Common/EntryDeatils";
import { getFromStorage } from "../../utils/storage";

function Details() {
  document.title = "Entry details | WOW Awards";
  const getType = getFromStorage("type");
  const location = useLocation();
  const entryId = location?.search?.slice(4);

  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [error, setError] = useState("");

  const entryActions = {
    select: (data) => {
      const list = data.data;
      return list;
    },
    onSuccess: (data) => {
      if (!data?.status) {
        setError(data?.errors[0]);
      }
    },
  };

  const {
    data: entryDetails,
    isLoading,
    isFetching,
  } = FetchData(
    "entry-details",
    `User/get-user-entry-by-id?id=${entryId}`,
    entryActions
  );

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
  };
  const t_col12 = () => {
    setcol12(!col12);
    setcol11(false);
    setcol10(false);
    setcol9(false);
  };
  const t_col13 = () => {
    setcol13(!col13);
    setcol11(false);
    setcol10(false);
    setcol9(false);
  };

  const [isReson, setIsReson] = useState(false);
  const [isOpenModel, setIsOpenModel] = useState(false);
  const toggleShowModal = () => {
    if (entryDetails?.responseData?.entry_basic_detail?.is_entry_rejected) {
      setIsOpenModel(!isOpenModel);
    }
  };

  return (
    <React.Fragment>
      <EntryDetails
        entryDetails={entryDetails?.responseData}
        toggleShowModal={toggleShowModal}
        setIsOpenModel={setIsOpenModel}
        setIsReson={setIsReson}
        isReson={isReson}
        entryId={entryId}
        isOpenModel={isOpenModel}
        isLoading={isLoading}
        isFetching={isFetching}
        getType={getType}
        error={error}
      />
    </React.Fragment>
  );
}

export default Details;
