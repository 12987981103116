import React from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ScreenLoader from "../../Common/ScreenLoader";
import { InputField } from "../../Form/Input";
import SelectInput from "../../Form/SelectInput";

const AddCategory = (props) => {
  return (
    <div>
      <Modal scrollable isOpen={props.modal} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} tag="h4">
          {"Add Category"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              props.formik.handleSubmit();
              return false;
            }}
          >
            <Col className="col-12">
              <div style={{ textAlign: "end" }}>
                <div className="btn-group" role="group">
                  <input
                    onChange={(e) => {
                      props.formik.setFieldValue(
                        "Category_Status",
                        e.target.value
                      );
                    }}
                    type="radio"
                    className="btn-check"
                    name="Category_Status"
                    id="btnradio5"
                    autoComplete="off"
                    value={true}
                    defaultChecked={
                      props.formik.values.Category_Status ? true : false
                    }
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor="btnradio5"
                  >
                    Publish
                  </label>

                  <input
                    onChange={(e) =>
                      props.formik.setFieldValue(
                        "Category_Status",
                        e.target.value
                      )
                    }
                    type="radio"
                    className="btn-check"
                    name="Category_Status"
                    id="btnradio6"
                    autoComplete="off"
                    value={false}
                    defaultChecked={
                      !props.formik.values.Category_Status ? true : false
                    }
                  />
                  <label
                    className="btn btn-outline-primary"
                    htmlFor="btnradio6"
                  >
                    Draft
                  </label>
                </div>
              </div>
              <Row>
                <Col md="12">
                  <div className="mb-4">
                    <SelectInput
                      label={"Select Region:"}
                      name={"Category_Region"}
                      type={"select"}
                      options={props.options}
                      value={props.Category_Region}
                      onBlur={props.formik.handleBlur}
                      onChange={(e) => props.onChangeRegion(e)}
                      invalid={
                        props.formik.touched.Category_Region &&
                        props.formik.errors.Category_Region
                          ? true
                          : false
                      }
                      formik={props.formik}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-4">
                    <SelectInput
                      label={"Select Group:"}
                      name={"Category_Group"}
                      type={"select"}
                      options={props.optionGroup}
                      value={props.Category_Group}
                      onBlur={props.formik.handleBlur}
                      onChange={(e) => props.onChangeGroup(e)}
                      invalid={
                        props.formik.touched.Category_Group &&
                        props.formik.errors.Category_Group
                          ? true
                          : false
                      }
                      formik={props.formik}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-4">
                    <SelectInput
                      label={"Select Sub-group:"}
                      name={"Category_Subgroup"}
                      type={"select"}
                      options={props.optionSubgroup || []}
                      value={props.Category_Subgroup}
                      onBlur={props.formik.handleBlur}
                      onChange={(e) => props.onChangeSubgroup(e)}
                      invalid={
                        props.formik.touched.Category_Subgroup &&
                        props.formik.errors.Category_Subgroup
                          ? true
                          : false
                      }
                      formik={props.formik}
                    />
                  </div>
                </Col>
              </Row>

              <div className="mb-3">
                <InputField
                  label={"Category Name"}
                  name={"Category_Title"}
                  type={"text"}
                  onChange={props.formik.handleChange}
                  onBlur={props.formik.handleBlur}
                  value={props.formik.values.Category_Title || ""}
                  invalid={
                    props.formik.touched.Category_Title &&
                    props.formik.errors.Category_Title
                      ? true
                      : false
                  }
                  validation={props.formik}
                />
              </div>
              {/* <div className="mb-4">
                <InputField
                  label={'Description'}
                  name={'Category_Des'}
                  type={'textarea'}
                  onChange={props.formik.handleChange}
                  onBlur={props.formik.handleBlur}
                  value={props.formik.values.Category_Des || ''}
                  invalid={
                    props.formik.touched.Category_Des &&
                    props.formik.errors.Category_Des
                      ? true
                      : false
                  }
                  validation={props.formik}
                />
              </div> */}
              {/* <Row>
                <Col md="6">
                  <div className="mb-4">
                    <Label className="Category_Curr">Select Currency:</Label>
                    <Select
                      name="Category_Curr"
                      type="select"
                      value={props.formik.values.Category_Curr || {}}
                      onChange={(e) => props.onChange(e)}
                      options={props.optionCurrency}
                      // defaultValue={optionCurrency[0].options[0]}
                      classNamePrefix="select2-selection"
                      onBlur={props.formik.handleBlur}
                      invalid={
                        props.formik.touched.Category_Curr &&
                        props.formik.errors.Category_Curr
                          ? true
                          : false
                      }
                    />
                    {props.formik.touched.Category_Curr &&
                    props.formik.errors.Category_Curr ? (
                      <FormFeedback type="invalid" style={{ display: 'flex' }}>
                        {props.formik.errors.Category_Curr}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-4">
                    <InputField
                      label={'Price:'}
                      name={'Category_Price'}
                      type={'number'}
                      onChange={props.formik.handleChange}
                      onBlur={props.formik.handleBlur}
                      value={props.formik.values.Category_Price || ''}
                      invalid={
                        props.formik.touched.Category_Price &&
                        props.formik.errors.Category_Price
                          ? true
                          : false
                      }
                      validation={props.formik}
                    />
                  </div>
                </Col>
              </Row> */}
            </Col>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    disabled={props.isLoadingAddCat}
                  >
                    {props.isLoadingAddCat && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          {props.loader && <ScreenLoader />}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddCategory;
