import moment from "moment";
import React, { Fragment, useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Button, Col, Input, Row, Table } from "reactstrap";
import { DefaultColumnFilter, Filter } from "./filters";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Col sm={2}>
      <div className="search-box me-2 mb-2 d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </div>
    </Col>
  );
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
  pagination,
  download,
  reportData,
  secoundRound,
  entrantData,
  entryData,
}) => {
  // const reportData = [
  //   {
  //     entry_Detail: {
  //       id: 175,
  //       name: "New Event Party",
  //       code: "611012",
  //       entrant_name: "Rohan Decoration Pvt ltd",
  //       form_status: "Submitted",
  //       isEntryRejected: false,
  //       isRoundTwoEligible: false,
  //     },
  //     event_Detail: { id: 45, name: "WOW Awards 2023" },
  //     round: 1,
  //     winner: false,
  //     created_date: "2023-02-13T15:24:38.555781",
  //     category_detail: {
  //       category_id: 61,
  //       category_name: "61. Achievement in Lighting Solution",
  //     },
  //     group_detail: {
  //       group_id: 45,
  //       group_name: "EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     subgroup_detail: {
  //       subgroup_id: 62,
  //       subgroup_name: "Subgroup EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     jury_member_round1: [
  //       {
  //         jury_id: 38,
  //         entry_Jury_Assign_Id: 391,
  //         jury_member: "Aamir testing",
  //         score: 4,
  //       },
  //       {
  //         jury_id: 24,
  //         entry_Jury_Assign_Id: 392,
  //         jury_member: "Rahul",
  //         score: 2,
  //       },
  //       {
  //         jury_id: 24,
  //         entry_Jury_Assign_Id: 392,
  //         jury_member: "Rahul",
  //         score: 2,
  //       },
  //     ],
  //     jury_member_round2: [],
  //     average_score_round1: 3,
  //     average_score_round2: null,
  //   },
  //   {
  //     entry_Detail: {
  //       id: 173,
  //       name: "Car opening Ceremony",
  //       code: "711010",
  //       entrant_name: "Rohan Decoration Pvt ltd",
  //       form_status: "Submitted",
  //       isEntryRejected: false,
  //       isRoundTwoEligible: false,
  //     },
  //     event_Detail: { id: 45, name: "WOW Awards 2023" },
  //     round: 1,
  //     winner: false,
  //     created_date: "2023-02-13T14:04:13.874196",
  //     category_detail: {
  //       category_id: 71,
  //       category_name: "71.  Car Opening Ceremony",
  //     },
  //     group_detail: {
  //       group_id: 47,
  //       group_name: " EXPERIENTIAL MANAGEMENT AWARDS",
  //     },
  //     subgroup_detail: {
  //       subgroup_id: 62,
  //       subgroup_name: "Subgroup EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     jury_member_round1: [
  //       {
  //         jury_id: 38,
  //         entry_Jury_Assign_Id: 393,
  //         jury_member: "Aamir testing",
  //         score: 3,
  //       },
  //       {
  //         jury_id: 24,
  //         entry_Jury_Assign_Id: 394,
  //         jury_member: "Rahul",
  //         score: 3,
  //       },
  //     ],
  //     jury_member_round2: [],
  //     average_score_round1: 3,
  //     average_score_round2: null,
  //   },
  //   {
  //     entry_Detail: {
  //       id: 175,
  //       name: "New Event Party",
  //       code: "611012",
  //       entrant_name: "Rohan Decoration Pvt ltd",
  //       form_status: "Submitted",
  //       isEntryRejected: false,
  //       isRoundTwoEligible: false,
  //     },
  //     event_Detail: { id: 45, name: "WOW Awards 2023" },
  //     round: 1,
  //     winner: false,
  //     created_date: "2023-02-13T15:24:38.555781",
  //     category_detail: {
  //       category_id: 61,
  //       category_name: "61. Achievement in Lighting Solution",
  //     },
  //     group_detail: {
  //       group_id: 45,
  //       group_name: "EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     subgroup_detail: {
  //       subgroup_id: 62,
  //       subgroup_name: "Subgroup EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     jury_member_round1: [
  //       {
  //         jury_id: 38,
  //         entry_Jury_Assign_Id: 391,
  //         jury_member: "Aamir testing",
  //         score: 4,
  //       },
  //       // {
  //       //   jury_id: 24,
  //       //   entry_Jury_Assign_Id: 392,
  //       //   jury_member: "Rahul",
  //       //   score: 2,
  //       // },
  //       // {
  //       //   jury_id: 24,
  //       //   entry_Jury_Assign_Id: 392,
  //       //   jury_member: "Rahul",
  //       //   score: 2,
  //       // },
  //     ],
  //     jury_member_round2: [],
  //     average_score_round1: 3,
  //     average_score_round2: null,
  //   },
  //   {
  //     entry_Detail: {
  //       id: 175,
  //       name: "New Event Party",
  //       code: "611012",
  //       entrant_name: "Rohan Decoration Pvt ltd",
  //       form_status: "Submitted",
  //       isEntryRejected: false,
  //       isRoundTwoEligible: false,
  //     },
  //     event_Detail: { id: 45, name: "WOW Awards 2023" },
  //     round: 1,
  //     winner: false,
  //     created_date: "2023-02-13T15:24:38.555781",
  //     category_detail: {
  //       category_id: 61,
  //       category_name: "61. Achievement in Lighting Solution",
  //     },
  //     group_detail: {
  //       group_id: 45,
  //       group_name: "EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     subgroup_detail: {
  //       subgroup_id: 62,
  //       subgroup_name: "Subgroup EXPERIENTIAL MARKETING AWARDS",
  //     },
  //     jury_member_round1: [
  //       // {
  //       //   jury_id: 38,
  //       //   entry_Jury_Assign_Id: 391,
  //       //   jury_member: "Aamir testing",
  //       //   score: 4,
  //       // },
  //       // {
  //       //   jury_id: 24,
  //       //   entry_Jury_Assign_Id: 392,
  //       //   jury_member: "Rahul",
  //       //   score: 2,
  //       // },
  //       // {
  //       //   jury_id: 24,
  //       //   entry_Jury_Assign_Id: 392,
  //       //   jury_member: "Rahul",
  //       //   score: 2,
  //       // },
  //     ],
  //     jury_member_round2: [],
  //     average_score_round1: 3,
  //     average_score_round2: null,
  //   },
  // ];
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            id: "#",
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Report",
    sheet: "Entries",
  });

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <i
          className="bx bx-caret-down font-size-16"
          style={{ color: "#f7c45e" }}
        />
      ) : (
        <i
          className="bx bx-caret-up font-size-16"
          style={{ color: "#f7c45e" }}
        />
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const renderValue = (items) => {
    let getLength = reportData?.map((item) => item?.jury_member_round1?.length);

    var arr = [];
    var greatestLength = Math.max(...getLength);
    for (let index = 0; index < greatestLength; index++) {
      arr.push(index);
    }
    var text = (
      <>
        {arr.map((item, index) => (
          <th>jury member {index + 1}</th>
        ))}
      </>
    );
    // var text = <th>Abhishek</th>;
    //   var arr = items[0]?.jury_member_round1;
    //  var text= <>
    //     {arr.map((item, index) => (
    //       <th>jury member {index}</th>
    //     ))}
    //   </>;

    return text;
  };
  const renderScore = (items) => {
    let getLength = reportData?.map((item) => item?.jury_member_round1?.length);

    var arr = [];
    var greatestLength = Math.max(...getLength);
    for (let index = 0; index < greatestLength; index++) {
      arr.push(index);
    }
    var text = (
      <>
        {arr.map((item, index) => (
          <th>Score jury member {index + 1}</th>
        ))}
      </>
    );

    return text;
  };

  const renderItems = (items) => {
    var text = "";
    let getLength = reportData?.map((item) => item?.jury_member_round1?.length);
    var greatestLength = Math.max(...getLength);
    var arr = [];

    for (let j = 0; j < greatestLength; j++) {
      if (items?.jury_member_round1[j]) {
        arr.push(items?.jury_member_round1[j]);
      } else {
        arr.push({});
      }
    }

    var text = (
      <>
        {arr.map((item, index) => (
          <td>{item?.jury_member}</td>
        ))}
      </>
    );

    return text;
  };

  const renderItemsScore = (items) => {
    var text = "";
    let getLength = reportData?.map((item) => item?.jury_member_round1?.length);
    var greatestLength = Math.max(...getLength);

    // var arr = items?.jury_member_round1.map((item) => item);
    var arr = [];

    for (let j = 0; j < greatestLength; j++) {
      if (items?.jury_member_round1[j]) {
        arr.push(items?.jury_member_round1[j]);
      } else {
        arr.push({});
      }
    }

    var text = (
      <>
        {arr.map((item, index) => (
          <td>{item?.score}</td>
        ))}
      </>
    );

    return text;
  };

  const renderValueTwo = (items) => {
    let getLength = reportData?.map((item) => item?.jury_member_round2?.length);

    var arr = [];
    var greatestLength = Math.max(...getLength);
    for (let index = 0; index < greatestLength; index++) {
      arr.push(index);
    }
    var text = (
      <>
        {arr.map((item, index) => (
          <th>jury member {index + 1}</th>
        ))}
      </>
    );
    // var text = <th>Abhishek</th>;
    //   var arr = items[0]?.jury_member_round1;
    //  var text= <>
    //     {arr.map((item, index) => (
    //       <th>jury member {index}</th>
    //     ))}
    //   </>;

    return text;
  };
  const renderScoreTwo = (items) => {
    let getLength = reportData?.map((item) => item?.jury_member_round2?.length);

    var arr = [];
    var greatestLength = Math.max(...getLength);
    for (let index = 0; index < greatestLength; index++) {
      arr.push(index);
    }
    var text = (
      <>
        {arr.map((item, index) => (
          <th>Score jury member {index + 1}</th>
        ))}
      </>
    );

    return text;
  };

  const renderItemsTwo = (items) => {
    var text = "";
    let getLength = reportData?.map((item) => item?.jury_member_round2?.length);
    var greatestLength = Math.max(...getLength);

    var arr = [];

    for (let j = 0; j < greatestLength; j++) {
      if (items?.jury_member_round2[j]) {
        arr.push(items?.jury_member_round2[j]);
      } else {
        arr.push({});
      }
    }

    var text = (
      <>
        {arr.map((item, index) => (
          <td>{item?.jury_member}</td>
        ))}
      </>
    );

    return text;
  };

  const renderItemsScoreTwo = (items) => {
    var text = "";
    let getLength = reportData?.map((item) => item?.jury_member_round2?.length);
    var greatestLength = Math.max(...getLength);

    // var arr = items?.jury_member_round1.map((item) => item);
    var arr = [];

    for (let j = 0; j < greatestLength; j++) {
      if (items?.jury_member_round2[j]) {
        arr.push(items?.jury_member_round2[j]);
      } else {
        arr.push({});
      }
    }

    var text = (
      <>
        {arr.map((item, index) => (
          <td>{item?.score}</td>
        ))}
      </>
    );

    return text;
  };

  return (
    <Fragment>
      <Row className="mb-2 mt-3" style={{ justifyContent: "flex-end" }}>
        {download && (
          <Col sm="2">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="button" color="primary" onClick={onDownload}>
                <i className="bx bx-downvote font-size-12 align-middle"></i>{" "}
                Download Report
              </Button>
            </div>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {!pagination && data.length > 10 && (
          <Col md={customPageSizeOptions ? 2 : 1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isAddOptions && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <table style={{ display: "none" }} {...getTableProps()} ref={tableRef}>
          {!reportData ? (
            entrantData ? (
              <thead className="table-light table-nowrap">
                <tr>
                  <th>Sr.no</th>
                  <th>Entrant Name</th>
                  <th>Entrant Email</th>
                  <th>Entrant Number</th>
                  <th>Primary Name</th>
                  <th>Primary Mobile Number</th>
                  <th>Primary Designation</th>
                  <th>Primary City</th>
                  <th>Primary State</th>
                  <th>Primary Country</th>
                  <th>Senior Name</th>
                  <th>Senior Designation</th>
                  <th>Senior Email</th>
                  <th>Senior Mobile Number</th>
                </tr>
              </thead>
            ) : entryData ? (
              <thead className="table-light table-nowrap">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers.map((column, ind) => {
                        return (
                          <th
                            key={ind}
                            style={{
                              minWidth:
                                column.Header === "Action" ||
                                column.Header === "Entry Category" ||
                                column.Header === "Entrant Name" ||
                                column.Header === "Entry Name" ||
                                column.Header === "Category"
                                  ? 200
                                  : null,
                              width: column.Header === "Action" ? 200 : null,
                            }}
                          >
                            <div
                              className="mb-2"
                              {...column.getSortByToggleProps()}
                            >
                              {column.render("Header")}
                              {generateSortingIndicator(column)}
                            </div>
                            <Filter column={column} />
                          </th>
                        );
                      })}
                      <th>Client Name</th>
                      <th>Client Email</th>
                      <th>Client Designation </th>
                      <th>Client Number</th>
                      <th>Client Company Name</th>
                      <th>Client City</th>
                    </tr>
                  );
                })}
              </thead>
            ) : (
              <thead className="table-light table-nowrap">
                {headerGroups.map((headerGroup, index) => {
                  return (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers.map((column, ind) => {
                        return (
                          <th
                            key={ind}
                            // style={{
                            //   minWidth:
                            //     column.Header === "Action" ||
                            //     column.Header === "Entry Category" ||
                            //     column.Header === "Entrant Name" ||
                            //     column.Header === "Entry Name" ||
                            //     column.Header === "Category"
                            //       ? 200
                            //       : null,
                            //   width: column.Header === "Action" ? 200 : null,
                            // }}
                          >
                            <div
                              className="mb-2"
                              {...column.getSortByToggleProps()}
                            >
                              {column.render("Header")}
                              {generateSortingIndicator(column)}
                            </div>
                            <Filter column={column} />
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
            )
          ) : secoundRound ? (
            <thead className="table-light table-nowrap">
              <tr>
                <th>Sr.no</th>
                <th>Code</th>
                <th>Created Date</th>
                {renderValueTwo(reportData)}
                <th>Entrant Organization</th>
                <th>Entry Name</th>
                <th>Group</th>
                <th>SubGroup</th>
                <th>Category</th>
                {renderScoreTwo(reportData)}
                <th>Average Score</th>
              </tr>
            </thead>
          ) : (
            <thead className="table-light table-nowrap">
              <tr>
                <th>Sr.no</th>
                <th>Code</th>
                <th>Created Date</th>
                {renderValue(reportData)}
                <th>Entrant Organization</th>
                <th>Entry Name</th>
                <th>Group</th>
                <th>SubGroup</th>
                <th>Category</th>
                {renderScore(reportData)}
                <th>Average Score</th>
              </tr>
            </thead>
          )}

          {!reportData ? (
            <tbody>
              {download && entrantData
                ? data?.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{row?.entrant_name}</td>
                        <td>{row?.email}</td>
                        <td>{row?.mobie_number}</td>
                        <td>
                          {row?.primary?.primary_salutation +
                            row?.primary?.pcp_first_name +
                            " " +
                            row?.primary?.pcp_last_name}
                        </td>
                        <td>{row?.primary?.pcp_mobile_number}</td>
                        <td>{row?.primary?.pcp_designation}</td>
                        <td>{row?.primary?.pcp_city}</td>{" "}
                        <td>{row?.primary?.pcp_state}</td>{" "}
                        <td>{row?.primary?.pcp_country}</td>
                        <td>
                          {row?.executive?.salutation_sr_executive !== null ||
                          (row?.executive?.first_name_sr_executive !== null &&
                            row?.executive?.last_name_sr_executive !== null)
                            ? row?.executive?.salutation_sr_executive +
                              row?.executive?.first_name_sr_executive +
                              " " +
                              row?.executive?.last_name_sr_executive
                            : " "}
                        </td>
                        <td>{row?.executive?.designation_sr_executive}</td>
                        <td>{row?.executive?.email_id_sr_executive}</td>
                        <td>{row?.executive?.mobile_no_sr_executive}</td>
                      </tr>
                    );
                  })
                : entryData
                ? data?.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{row?.entry_code}</td>
                        <td>
                          {moment(row.created_date).format("Do MMM YYYY")}
                        </td>
                        <td>{row?.entry_name}</td>
                        <td>{row?.entrant_name}</td>
                        <td>{row?.region}</td>
                        <td>{row?.event_detail?.event_name}</td>
                        <td>{row?.group_name}</td>
                        <td>{row?.subgroup_name}</td>
                        <td>{row?.category_name}</td>
                        <td>{row?.form_status}</td>
                        <td>{row?.client_approval_status}</td>
                        <td>{row?.payment_status}</td>
                        <td>{row?.final_status}</td>
                        <td>{row?.client_name}</td>
                        <td>{row?.client_email}</td>
                        <td>{row?.client_designation}</td>
                        <td>{row?.client_number}</td>
                        <td>{row?.company_name}</td>
                        <td>{row?.city}</td>
                      </tr>
                    );
                  })
                : page?.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Fragment key={row.getRowProps().key}>
                        <tr>
                          {row.cells.map((cell, i) => {
                            return (
                              <>
                                {/* {cell.column.Header === "#" ? 
                              <td key={cell.id} {...cell.getCellProps()}>
                                {index + 1}
                              </td>: */}
                                <td key={cell.id} {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                                {/* } */}
                              </>
                            );
                          })}
                        </tr>
                      </Fragment>
                    );
                  })}
            </tbody>
          ) : secoundRound ? (
            <tbody>
              {download &&
                reportData &&
                reportData?.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{row?.entry_Detail?.code}</td>
                      <td>{moment(row.created_date).format("Do MMM YYYY")}</td>
                      {renderItemsTwo(row)}
                      <td>{row?.entry_Detail?.entrant_name}</td>
                      <td>{row?.entry_Detail?.name}</td>
                      <td>{row?.group_detail?.group_name}</td>
                      <td>{row?.subgroup_detail?.subgroup_name}</td>
                      <td>{row?.category_detail?.category_name}</td>
                      {renderItemsScoreTwo(row)}
                      <td>{Math.round(row?.average_score_round2)}</td>
                    </tr>
                  );
                })}
            </tbody>
          ) : (
            <tbody>
              {download &&
                reportData &&
                reportData?.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{row?.entry_Detail?.code}</td>
                      <td>{moment(row.created_date).format("Do MMM YYYY")}</td>
                      {renderItems(row)}
                      <td>{row?.entry_Detail?.entrant_name}</td>
                      <td>{row?.entry_Detail?.name}</td>
                      <td>{row?.group_detail?.group_name}</td>
                      <td>{row?.subgroup_detail?.subgroup_name}</td>
                      <td>{row?.category_detail?.category_name}</td>
                      {renderItemsScore(row)}
                      <td>{Math.round(row?.average_score_round1)}</td>
                    </tr>
                  );
                })}
            </tbody>
          )}
        </table>
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup?.headers.map((column) => {
                  return (
                    <th
                      key={column.id}
                      // style={{
                      //   minWidth:
                      //     column.Header === "Action" || column.Header === "Entry Category" || column.Header === "Entrant Name" || column.Header === "Entry Name" || column.Header === "Category"
                      //       ? 200
                      //       : null,
                      //   width: column.Header === "Action" || column.Header === "View Details" ? 150 : null,
                      // }}
                    >
                      <div className="mb-2" {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page?.map((row, index) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell, i) => {
                      return (
                        <>
                          {/* {cell.column.Header === "#" ? 
                          <td key={cell.id} {...cell.getCellProps()}>
                            {index + 1}
                          </td>: */}
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                          {/* } */}
                        </>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {!pagination && data.length > 10 && (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

export default TableContainer;
