import * as moment from "moment";
import React from "react";
import { Card, Col, Row } from "reactstrap";

const DetailsCard = (props) => {
  return (
    <>
      <Row>
        <Col xl="12">
          <Card className="overflow-hidden">
            <span
              style={{
                color: props.entryDetails?.entry_basic_detail?.is_entry_rejected
                  ? "#f46a6a"
                  : "#f1b44c",
                position: "absolute",
                top: "5px",
                right: "10px",
                fontSize: "13px",
                cursor: props.entryDetails?.entry_basic_detail
                  ?.is_entry_rejected
                  ? "pointer"
                  : "none",
                zIndex: props.entryDetails?.entry_basic_detail
                  ?.is_entry_rejected
                  ? "1"
                  : "0",
              }}
              onClick={() => {
                props.toggleShowModal();
                props.setIsReson(
                  props.entryDetails?.entry_basic_detail?.entry_reject_reason
                );
              }}
            >
              {" "}
              {props.entryDetails?.entry_basic_detail?.is_entry_rejected &&
                "Rejected (Click here to show reason)"}
            </span>
            <div
              className={
                "font-size-12 badge-soft-" +
                (props.entryDetails?.entry_basic_detail?.is_entry_rejected
                  ? "danger"
                  : "primary")
              }
            >
              <Row>
                <Col xs="10">
                  <div className="text-primary p-3">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {props.getType !== "Jury" ? (
                          <>
                            {" "}
                            <h2
                              style={{
                                color: props.entryDetails?.entry_basic_detail
                                  ?.is_entry_rejected
                                  ? "#f46a6a"
                                  : "#f1b44c",
                              }}
                              className={
                                props.entryDetails?.entry_basic_detail
                                  ?.is_entry_rejected
                                  ? "danger"
                                  : "success"
                              }
                            >
                              {
                                props.entryDetails?.entry_basic_detail
                                  ?.entry_group?.name
                              }
                            </h2>
                            <h3
                              style={{
                                color: props.entryDetails?.entry_basic_detail
                                  ?.is_entry_rejected
                                  ? "#f46a6a"
                                  : "#f1b44c",
                              }}
                              className={
                                props.entryDetails?.entry_basic_detail
                                  ?.is_entry_rejected
                                  ? "danger"
                                  : "success"
                              }
                            >
                              {
                                props.entryDetails?.entry_basic_detail
                                  ?.entry_subgroup?.name
                              }
                            </h3>
                            <h4
                              style={{
                                color: props.entryDetails?.entry_basic_detail
                                  ?.is_entry_rejected
                                  ? "#f46a6a"
                                  : "#f1b44c",
                              }}
                              className={
                                props.entryDetails?.entry_basic_detail
                                  ?.is_entry_rejected
                                  ? "danger"
                                  : "success"
                              }
                            >
                              {
                                props.entryDetails?.entry_basic_detail
                                  ?.entry_category?.category_name
                              }
                            </h4>
                          </>
                        ) : (
                          <>
                            <h3
                              style={{
                                color: "#f1b44c",
                              }}
                            >
                              {`Category Name :${props.entryDetails?.entry_basic_detail?.entry_category?.category_name}`}
                            </h3>
                            <div style={{ display: "flex" }}>
                              <h4
                                style={{
                                  color: "#f1b44c",
                                  marginRight: "15px",
                                }}
                              >
                                Campaign Start Date:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {moment(
                                    props?.entryDetails?.entry_basic_detail
                                      ?.campaign_start_date
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </h4>

                              <h4
                                style={{
                                  color: "#f1b44c",
                                  marginRight: "15px",
                                }}
                              >
                                Campaign End Date:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {moment(
                                    props?.entryDetails?.entry_basic_detail
                                      ?.campaign_End_Date
                                  ).format("DD/MM/YYYY")}
                                </span>
                              </h4>
                            </div>
                          </>
                        )}
                        <div style={{ display: "flex" }}>
                          <h4
                            style={{
                              color: props.entryDetails?.entry_basic_detail
                                ?.is_entry_rejected
                                ? "#f46a6a"
                                : "#f1b44c",
                              marginRight: "15px",
                            }}
                            className={
                              props.entryDetails?.entry_basic_detail
                                ?.is_entry_rejected
                                ? "danger"
                                : "success"
                            }
                          >
                            Entry Name:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {
                                props.entryDetails?.entry_basic_detail
                                  ?.entry_name
                              }
                            </span>
                          </h4>

                          <h4
                            style={{
                              color: props.entryDetails?.entry_basic_detail
                                ?.is_entry_rejected
                                ? "#f46a6a"
                                : "#f1b44c",
                              marginRight: "15px",
                            }}
                            className={
                              props.entryDetails?.entry_basic_detail
                                ?.is_entry_rejected
                                ? "danger"
                                : "success"
                              //  (cell.value === 'Approved' ? 'success' cell.value === "Reject" ? "denger" )
                            }
                          >
                            Entry Code:
                            <span style={{ fontWeight: "bold" }}>
                              {
                                props.entryDetails?.entry_basic_detail
                                  ?.entry_code
                              }
                            </span>
                          </h4>
                        </div>
                        {props.getType == "jury" && (
                          <>
                            {props.note && (
                              <h3
                                style={{ color: "#fff", fontWeight: "bold" }}
                                className="text-primary"
                              >
                                {props.note}{" "}
                              </h3>
                            )}

                            <h4
                              style={{ fontWeight: "bold" }}
                              className="text-primary"
                            >
                              Round {props?.round}
                            </h4>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs="1" className="align-self-start">
                  {props.getType !== "Jury" ? (
                    <i
                      className="bx bx-news  display-2"
                      style={{
                        marginTop: "15px",
                        color: `${
                          props.entryDetails?.entry_basic_detail
                            ?.is_entry_rejected
                            ? "Reject"
                            : "success"
                        }`,
                      }}
                    />
                  ) : (
                    props.entryDetails?.entry_basic_detail?.score_round1 !==
                      null && (
                      <div
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <>
                            {" "}
                            <span
                              style={{
                                color: "#f1b44c",
                                fontWeight: "500",
                                fontSize: "80px",
                                lineHeight: "81px",
                                textAlign: "center",
                              }}
                            >
                              {
                                props.entryDetails?.entry_basic_detail
                                  ?.score_round1
                              }
                            </span>
                            <span
                              style={{
                                color: "#f1b44c",
                                fontSize: "16px",
                                lineHeight: "18px",
                                fontWeight: "500",
                                textAlign: "center",
                              }}
                            >
                              Score
                            </span>
                          </>
                        </div>
                      </div>
                    )
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DetailsCard;
