import TableContainer from "@components/Common/TableContainer";
import { FetchData } from "@hooks/api/useApi";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AssignJurryModel from "../../../components/Common/AssignJurryModel";
import JurydetailsModel from "../../../components/Common/JurydetailsModel";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import { InputField } from "../../../components/Form/Input";
import SelectInput from "../../../components/Form/SelectInput";
import { ID } from "../../Admin/Jury/EditableCol";
import WelcomeAudit from "./WelecomeAudit";

const roundOptions = [
  {
    options: [
      { label: "1", value: "1" },
      { label: "2", value: "2" },
    ],
  },
];

function AssignJury() {
  const [optionGroup, setOptionGroup] = useState();
  const [optionCategory, setOptionCategory] = useState();
  const [optionJurry, setOptionJurry] = useState();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addEntry, setAddEntry] = useState([]);
  const [selectedRounds, setselectedRounds] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [juryDetails, setJuryDetails] = useState(null);
  const [juryData, setJuryData] = useState(null);
  const [tableDataError, setTableDataError] = useState("");
  const [categoryDataError, setCategroyDataError] = useState("");
  const [roundDataError, setRoundDataError] = useState("");
  const [entryCodeDataError, setentryCodeDataError] = useState("");
  const [juryDataError, setJuryDataError] = useState("");
  const [modalAward, setModalAward] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [isAward, setIsAward] = useState(null);
  const [optionAwards, setOptionAwards] = useState(null);
  const [avgScore, setAvgScore] = useState(false);
  const history = useHistory();
  const location = useLocation();

  let awardName = location?.search?.slice(12);
  let awardId = location?.search?.slice(7, 9);
  let awardRound = location?.search?.slice(10, 11);

  const groupAction = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.group_name;
          obj["value"] = item.group_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionGroup(getList);
        }
      });
    },
  };

  const categoryActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);

          setOptionCategory(optionList);
        }
      });
    },
  };

  const { data: groupData } = FetchData(
    "group-details",
    "/Admin/get-all-group",
    groupAction
  );

  const { data: categoryData } = FetchData(
    "category",
    "/Admin/get-all-category",
    categoryActions
  );

  const actionsjuryList = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data?.map((item) => {
        let obj = {};
        obj["label"] = item.jury_Name;
        obj["value"] = item.id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setOptionJurry(getList);
      });
    },
  };

  const { data: juryList, isFetching: juryFetching } = FetchData(
    "jurylist",
    "Jury/get-all-jury-detail",
    actionsjuryList
  );

  const awardsActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.event_list.map((item) => {
        // console.log("newObj", item);
        let obj = {};
        if (item.event_status === "Published") {
          obj["label"] = item.event_name;
          obj["value"] = item.event_id;
          obj["round"] = item.round;
          optionList.push({...item, ...obj});
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionAwards(getList);
        }
      });
    },
  };

  const {
    data: eventsList,
    isFetching,
    isLoading,
    isFetched,
  } = FetchData("events", "/Admin/get-all-event", awardsActions);

  const juryColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Jury Member",
        accessor: "jury_member",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },

      {
        Header: "Jury score",
        accessor: "score",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.code}</p>;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD MMM YYYY");
        },
      },

      {
        Header: "Entrant organization",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.entrant_name}</p>;
        },
      },

      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        // Cell: (cellProps) => {
        //   return <p>{cellProps.row.original?.entry_Detail?.name}</p>;
        // },
        Cell: (cellProps) => {
          return (
            <p
              style={{
                color: `${
                  cellProps.row.original.is_entry_rejected ? "red" : "#CC962B"
                }`,
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: "/user-entry-details",
                  search: `id=${cellProps.row.original?.entry_Detail?.id}`,
                });
              }}
            >
              {cellProps.row.original?.entry_Detail?.name}
            </p>
          );
        },
      },
      {
        Header: "group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.group_detail?.group_name}</p>;
        },
      },
      {
        Header: "Subgroup",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>{cellProps.row.original?.subgroup_detail?.subgroup_name}</p>
          );
        },
      },

      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>{cellProps.row.original?.category_detail?.category_name}</p>
          );
        },
      },

      {
        Header: "Jury Assigned",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <>{cellProps.row.original.jury_member_round1?.length}</>;
        },
      },

      {
        Header: "Jury Scored",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              {
                cellProps.row.original.jury_member_round1?.filter(
                  (item) => item.score !== null
                )?.length
              }
            </>
          );
        },
      },
      // {
      //   Header: "Average score",
      //   accessor: "average_score",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <ID {...cellProps} />;
      //   },
      // },
      {
        Header: "Action",
        accessor: "view",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() =>
                  toggle(
                    cellProps.row?.original?.jury_member_round1,
                    cellProps.row?.original,
                    setAvgScore(false)
                  )
                }
                disabled={
                  cellProps.row?.original?.jury_member_round1?.length === 0
                    ? true
                    : false
                }
              >
                View Details
              </Button>
            </>
          );
        },
      },
    ],
    []
  );
  const roundTwoColumns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.code}</p>;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD MMM YYYY");
        },
      },

      {
        Header: "Entrant organization",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.entrant_name}</p>;
        },
      },

      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.entry_Detail?.name}</p>;
        },
      },
      {
        Header: "group",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.row.original?.group_detail?.group_name}</p>;
        },
      },
      {
        Header: "Subgroup",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>{cellProps.row.original?.subgroup_detail?.subgroup_name}</p>
          );
        },
      },

      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>{cellProps.row.original?.category_detail?.category_name}</p>
          );
        },
      },

      {
        Header: "Jury Assigned",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <>{cellProps.row.original.jury_member_round2?.length}</>;
        },
      },

      {
        Header: "Jury Scored",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <>
              {
                cellProps.row.original.jury_member_round2?.filter(
                  (item) => item.score !== null
                )?.length
              }
            </>
          );
        },
      },
      // {
      //   Header: "Average score",
      //   accessor: "average_score",
      //   filterable: true,
      //   disableFilters: true,
      //   Cell: (cellProps) => {
      //     return <ID {...cellProps} />;
      //   },
      // },
      {
        Header: "Action",
        accessor: "view",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() =>
                  toggle(
                    cellProps.row?.original?.jury_member_round2,
                    cellProps.row?.original,
                    setAvgScore(true)
                  )
                }
                disabled={
                  cellProps.row?.original?.jury_member_round2?.length === 0
                    ? true
                    : false
                }
              >
                View Details
              </Button>
            </>
          );
        },
      },
    ],
    []
  );
  const formik = useFormik({
    initialValues: {
      awards: "",
      category: "",
      juryMember: "",
      entryCode: "",
      jury: "",
      entries: "",
    },

    onSubmit: (values) => {},
  });

  const handleCheckTerms = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("isAcceptingTerms", e.target.checked);
    } else {
      formik.setFieldValue("isAcceptingTerms", e.target.checked);
    }
  };

  const handleSameContact = (e) => {
    if (e.target.value === "yes") {
      formik.setFieldValue("isPrimarySame", true);
    } else {
      formik.setFieldValue("isPrimarySame", false);
    }
  };
  const handleOfficialAd = (e) => {
    if (e.target.value === "yes") {
      formik.setFieldValue("isOfficialAd", true);
    } else {
      formik.setFieldValue("isOfficialAd", false);
    }
  };

  function handleRounds(e) {
    setselectedRounds(e);
  }

  function handleSelectMember(e) {
    setSelectedMember(e);
  }

  function handleSelectGroup(item) {
    setSelectedGroup(item);
  }

  function handleSelectCategory(item) {
    setSelectedCategory(item);
  }

  const [allEntries, setAllEntries] = useState([]);
  const actions = {
    enabled: false,
    select: (data) => {
      const entryList = data.data.responseData;
      return entryList;
    },
    onSuccess: (data) => {
      if (data) {
        setAllEntries(
          awardRound == 1 || selectedAward?.round == 1
            ? data
            : data &&
                data?.filter(
                  (item) =>
                    item?.round === 2 && item?.jury_member_round2?.length > 0
                )
        );
      }
    },
  };

  const {
    data,
    isFetching: EntriyFetching,
    refetch,
  } = FetchData(
    "all-entries",
    `Auditor/get-all-jury-allocation-auditor?event_id=${
      selectedAward?.value || awardId
    }`,
    actions
  );

  const {
    data: getAllEntries,
    isFetching: getAllEntryFetching,
    refetch: getEntriesRefetch,
  } = FetchData(
    "entries",
    `Auditor/get-all-jury-allocation-auditor?event_id=${
      selectedAward?.value || awardId
    }&update_data=true`,
    actions
  );

  const handleRefetch = () => {
    getEntriesRefetch();
  };

  const [modal, setModal] = useState(false);
  const toggle = (item, data) => {
    if (modal) {
      setModal(false);
      setJuryDetails(null);
    } else {
      setModal(true);
      setJuryDetails(item);
      setJuryData(data);
    }
  };

  const [listEntries, setListEntries] = useState(null);
  const [groupList, setGroupList] = useState(null);
  const [subGroupList, setSubGroupList] = useState(null);
  const [catList, setCatList] = useState(null);
  let list_of_entries = allEntries;
  useEffect(() => {
    if (selectedGroup) {
      let groupData = list_of_entries?.filter(
        (item) => item?.group_detail?.group_name === selectedGroup?.label
      );
      if (groupData?.length === 0) {
        groupData = list_of_entries;
        setTableDataError("Data not found");
      } else {
        setTableDataError("");
      }

      list_of_entries = groupData;
      setListEntries(groupData);
    }
    if (selectedCategory) {
      let categoryData = list_of_entries?.filter(
        (item) =>
          item?.category_detail?.category_name === selectedCategory?.label
      );
      if (categoryData?.length === 0) {
        categoryData = list_of_entries;
        setCategroyDataError("Data not found");
      } else {
        setCategroyDataError("");
      }

      list_of_entries = categoryData;
      setListEntries(categoryData);
    }

    // if (selectedRounds) {
    //   let roundData = list_of_entries?.filter(
    //     (item) => item?.round === Number(selectedRounds?.label)
    //   );

    //   if (roundData?.length === 0) {
    //     roundData = list_of_entries;
    //     setRoundDataError("Data not found");
    //   } else {
    //     setRoundDataError("");
    //   }

    //   list_of_entries = roundData;
    //   setListEntries(roundData);
    // }
    if (selectedMember) {
      let juryarray = list_of_entries.filter((eachVal) => {
        let opt =
          awardRound == 2
            ? eachVal.jury_member_round2?.some(
                (juryName) => juryName?.jury_member === selectedMember.label
              )
            : eachVal.jury_member_round1?.some(
                (juryName) => juryName?.jury_member === selectedMember.label
              );

        return opt;
      });
      if (juryarray?.length === 0) {
        juryarray = list_of_entries;
        setJuryDataError("Data Not Found");
      } else {
        setJuryDataError("");
      }
      list_of_entries = juryarray;
      setListEntries(juryarray);
    }
    if (formik.values.entryCode?.length >= 4) {
      let entryCodeData = list_of_entries?.filter(
        (item) =>
          item?.entry_Detail?.code === formik.values.entryCode.toString()
      );

      if (entryCodeData?.length == 0) {
        entryCodeData = list_of_entries;
      } else {
        setentryCodeDataError("");
      }
      list_of_entries = entryCodeData;
      setListEntries(entryCodeData);
    }
  }, [
    list_of_entries,
    selectedGroup,
    selectedMember,
    selectedCategory,
    formik.values.entryCode,
  ]);

  const handleClearAll = () => {
    setSelectedGroup(null);
    setSelectedMember(null);
    setSelectedCategory(null);
    formik.setFieldValue("entryCode", "");
    setJuryDataError("");
    setTableDataError("");
    setCategroyDataError("");
  };

  function handleSelectAward(item) {
    setSelectedAward(item);
    setIsAward(item.label);

    // console.log("optionAwards", optionAwards);
    let filterGroupList = optionAwards[0]?.options.filter((eachVal) => {
      return eachVal.value === item.value;
    });

    let group = [];
    filterGroupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.groupName;
      obj["value"] = item.groupId;
      group.push(obj);
      const arrayUniqueByKey = [
        ...new Map(group.map((item) => [item["label"], item])).values(),
      ];
      setGroupList(arrayUniqueByKey);
    });

    // let subgroup = [];
    // filterGroupList[0]?.event_categorydata.map((item) => {
    //   let obj = {};
    //   obj["label"] = item.subGroupName;
    //   obj["value"] = item.subGroupId;
    //   subgroup.push(obj);
    //   const arrayUniqueByKeysubGroup = [
    //     ...new Map(subgroup.map((item) => [item["label"], item])).values(),
    //   ];
    //   setSubGroupList(arrayUniqueByKeysubGroup);
    // });

    let categories = [];
    filterGroupList[0]?.event_categorydata.map((item) => {
      let obj = {};
      obj["label"] = item.categoryName;
      obj["value"] = item.categoryId;
      categories.push(obj);
      const arrayUniqueByKeyCatList = [
        ...new Map(categories.map((item) => [item["label"], item])).values(),
      ];
      setCatList(arrayUniqueByKeyCatList);
    });
  }

  const toggleViewModal = () => {
    setModalAward(!modalAward);
  };

  const handleAward = () => {
    if (selectedAward) {
      setModalAward(false);
      refetch();
      history.push({
        pathname: "/",
        search: `award=${selectedAward?.value}:${selectedAward?.round}:${selectedAward?.label}`,
      });
    }
  };

  useEffect(() => {
    setIsAward(awardName.replaceAll("%20", " "));
    if (!awardName) {
      setModalAward(!modalAward);
      setSelectedAward(null);
    }
  }, [awardName]);

  useEffect(() => {
    if (awardId) {
      refetch();
    }
  }, [awardId]);

  // const roundtwoData =
  //   allEntries && allEntries?.filter((item) => item?.round === 2);
  document.title = "Dashboard | WOW Awards";

  console.log("group", groupList, catList);

  return (
    <React.Fragment>
      <JurydetailsModel
        toggle={toggle}
        modal={modal}
        juryColumns={juryColumns}
        data={juryDetails}
        details={juryData}
        avgScore={avgScore}
      />
      <AssignJurryModel
        isOpen={modalAward}
        toggle={toggleViewModal}
        handleSelectAward={handleSelectAward}
        optionAwards={optionAwards}
        handleAward={handleAward}
        selectedAward={selectedAward}
        modal={modalAward}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Jury" breadcrumbItem="Assign" /> */}
          <Row>
            <Col xl="12">
              <WelcomeAudit />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="d-flex align-items-center mb-1">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-sitemap text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">Allocation</h3>
                    </div>
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        onClick={handleRefetch}
                        style={{ marginRight: "10px" }}
                      >
                        Refetch
                      </Button>
                      <Button
                        outline
                        type="submit"
                        color="primary"
                        className="btn "
                        onClick={handleClearAll}
                      >
                        Clear all
                      </Button>
                    </div>
                  </div>
                  <div>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <SelectInput
                            label={"Select Group:"}
                            type={"select"}
                            onChange={(e) => {
                              handleSelectGroup(e);
                            }}
                            options={groupList}
                            classNamePrefix="select2-selection"
                            value={selectedGroup}
                          />
                          {tableDataError && (
                            <span
                              style={{
                                color: "#f46a6a",
                                fontSize: "12px",
                              }}
                            >
                              {tableDataError}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <SelectInput
                            label={"Select Category:"}
                            type={"select"}
                            onChange={(e) => {
                              handleSelectCategory(e);
                            }}
                            options={catList}
                            classNamePrefix="select2-selection"
                            value={selectedCategory}
                          />
                          {categoryDataError && (
                            <span
                              style={{
                                color: "#f46a6a",
                                fontSize: "12px",
                              }}
                            >
                              {categoryDataError}
                            </span>
                          )}
                        </div>
                      </Col>
                      {/* <Col sm="6">
                        <div className="mb-3">
                          <SelectInput
                            label={"Select Active Round:"}
                            type={"select"}
                            onChange={(e) => {
                              handleRounds(e);
                            }}
                            options={roundOptions}
                            classNamePrefix="select2-selection"
                            value={selectedRounds || ""}
                          />
                        </div>
                      </Col> */}
                      <Col sm="6">
                        <div className="mb-3">
                          <SelectInput
                            label={" Search By Jury member:"}
                            type={"select"}
                            onChange={(e) => {
                              handleSelectMember(e);
                            }}
                            value={selectedMember || ""}
                            name="juryMember"
                            options={optionJurry}
                            classNamePrefix="select2-selection"
                          />
                          {juryDataError && (
                            <span
                              style={{
                                color: "#f46a6a",
                                fontSize: "12px",
                              }}
                            >
                              {juryDataError}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col xl="6">
                        <div className="mb-3">
                          <InputField
                            label={"Enter Entry Code"}
                            type={"text"}
                            name="entryCode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleChange}
                            value={formik.values.entryCode || ""}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
              {/* <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <SelectInput
                            label={"Select Category:"}
                            type={"select"}
                            onChange={(e) => {
                              handleSelectCategory(e);
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                            value={selectedGroup}
                          />
                          {tableDataError && (
                            <span
                              style={{
                                color: "#f46a6a",
                                fontSize: "12px",
                              }}
                            >
                              {tableDataError}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <SelectInput
                            label={"Select Active Round:"}
                            type={"select"}
                            onChange={(e) => {
                              handleRounds(e);
                            }}
                            options={roundOptions}
                            classNamePrefix="select2-selection"
                            // value={selectedGroup}
                          />
                        </div>
                      </Col>
                    </Row>
                 
                  </Form>
                </CardBody>
              </Card> */}
              {/* {allEntries?.length > 0 && ( */}
              {allEntries && (selectedAward?.round == 1 || awardRound == 1) && (
                <Card>
                  <CardBody>
                    <CardTitle>Round 1 Entries</CardTitle>
                    <div>
                      {allEntries &&
                      selectedGroup === null &&
                      selectedMember === null &&
                      selectedCategory === null &&
                      formik.values.entryCode === "" ? (
                        <TableContainer
                          columns={columns}
                          data={allEntries}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          download
                          reportData={allEntries}
                        />
                      ) : null}
                    </div>
                    <div>
                      {listEntries &&
                        (selectedGroup !== null ||
                          selectedMember !== null ||
                          selectedCategory !== null ||
                          formik.values.entryCode !== "") && (
                          <TableContainer
                            columns={columns}
                            data={listEntries}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            download
                            reportData={listEntries}
                          />
                        )}
                    </div>
                  </CardBody>
                </Card>
              )}
              {allEntries && (selectedAward?.round == 2 || awardRound == 2) && (
                <Card>
                  <CardBody>
                    <CardTitle>Round 2 Entries</CardTitle>
                    <div>
                      {allEntries &&
                      selectedGroup === null &&
                      selectedMember === null &&
                      selectedCategory === null &&
                      formik.values.entryCode === "" ? (
                        <TableContainer
                          columns={roundTwoColumns}
                          data={allEntries}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          download
                          reportData={allEntries}
                          secoundRound
                        />
                      ) : null}
                    </div>
                    <div>
                      {listEntries &&
                        (selectedGroup !== null ||
                          selectedMember !== null ||
                          selectedCategory !== null ||
                          formik.values.entryCode !== "") && (
                          <TableContainer
                            columns={roundTwoColumns}
                            data={listEntries}
                            isGlobalFilter={true}
                            isAddOptions={false}
                            customPageSize={10}
                            download
                            reportData={listEntries}
                            secoundRound
                          />
                        )}
                    </div>
                  </CardBody>
                </Card>
              )}
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </div>
      {(EntriyFetching || getAllEntryFetching || juryFetching) && (
        <ScreenLoader />
      )}
    </React.Fragment>
  );
}

export default AssignJury;
