import React from 'react';
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import FormHeader from '../Common/FormHeader';
import ScreenLoader from '../Common/ScreenLoader';
import { InputField } from '../Form/Input';
import SelectInput from '../Form/SelectInput';

const AddJuryPage = (props) => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={'bx-add-to-queue'}
                    heading={'Add Jury'}
                  />

                  <Form className="mt-4">
            <Row>
              <Col sm="12">
              <div className="mb-3">
                          <SelectInput
                            label={'Region'}
                            name={'region'}
                            type={'select'}
                            onChange={(e) => {
                              props.handleSelectRegion(e);
                            }}
                            options={props.optionsList}
                            onBlur={props.formik.handleBlur}
                            invalid={
                              props.formik.touched.region &&
                              props.formik.errors.region
                                ? true
                                : false
                            }
                            formik={props.formik}
                          />
                         
                        </div>

              </Col>
              <Col sm="6">
              <div className="mb-3">

              <Label>Select Awards</Label>
            <Select
              onChange={(e) => {
                props.handleSelectAward(e);
              }}
              options={props.optionAwards}
              classNamePrefix="select2-selection"
            />
          </div>
              </Col>
              <Col sm="6">
              <div className='mb-3'>
              <SelectInput
              isMulti={true}
                            label={'Group'}
                            name={'group'}
                            type={'select'}
                            onChange={(e) => {
                              props.handleSelectGroup(e);
                            }}
                            options={props.optionGroup?.reverse()}
                            onBlur={props.formik.handleBlur}
                            invalid={
                              props.formik.touched.group &&
                              props.formik.errors.group
                                ? true
                                : false
                            }
                            formik={props.formik}
                          />
              </div>
             
              </Col>
            </Row>
                        
                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <InputField
                                label={'FIRST NAME*'}
                                id={'fistName'}
                                name={'firstName'}
                                type={'text'}
                                className={'form-control'}
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.firstName || ''}
                                invalid={
                                  props.formik.touched.firstName &&
                                  props.formik.errors.firstName
                                    ? true
                                    : false
                                }
                                // placeholder={"Enter Your Client Name"}
                                validation={props.formik}
                              />
                              {/* <Label htmlFor="fistName">FIRST NAME</Label>
                              <Input
                                id="fistName"
                                name="firstName"
                                type="text"
                                className="form-control"
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.firstName || ""}
                                invalid={
                                  props.formik.touched.firstName &&
                                  props.formik.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {props.formik.touched.firstName &&
                              props.formik.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  {props.formik.errors.firstName}
                                </FormFeedback>
                              ) : null} */}
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <InputField
                                label={'LAST NAME*'}
                                id={'lastName'}
                                name={'lastName'}
                                type={'text'}
                                className={'form-control'}
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.lastName || ''}
                                invalid={
                                  props.formik.touched.lastName &&
                                  props.formik.errors.lastName
                                    ? true
                                    : false
                                }
                                // placeholder={"Enter Your Client Name"}
                                validation={props.formik}
                              />
                              {/* <Label htmlFor="lastName">LAST NAME</Label>
                              <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                className="form-control"
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.lastName || ""}
                                invalid={
                                  props.formik.touched.lastName &&
                                  props.formik.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {props.formik.touched.lastName &&
                              props.formik.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  {props.formik.errors.lastName}
                                </FormFeedback>
                              ) : null} */}
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <InputField
                                label={'USERNAME (EMAIL ID)*'}
                                id={'user'}
                                name={'userName'}
                                type={'email'}
                                className={'form-control'}
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.userName || ''}
                                invalid={
                                  props.formik.touched.userName &&
                                  props.formik.errors.userName
                                    ? true
                                    : false
                                }
                                validation={props.formik}
                              />
                              {/* <Label htmlFor="user">USERNAME (EMAIL ID)</Label>
                              <Input
                                id="user"
                                name="userName"
                                type="email"
                                className="form-control"
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.userName || ""}
                                invalid={
                                  props.formik.touched.userName &&
                                  props.formik.errors.userName
                                    ? true
                                    : false
                                }
                              />
                              {props.formik.touched.userName &&
                              props.formik.errors.userName ? (
                                <FormFeedback type="invalid">
                                  {props.formik.errors.userName}
                                </FormFeedback>
                              ) : null} */}
                            </div>{' '}
                          </Col>
                          <Col sm="6">
                            {' '}
                            <div className="mb-3">
                              {/* <InputField
                                label={"PASSWORD"}
                                id={"password"}
                                name={"password"}
                                type={"text"}
                                className={"form-control"}
                                onChange={props.formik.handleChange}
                                onBlur={props.formik.handleBlur}
                                value={props.formik.values.password || ""}
                                invalid={
                                  props.formik.touched.password &&
                                  props.formik.errors.password
                                    ? true
                                    : false
                                }
                                validation={props.formik}
                              /> */}

                              <Label htmlFor="password">PASSWORD</Label>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <>
                                  <Col sm="9">
                                    <Input
                                      id="password"
                                      name="password"
                                      type="text"
                                      className="form-control"
                                      onChange={props.formik.handleChange}
                                      onBlur={props.formik.handleBlur}
                                      value={props.formik.values.password || ''}
                                      invalid={
                                        props.formik.touched.password &&
                                        props.formik.errors.password
                                          ? true
                                          : false
                                      }
                                    />
                                  </Col>
                                  {/* <Col sm="2"> */}
                                  <Button
                                    // type="submit"
                                    color="primary"
                                    className="gentrate-btn"
                                    // disabled={props.isLoading}
                                    onClick={props.generatePass}
                                  >
                                    {/* {props.isLoading && (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> */}
                                    {/* )} */}
                                    Generate Pass
                                  </Button>
                                  {/* </Col> */}
                                </>
                              </div>
                              {props.formik.touched.password &&
                              props.formik.errors.password ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ display: 'flex' }}
                                >
                                  {props.formik.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>{' '}
                          </Col>
                        </Row>
                     
                    <div
                      className="d-flex flex-wrap gap-2 mt-4"
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        className="btn "
                        disabled={props.isLoading}
                        onClick={(e) => {
                          e.preventDefault();
                          props.formik.handleSubmit(e);
                          return false;
                        }}
                      >
                        {props.isLoading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        )}
                        Register
                      </Button>
                    </div>
                  </Form>
                  {props.addJuryError ? (
                    <div
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        marginTop: '10px',
                      }}
                    >
                      {props.addJuryError}
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {props.isLoadingRegion && <ScreenLoader />}
      </div>
    </>
  );
};

export default AddJuryPage;
