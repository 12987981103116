import axios from "axios";
import { getFromStorage } from "./storage";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${getFromStorage("token")}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response.status === 401) {
      localStorage.clear();
    }
    // const originalRequest = err.config;
    //   if (err.response.status === 401 && !originalRequest._retry) {
    //     originalRequest._retry = true;
    //         try {
    //           const rs = await axios.post("Authentication/refresh-token", {
    //             token: JSON.parse(localStorage.getItem("refreshToken")),
    //           });
    //           localStorage.setItem("token", JSON.stringify(rs.data.responseData.token));
    //           localStorage.setItem("refreshToken", JSON.stringify(rs.data.responseData.refresh_token));
    //           axios.defaults.headers.common['Authorization'] = `Bearer ${rs.data.responseData.token}`
    //           return axios(originalRequest);
    //         } catch (_error) {
    //           if (_error.response && _error.response.data) {
    //             return Promise.reject(_error.response.data);
    //           }
    //         }
    //   }
    return Promise.reject(err);
  }
);

export default axios;

// const refreshToken = new Promise(async function(resolve, reject) {
//         try {
//               const authData = JSON.parse(localStorage.getItem("refreshToken"));
//               const payload = {
//                 token: authData,
//               };

//               let apiResponse = await axios.post(
//                 "Authentication/refresh-token",
//                 payload
//               );
//               localStorage.setItem("token", JSON.stringify(apiResponse.data.responseData.token));
//               localStorage.setItem("refreshToken", JSON.stringify(apiResponse.data.responseData.refresh_token));
//               axios.defaults.headers.common['Authorization'] = `Bearer ${apiResponse.data.responseData.token}`
//               resolve('pass')
//         } catch (error) {
//               reject('fail')
//         }
//           });

// function refreshTokenPromise(){
//   return new Promise(async (resolve, reject)=>{
//     try {
//       const authData = JSON.parse(localStorage.getItem("refreshToken"));
//       const payload = {
//         token: authData,
//       };

//       let apiResponse = await axios.post(
//         "Authentication/refresh-token",
//         payload
//       );
//       localStorage.setItem("token", JSON.stringify(apiResponse.data.responseData.token));
//       localStorage.setItem("refreshToken", JSON.stringify(apiResponse.data.responseData.refresh_token));
//       axios.defaults.headers.common['Authorization'] = `Bearer ${apiResponse.data.responseData.token}`
//       resolve('pass')
//     } catch (error) {
//           reject()
//     }
//       })
// }
