import React from 'react';
import { Card, CardBody, CardTitle, Table } from 'reactstrap';

const ExecutiveCard = (props) => {
  return (
    <>
      <Card className="overflow-hidden">
        <CardBody>
          <CardTitle className="mb-4">{props.title}</CardTitle>
          <div className="table-responsive">
            <Table className="table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">Name :</th>
                  <td>
                    {props.salution} {''}
                    {props.firstName}
                    {''} {props.lastname}
                  </td>
                </tr>
                <tr>
                  <th scope="row">E-mail :</th>
                  <td>{props.email}</td>
                </tr>
                <tr>
                  <th scope="row">Designation :</th>
                  <td>{props.designation}</td>
                </tr>
                <tr>
                  <th scope="row">Mobile :</th>
                  <td>{props.mobile}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ExecutiveCard;
