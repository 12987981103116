import React from "react";
import { Button, Spinner } from "reactstrap";

const FormHeader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        // alignItems: "center",
      }}
    >
      <div className="d-flex align-items-center mb-2">
        <div className="avatar-xs me-3 ">
          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
            <i className={`bx ${props.iconName} text-primary display-7`} />
          </span>
        </div>
        <h3 className="font-size-25 mb-0"> {props.heading} </h3>

        {props.pageLoading && (
          <span>
            <Spinner
              style={{
                height: "25px",
                width: "25px",
                marginLeft: "10px",
              }}
              color="primary"
            />
          </span>
        )}
      </div>

      <div>
        <span style={{ marginRight: "10px" }}>
          {props.resetButton && props.awardName && (
            <Button
              type="button"
              outline
              color="primary"
              // className="btn-sm "
              onClick={props.onResetClick}
            >
              {props.resetButton}
            </Button>
          )}
        </span>

        {props.hasButton && (
          <Button
            type="button"
            color="primary"
            // className="btn-sm "
            onClick={props.onButtonClick}
          >
            {props.buttonName}
          </Button>
        )}
        {props.hideButton && (
          <Button
            type="button"
            color="primary"
            // className="btn-sm "
            onClick={props.onHideClick}
            style={{ marginLeft: "10px" }}
          >
            {props.hideButton}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormHeader;
