import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const EcommerceOrdersModal = (props) => {
  const { isOpen, toggle, data } = props;

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Group Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Is Active:{' '}
            <span className="text-primary">
              {data?.is_active ? 'Published' : 'Draft'}
            </span>
          </p>
          <p className="mb-2">
            Title: <span className="text-primary">{data?.sub_group_name}</span>
          </p>
          <p className="mb-4">
            Description:{' '}
            <span className="text-primary">{data?.sub_group_detail}</span>
          </p>
          <p className="mb-2">
            Selected Group:{' '}
            <span className="text-primary">{data?.group?.name}</span>
          </p>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default EcommerceOrdersModal;
