import emptyLogo from "@assets/images/empty-logo.png";
import "@assets/scss/custom/pages/_profile.scss";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import { getFromStorage } from "@utils/storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import * as Yup from "yup";
import ScreenLoader from "../../components/Common/ScreenLoader";
import { InputField } from "../../components/Form/Input";

const Profile = () => {
  const [showEditeProfile, setShowEditeProfile] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [optionList, setOptionList] = useState();
  const [errorMsg, setErrorMSg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [group, setGroup] = useState(null);
  const [photoInput, setPhotoInput] = useState();
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const getUserEmail = getFromStorage("userEmail");
  const [errorMess, setErrorMess] = useState("");
  const Img_url = process.env.REACT_APP_IMG_URL


  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };
  const {
    data: juryList,
    refetch,
    isFetching,
    isLoading: loadinJury,
    isFetched,
  } = FetchData("jury", "Jury/get-jury-profile", actions);

  const actionsSubgroup = {
    select: (data) => {
      const list = data.data.responseData.filter(
        (eachVal) => eachVal.is_active === "Published"
      );
      return list;
    },
  };
  const { data: groupList, refetch: refetchGroup } = FetchData(
    "groups",
    "Admin/get-all-group",
    actionsSubgroup
  );

  useEffect(() => {
    let optionList = [];
    groupList?.map((item) => {
      let obj = {};
      obj["label"] = item.group_name;
      obj["value"] = item.group_id;
      optionList.push(obj);
      let getList = [];
      let newObj = {};
      newObj["options"] = optionList;
      getList.push(newObj);
      setOptionList(getList);
    });
  }, [groupList]);

  const handleEditeProfile = () => {
    setShowEditeProfile(true);
    setDeletePhoto(true);
    validation.setValues({
      firstname: juryList?.jury_name,
      designation: juryList?.designation,
      companyname: juryList?.company_name,
      description: juryList?.description,
    });
  };

  const handleGoBack = () => {
    setShowEditeProfile(false);
    setShowChangePassword(false);
  };

  const handleChangePasswordForm = () => {
    setShowChangePassword(true);
  };

  const handleBackButton = () => {
    setShowChangePassword(false);
  };

  const changePasswordActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setSuccessMsg(data.data.responseData);
        setErrorMSg("");
        Formik.resetForm();
      } else {
        setErrorMSg(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setSuccessMsg("");
      setErrorMSg("Something went wrong!");
    },
  };

  const { mutate, isLoading } = PostQuery(
    "Authentication/change-password",
    "post",
    changePasswordActions
  );
  // change password
  const Formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      oldpassword: "",
      newpassword: "",
      confimpassword: "",
    },
    validationSchema: Yup.object({
      oldpassword: Yup.string().required("Please Enter Your Email"),
      newpassword: Yup.string()
        .matches(
          passwordRegex,
          "password must be at least 8 characters with at least a symbol, upper and lower case letters and a number"
        )
        .required("Please Enter Your Password"),
      confimpassword: Yup.string()
        .required("Please Enter Your Confirm Password")
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      const getDetails = {
        user_name: getUserEmail,
        oldpassword: values.oldpassword,
        new_password: values.newpassword,
      };
      mutate(getDetails);
    },
  });

  // Edite form

  const handleChangePhoto = (e, i) => {
    const maxAllowedSize = 5 * 1024 * 1024;
    setPhotoInput(e.target.files[0]);
    if (e.target.files[0].size > maxAllowedSize) {
      e.target.value = "";
      setErrorMess("Your file is too big");
    }
  };

  const profileActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setSuccessMsg(data.data.responseData);
        setErrorMSg("");
        refetch();
        Formik.resetForm();
        setShowEditeProfile(false);
      } else {
        setErrorMSg(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setSuccessMsg("");
      setErrorMSg("Something went wrong!");
    },
  };

  const { mutate: addProfile, isLoading: loading } = PostQuery(
    "Jury/update-jury-profile",
    "post",
    profileActions
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstname: "",
      designation: "",
      companyname: "",
      description: "",
      // group: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter First Name").nullable(),
      designation: Yup.string().required("Please Enter Designation").nullable(),
      companyname: Yup.string()
        .required("Please Enter Company Name")
        .nullable(),
      description: Yup.string().required("Please Enter Description").nullable(),
      // group: Yup.string().required("Please Select Group"),
    }),
    onSubmit: (values) => {
      var formdataOne = new FormData();
      formdataOne.append("description", values.description);
      formdataOne.append("designation", values.designation);
      formdataOne.append("company_name", values.companyname);
      photoInput && formdataOne.append("Photo", photoInput ? photoInput : null);
      formdataOne.append("group_id", group?.value);
      addProfile(formdataOne);
    },
  });

  function handleUpdateGroup(item) {
    setGroup(item);
    validation.setFieldValue("group", item.value);
  }

  useEffect(() => {
    const getLabel =
      optionList &&
      optionList[0].options.find(
        (item) => item.value === juryList?.juryGroupDetail?.id
      );
    validation.setFieldValue("group", getLabel?.value);

    setGroup(getLabel);
  }, [juryList, optionList]);

  const [deletePhoto, setDeletePhoto] = useState(true);
  const onClickDelete = () => {
    setDeletePhoto(false);
    setPhotoInput(null);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="img-wrap">
                  {(deletePhoto || !showEditeProfile) &&
                    juryList?.photo_path ? (
                    <img
                      // src={`https://wowawards.digitalsalt.in/${juryList?.photo_path}`}
                      src={`${Img_url}${juryList?.photo_path}`}
                      alt=""
                      className="p-img"
                    />
                  ) : (
                    <img src={emptyLogo} alt="" />
                  )}

                  {showEditeProfile && deletePhoto && juryList?.photo_path && (
                    <Link
                      to="#"
                      className="text-danger photo-danger"
                      onClick={() => {
                        onClickDelete();
                      }}
                      style={{
                        position: "absolute",
                        top: "-4px",
                        right: "-5px",
                      }}
                    >
                      <i
                        className="mdi mdi-delete font-size-24 me-2"
                        id="deletetooltip"
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target="deletetooltip"
                      >
                        Delete
                      </UncontrolledTooltip>
                    </Link>
                  )}
                  {/* </label> */}
                </div>
                <div className="flex-grow-1 align-self-center ms-3">
                  <div className="text-muted">
                    <h5>{juryList?.jury_name}</h5>
                    <p className="mb-1">{juryList?.email}</p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>

          {showEditeProfile ? (
            <Card>
              <CardBody>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    color="primary"
                    className="btn m-2"
                    onClick={handleGoBack}
                  >
                    Go Back
                  </Button>
                </div>
                <Form>
                  <Row>
                    {(!deletePhoto || juryList?.photo_path === "") && (
                      <Col sm="6">
                        <div className="mb-3">
                          <Label>Add Photo</Label>
                          <Input
                            className="form-control"
                            name="photo"
                            type="file"
                            id="formFile"
                            onChange={(e) => handleChangePhoto(e)}
                            accept="image/x-png,image/gif,image/jpeg"
                            onBlur={validation.handleBlur}
                            data-max-size="5120"
                          />
                        </div>
                        {/* )} */}
                      </Col>
                    )}
                    <Col sm={deletePhoto && juryList?.photo_path ? "12" : "6"}>
                      <div className="mb-3">
                        <InputField
                          label={"Name"}
                          name={"firstname"}
                          type={"text"}
                          disabled={true}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          invalid={
                            validation.touched.firstname &&
                              validation.errors.firstname
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <div className="mb-3">
                        <InputField
                          label={"Designation"}
                          name={"designation"}
                          type={"text"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.designation || ""}
                          invalid={
                            validation.touched.designation &&
                              validation.errors.designation
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <InputField
                          label={"Company Name"}
                          name={"companyname"}
                          type={"text"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.companyname || ""}
                          invalid={
                            validation.touched.companyname &&
                              validation.errors.companyname
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                      </div>
                    </Col>
                    <Col sm="4">
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="updateSubGroup">
                          Select Group:
                        </Label>
                        <Select
                          name="group"
                          onChange={(e) => handleUpdateGroup(e)}
                          options={optionList}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.group && validation.errors.group
                              ? true
                              : false
                          }
                          type="select"
                          value={group || ""}
                        />
                        {validation.errors.group &&
                          validation.touched.group && (
                            <span
                              style={{
                                color: "#f46a6a",
                                fontSize: "10px",
                                display: "flex",
                              }}
                            >
                              {validation.errors.group}
                            </span>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <InputField
                        label={"Description "}
                        name={"description"}
                        type={"textarea"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.description || ""}
                        invalid={
                          validation.touched.description &&
                            validation.errors.description
                            ? true
                            : false
                        }
                        validation={validation}
                      />
                    </Col>
                  </Row>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      color="primary"
                      className="btn m-2"
                      type="submit"
                      // disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {loading && (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      )}
                      save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          ) : showChangePassword ? (
            <Card>
              <CardBody>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    color="primary"
                    className="btn m-2"
                    onClick={handleGoBack}
                  >
                    Go Back
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <CardTitle className="mb-4">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="bx bx-shield-quarter text-primary display-6" />
                        <h3 style={{ marginLeft: "10px" }}>Change Password</h3>
                      </div>
                    </CardTitle>
                  </div>
                </div>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    Formik.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label className="form-label">Enter Old Password</Label>
                    <Input
                      name="oldpassword"
                      className="form-control"
                      placeholder="Enter Old Password"
                      type="password"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.oldpassword || ""}
                      invalid={
                        Formik.touched.oldpassword && Formik.errors.oldpassword
                          ? true
                          : false
                      }
                    />
                    {Formik.touched.oldpassword && Formik.errors.oldpassword ? (
                      <FormFeedback type="invalid">
                        {Formik.errors.oldpassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Enter New Password</Label>
                    <Input
                      name="newpassword"
                      className="form-control"
                      placeholder="Enetr New Password"
                      type="password"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.newpassword || ""}
                      invalid={
                        Formik.touched.newpassword && Formik.errors.newpassword
                          ? true
                          : false
                      }
                    />
                    {Formik.touched.newpassword && Formik.errors.newpassword ? (
                      <FormFeedback type="invalid">
                        {Formik.errors.newpassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Confirm new Password </Label>
                    <Input
                      name="confimpassword"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      type="password"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.confimpassword || ""}
                      invalid={
                        Formik.touched.confimpassword &&
                          Formik.errors.confimpassword
                          ? true
                          : false
                      }
                    />
                    {Formik.touched.confimpassword &&
                      Formik.errors.confimpassword ? (
                      <FormFeedback type="invalid">
                        {Formik.errors.confimpassword}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      color="primary"
                      className="btn m-2"
                      type="submit"
                    // disabled={isLoading}
                    >
                      {/* {isLoading && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )} */}
                      save
                    </Button>
                  </div>
                </Form>

                {errorMsg ? (
                  <div
                    style={{
                      color: "red",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {errorMsg}
                  </div>
                ) : null}
                {successMsg ? (
                  <div
                    style={{
                      color: "green",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {successMsg}
                  </div>
                ) : null}
              </CardBody>
            </Card>
          ) : (
            <Card>
              <CardBody>
                {/* <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    color="primary"
                    className="btn m-2"
                    onClick={handleEditeProfile}
                  >
                    Edit Profile
                  </Button>
                  <Button
                    color="primary"
                    className="btn m-2"
                    onClick={handleChangePasswordForm}
                  >
                    Change password{" "}
                  </Button>
                </div> */}

                <div className="table-responsive">
                  <div>
                    <div
                      style={{
                        borderBottom: "1px solid #eff2f7",
                        padding: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          padding: "20px 0",
                          color: "#495057",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>Name: </span>
                        <span>{juryList?.jury_name}</span>
                      </span>
                    </div>
                    {/* <div
                      style={{
                        borderBottom: "1px solid #eff2f7",
                        padding: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          padding: "20px 0",
                          color: "#495057",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>Company Name:</span>
                        <span> {juryList?.company_name}</span>
                      </span>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid #eff2f7",
                        padding: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          padding: "20px 0",
                          color: "#495057",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>Designation: </span>
                        <span>{juryList?.designation}</span>
                      </span>
                    </div> */}
                    <div
                      style={{
                        borderBottom: "1px solid #eff2f7",
                        padding: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          padding: "20px 0",
                          color: "#495057",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>Category: </span>
                        <span>
                          {" "}
                          {juryList?.juryGroupDetail
                            ?.map((item) => item?.name)
                            .join(", ")}
                        </span>
                      </span>
                    </div>{" "}
                    {/* <div style={{ padding: "15px" }}>
                      <div
                        style={{
                          fontSize: "13px",
                          color: "#495057",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>Description:</span>
                        <span
                          style={{
                            marginLeft: "5px",
                            width: "60% !important",
                          }}
                        >
                          {juryList?.description}
                        </span>
                      </div>
                    </div> */}
                  </div>
                  {/* <Table className="table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <th>Name :</th>
                        <td>{juryList?.jury_name}</td>
                      </tr>

                      <tr>
                        <th>Company Name:</th>
                        <td> {juryList?.company_name}</td>
                      </tr>
                      <tr>
                        <th>Designation:</th>
                        <td>{juryList?.designation}</td>
                      </tr>
                      <tr>
                        <th>Category:</th>
                        <td> {juryList?.jury_category?.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Description :</th>
                        <td>
                          <p>{juryList?.description}</p>
                        </td>
                      </tr>
                    </tbody>
                  </Table> */}
                </div>
              </CardBody>
            </Card>
          )}
        </Container>
        {isFetching && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
};

export default Profile;
