import PropTypes from "prop-types";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { BreadcrumbItem, Col, Row } from "reactstrap";

const Breadcrumb = (props) => {
  const history = useHistory()
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link onClick={()=> history.goBack()}>{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link >{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
