import emptyLogo from "@assets/images/empty-logo.png";
import logo from "@assets/images/wow-logo.svg";
import "@assets/scss/custom/pages/_profile.scss";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import { getFromStorage } from "@utils/storage";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import * as Yup from "yup";
import ScreenLoader from "../../components/Common/ScreenLoader";
import { InputField } from "../../components/Form/Input";

const optionGroup = [
  {
    options: [
      { label: "Dr.", value: "Dr." },
      { label: "Mr.", value: "Mr." },
      { label: "Mrs.", value: "Mrs." },
      { label: "Ms.", value: "Ms." },
    ],
  },
];

function Profile() {
  document.title = "Profile | WOW Awards";
  const [showLogo, setShowLogo] = useState(false);
  const [errorMsg, setErrorMSg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [editeProfileForm, setEditeProfileForm] = useState(null);
  const [changePasswordFrom, setChangePasswordFrom] = useState(null);
  const [updateSalutation, setUpdateSalution] = useState(null);
  const [updateExecutiveSalutation, setUpdateExecutiveSalutation] =
    useState(null);
  const history = useHistory();
  const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const gstPattern =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const textOnly = /^[A-Za-z]+$/;

  const getUserName = getFromStorage("userName");

  const handleEditForm = (data) => {
    setEditeProfileForm(true);
    validation.setValues({
      entrant: data?.general?.entrant_name,
      userName: data?.general?.user_name,
      website: data?.general?.website,
      gst: data?.general?.gst_number,
      // salutation: data?.primary?.primary_salutation,
      firstName: data?.primary?.pcp_first_name,
      lastName: data?.primary?.pcp_last_name,
      designation: data?.primary?.pcp_designation,
      // secSalutation: data?.executive?.salutation,
      secFirstName:
        data?.executive?.first_name_sr_executive !== null
          ? data?.executive?.first_name_sr_executive
          : "",
      secLastName:
        data?.executive?.last_name_sr_executive !== null
          ? data?.executive?.last_name_sr_executive
          : "",
      secDesignation:
        data?.executive?.designation_sr_executive !== null
          ? data?.executive?.designation_sr_executive
          : "",
      secEmail:
        data?.executive?.email_id_sr_executive !== null
          ? data?.executive?.email_id_sr_executive
          : "",
      secMobile:
        data?.executive?.mobile_no_sr_executive !== null
          ? data?.executive?.mobile_no_sr_executive
          : "",
      mobile: data?.primary?.pcp_mobile_number,
      tel: data?.primary?.pcp_tel_number,
      ad1: data?.primary?.pcp_address_line1,
      ad2: data?.primary?.pcp_address_line2,
      ad3: data?.primary?.pcp_address_line3,
      country: data?.primary?.pcp_country,
      state: data?.primary?.pcp_state,
      city: data?.primary?.pcp_city,
      zip: data?.primary?.pcp_zip,
      offAdd1:
        data?.official?.address_line1_secondary !== null
          ? data?.official?.address_line1_secondary
          : "",
      offAdd2:
        data?.official?.address_line2_secondary !== null
          ? data?.official?.address_line2_secondary
          : "",
      offAdd3:
        data?.official?.address_line3_secondary !== null
          ? data?.official?.address_line3_secondary
          : "",
      offCountry:
        data?.official?.country_secondary !== null
          ? data?.official?.country_secondary
          : "",
      offState:
        data?.official?.state_secondary !== null
          ? data?.official?.state_secondary
          : "",
      offCity:
        data?.official?.city_secondary !== null
          ? data?.official?.city_secondary
          : "",
      offZip:
        data?.official?.zip_secondary !== null
          ? data?.official?.zip_secondary
          : "",
      telPhone1:
        data?.official?.tele_phoneno1_secondary !== null
          ? data?.official?.tele_phoneno1_secondary
          : "",
      telPhone2:
        data?.official?.tele_phoneno2_secondary !== null
          ? data?.official?.tele_phoneno2_secondary
          : "",
      isOfficialAd: data?.is_official_address,
      isPrimarySame: data?.is_person_same_as_execut,
    });
    const getSalutation =
      optionGroup &&
      optionGroup[0].options.find(
        (item) => item.value === userDetails?.primary?.primary_salutation
      );
    setUpdateSalution(getSalutation);

    let getSrSalutation =
      optionGroup &&
      optionGroup[0].options.find(
        (item) => item.value === userDetails?.executive?.salutation
      );
    // if (getLabel === undefined) {
    //   getLabel = null;
    // }
    setUpdateExecutiveSalutation(getSrSalutation);
  };

  const handleBackButton = () => {
    setEditeProfileForm(false);
    setChangePasswordFrom(false);
  };

  const handleChangePasswordForm = () => {
    setChangePasswordFrom(true);
  };

  // get User profile

  const actionsUserDetail = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };

  const {
    data: userDetails,
    refetch,
    isFetching,
    isLoading,
  } = FetchData("userDetails", "User/get-user-profile", actionsUserDetail);

  const userEmail = userDetails?.general?.user_name;

  // change password from
  // const changePasswordActions = {
  //   onSuccess: (data) => {
  //     if (data.data.status) {
  //       setSuccessMsg(data.data.responseData);
  //       setErrorMSg('');
  //       Formik.resetForm();
  //     } else {
  //       setErrorMSg(data.data.errors[0]);
  //     }
  //   },
  //   onError: (error) => {
  //     setSuccessMsg('');
  //     setErrorMSg('Something went wrong!');
  //   },
  // };

  // const { mutate, isLoading: isChangePLoading } = PostQuery(
  //   'Authentication/change-password',
  //   'post',
  //   changePasswordActions
  // );

  // const Formik = useFormik({
  //   enableReinitialize: true,

  //   initialValues: {
  //     oldpassword: '',
  //     newpassword: '',
  //     confimpassword: '',
  //   },
  //   validationSchema: Yup.object({
  //     oldpassword: Yup.string().required('Please Enter Your Email'),
  //     newpassword: Yup.string()
  //       .matches(
  //         passwordRegex,
  //         'password must be at least 8 characters with at least a symbol, upper and lower case letters and a number'
  //       )
  //       .required('Please Enter Your Password'),
  //     confimpassword: Yup.string()
  //       .required('Please Enter Your Confirm Password')
  //       .oneOf([Yup.ref('newpassword'), null], 'Passwords must match'),
  //   }),
  //   onSubmit: (values) => {
  //     const getDetails = {
  //       user_name: userEmail,
  //       oldpassword: values.oldpassword,
  //       new_password: values.newpassword,
  //     };
  //     mutate(getDetails);
  //   },
  // });
  // update profile form

  const registerUpdateFormActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setEditeProfileForm(false);
        refetch();
        validation.resetForm();
      }
    },
  };

  const { mutate: UpdateFrom, isLoading: isUpdateProfileLoading } = PostQuery(
    "User/update-user-detail",
    "post",
    registerUpdateFormActions
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      entrant: "",
      userName: "",
      website: "",
      gst: "",
      salutation: {},
      firstName: "",
      lastName: "",
      designation: "",
      secSalutation: {},
      secFirstName: "",
      secLastName: "",
      secDesignation: "",
      secEmail: "",
      secMobile: "",
      mobile: "",
      tel: "",
      ad1: "",
      ad2: "",
      ad3: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      offAdd1: "",
      offAdd2: "",
      offAdd3: "",
      offCountry: "",
      offState: "",
      offCity: "",
      offZip: "",
      telPhone1: "",
      telPhone2: "",
      isOfficialAd: "",
      isPrimarySame: "",
    },

    validationSchema: Yup.object({
      // website: Yup.string().required('Please Enter Your website'),
      firstName: Yup.string()
        .min(3, "First Name Should Be More Then 3 Word")
        .matches(textOnly, "Please enter only alphabets")
        .required("Please Enter Your First Name"),
      lastName: Yup.string()
        .min(3, "Please Write Valid Last Name")
        .matches(textOnly, "Please enter only alphabets")
        .required("Please Enter Your Last Name"),
      designation: Yup.string().required("Please Enter Your Designation"),

      mobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .required("Please Enter Your Mobile"),

      isOfficialAd: Yup.boolean()
        .nullable()
        .required("Select one of the above button"),
      isPrimarySame: Yup.boolean()
        .required("Select one of the above button")
        .nullable(),
      ad1: Yup.string().required("Please Enter Address"),
      ad2: Yup.string().required("Please Enter Address"),
      country: Yup.string().required("Please Enter Your country"),
      state: Yup.string().required("Please Enter Your state"),
      city: Yup.string().required("Please Enter Your city"),
      zip: Yup.string()
        .max(6, "Invalid Zip Code")
        .required("Please Enter Your zip"),
      offAdd1: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Official address").nullable(),
      }),
      offAdd2: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Official address").nullable(),
      }),
      offCountry: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your country").nullable(),
      }),
      offState: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your state").nullable(),
      }),
      offCity: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your city").nullable(),
      }),
      offZip: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your zip").nullable(),
      }),
      telPhone1: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your telephone").nullable(),
      }),
      secFirstName: Yup.string()
        .min(3, "Please Write Valid First Name")
        .matches(textOnly, "Please enter only alphabets")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your First Name"),
        }),
      secLastName: Yup.string()
        .min(3, "Please Write Valid Last Name")
        .matches(textOnly, "Please enter only alphabets")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your Last Name"),
        }),

      secDesignation: Yup.string().when("isPrimarySame", {
        is: false,
        then: Yup.string().required("Please Enter Your Designation"),
      }),

      secMobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your Mobile"),
        }),
      secEmail: Yup.string().when("isPrimarySame", {
        is: false,
        then: Yup.string().required("Please Enter Your Email"),
      }),
    }),

    onSubmit: (values) => {
      const getUpdateForm = {
        entrant_name: values.entrant,
        username: values.userName,
        website: values.website,
        gst_number: values.gst,
        primary_salutation: values.salutation ? values.salutation.value : "",
        pcp_first_name: values.firstName,
        pcp_last_name: values.lastName,
        pcp_designation: values.designation,
        pcp_mobile_number: values.mobile,
        pcp_tel_number: values.tel,
        pcp_address_line1: values.ad1,
        pcp_address_line2: values.ad2,
        pcp_address_line3: values.ad3,
        pcp_country: values.country,
        pcp_state: values.state,
        pcp_zip: values.zip.toString(),
        pcp_city: values.city,
        is_official_address: values.isOfficialAd,
        address_line1_secondary: values.offAdd1,
        address_line2_secondary: values.offAdd2,
        address_line3_secondary: values.offAdd3,
        country_secondary: values.offCountry,
        state_secondary: values.offState,
        city_secondary: values.offCity,
        zip_secondary: values.offZip.toString(),
        telephoneno1_secondary: values.telPhone1,
        telephoneno2_secondary: values.telPhone2,
        is_person_same_as_execut: values.isPrimarySame,

        salutation: values.updateExecutiveSalutation
          ? values.updateExecutiveSalutation.value
          : "",
        firstname_sr_executive: values.secFirstName,
        lastname_sr_executive: values.secLastName,
        designation_sr_executive: values.secDesignation,
        emailId_sr_executive: values.secEmail,
        mobileNo_sr_executive: values.secMobile,
      };
      UpdateFrom(getUpdateForm);
    },
  });

  function handleSalution(e) {
    setUpdateSalution(e);
    validation.setFieldValue("salutation", e);
  }
  // useEffect(() => {
  //   const getLabel =
  //     optionGroup &&
  //     optionGroup[0].options.find(
  //       (item) => item.value === userDetails?.primary?.primary_salutation
  //     );
  //   setUpdateSalution(getLabel);
  // }, [optionGroup, userDetails]);

  function handleExecutiveSalution(e) {
    setUpdateExecutiveSalutation(e);
    validation.setFieldValue("secSalutation", e);
  }

  useEffect(() => {
    validation.setFieldValue("salutation", updateSalutation);
    validation.setFieldValue(
      "updateExecutiveSalutation",
      updateExecutiveSalutation
    );
  }, [updateSalutation, updateExecutiveSalutation]);
  // useEffect(() => {
  //   let getLabel =
  //     optionGroup &&
  //     optionGroup[0].options.find(
  //       (item) => item.value === userDetails?.executive?.salutation
  //     );
  //   if (getLabel === undefined) {
  //     getLabel = null;
  //   }
  //   setUpdateExecutiveSalutation(getLabel);
  // }, [optionGroup, userDetails]);

  const handleSameContact = (e) => {
    if (e.target.value === "yes") {
      validation.setFieldValue("isPrimarySame", true);
    } else {
      validation.setFieldValue("isPrimarySame", false);
    }
  };
  const handleOfficialAd = (e) => {
    if (e.target.value === "yes") {
      validation.setFieldValue("isOfficialAd", true);
    } else {
      validation.setFieldValue("isOfficialAd", false);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="img-wrap">
                  {showLogo ? (
                    <img src={logo} alt="" className="p-img" />
                  ) : (
                    <img src={emptyLogo} alt="" />
                  )}
                  {/* <input
                    type="file"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                    className="file-picker"
                    // onChange={handleUploadChange}
                  /> */}
                  {/* <label className="icon-wrap" htmlFor="avatar">
                    <svg
                      className="icon"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5649 1.4178C8.69736 1.28534 8.85461 1.18027 9.02767 1.10858C9.20074 1.0369 9.38623 1 9.57355 1C9.76087 1 9.94636 1.0369 10.1194 1.10858C10.2925 1.18027 10.4497 1.28534 10.5822 1.4178C10.7147 1.55026 10.8197 1.70751 10.8914 1.88057C10.9631 2.05364 11 2.23913 11 2.42645C11 2.61377 10.9631 2.79926 10.8914 2.97233C10.8197 3.14539 10.7147 3.30265 10.5822 3.4351L3.7738 10.2435L1 11L1.75649 8.2262L8.5649 1.4178Z"
                        fill="#D9D9D9"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </label> */}
                </div>
                <div className="flex-grow-1 align-self-center ms-3">
                  <div className="text-muted">
                    <h5>{userDetails?.general?.entrant_name}</h5>
                    <p className="mb-1">{userDetails?.general?.user_name}</p>
                    <p className="mb-0">
                      GST no: {userDetails?.general?.gst_number}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          {editeProfileForm ? (
            <>
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <CardTitle className="mb-4">
                        <h3>Edit Profile</h3>
                      </CardTitle>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="btn m-2"
                        onClick={handleBackButton}
                      >
                        Go Back
                      </Button>{" "}
                    </div>
                  </div>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col xs="12">
                        <Row>
                          <Col sm="12">
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label className="entrant">
                                    Entrant Name (Organisation, Agency, Venue,
                                    Vendor Co.)*
                                  </Label>
                                  <Input
                                    name="entrant"
                                    disabled
                                    type="text"
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.entrant || ""}
                                    invalid={
                                      validation.touched.entrant &&
                                      validation.errors.entrant
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.entrant &&
                                  validation.errors.entrant ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.entrant}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label htmlFor="userName">
                                    Username (Email ID)*
                                  </Label>
                                  <Input
                                    id="userName"
                                    name="userName"
                                    type="email"
                                    disabled
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.userName || ""}
                                    invalid={
                                      validation.touched.userName &&
                                      validation.errors.userName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.userName &&
                                  validation.errors.userName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.userName}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={"Website"}
                                    name={"website"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.website || ""}
                                    invalid={
                                      validation.touched.website &&
                                      validation.errors.website
                                        ? true
                                        : false
                                    }
                                    // validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label htmlFor="gst">
                                    GST No. (For Indian Companies)
                                  </Label>
                                  <Input
                                    id="gst"
                                    name="gst"
                                    type="text"
                                    disabled
                                    className="form-control"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.gst || ""}
                                    invalid={
                                      validation.touched.gst &&
                                      validation.errors.gst
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.gst &&
                                  validation.errors.gst ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.gst}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <CardTitle className="mb-3">
                          PRIMARY CONTACT PERSON
                        </CardTitle>
                        <Row>
                          <Col sm="12">
                            <Row>
                              <Col sm="2">
                                <div className="mb-3">
                                  <Label htmlFor="salutation">Salutation</Label>
                                  <Select
                                    value={updateSalutation}
                                    type="select"
                                    placeholder="select"
                                    id="salutation"
                                    options={
                                      updateSalutation ? null : optionGroup
                                    }
                                    classNamePrefix="select2-selection"
                                    name="salutation"
                                    onChange={(e) => {
                                      handleSalution(e);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col sm="5">
                                <div className="mb-3">
                                  <InputField
                                    label={"First Name*"}
                                    name={"firstName"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""}
                                    invalid={
                                      validation.touched.firstName &&
                                      validation.errors.firstName
                                        ? true
                                        : false
                                    }
                                    disabled
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="5">
                                <div className="mb-3">
                                  <InputField
                                    label={"Last Name*"}
                                    name={"lastName"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""}
                                    invalid={
                                      validation.touched.lastName &&
                                      validation.errors.lastName
                                        ? true
                                        : false
                                    }
                                    disabled
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="mb-3">
                              <InputField
                                label={"Designation*"}
                                name={"designation"}
                                type={"text"}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.designation || ""}
                                invalid={
                                  validation.touched.designation &&
                                  validation.errors.designation
                                    ? true
                                    : false
                                }
                                validation={validation}
                              />
                            </div>
                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={"Mobile No.*"}
                                    name={"mobile"}
                                    type={"tel"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.mobile || ""}
                                    invalid={
                                      validation.touched.mobile &&
                                      validation.errors.mobile
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <InputField
                                    label={"Telephone No."}
                                    name={"tel"}
                                    type={"tel"}
                                    onChange={validation.handleChange}
                                    value={validation.values.tel || ""}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="4">
                                <div className="mb-3">
                                  <InputField
                                    label={"Address Line 1*"}
                                    name={"ad1"}
                                    type={"textarea"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.ad1 || ""}
                                    invalid={
                                      validation.touched.ad1 &&
                                      validation.errors.ad1
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="mb-3">
                                  <InputField
                                    label={"Address Line 2*"}
                                    name={"ad2"}
                                    type={"textarea"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.ad2 || ""}
                                    invalid={
                                      validation.touched.ad2 &&
                                      validation.errors.ad2
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="4">
                                <div className="mb-3">
                                  <InputField
                                    label={"Address Line 3"}
                                    name={"ad3"}
                                    type={"textarea"}
                                    onChange={validation.handleChange}
                                    value={validation.values.ad3 || ""}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={"Country*"}
                                    name={"country"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.country || ""}
                                    invalid={
                                      validation.touched.country &&
                                      validation.errors.country
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={"State / Province / Region*"}
                                    name={"state"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.state || ""}
                                    invalid={
                                      validation.touched.state &&
                                      validation.errors.state
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={"City*"}
                                    name={"city"}
                                    type={"text"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.city || ""}
                                    invalid={
                                      validation.touched.city &&
                                      validation.errors.city
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                              <Col sm="3">
                                <div className="mb-3">
                                  <InputField
                                    label={`Zip / Postal Code (if not applicable, please
                                      key in N/A)*`}
                                    name={"zip"}
                                    type={"number"}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.zip || ""}
                                    invalid={
                                      validation.touched.zip &&
                                      validation.errors.zip
                                        ? true
                                        : false
                                    }
                                    validation={validation}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <div className="mb-3">
                              <Label htmlFor="official-add">
                                Is this the official address
                              </Label>
                              <div style={{ display: "flex" }}>
                                <div className="form-check mb-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isOfficialAd"
                                    id="exampleRadios1"
                                    value="yes"
                                    onClick={handleOfficialAd}
                                    defaultChecked={
                                      userDetails?.is_official_address
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div
                                  style={{ marginLeft: 20 }}
                                  className="form-check"
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isOfficialAd"
                                    id="exampleRadios2"
                                    value="no"
                                    onClick={handleOfficialAd}
                                    defaultChecked={
                                      userDetails?.is_official_address
                                        ? false
                                        : true
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleRadios2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                              {validation.errors.isOfficialAd ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{
                                    display: "flex",
                                    marginTop: "-10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {validation.errors.isOfficialAd}
                                </FormFeedback>
                              ) : null}

                              {!validation.values.isOfficialAd &&
                                validation.values.isOfficialAd !== null && (
                                  <>
                                    <Row>
                                      <Col sm="4">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Address Line 1*"}
                                            name={"offAdd1"}
                                            type={"textarea"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offAdd1 || ""
                                            }
                                            invalid={
                                              validation.touched.offAdd1 &&
                                              validation.errors.offAdd1
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Address Line 2*"}
                                            name={"offAdd2"}
                                            type={"textarea"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offAdd2 || ""
                                            }
                                            invalid={
                                              validation.touched.offAdd2 &&
                                              validation.errors.offAdd2
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="4">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Address Line 3"}
                                            name={"offAdd3"}
                                            type={"textarea"}
                                            onChange={validation.handleChange}
                                            value={
                                              validation.values.offAdd3 || ""
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={"Country*"}
                                            name={"offCountry"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offCountry || ""
                                            }
                                            invalid={
                                              validation.touched.offCountry &&
                                              validation.errors.offCountry
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={"State / Province / Region*"}
                                            name={"offState"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offState || ""
                                            }
                                            invalid={
                                              validation.touched.offState &&
                                              validation.errors.offState
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={"City*"}
                                            name={"offCity"}
                                            type={"text"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offCity || ""
                                            }
                                            invalid={
                                              validation.touched.offCity &&
                                              validation.errors.offCity
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="3">
                                        <div className="mb-3">
                                          <InputField
                                            label={`Zip / Postal Code (if not
                                              applicable, please key in N/A)*`}
                                            name={"offZip"}
                                            type={"number"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.offZip || ""
                                            }
                                            invalid={
                                              validation.touched.offZip &&
                                              validation.errors.offZip
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm="6">
                                        <div className="mb-3">
                                          <InputField
                                            label={`Telephone No. #1 *`}
                                            name={"telPhone1"}
                                            type={"tel"}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values.telPhone1 || ""
                                            }
                                            invalid={
                                              validation.touched.telPhone1 &&
                                              validation.errors.telPhone1
                                                ? true
                                                : false
                                            }
                                            validation={validation}
                                          />
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="mb-3">
                                          <InputField
                                            label={`Telephone No. #2`}
                                            name={"telPhone2"}
                                            type={"tel"}
                                            onChange={validation.handleChange}
                                            value={
                                              validation.values.telPhone2 || ""
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </>
                                )}
                            </div>
                          </Col>
                        </Row>

                        <CardTitle className="mb-3">
                          SENIOR EXECUTIVE’S DETAILS (COMPANY OWNER, DIRECTOR,
                          PROPRIETOR, ETC.)
                        </CardTitle>
                        <div className="mb-0">
                          <Label htmlFor="executive-add">
                            Same as above mentioned Primary Contact Person*
                          </Label>
                          <div style={{ display: "flex" }}>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isPrimarySame"
                                id="isPrimarySame1"
                                value="yes"
                                onClick={handleSameContact}
                                defaultChecked={
                                  userDetails?.is_person_same_as_execut
                                    ? true
                                    : false
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isPrimarySame1"
                              >
                                Yes
                              </label>
                            </div>
                            <div
                              style={{ marginLeft: 20 }}
                              className="form-check"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isPrimarySame"
                                id="isPrimarySame2"
                                value="no"
                                onClick={handleSameContact}
                                defaultChecked={
                                  userDetails?.is_person_same_as_execut
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="isPrimarySame2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>

                        {!validation.values.isPrimarySame &&
                          validation.values.isPrimarySame !== null && (
                            <>
                              <Row>
                                <Col sm="2">
                                  <div className="mb-3">
                                    <Label htmlFor="secSalutation">
                                      Salutation
                                    </Label>
                                    <Select
                                      options={optionGroup}
                                      classNamePrefix="select2-selection"
                                      name="secSalutation"
                                      value={updateExecutiveSalutation}
                                      type="select"
                                      id="secSalutation"
                                      onChange={(e) => {
                                        handleExecutiveSalution(e);
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col sm="5">
                                  <div className="mb-3">
                                    <InputField
                                      label={"First Name*"}
                                      name={"secFirstName"}
                                      type={"text"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secFirstName || ""
                                      }
                                      invalid={
                                        validation.touched.secFirstName &&
                                        validation.errors.secFirstName
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                                <Col sm="5">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Last Name*"}
                                      name={"secLastName"}
                                      type={"text"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secLastName || ""
                                      }
                                      invalid={
                                        validation.touched.secLastName &&
                                        validation.errors.secLastName
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Designation*"}
                                      name={"secDesignation"}
                                      type={"text"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.secDesignation || ""
                                      }
                                      invalid={
                                        validation.touched.secDesignation &&
                                        validation.errors.secDesignation
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Email*"}
                                      name={"secEmail"}
                                      type={"email"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.secEmail || ""}
                                      invalid={
                                        validation.touched.secEmail &&
                                        validation.errors.secEmail
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="mb-3">
                                    <InputField
                                      label={"Mobile No.*"}
                                      name={"secMobile"}
                                      type={"tel"}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.secMobile || ""}
                                      invalid={
                                        validation.touched.secMobile &&
                                        validation.errors.secMobile
                                          ? true
                                          : false
                                      }
                                      validation={validation}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </>
                          )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            type="submit"
                            color="primary"
                            className="btn "
                            // disabled={isUpdateProfileLoading}
                          >
                            {isUpdateProfileLoading && (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            )}
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </>
          ) : (
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        {" "}
                        <CardTitle className="mb-4">
                          <h3> Profile Details</h3>
                        </CardTitle>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          className="btn"
                          onClick={() => {
                            const orderData = userDetails;
                            // updateCategoryClick(orderData);
                            handleEditForm(orderData);
                          }}
                        >
                          Edit Profile{" "}
                        </Button>
                        {/* <Button
                          color="primary"
                          className="btn m-2"
                          onClick={handleChangePasswordForm}
                        >
                          Change password{' '}
                        </Button> */}
                      </div>
                    </div>

                    <div className="table-responsive">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">Name :</th>
                            <td>
                              {userDetails?.primary?.primary_salutation} {""}{" "}
                              {userDetails?.primary?.pcp_first_name} {""}
                              {userDetails?.primary?.pcp_last_name}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Designation :</th>
                            <td>{userDetails?.primary?.pcp_designation} </td>
                          </tr>
                          <tr>
                            <th scope="row">Mobile :</th>
                            <td>{userDetails?.primary?.pcp_mobile_number}</td>
                          </tr>
                          <tr>
                            <th scope="row">Telephone :</th>
                            <td>{userDetails?.primary?.pcp_tel_number}</td>
                          </tr>
                          <tr>
                            <th scope="row">Website :</th>
                            <td>{userDetails?.general?.website}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
                <Row>
                  <Col
                    xl={`${
                      userDetails?.is_official_address &&
                      userDetails?.is_person_same_as_execut
                        ? "12"
                        : userDetails?.is_official_address ||
                          userDetails?.is_person_same_as_execut
                        ? "6"
                        : "4"
                    }`}
                  >
                    <Card className="overflow-hidden">
                      <CardBody>
                        <CardTitle className="mb-4">Primary Address</CardTitle>
                        <div className="text-center">
                          <div className="mb-4">
                            <i className="bx bx-map-pin text-primary display-4" />
                          </div>
                          <h3>{userDetails?.primary?.pcp_city} </h3>
                          <p>
                            {userDetails?.primary?.pcp_state},{" "}
                            {userDetails?.primary?.pcp_country}
                          </p>
                        </div>
                        <Row>
                          <Col sm="12">
                            <div className="pt-4">
                              <Row>
                                <Col xs="6">
                                  <p className="text-muted mb-0">Address</p>
                                  <h5 className="font-size-15">
                                    {userDetails?.primary?.pcp_address_line1}{" "}
                                    {userDetails?.primary?.pcp_address_line2}{" "}
                                    {userDetails?.primary?.pcp_address_line3}
                                  </h5>
                                </Col>
                                <Col xs="6">
                                  <p className="text-muted mb-0">Zipcode</p>
                                  <h5 className="font-size-15">
                                    {userDetails?.primary?.pcp_zip}
                                  </h5>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {userDetails?.is_official_address ? null : (
                    <Col
                      xl={`${
                        userDetails?.is_official_address ||
                        userDetails?.is_person_same_as_execut
                          ? "6"
                          : "4"
                      }`}
                    >
                      <Card className="overflow-hidden">
                        <CardBody>
                          <CardTitle className="mb-4">
                            Official Address
                          </CardTitle>
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="bx bx-map-pin text-primary display-4" />
                            </div>
                            <h3>{userDetails?.official?.city_secondary}</h3>
                            <p>
                              {userDetails?.official?.state_secondary},{" "}
                              {userDetails?.official?.country_secondary}
                            </p>
                          </div>
                          <Row>
                            <Col sm="12">
                              <div className="pt-4">
                                <Row>
                                  <Col xs="6">
                                    <p className="text-muted mb-0">Address</p>
                                    <h5 className="font-size-15">
                                      {
                                        userDetails?.official
                                          ?.address_line1_secondary
                                      }{" "}
                                      {
                                        userDetails?.official
                                          ?.address_line2_secondary
                                      }{" "}
                                      {
                                        userDetails?.official
                                          ?.address_line3_secondary
                                      }
                                    </h5>
                                  </Col>
                                  <Col xs="6">
                                    <p className="text-muted mb-0">Zipcode</p>
                                    <h5 className="font-size-15">
                                      {" "}
                                      {userDetails?.official?.zip_secondary}
                                    </h5>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {userDetails?.is_person_same_as_execut ? null : (
                    <Col
                      xl={`${
                        userDetails?.is_official_address ||
                        userDetails?.is_person_same_as_execut
                          ? "6"
                          : "4"
                      }`}
                    >
                      <Card className="overflow-hidden">
                        <CardBody>
                          <CardTitle className="mb-4">
                            Executive Address
                          </CardTitle>
                          <div className="table-responsive">
                            <Table className="table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Name :</th>
                                  <td>
                                    {userDetails?.executive?.salutation}{" "}
                                    {
                                      userDetails?.executive
                                        ?.first_name_sr_executive
                                    }{" "}
                                    {
                                      userDetails?.executive
                                        ?.last_name_sr_executive
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">E-mail :</th>
                                  <td>
                                    {
                                      userDetails?.executive
                                        ?.email_id_sr_executive
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Designation :</th>
                                  <td>
                                    {
                                      userDetails?.executive
                                        ?.designation_sr_executive
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Mobile :</th>
                                  <td>
                                    {
                                      userDetails?.executive
                                        ?.mobile_no_sr_executive
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}

          {/* {changePasswordFrom ? (
            <Card>
              <CardBody>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <CardTitle className="mb-4">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i className="bx bx-shield-quarter text-primary display-6" />
                        <h3 style={{ marginLeft: '10px' }}>Change Password</h3>
                      </div>
                    </CardTitle>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      className="btn m-2"
                      onClick={handleBackButton}
                    >
                      Go Back
                    </Button>{' '}
                  </div>
                </div>
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    Formik.handleSubmit();
                    return false;
                  }}
                >
                  <div className="mb-3">
                    <Label className="form-label">Enter Old Password</Label>
                    <Input
                      name="oldpassword"
                      className="form-control"
                      placeholder="Enter Old Password"
                      type="password"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.oldpassword || ''}
                      invalid={
                        Formik.touched.oldpassword && Formik.errors.oldpassword
                          ? true
                          : false
                      }
                    />
                    {Formik.touched.oldpassword && Formik.errors.oldpassword ? (
                      <FormFeedback type="invalid">
                        {Formik.errors.oldpassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Enter New Password</Label>
                    <Input
                      name="newpassword"
                      className="form-control"
                      placeholder="Enetr New Password"
                      type="password"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.newpassword || ''}
                      invalid={
                        Formik.touched.newpassword && Formik.errors.newpassword
                          ? true
                          : false
                      }
                    />
                    {Formik.touched.newpassword && Formik.errors.newpassword ? (
                      <FormFeedback type="invalid">
                        {Formik.errors.newpassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Confirm new Password </Label>
                    <Input
                      name="confimpassword"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      type="password"
                      onChange={Formik.handleChange}
                      onBlur={Formik.handleBlur}
                      value={Formik.values.confimpassword || ''}
                      invalid={
                        Formik.touched.confimpassword &&
                        Formik.errors.confimpassword
                          ? true
                          : false
                      }
                    />
                    {Formik.touched.confimpassword &&
                    Formik.errors.confimpassword ? (
                      <FormFeedback type="invalid">
                        {Formik.errors.confimpassword}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      color="primary"
                      className="btn m-2"
                      type="submit"
                      disabled={isChangePLoading}
                    >
                      {isChangePLoading && (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      )}
                      save
                    </Button>
                  </div>
                </Form>

                {errorMsg ? (
                  <div
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      marginTop: '10px',
                    }}
                  >
                    {errorMsg}
                  </div>
                ) : null}
                {successMsg ? (
                  <div
                    style={{
                      color: 'green',
                      textAlign: 'center',
                      marginTop: '10px',
                    }}
                  >
                    {successMsg}
                  </div>
                ) : null}
              </CardBody>
            </Card>
          ) : null} */}
        </Container>
        {isLoading && <ScreenLoader />}
      </div>
    </React.Fragment>
  );
}

export default Profile;
