import TableContainer from "@components/Common/TableContainer";
import { FetchData } from "@hooks/api/useApi";
import { useFormik } from "formik";
import moment from "moment/moment";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import { ID } from "../../Admin/Jury/EditableCol";

const region = [
  {
    options: [
      { label: "Asia", value: "asia" },
      { label: "Middle East", value: "middle-east" },
    ],
  },
];

const roundOptions = [
  {
    options: [
      { label: "Round 1", value: "0" },
      { label: "Round 2", value: "1" },
    ],
  },
];

const optionGroup = [
  {
    label: "Picnic",
    options: [
      { label: "Mustard", value: "Mustard" },
      { label: "Ketchup", value: "Ketchup" },
      { label: "Relish", value: "Relish" },
    ],
  },
  {
    label: "Camping",
    options: [
      { label: "Tent", value: "Tent" },
      { label: "Flashlight", value: "Flashlight" },
      { label: "Toilet Paper", value: "Toilet Paper" },
    ],
  },
];

// const products = [
//   {
//     code: 2753,
//     date: "11-04-2022",
//     name: "Purva-Mantri",
//     category:
//       "113. Best Singer for Weddings & Social Events - Female (On-ground + Digital)",
//     Group: "Group E: LIVE Quotient Awards",
//     entrantName: "Purva Mantri",
//     email: "admin@purvamantri.com",
//     formstatus: "submited",
//     approvalStatus: "Approved",
//     paymentCode: "hvbksjbcnieyvcbiwbjk",
//     paymentStatus: "done",
//     finalStatus: "done",
//     noSubmitting: "submitting",
//   },
//   {
//     code: 2753,
//     date: "11-04-2022",
//     name: "NHPC--New-Year-",
//     category:
//       "113. Best Singer for Weddings & Social Events - Female (On-ground + Digital)",
//     Group: "Group E: LIVE Quotient Awards",
//     entrantName: "Vipin Aneja",
//     email: "admin@purvamantri.com",
//     formstatus: "submited",
//     approvalStatus: "Approved",
//     paymentCode: "hvbksjbcnieyvcbiwbjk",
//     paymentStatus: "done",
//     finalStatus: "done",
//     noSubmitting: "submitting",
//   },
//   {
//     code: 2753,
//     date: "11-04-2022",
//     name: "Purva-Mantri",
//     category:
//       "113. Best Singer for Weddings & Social Events - Female (On-ground + Digital)",
//     Group: "Group E: LIVE Quotient Awards",
//     entrantName: "Purva Mantri",
//     email: "admin@purvamantri.com",
//     formstatus: "submited",
//     approvalStatus: "Approved",
//     paymentCode: "hvbksjbcnieyvcbiwbjk",
//     paymentStatus: "done",
//     finalStatus: "done",
//     noSubmitting: "submitting",
//   },
//   {
//     code: 2753,
//     date: "11-04-2022",
//     name: "BMP--ICWF-2019",
//     category:
//       "113. Best Singer for Weddings & Social Events - Female (On-ground + Digital)",
//     Group: "Group E: LIVE Quotient Awards",
//     entrantName: "Nupur Pant",
//     email: "admin@purvamantri.com",
//     formstatus: "submited",
//     approvalStatus: "Approved",
//     paymentCode: "hvbksjbcnieyvcbiwbjk",
//     paymentStatus: "pending",
//     finalStatus: "",
//     noSubmitting: "submitting",
//   },
//   {
//     code: 2753,
//     date: "11-04-2022",
//     name: "BMP--ICWF-2019",
//     category:
//       "113. Best Singer for Weddings & Social Events - Female (On-ground + Digital)",
//     Group: "Group E: LIVE Quotient Awards",
//     entrantName: "Nupur Pant uhijhsd",
//     email: "admin@purvamantri.com",
//     formstatus: "submited",
//     approvalStatus: "Approved",
//     paymentCode: "hvbksjbcnieyvcbiwbjk",
//     paymentStatus: "done",
//     finalStatus: "done",
//     noSubmitting: "submitting",
//   },
//   {
//     code: 2753,
//     date: "11-04-2022",
//     name: "Swaaha-Wedding-Udaipur-wedding-with-Chitrangada-Singh-and-Amit-Trivedi",
//     category:
//       "113. Best Singer for Weddings & Social Events - Female (On-ground + Digital)",
//     Group: "Group E: LIVE Quotient Awards",
//     entrantName: "Devika Singh",
//     email: "admin@purvamantri.com",
//     formstatus: "submited",
//     approvalStatus: "Approved",
//     paymentCode: "hvbksjbcnieyvcbiwbjk",
//     paymentStatus: "pending",
//     finalStatus: "",
//     noSubmitting: "submitting",
//   },
// ];

function AssignJury() {
  const [selectedGroup, setselectedGroup] = useState(null);
  const [optionCategory, setOptionCategory] = useState();
  const [optionJurry, setOptionJurry] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addEntry, setAddEntry] = useState([]);
  const [selectedRounds, setselectedRounds] = useState(null);
  const [selectedMultiJury, setselectedMultiJury] = useState(null);
  const history = useHistory();
  const categoryActions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        if (item.is_active === "Published") {
          obj["label"] = item.category_name;
          obj["value"] = item.category_id;
          optionList.push(obj);
          let getList = [];
          let newObj = {};
          newObj["options"] = optionList;
          getList.push(newObj);
          setOptionCategory(getList);
        }
      });
    },
  };

  const { data: categoryData, isFetching: categoryFetching } = FetchData(
    "category",
    "/Admin/get-all-category",
    categoryActions
  );

  const actionsjuryList = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      let optionList = [];
      data.map((item) => {
        let obj = {};
        obj["label"] = item.jury_Name;
        obj["value"] = item.id;
        optionList.push(obj);
        let getList = [];
        let newObj = {};
        newObj["options"] = optionList;
        getList.push(newObj);
        setOptionJurry(getList);
      });
    },
  };

  const { data: juryList, isFetching: juryFetching } = FetchData(
    "jurylist",
    "Jury/get-all-jury-detail",
    actionsjuryList
  );

  const handleChange = (e, item) => {
    if (e.target.checked) {
      setAddEntry((prev) => {
        return [...prev, item];
      });
    } else {
      setAddEntry((prev) => {
        return prev.filter((ele) => {
          if (ele !== item) {
            return item;
          }
        });
      });
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "cellProps",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          // if (row.index === 0) {
          return (
            <div>
              <Input
                type="checkbox"
                style={{ cursor: "pointer" }}
                onChange={(e) => {
                  handleChange(e, row.row.original.entry_id);
                }}
                disabled={false}
              />
            </div>
          );
          // }
        },
      },

      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },

      {
        Header: "Entry Code",
        accessor: "entry_code",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Date Created",
        accessor: "created_date",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("Do MMM YYYY");
        },
      },
      {
        Header: "Entrant organization",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },
      {
        Header: "Entry Name",
        accessor: "entry_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p>{cellProps.value}</p>;
        },
      },

      {
        Header: "Category",
        accessor: "category_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Sub category",
        accessor: "group_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Category group",
        accessor: "subgroup_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
      {
        Header: "Average score",
        // accessor: "finalStatus",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <ID {...cellProps} />;
        },
      },
    ],
    []
  );

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  const formik = useFormik({
    initialValues: {
      region: "",
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleCheckTerms = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("isAcceptingTerms", e.target.checked);
    } else {
      formik.setFieldValue("isAcceptingTerms", e.target.checked);
    }
  };

  const handleSameContact = (e) => {
    if (e.target.value === "yes") {
      formik.setFieldValue("isPrimarySame", true);
    } else {
      formik.setFieldValue("isPrimarySame", false);
    }
  };
  const handleOfficialAd = (e) => {
    if (e.target.value === "yes") {
      formik.setFieldValue("isOfficialAd", true);
    } else {
      formik.setFieldValue("isOfficialAd", false);
    }
  };

  function handleRounds(e) {
    setselectedRounds(e);
  }

  function handleMultiJury(e) {
    setselectedMultiJury(e);
  }

  function handleSelectCategory(item) {
    setSelectedCategory(item);
  }
  const actions = {
    select: (data) => {
      const entryList = data.data.responseData;
      return entryList;
    },
  };

  const {
    data: allEntries,
    isFetching: EntriyFetching,
    refetch,
  } = FetchData("all-entries", `User/get-all-user-entry`, actions);

  function handleData() {
    return allEntries?.filter(
      (item) => item?.category_name === selectedCategory?.label
    );
  }

  document.title = "Assign Jury | WOW Awards";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Jury" breadcrumbItem="Assign" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="d-flex align-items-center mb-1">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-sitemap text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">Assign Jury</h3>
                    </div>
                    <Button type="submit" color="primary" className="btn ">
                      Assign Jury
                    </Button>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Select Category</Label>
                          <Select
                            onChange={(e) => {
                              handleSelectCategory(e);
                            }}
                            options={optionCategory}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Select Jury Member
                          </Label>
                          <Select
                            isMulti={true}
                            onChange={(e) => {
                              handleMultiJury(e);
                            }}
                            options={optionJurry}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">
                            Select Active Round
                          </Label>
                          <Select
                            onChange={(e) => {
                              handleRounds(e);
                            }}
                            options={roundOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
              {allEntries?.length > 0 && (
                <Card>
                  <CardBody>
                    <CardTitle>Select Entries</CardTitle>
                    <div>
                      {allEntries && selectedCategory === null ? (
                        <TableContainer
                          columns={columns}
                          data={allEntries}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                        />
                      ) : null}
                    </div>
                    <div>
                      {allEntries && selectedCategory !== null && (
                        <TableContainer
                          columns={columns}
                          data={handleData()}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {(EntriyFetching || juryFetching || categoryFetching) && <ScreenLoader />}
    </React.Fragment>
  );
}

export default AssignJury;
