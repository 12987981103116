import TableContainer from "@components/Common/TableContainer";
import React from "react";
import { Card, CardBody, Col, Row, UncontrolledAlert } from "reactstrap";
import GroupDetailsModal from "../../../pages/Admin/Master/Sub-group/GroupDetailsModal";
import DeleteModal from "../../Common/DeleteModal";
import FormHeader from "../../Common/FormHeader";
import AddSubgroup from "./AddSubgroup";
import UpdateSubgroup from "./UpdateSubgroup";

const Subgroup = (props) => {
  return (
    <div>
      {!props.showErrorAlert && (
        <UncontrolledAlert
          color="danger"
          className={`alert-dismissible fade ${
            props.showErrorAlert ? "show" : ""
          }`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-block-helper me-2"></i>
          {props.showErrorMsg}
        </UncontrolledAlert>
      )}
      <GroupDetailsModal
        isOpen={props.modal1}
        toggle={props.toggleViewModal}
        data={props.modalData}
        list={props.list}
      />

      <DeleteModal
        show={props.deleteModal}
        onDeleteClick={() => props.onDeleteClick()}
        onCloseClick={() => props.onCloseClick()}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <FormHeader
                    iconName={"bx-sitemap"}
                    heading={`Sub-groups ${
                      props.subGroupList?.length > 0
                        ? `(${props.subGroupList?.length})`
                        : " "
                    }`}
                    buttonName={"Add New Sub-group"}
                    onButtonClick={() => props.onClick()}
                    pageLoading={
                      props.isLoadingSubGroup || props.isFetchingSubGroup
                    }
                    hasButton={true}
                  />
                  {/* <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="d-flex align-items-center mb-3">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                          <i className="bx bx-sitemap text-primary display-7" />
                        </span>
                      </div>
                      <h3 className="font-size-25 mb-0">
                        Sub-groups{' '}
                        {props.subGroupList?.length > 0 &&
                          `(${props.subGroupList?.length})`}
                      </h3>
                      {(props.isLoadingSubGroup ||
                        props.isFetchingSubGroup) && (
                        <span>
                          <Spinner
                            style={{
                              height: '25px',
                              width: '25px',
                              marginLeft: '10px',
                            }}
                            color="primary"
                          />
                        </span>
                      )}
                    </div>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-sm "
                      onClick={() => props.onClick()}
                    >
                      Add New Sub-group
                    </Button>
                  </div> */}

                  {props.subGroupList ? (
                    <TableContainer
                      columns={props.columns}
                      data={props.subGroupList}
                      isGlobalFilter={true}
                      customPageSize={10}
                    />
                  ) : null}
                </CardBody>
              </Card>
              {props.isFetched && props.subGroupList === null && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="text-primary">No data Found</h4>
                </div>
              )}
            </Col>
          </Row>
          <AddSubgroup
            validation={props.validation}
            modal={props.modal}
            toggle={props.toggle}
            isLoadingAddSubGroup={props.isLoadingAddSubGroup}
            onChange={(e) => props.onChange(e)}
            options={props.options}
          />
          <UpdateSubgroup
            formik={props.formik}
            updateModal={props.updateModal}
            updateToggle={props.updateToggle}
            isLoadingUpdateSubGroup={props.isLoadingUpdateSubGroup}
            onChangeUpdate={(e) => props.onChangeUpdate(e)}
            options={props.options}
            updateSubGroup={props.updateSubGroup}
          />
        </div>
      </div>
    </div>
  );
};

export default Subgroup;
