import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EntryDetails from "../../components/Common/EntryDeatils";
import { FetchData, PostQuery } from "../../hooks/api/useApi";
import { getFromStorage } from "../../utils/storage";

const ViewEntryDetails = () => {
  const getType = getFromStorage("type");
  const history = useHistory();
  const location = useLocation();
  const [imageModal, setImageModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [scoreModal, setScoreModal] = useState(false);
  const [score, setScore] = useState(null);
  const [updateScore, setUpdateScore] = useState(null);

  const getUserName = getFromStorage("userName");

  const RoundOneScoreOption = [
    {
      options: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
      ],
    },
  ];

  // const getId = location?.search.slice(4, 7);
  const getAssignId = location?.search.split("&");
  const getId = getAssignId[0]?.split("=");
  const getAssignIdTwo = getAssignId[1]?.split("=");
  const [error, setError] = useState("");

  const handleOpenImage = (elem) => {
    setImageModal(true);
    setModalData(elem);
  };
  const actions = {
    select: (data) => {
      const list = data.data;
      return list;
    },
    onSuccess: (data) => {
      if (data?.status) {
        const scoreValue =
          RoundOneScoreOption &&
          RoundOneScoreOption[0].options.find(
            (item) =>
              Number(item.value) ===
              data?.responseData?.entry_basic_detail?.score_round1
          );
        setUpdateScore(scoreValue);
      } else {
        setError(data?.errors?.[0]);
      }
    },
    // enabled:false
  };

  const {
    data: juryDetails,
    refetch,
    isLoading,
    isFetching,
  } = FetchData(
    "entrants-details",
    `Jury/get-entry-detail-by-id-jury?EntryId=${getId[1]}&AssignJuryid=${getAssignIdTwo[1]}`,
    actions
  );
  const updateActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        setScoreModal(!scoreModal);
        refetch();
      }
    },
  };
  const { mutate: updateEntryScore, isLoading: isLoadingUpdateScore } =
    PostQuery("/Jury/update-entry-score", "post", updateActions);
  const toggleViewModal = () => setImageModal(!imageModal);
  const toggleScoreModal1 = () => setScoreModal(!scoreModal);

  const handleChnageScore = (e) => {
    setScore(e.value);
  };

  const handleScoreUpdate = () => {
    let data = {
      EntryId: Number(getId[1]),
      Score: Number(score),
      jury_assign_id: Number(juryDetails?.responseData?.jury_assign_id),
    };
    updateEntryScore(data);
  };

  useEffect(() => {
    refetch();
  }, [location.search]);

  const goToNextEntry = ()=>{
    // window.location.reload();
    const findIndex = location.state.entries?.findIndex((elem)=> elem?.entry_id == getId[1])
    const getEntryData = location.state.entries[findIndex + 1]
    console.log("goToNextEntry", getEntryData);
    history.push({
      pathname: "/ViewEntryDetails",
      search: `id=${getEntryData.entry_id}&assignId=${getEntryData?.entryjuryassignid}`,
      state:{"entries": location.state.entries, "url": getFromStorage("url") }
    });
  }

  const goToPreviousEntry = ()=>{
    // window.location.reload();
    const findIndex = location.state.entries?.findIndex((elem)=> elem?.entry_id == getId[1])
    const getEntryData = location.state.entries[findIndex - 1]
    history.push({
      pathname: "/ViewEntryDetails",
      search: `id=${getEntryData.entry_id}&assignId=${getEntryData?.entryjuryassignid}`,
      state:{"entries": location.state.entries, "url": getFromStorage("url")}
    });
  }

  console.log("location", location.state.entries?.findIndex((elem)=> elem?.entry_id == getId[1]), location.state);

  return (
    <React.Fragment>
      <EntryDetails
        note={"PLEASE NOTE: 1 is the lowest score & 10 is the highest *"}
        getUserName={getUserName}
        imageModal={imageModal}
        toggleViewModal={toggleViewModal}
        modalData={modalData}
        scoreModal={scoreModal}
        toggleScoreModal1={toggleScoreModal1}
        setScore={setScore}
        score={score}
        handleChnageScore={handleChnageScore}
        scoreOption={RoundOneScoreOption}
        handleScoreUpdate={handleScoreUpdate}
        isLoadingUpdateScore={isLoadingUpdateScore}
        updateScore={updateScore}
        entryDetails={juryDetails?.responseData}
        isLoading={isLoading}
        isFetching={isFetching}
        getType={getType}
        handleOpenImage={handleOpenImage}
        error={error}
        goToPreviousEntry={goToPreviousEntry}
        goToNextEntry={goToNextEntry}
        disabledNextButton={(location.state.entries?.findIndex((elem)=> elem?.entry_id == getId[1]) + 1) == location.state.entries?.length ? true : false}
        disabledPrevButton={location.state.entries?.findIndex((elem)=> elem?.entry_id == getId[1]) == 0 ? true : false}
      />
    </React.Fragment>
  );
};

export default ViewEntryDetails;
