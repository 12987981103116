import React from 'react';
import { getFromStorage } from '../../utils/storage';
import AdminProfile from '../Admin/Profile/Index';
import AuditorProfile from '../Audit/Profile';
import JuryProfile from '../Jury/Profile';
import UserProfile from '../User/Profile';

function Profile() {
  const userType = getFromStorage('type');

  if (userType === 'Admin') {
    return <AdminProfile />;
  } else if (userType === 'Jury') {
    return <JuryProfile />;
  } else if (userType === 'User') {
    return <UserProfile />;
  } else {
    return <AuditorProfile />;
  }
}

export default Profile;
