import { FetchData } from '@hooks/api/useApi';
import React from 'react';
import { useLocation } from 'react-router-dom';
import ApprovalSuccess from '../../../components/Approval/ApprovalSuccess';

const ClientApprove = () => {
  const location = useLocation();
  const entryId = location?.pathname.slice(25);
  const action = {
    enabeld: false,
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
  };

  const { data: ApprovalData, isLoading } = FetchData(
    'approval-data',
    `/Admin/client-approval/?id=${entryId}`,
    action
  );
  return (
    <>
      <ApprovalSuccess />
    </>
  );
};

export default ClientApprove;
