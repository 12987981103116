import React from "react";
import { Spinner } from "reactstrap";

function ScreenLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.2)",
        zIndex:"9999"
      }}
    >
      <Spinner
        style={{
          height: "40px",
          width: "40px",
          marginLeft: "10px",
        }}
        color="primary"
      />
    </div>
  );
}

export default ScreenLoader;
