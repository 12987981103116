import TableContainer from "@components/Common/TableContainer";
import { FetchData, PostQuery } from "@hooks/api/useApi";
import classNames from "classnames";
import * as moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledAlert,
  UncontrolledTooltip,
} from "reactstrap";
import FormHeader from "../../../components/Common/FormHeader";
import ScreenLoader from "../../../components/Common/ScreenLoader";

function Index() {
  const history = useHistory();
  const [entrentData, setEntrantData] = useState([]);
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabName, setactiveTabName] = useState();
  const [regions, setRegions] = useState([]);
  const [sucessMsg, setSucessMsg] = useState(false);
  const [selectFilterEntrantStatus, setSelectFilterEntrantStatus] =
    useState(null);
  const [selectFilterEntryStatus, setSelectFilterEntryStatus] = useState(null);

  const entriesStatus = [
    {
      options: [
        { label: "Incomplete", value: "0" },
        { label: "Submitted", value: "1" },
      ],
    },
  ];
  const entrantStatus = [
    {
      options: [
        { label: "Reject", value: "Reject" },
        { label: "Pending", value: "Pending" },
        { label: "Approved", value: "Approved" },
      ],
    },
  ];

  const actions = {
    select: (data) => {
      const list = data.data;
      return list;
    },
    onSuccess :(data)=>{
      // console.log("data.data", data);
      if (data?.status) {
        setRegions([...new Set(data?.responseData?.map((item) => item.region_Id))].filter(regionId => regionId !== 0));
        setactiveTabName([...new Set(data?.responseData?.map((item) => item.region_Id))].filter(regionId => regionId !== 0)[0]);
      }
    }
  };
  
  const {
    data: entrantList,
    isFetching,
    isLoading,
    isFetched,
  } = FetchData("entrants", "Admin/get-all-entrant", actions);

  const statusAction = {
    onSuccess: (data) => {
      if (data.data.status) {
        setSucessMsg(true);
      }
    },
  };

  console.log("regions", regions)
  const { mutate, isLoading: loadingResend } = PostQuery(
    "Admin/resend-password-to-user",
    "post",
    statusAction
  );

  const handleResetPassword = (e, email) => {
    e.preventDefault();
    const Approvestatus = {
      email: email,
    };
    mutate(Approvestatus);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        filterable: true,
        disableFilters: true,
        disableSortBy: true,
        Cell: (row) => {
          return <p>{row.row.index + 1}</p>;
        },
      },
      {
        Header: "Date",
        accessor: "created_date",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>
              {moment(cellProps.row.original?.created_date).format(
                "DD/MM/YYYY"
              )}
            </p>
          );
        },
      },
      {
        Header: "Name",
        accessor: "entrant_name",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Email ID ",
        accessor: "email",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Phone Number",
        accessor: "mobie_number",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Created Entries",
        accessor: "number_of_entries",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <p> {cellProps.value}</p>;
        },
      },
      {
        Header: "Incomplete Entries",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>
              {cellProps.cell.row.original.number_of_entries === 0
                ? "---"
                : cellProps.cell.row.original.form_status.incomplete > 0
                ? `${cellProps.cell.row.original.form_status.incomplete}`
                : cellProps.cell.row.original.form_status.incomplete === 0 &&
                  cellProps.cell.row.original.form_status.submitted > 0
                ? "---"
                : null}{" "}
            </p>
          );
        },
      },
      {
        Header: "Submited Entries",
        accessor: "",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <p>
              {cellProps.cell.row.original.number_of_entries === 0
                ? "---"
                : cellProps.cell.row.original.form_status.incomplete > 0
                ? `${cellProps.cell.row.original.form_status.submitted}`
                : cellProps.cell.row.original.form_status.incomplete === 0 &&
                  cellProps.cell.row.original.form_status.submitted > 0
                ? cellProps.cell.row.original.form_status.submitted
                : null}{" "}
            </p>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-12 badge-soft-" +
                (cellProps.value === "Approved"
                  ? "success"
                  : cellProps.value === "Reject"
                  ? "danger"
                  : "warning")
              }
              pill
            >
              {cellProps.value === "Approved"
                ? "Approved"
                : cellProps.value === "Reject"
                ? "Reject"
                : "Pending"}
            </Badge>
          );
        },
      },
      {
        Header: "Details",
        accessor: "view",
        disableFilters: true,
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() =>
                  history.push({
                    pathname: "/entrants-details",
                    search: `userId=${cellProps.cell.row.original.registration_id}`,
                  })
                }
                style={{ marginBottom: "4px", marginRight: "8px" }}
              >
                View Details
              </Button>
              {cellProps.cell.row.original.status === "Approved" ? (
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded "
                  onClick={(e) =>
                    handleResetPassword(e, cellProps.cell.row.original.email)
                  }
                >
                  Resend Password
                </Button>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "",
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <div
                className="text-success"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push({
                    pathname: "/entrants-edit-form",
                    search: `userId=${cellProps.row.original.registration_id}`,
                  });

                  // handleUpdateEntrants(data);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  function handleEntryStatus(item) {
    setSelectFilterEntryStatus(item);
  }
  function handleEntrantStatus(item) {
    setSelectFilterEntrantStatus(item);
  }

  const [listEntries, setListEntries] = useState(null);
  let list_Of_Entries = entrantList?.responseData?.filter(
    (item) => item.region_Id === activeTabName
  );

  useEffect(() => {
    if (selectFilterEntryStatus?.label === "Incomplete") {
      let entrystatus = list_Of_Entries?.filter((item) => {
        return item?.number_of_entries > 0 && item?.form_status?.incomplete > 0;
      });
      if (entrystatus.length === 0) {
        entrystatus = list_Of_Entries;
      }
      list_Of_Entries = entrystatus;
      setListEntries(entrystatus);
    }
    if (selectFilterEntryStatus?.label === "Submitted") {
      let entrystatus = list_Of_Entries?.filter((item) => {
        return item?.number_of_entries > 0 && item?.form_status?.submitted > 0;
      });
      if (entrystatus.length === 0) {
        entrystatus = list_Of_Entries;
      }
      list_Of_Entries = entrystatus;
      setListEntries(entrystatus);
    }
    if (selectFilterEntrantStatus) {
      let entrantstatus = list_Of_Entries?.filter((item) => {
        return item?.status === selectFilterEntrantStatus?.label;
      });
      if (entrantstatus.length === 0) {
        entrantstatus = list_Of_Entries;
      }
      list_Of_Entries = entrantstatus;
      setListEntries(entrantstatus);
    }
  }, [selectFilterEntrantStatus, selectFilterEntryStatus, list_Of_Entries]);

  const handleClearAll = () => {
    setSelectFilterEntryStatus(null);
    setSelectFilterEntrantStatus(null);
  };

  document.title = "Entrants | WOW Awards";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs={12}>
              {isFetched && entrantList?.responseData?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4 className="text-primary">No data Found</h4>
                </div>
              ) : (
                <Card>
                  <CardBody>
                    <FormHeader
                      iconName={"bx-folder"}
                      heading={` Entrants
                    ${
                      entrantList?.responseData?.length > 0 ? `(${entrantList?.responseData?.length})` : " "
                    }`}
                      pageLoading={isFetching || isLoading || loadingResend}
                      hasButton={false}
                    />
                     {regions.length > 1 ? (
                        <Nav
                          pills
                          className="icon-tab nav-justified"
                          role="tablist"
                          style={{ width: "45%" , margin:"20px 0"}}
                        >
                          {regions?.map((item, i) => {
                            return (
                              <NavItem key={i}>
                                <NavLink
                                  className={classNames({
                                    active: activeTab === i + 1,
                                  })}
                                  onClick={() => {
                                    setactiveTab(i + 1);
                                    setactiveTabName(item);
                                  }}
                                >
                                  {`${item ==  1 ? "Asia" : "Middle East"} (${
                                    entrantList?.responseData?.filter(
                                      (cat) => cat?.region_Id === item
                                    )?.length
                                  })`}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      ) : null}
                    <Row>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Col sm="5">
                          <Label className="form-label">Entry Status:</Label>{" "}
                          <Select
                            value={selectFilterEntryStatus}
                            // classNamePrefix="select2-selection"
                            onChange={(e) => {
                              handleEntryStatus(e);
                            }}
                            options={entriesStatus}
                          />
                        </Col>
                        <Col sm="5">
                          <Label className="form-label">
                            Registration Status:
                          </Label>{" "}
                          <Select
                            value={selectFilterEntrantStatus}
                            // value={props.selectFilterSubGroup}
                            // classNamePrefix="select2-selection"
                            onChange={(e) => {
                              handleEntrantStatus(e);
                            }}
                            options={entrantStatus}
                          />
                        </Col>

                        <Col sm="1">
                          <Button
                            outline
                            type="button"
                            style={{ padding: "8px", float: "right" }}
                            color="primary"
                            className="btn-sm mt-4 align-items-center"
                            onClick={handleClearAll}
                          >
                            Clear all
                          </Button>
                        </Col>
                      </div>
                    </Row>

                    <div>
                      {entrantList?.responseData &&
                      selectFilterEntrantStatus === null &&
                      selectFilterEntryStatus === null ? (
                        <TableContainer
                          columns={columns}
                          data={entrantList?.responseData?.filter(
                            (item) => item.region_Id === activeTabName
                          )}
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          download
                          entrantData
                        />
                      ) : null}
                      {entrantList &&
                      (selectFilterEntrantStatus !== null ||
                        selectFilterEntryStatus !== null) ? (
                        <TableContainer
                          columns={columns}
                          data={
                            listEntries?.length ? listEntries : list_Of_Entries
                          }
                          isGlobalFilter={true}
                          isAddOptions={false}
                          customPageSize={10}
                          // download
                        />
                      ) : null}
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {sucessMsg && (
            <UncontrolledAlert
              color="success"
              className={`alert-dismissible fade ${sucessMsg ? "show" : ""}`}
              role="alert"
              style={{
                position: "fixed",
                right: "10px",
                top: "12%",
                zIndex: "2000",
              }}
            >
              <i className="mdi mdi-check-all me-2"></i>
              Password Resend To The User
            </UncontrolledAlert>
          )}

          {(loadingResend || isFetching) && <ScreenLoader />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Index;
