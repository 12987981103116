import profile from "@assets/images/profile-img.png";
import wowLogo from "@assets/images/wow-awards-logo.png";
import { PostQuery } from "@hooks/api/useApi";
import { saveToStorage } from "@utils/storage";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { InputField } from "../../components/Form/Input";
import { fetch_region, fetch_type } from "../../utils/emitter";

const Login = (props) => {
  const history = useHistory();
  const [loginError, setLoginError] = useState();

  const loginActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.push("/");
        saveToStorage("userEmail", data?.data?.responseData?.user_mail);
        saveToStorage("userName", data?.data?.responseData?.user_name);
        saveToStorage("token", data?.data?.responseData?.token);
        saveToStorage("refreshToken", data?.data?.responseData?.refresh_token);
        saveToStorage("type", data?.data?.responseData?.user_type);
        saveToStorage("region", data?.data?.responseData?.region_Id);
        fetch_type(data?.data?.responseData?.user_type);
        fetch_region(data?.data?.responseData?.region_Id);

      } else {
        setLoginError(data.data.errors[0]);
      }
    },
    onError: (error) => {
      setLoginError("Something went wrong!");
    },
  };

  const { mutate, isLoading } = PostQuery(
    "Authentication/log-in",
    "post",
    loginActions
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email is Invalid")
        .required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { email, password } = values;
      setLoginError("");
      mutate({ email, password });

      // resetForm();
    },
  });

  document.title = "Login | WOW Awards";

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft" style={{ backgroundColor: "#111" }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5
                          style={{ fontWeight: "bold" }}
                          className="text-primary"
                        >
                          Welcome!
                        </h5>
                        <p style={{ fontWeight: "bold" }}>
                          Sign in to continue to WOW Awards.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={wowLogo} alt="" height="25" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <InputField
                          label={"Email"}
                          name={"email"}
                          type={"email"}
                          placeholder={"Enter email"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                      </div>

                      <div className="mb-3">
                        <InputField
                          label={"Password"}
                          name={"password"}
                          type={"password"}
                          placeholder={"Enter password"}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                          validation={validation}
                        />
                      </div>

                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          )}
                          Log In
                        </button>
                      </div>
                      {loginError ? (
                        <div
                          style={{
                            color: "red",
                            textAlign: "center",
                            marginTop: "10px",
                          }}
                        >
                          {loginError}
                        </div>
                      ) : null}

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
