import * as moment from "moment";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const EventsDetailModal = (props) => {
  const { isOpen, toggle, data } = props;

  const region = [
    ...new Set(data?.event_categorydata?.map((item) => item.regionName)),
  ];
  const group = [
    ...new Set(data?.event_categorydata?.map((item) => item.groupName)),
  ];
  const subgroup = [
    ...new Set(data?.event_categorydata?.map((item) => item.subGroupName)),
  ];
  const category = [
    ...new Set(data?.event_categorydata?.map((item) => item.categoryName)),
  ];

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Award Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Status: <span className="text-primary">{data?.event_status}</span>
          </p>
          <p className="mb-2">
            Round: <span className="text-primary">{data?.round}</span>
          </p>
          <p className="mb-2">
            Name: <span className="text-primary">{data?.event_name}</span>
          </p>
          <p className="mb-4">
            Description:{" "}
            <span className="text-primary">{data?.event_description}</span>
          </p>
          <p className="mb-2">
            Registration Start & End Date:{" "}
            <span className="text-primary">
              {moment(data?.registration_start_date).format("DD/MM/YYY")} to{" "}
              {moment(data?.registration_end_date).format("DD/MM/YYY")}
            </span>
          </p>
          <p className="mb-2">
            Award Start & End Date:{" "}
            <span className="text-primary">
              {moment(data?.event_start_date).format("DD/MM/YYY")} to{" "}
              {moment(data?.event_end_date).format("DD/MM/YYY")}
            </span>
          </p>
          {data && data?.event_categorydata?.length > 0 && (
            <>
              <p className="mb-2">
                Region:{" "}
                <span className="text-primary">{region?.join(", ")}</span>
              </p>
              <p className="mb-2">
                Selected Groups:{" "}
                <span className="text-primary">{group?.join(", ")}</span>
              </p>
              <p className="mb-2">
                Selected Sub-groups:{" "}
                <span className="text-primary">{subgroup?.join(", ")}</span>
              </p>
              <p className="mb-2">
                Selected Categories:{" "}
                <span className="text-primary">{category?.join(", ")}</span>
              </p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default EventsDetailModal;
