import profileImg from "@assets/images/profile-img.png";
import React from "react";
import { Card, Col, Row } from "reactstrap";

const WelcomeJury = (props) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className=" bg-soft" style={{ backgroundColor: "#111" }}>
          <Row>
            <Col xs="10">
              <div className="text-primary p-3">
                {props.getUserName && (
                  <h3 style={{ fontWeight: "bold" }} className="text-primary">
                    Welcome {props.getUserName} !
                  </h3>
                )}
                {props.data?.length > 0 && (
                  <>
                    <h5
                      style={{ color: "#fff", fontWeight: "bold" }}
                      className="text-primary"
                    >
                      {props.note}{" "}
                    </h5>
                    <h5
                      style={{ color: "#fff", fontWeight: "bold" }}
                      className="text-primary"
                    >
                      Round {props.awardRound}{" "}
                    </h5>
                  </>
                )}
              </div>
            </Col>
            <Col xs="2" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
      </Card>
    </React.Fragment>
  );
};
export default WelcomeJury;
