import React from 'react';
import Select from 'react-select';
import {
  Button,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { InputField } from '../../Form/Input';

const UpdateSubgroup = (props) => {
  return (
    <div>
      <Modal scrollable isOpen={props.updateModal} toggle={props.updateToggle}>
        <ModalHeader toggle={props.updateToggle} tag="h4">
          {'Update Sub-Group'}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              props.formik.handleSubmit();
              return false;
            }}
          >
            <Row form>
              <Col className="col-12">
                <div style={{ textAlign: 'end' }}>
                  <div className="btn-group" role="group">
                    <input
                      onChange={(e) =>
                        props.formik.setFieldValue('status', e.target.value)
                      }
                      type="radio"
                      className="btn-check"
                      name="status"
                      id="btnradio5"
                      autoComplete="off"
                      value={true}
                      defaultChecked={props.formik.values.status ? true : false}
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btnradio5"
                    >
                      Publish
                    </label>

                    <input
                      onChange={(e) =>
                        props.formik.setFieldValue('status', e.target.value)
                      }
                      type="radio"
                      className="btn-check"
                      name="status"
                      id="btnradio6"
                      autoComplete="off"
                      value={false}
                      defaultChecked={
                        !props.formik.values.status ? true : false
                      }
                    />
                    <label
                      className="btn btn-outline-primary"
                      htmlFor="btnradio6"
                    >
                      Draft
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <Label className="form-label">Select Group:</Label>
                  <Select
                    name="group"
                    type="select"
                    value={props.updateSubGroup}
                    onChange={(e) => props.onChangeUpdate(e)}
                    options={props.options}
                    classNamePrefix="select2-selection"
                  />
                </div>
                <div className="mb-3">
                  <Label className="form-label">Subgroup Name</Label>
                  <InputField
                    label={'Group Name'}
                    name={'title'}
                    type={'text'}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}
                    value={props.formik.values.title || ''}
                    invalid={
                      props.formik.touched.title && props.formik.errors.title
                        ? true
                        : false
                    }
                    validation={props.formik}
                  />
                </div>
                <div className="mb-4">
                  <InputField
                    label={'Description'}
                    name={'desc'}
                    type={'textarea'}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}
                    value={props.formik.values.desc || ''}
                    invalid={
                      props.formik.touched.desc && props.formik.errors.desc
                        ? true
                        : false
                    }
                    validation={props.formik}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    disabled={props.isLoadingUpdateSubGroup}
                  >
                    {props.isLoadingUpdateSubGroup && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateSubgroup;
