import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import * as Yup from "yup";
import ScreenLoader from "../../../components/Common/ScreenLoader";
import { FetchData, PostQuery } from "../../../hooks/api/useApi";

const optionGroup = [
  {
    options: [
      { label: "Dr.", value: "Dr." },
      { label: "Mr.", value: "Mr." },
      { label: "Mrs.", value: "Mrs." },
      { label: "Ms.", value: "Ms." },
    ],
  },
];

function Index() {
  document.title = "Register | WOW Awards";

  const [updateSalutation, setUpdateSalution] = useState(null);
  const [updateExecutiveSalutation, setUpdateExecutiveSalutation] =
    useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const getId = location?.search.slice(8);

  const entrantFormActions = {
    onSuccess: (data) => {
      if (data.data.status) {
        history.replace("/entrants");
      } else {
        setShowErrorAlert(false);
      }
    },
  };

  const {
    mutate: entrantData,
    isLoading: isEntrantLoading,
    isFetching: isEntrantFetching,
  } = PostQuery("Admin/upsert-registration-detail", "post", entrantFormActions);

  const actions = {
    select: (data) => {
      const list = data.data.responseData;
      return list;
    },
    onSuccess: (data) => {
      validation.setValues({
        entrant: data.general.entrant_name,
        userName: data.general.user_name,
        website: data.general.website,
        gst: data.general.gst_number,
        // salutation: data?.primary?.primary_salutation,
        firstName: data.primary.pcp_first_name,
        lastName: data.primary.pcp_last_name,
        designation: data.primary.pcp_designation,
        // secSalutation: data?.executive?.salutation_sr_executive,
        secFirstName:
          data.executive.first_name_sr_executive !== null
            ? data.executive.first_name_sr_executive
            : "",
        secLastName:
          data.executive.last_name_sr_executive !== null
            ? data.executive.last_name_sr_executive
            : "",
        secDesignation:
          data.executive.designation_sr_executive !== null
            ? data.executive.designation_sr_executive
            : "",
        secEmail:
          data.executive.email_id_sr_executive !== null
            ? data.executive.email_id_sr_executive
            : "",
        secMobile:
          data.executive.mobile_no_sr_executive !== null
            ? data.executive.mobile_no_sr_executive
            : "",
        mobile: data.primary.pcp_mobile_number,
        tel: data.primary.pcp_tel_number,
        ad1: data.primary.pcp_address_line1,
        ad2: data.primary.pcp_address_line2,
        ad3: data.primary.pcp_address_line3,
        country: data.primary.pcp_country,
        state: data.primary.pcp_state,
        city: data.primary.pcp_city,
        zip: data.primary.pcp_zip,
        offAdd1:
          data.official.address_line1_secondary !== null
            ? data.official.address_line1_secondary
            : "",
        offAdd2:
          data.official.address_line2_secondary !== null
            ? data.official.address_line2_secondary
            : "",
        offAdd3:
          data.official.address_line3_secondary !== null
            ? data.official.address_line3_secondary
            : "",
        offCountry:
          data.official.country_secondary !== null
            ? data.official.country_secondary
            : "",
        offState:
          data.official.state_secondary !== null
            ? data.official.state_secondary
            : "",
        offCity:
          data.official.city_secondary !== null
            ? data.official.city_secondary
            : "",
        offZip:
          data.official.zip_secondary !== null
            ? data.official.zip_secondary
            : "",
        telPhone1:
          data.official.tele_phoneno1_secondary !== null
            ? data.official.tele_phoneno1_secondary
            : "",
        telPhone2:
          data.official.tele_phoneno2_secondary !== null
            ? data.official.tele_phoneno2_secondary
            : "",
        isOfficialAd: data.is_official_address,
        isPrimarySame: data.is_person_same_as_execut,
      });
      const getSalutation =
        optionGroup &&
        optionGroup[0].options.find(
          (item) => item.value === data?.primary?.primary_salutation
        );
      setUpdateSalution(getSalutation);

      const getExecutiveSalutation =
        optionGroup &&
        optionGroup[0].options.find(
          (item) => item.value === data?.executive?.salutation_sr_executive
        );
      setUpdateExecutiveSalutation(getExecutiveSalutation);
    },
  };
  const {
    data: details,
    isLoading,
    isFetching,
  } = FetchData(
    "entrants-details",
    `Admin/get-entrant-by-id?id=${getId}`,
    actions
  );

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const gstPattern =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  const textOnly = /^[A-Za-z]+$/;

  const [mySalu, setMySalu] = useState(false);
  function handleSalution(e) {
    setUpdateSalution(e);
  }

  function handleExecutiveSalution(e) {
    setUpdateExecutiveSalutation(e);
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      entrant: "",
      userName: "",
      website: "",
      gst: "",
      salutation: {},
      firstName: "",
      lastName: "",
      designation: "",
      secSalutation: {},
      secFirstName: "",
      secLastName: "",
      secDesignation: "",
      secEmail: "",
      secMobile: "",
      mobile: "",
      tel: "",
      ad1: "",
      ad2: "",
      ad3: "",
      country: "",
      state: "",
      city: "",
      zip: "",
      offAdd1: "",
      offAdd2: "",
      offAdd3: "",
      offCountry: "",
      offState: "",
      offCity: "",
      offZip: "",
      telPhone1: "",
      telPhone2: "",
      isOfficialAd: null,
      isPrimarySame: null,
      // isAcceptingTerms: false,
    },

    validationSchema: Yup.object({
      entrant: Yup.string()
        .min(3, "Please Write Valid Entrant Name")
        .required("Please Enter Entrant Name"),
      userName: Yup.string()
        .email("Email is Invalid")
        .required("Please Enter User Name"),
      gst: Yup.string().matches(gstPattern, "GST number not match"),
      firstName: Yup.string()
        .min(3, "Please Write Valid First Name")
        .matches(textOnly, "Please enter only alphabets")
        .required("Please Enter Your First Name"),
      lastName: Yup.string()
        .min(3, "Please Write Valid Last Name")
        .matches(textOnly, "Please enter only alphabets")
        .required("Please Enter Your Last Name"),
      designation: Yup.string().required("Please Enter Your Designation"),
      secFirstName: Yup.string()
        .min(3, "Please Write Valid First Name")
        .matches(textOnly, "Please enter only alphabets")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your First Name"),
        }),
      secLastName: Yup.string()
        .min(3, "Please Write Valid Last Name")
        .matches(textOnly, "Please enter only alphabets")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your Last Name"),
        }),
      // secFirstName: Yup.string().when("isPrimarySame", {
      //   is: false,
      //   then: Yup.string()
      //     .min(3, "Please Write Valid First Name")
      //     .matches(textOnly, "Please enter only alphabets")
      //     .required("Please Enter Your First Name"),
      // }),
      // secLastName: Yup.string().when("isPrimarySame", {
      //   is: false,
      //   then: Yup.string()
      //     .min(3, "Please Write Valid Last Name")
      //     .matches(textOnly, "Please enter only alphabets")
      //     .required("Please Enter Your Last Name"),
      // }),
      //  Yup.string()
      //   .min(3, 'Please Write Valid Last Name')
      //   .matches(textOnly, 'Please enter only alphabets')
      //   .when('isPrimarySame', {
      //     is: false,
      //     then: Yup.string().required('Please Enter Your Last Name'),
      //   }),
      secDesignation: Yup.string().when("isPrimarySame", {
        is: false,
        then: Yup.string().required("Please Enter Your Designation"),
      }),
      secEmail: Yup.string().when("isPrimarySame", {
        is: false,
        then: Yup.string().required("Please Enter Your Email"),
      }),
      secMobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .when("isPrimarySame", {
          is: false,
          then: Yup.string().required("Please Enter Your Mobile"),
        }),
      mobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .required("Please Enter Your Mobile"),
      isOfficialAd: Yup.boolean()
        .nullable()
        .required("Select one of the above button"),
      isPrimarySame: Yup.boolean()
        .nullable()
        .required("Select one of the above button"),
      ad1: Yup.string().required("Please Enter Address"),
      ad2: Yup.string().required("Please Enter Address"),
      country: Yup.string().required("Please Enter Your country"),
      state: Yup.string().required("Please Enter Your state"),
      city: Yup.string().required("Please Enter Your city"),
      zip: Yup.string()
        .max(6, "Invalid Zip Code")
        .required("Please Enter Your zip"),
      offAdd1: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Official address"),
      }),
      offAdd2: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Official address"),
      }),
      offCountry: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your country"),
      }),
      offState: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your state"),
      }),
      offCity: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your city"),
      }),
      offZip: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your zip"),
      }),
      telPhone1: Yup.string().when("isOfficialAd", {
        is: false,
        then: Yup.string().required("Please Enter Your telephone"),
      }),
    }),
    validate: (values) => {
      const errors = {};

      if (values.isPrimarySame === null) {
        errors.isPrimarySame = "Please select any one option";
      }
      if (values.isOfficialAd === null) {
        errors.isOfficialAd = "Please select any one option";
      }

      return errors;
    },
    onSubmit: (values) => {
      const getForm = {
        registration_id: Number(getId),
        entrant_name: values.entrant,
        username: values.userName,
        website: values.website,
        gst_number: values.gst,
        primary_contact_person_salutation: values.salutation
          ? values.salutation.value
          : "",
        pcp_first_name: values.firstName,
        pcp_last_name: values.lastName,
        pcp_designation: values.designation,
        pcp_mobile_number: values.mobile,
        pcp_tel_number: values.tel,
        pcp_address_line1: values.ad1,
        pcp_address_line2: values.ad2,
        pcp_address_line3: values.ad3,
        pcp_country: values.country,
        pcp_state: values.state,
        pcp_zip: values.zip,
        pcp_city: values.city,
        is_official_address: values.isOfficialAd,
        address_line1_secondary: values.offAdd1,
        address_line2_secondary: values.offAdd2,
        address_line3_secondary: values.offAdd3,
        country_secondary: values.offCountry,
        state_secondary: values.offState,
        city_secondary: values.offCity,
        zip_secondary: values.offZip,
        telephoneno1_secondary: values.telPhone1,
        telephoneno2_secondary: values.telPhone2,
        // isaccepted: values.isAcceptingTerms ? true : false,
        // reason: ,
        ispersonsameasexecut: values.isPrimarySame,
        salutation_sr_executive: values.updateExecutiveSalutation
          ? values.updateExecutiveSalutation.value
          : "",
        firstname_sr_executive: values.secFirstName,
        lastname_sr_executive: values.secLastName,
        designation_sr_executive: values.secDesignation,
        emailId_sr_executive: values.secEmail,
        mobileNo_sr_executive: values.secMobile,
      };
      // mutate(getForm);
      entrantData(getForm);
    },
  });

  const handleCheckTerms = (e) => {
    if (e.target.checked) {
      validation.setFieldValue("isAcceptingTerms", e.target.checked);
    } else {
      validation.setFieldValue("isAcceptingTerms", e.target.checked);
    }
  };

  const handleSameContact = (e) => {
    if (e.target.value === "yes") {
      validation.setFieldValue("isPrimarySame", true);
    } else {
      validation.setFieldValue("isPrimarySame", false);
    }
  };
  const handleOfficialAd = (e) => {
    if (e.target.value === "yes") {
      validation.setFieldValue("isOfficialAd", true);
    } else {
      validation.setFieldValue("isOfficialAd", false);
    }
  };

  useEffect(() => {
    validation.setFieldValue("salutation", updateSalutation);
    validation.setFieldValue(
      "updateExecutiveSalutation",
      updateExecutiveSalutation
    );
  }, [updateSalutation, updateExecutiveSalutation]);

  return (
    <React.Fragment>
      {/* {!showSuccessAlert && (
        <UncontrolledAlert
          color="success"
          className={`alert-dismissible fade ${showSuccessAlert ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-check-all me-2"></i>Registeration form has been
          submitted Successfully!
        </UncontrolledAlert>
      )} */}
      {!showErrorAlert && (
        <UncontrolledAlert
          color="warning"
          className={`alert-dismissible fade ${showErrorAlert ? "show" : ""}`}
          role="alert"
          style={{
            position: "fixed",
            right: "10px",
            top: "12%",
            zIndex: "2000",
          }}
        >
          <i className="mdi mdi-check-all me-2"></i>Something went wrong!
        </UncontrolledAlert>
      )}
      <div className="page-content">
        <Container fluid>
          <Row>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar-xs me-3 ">
                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-22">
                            <i className="bx bx-food-menu text-primary display-7" />
                          </span>
                        </div>
                        <h3 className="font-size-25 mb-0">
                          Edit Entrant Form : {validation.values.entrant}{" "}
                        </h3>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <Label className="entrant">
                                Entrant Name (Organisation, Agency, Venue,
                                Vendor Co.)*
                              </Label>
                              <Input
                                // id="entrant"
                                disabled
                                name="entrant"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.entrant || ""}
                                invalid={
                                  validation.touched.entrant &&
                                  validation.errors.entrant
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.entrant &&
                              validation.errors.entrant ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.entrant}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <Label htmlFor="userName">
                                Username (Email ID)
                              </Label>
                              <Input
                                id="userName"
                                name="userName"
                                type="email"
                                disabled
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.userName || ""}
                                invalid={
                                  validation.touched.userName &&
                                  validation.errors.userName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.userName &&
                              validation.errors.userName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.userName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <Label htmlFor="website">Website</Label>
                              <Input
                                id="website"
                                name="website"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                value={validation.values.website || ""}
                              />
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <Label htmlFor="gst">
                                GST No. (For Indian Companies)
                              </Label>
                              <Input
                                id="gst"
                                name="gst"
                                type="text"
                                disabled
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.gst || ""}
                                invalid={
                                  validation.touched.gst &&
                                  validation.errors.gst
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.gst &&
                              validation.errors.gst ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gst}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      PRIMARY CONTACT PERSON
                    </CardTitle>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col sm="2">
                            <div className="mb-3">
                              <Label htmlFor="salutation">Salutation</Label>
                              <Select
                                value={updateSalutation}
                                type="select"
                                placeholder="select"
                                id="salutation"
                                options={optionGroup}
                                classNamePrefix="select2-selection"
                                name="salutation"
                                onChange={(e) => {
                                  handleSalution(e);
                                }}
                              />
                            </div>
                          </Col>
                          <Col sm="5">
                            <div className="mb-3">
                              <Label htmlFor="firstName">First Name</Label>
                              <Input
                                id="firstName"
                                name="firstName"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstName &&
                              validation.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="5">
                            <div className="mb-3">
                              <Label htmlFor="lastName">Last Name</Label>
                              <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastName &&
                              validation.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-3">
                          <Label htmlFor="designation">Designation</Label>
                          <Input
                            id="designation"
                            name="designation"
                            type="text"
                            className="form-control"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.designation || ""}
                            invalid={
                              validation.touched.designation &&
                              validation.errors.designation
                                ? true
                                : false
                            }
                          />
                          {validation.touched.designation &&
                          validation.errors.designation ? (
                            <FormFeedback type="invalid">
                              {validation.errors.designation}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <Row>
                          <Col sm="6">
                            <div className="mb-3">
                              <Label htmlFor="mobile">Mobile No.</Label>
                              <Input
                                id="mobile"
                                name="mobile"
                                type="tel"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile || ""}
                                invalid={
                                  validation.touched.mobile &&
                                  validation.errors.mobile
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.mobile &&
                              validation.errors.mobile ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.mobile}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="6">
                            <div className="mb-3">
                              <Label htmlFor="tel">Telephone No.</Label>
                              <Input
                                id="tel"
                                name="tel"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tel || ""}
                                invalid={
                                  validation.touched.tel &&
                                  validation.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tel &&
                              validation.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <div className="mb-3">
                              <Label htmlFor="ad1">Address Line 1</Label>
                              <Input
                                id="ad1"
                                name="ad1"
                                type="textarea"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ad1 || ""}
                                invalid={
                                  validation.touched.ad1 &&
                                  validation.errors.ad1
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.ad1 &&
                              validation.errors.ad1 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.ad1}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mb-3">
                              <Label htmlFor="ad2">Address Line 2</Label>
                              <Input
                                id="ad2"
                                name="ad2"
                                type="textarea"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ad2 || ""}
                                invalid={
                                  validation.touched.ad2 &&
                                  validation.errors.ad2
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.ad2 &&
                              validation.errors.ad2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.ad2}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mb-3">
                              <Label htmlFor="ad3">Address Line 3</Label>
                              <Input
                                id="ad3"
                                name="ad3"
                                type="textarea"
                                className="form-control"
                                onChange={validation.handleChange}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="3">
                            <div className="mb-3">
                              <Label htmlFor="country">Country</Label>
                              <Input
                                id="country"
                                name="country"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.country || ""}
                                invalid={
                                  validation.touched.country &&
                                  validation.errors.country
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.country &&
                              validation.errors.country ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.country}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="mb-3">
                              <Label htmlFor="state">
                                State / Province / Region
                              </Label>
                              <Input
                                id="state"
                                name="state"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.state || ""}
                                invalid={
                                  validation.touched.state &&
                                  validation.errors.state
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.state &&
                              validation.errors.state ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.state}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="mb-3">
                              <Label htmlFor="city">City</Label>
                              <Input
                                id="city"
                                name="city"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                invalid={
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="mb-3">
                              <Label htmlFor="zip">
                                Zip / Postal Code (if not applicable, please key
                                in N/A)
                              </Label>
                              <Input
                                id="zip"
                                name="zip"
                                type="text"
                                className="form-control"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.zip || ""}
                                invalid={
                                  validation.touched.zip &&
                                  validation.errors.zip
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.zip &&
                              validation.errors.zip ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.zip}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-3">
                          <Label htmlFor="official-add">
                            Is this the official address
                          </Label>
                          <div style={{ display: "flex" }}>
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isOfficialAd"
                                id="exampleRadios1"
                                value="yes"
                                onClick={handleOfficialAd}
                                defaultChecked={validation.values.isOfficialAd}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                Yes
                              </label>
                            </div>
                            <div
                              style={{ marginLeft: 20 }}
                              className="form-check"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="isOfficialAd"
                                id="exampleRadios2"
                                value="no"
                                onClick={handleOfficialAd}
                                defaultChecked={!validation.values.isOfficialAd}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios2"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {validation.errors.isOfficialAd ? (
                            <FormFeedback
                              type="invalid"
                              style={{
                                display: "flex",
                                marginTop: "-10px",
                                marginBottom: "10px",
                              }}
                            >
                              {validation.errors.isOfficialAd}
                            </FormFeedback>
                          ) : null}

                          {!validation.values.isOfficialAd &&
                            validation.values.isOfficialAd !== null && (
                              <>
                                <Row>
                                  <Col sm="4">
                                    <div className="mb-3">
                                      <Label htmlFor="secAdd1">
                                        Address Line 1
                                      </Label>
                                      <Input
                                        id="secAdd1"
                                        name="offAdd1"
                                        type="textarea"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.offAdd1 || ""}
                                        invalid={
                                          validation.touched.offAdd1 &&
                                          validation.errors.offAdd1
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.offAdd1 &&
                                      validation.errors.offAdd1 ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.offAdd1}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm="4">
                                    <div className="mb-3">
                                      <Label htmlFor="secAdd2">
                                        Address Line 2
                                      </Label>
                                      <Input
                                        id="secAdd2"
                                        name="offAdd2"
                                        type="textarea"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.offAdd2 || ""}
                                        invalid={
                                          validation.touched.offAdd2 &&
                                          validation.errors.offAdd2
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.offAdd2 &&
                                      validation.errors.offAdd2 ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.offAdd2}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm="4">
                                    <div className="mb-3">
                                      <Label htmlFor="secAdd3">
                                        Address Line 3
                                      </Label>
                                      <Input
                                        id="secAdd3"
                                        name="offAdd3"
                                        type="textarea"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="3">
                                    <div className="mb-3">
                                      <Label htmlFor="secCountry">
                                        Country
                                      </Label>
                                      <Input
                                        id="secCountry"
                                        name="offCountry"
                                        type="text"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.offCountry || ""
                                        }
                                        invalid={
                                          validation.touched.offCountry &&
                                          validation.errors.offCountry
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.offCountry &&
                                      validation.errors.offCountry ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.offCountry}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm="3">
                                    <div className="mb-3">
                                      <Label htmlFor="secState">
                                        State / Province / Region
                                      </Label>
                                      <Input
                                        id="secState"
                                        name="offState"
                                        type="text"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.offState || ""}
                                        invalid={
                                          validation.touched.offState &&
                                          validation.errors.offState
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.offState &&
                                      validation.errors.offState ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.offState}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm="3">
                                    <div className="mb-3">
                                      <Label htmlFor="secCity">City</Label>
                                      <Input
                                        id="secCity"
                                        name="offCity"
                                        type="text"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.offCity || ""}
                                        invalid={
                                          validation.touched.offCity &&
                                          validation.errors.offCity
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.offCity &&
                                      validation.errors.offCity ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.offCity}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm="3">
                                    <div className="mb-3">
                                      <Label htmlFor="seczip">
                                        Zip / Postal Code (if not applicable,
                                        please key in N/A)
                                      </Label>
                                      <Input
                                        id="seczip"
                                        name="offZip"
                                        type="text"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.offZip || ""}
                                        invalid={
                                          validation.touched.offZip &&
                                          validation.errors.offZip
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.offZip &&
                                      validation.errors.offZip ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.offZip}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label className="telPhone1">
                                        Telephone No. #1
                                      </Label>
                                      <Input
                                        // id="entrant"
                                        name="telPhone1"
                                        type="tel"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.telPhone1 || ""
                                        }
                                        invalid={
                                          validation.touched.telPhone1 &&
                                          validation.errors.telPhone1
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.telPhone1 &&
                                      validation.errors.telPhone1 ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.telPhone1}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col sm="6">
                                    <div className="mb-3">
                                      <Label htmlFor="telPhone2">
                                        Telephone No. #2
                                      </Label>
                                      <Input
                                        id="telPhone2"
                                        name="telPhone2"
                                        type="tel"
                                        className="form-control"
                                        onChange={validation.handleChange}
                                        value={
                                          validation.values.telPhone2 || ""
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-3">
                      SENIOR EXECUTIVE’S DETAILS (COMPANY OWNER, DIRECTOR,
                      PROPRIETOR, ETC.)
                    </CardTitle>
                    <div className="mb-0">
                      <Label htmlFor="executive-add">
                        Same as above mentioned Primary Contact Person*
                      </Label>
                      <div style={{ display: "flex" }}>
                        <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isPrimarySame"
                            id="isPrimarySame1"
                            value="yes"
                            onClick={handleSameContact}
                            defaultChecked={validation.values.isPrimarySame}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isPrimarySame1"
                          >
                            Yes
                          </label>
                        </div>
                        <div style={{ marginLeft: 20 }} className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isPrimarySame"
                            id="isPrimarySame2"
                            value="no"
                            onClick={handleSameContact}
                            defaultChecked={!validation.values.isPrimarySame}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="isPrimarySame2"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    {validation.errors.isPrimarySame ? (
                      <FormFeedback
                        type="invalid"
                        style={{
                          display: "flex",
                          marginTop: "-10px",
                          marginBottom: "10px",
                        }}
                      >
                        {validation.errors.isPrimarySame}
                      </FormFeedback>
                    ) : null}

                    {!validation.values.isPrimarySame &&
                      validation.values.isPrimarySame !== null && (
                        <>
                          <Row>
                            <Col sm="2">
                              <div className="mb-3">
                                <Label htmlFor="secSalutation">
                                  Salutation
                                </Label>
                                <Select
                                  options={optionGroup}
                                  classNamePrefix="select2-selection"
                                  name="secSalutation"
                                  value={updateExecutiveSalutation}
                                  type="select"
                                  id="secSalutation"
                                  onChange={(e) => {
                                    handleExecutiveSalution(e);
                                  }}
                                />
                              </div>
                            </Col>
                            <Col sm="5">
                              <div className="mb-3">
                                <Label htmlFor="firstName">First Name</Label>
                                <Input
                                  id="firstName"
                                  name="secFirstName"
                                  type="text"
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.secFirstName || ""}
                                  invalid={
                                    validation.touched.secFirstName &&
                                    validation.errors.secFirstName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.secFirstName &&
                                validation.errors.secFirstName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.secFirstName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm="5">
                              <div className="mb-3">
                                <Label htmlFor="lastName">Last Name</Label>
                                <Input
                                  id="lastName"
                                  name="secLastName"
                                  type="text"
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.secLastName || ""}
                                  invalid={
                                    validation.touched.secLastName &&
                                    validation.errors.secLastName
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.secLastName &&
                                validation.errors.secLastName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.secLastName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="4">
                              <div className="mb-3">
                                <Label htmlFor="designation">Designation</Label>
                                <Input
                                  id="designation"
                                  name="secDesignation"
                                  type="text"
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.secDesignation || ""}
                                  invalid={
                                    validation.touched.secDesignation &&
                                    validation.errors.secDesignation
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.secDesignation &&
                                validation.errors.secDesignation ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.secDesignation}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm="4">
                              <div className="mb-3">
                                <Label htmlFor="secEmail">Email</Label>
                                <Input
                                  id="secEmail"
                                  name="secEmail"
                                  type="email"
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.secEmail || ""}
                                  invalid={
                                    validation.touched.secEmail &&
                                    validation.errors.secEmail
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.secEmail &&
                                validation.errors.secEmail ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.secEmail}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col sm="4">
                              <div className="mb-3">
                                <Label htmlFor="secMobile">Mobile No.</Label>
                                <Input
                                  id="secMobile"
                                  name="secMobile"
                                  type="tel"
                                  className="form-control"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.secMobile || ""}
                                  invalid={
                                    validation.touched.secMobile &&
                                    validation.errors.secMobile
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.secMobile &&
                                validation.errors.secMobile ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.secMobile}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                  </CardBody>
                </Card>
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{ float: "right" }}
                >
                  <Button
                    type="submit"
                    color="primary"
                    className="btn "
                    // disabled={isLoading}
                  >
                    {isLoading && (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    )}
                    Update
                  </Button>
                </div>
                {/* <Card>
                  <CardBody>
                    <div
                      style={{
                        float: "right",
                      }}
                    >
                      <div className="form-check mb-3">
                        <Label
                          className="form-check-label"
                          htmlFor="isAcceptingTerms"
                        >
                          {" "}
                          I accept the Terms and Conditions
                        </Label>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="isAcceptingTerms"
                          name="isAcceptingTerms"
                          onChange={handleCheckTerms}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.isAcceptingTerms &&
                            validation.errors.isAcceptingTerms
                              ? true
                              : false
                          }
                          checked={validation.values.isAcceptingTerms}
                        />

                        {validation.errors.isAcceptingTerms && (
                          <FormFeedback type="invalid">
                            {validation.errors.isAcceptingTerms}
                          </FormFeedback>
                        )}
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn "
                          // disabled={isLoading}
                        >
                          {isLoading && (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          )}
                          Submit
                        </Button>
                      </div>
                    </div>
                  </CardBody>
                </Card> */}
              </Col>
            </Form>
          </Row>
        </Container>
        {(isFetching || isEntrantLoading || isEntrantFetching) && (
          <ScreenLoader />
        )}
      </div>
    </React.Fragment>
  );
}

export default Index;
